import { Typography } from 'components/Atoms/Typography'

export interface IProps {
  label: string
  text?: string | string[] | React.ReactNode
  addClass?: string
}

export const LabelTypography: React.FC<IProps> = ({ addClass, label, text }) => (
  <div className={addClass}>
    <p className='mb-1 block text-sm font-medium text-gray-700'>{label}</p>
    <Typography size='base' weight='medium' className='whitespace-normal'>
      {text
        ? Array.isArray(text)
          ? text.map((item) => <span key={item}>{item}</span>)
          : text
        : '-'}
    </Typography>
  </div>
)
