import * as yup from 'yup'

export const createTrainingSchema = yup.object({
  name: yup
    .string()
    .required('Nazwa szkolenia jest wymagana')
    .min(3, 'Nazwa szkolenia musi mieć co najmniej 3 znaki'),
  dateStart: yup.string().required('Data rozpoczęcia jest wymagana'),
  hourStart: yup.string().required('Godzina rozpoczęcia jest wymagana'),
  trainerName: yup
    .string()
    .required('Imię i nazwisko prowadzącego jest wymagane')
    .min(3, 'Imię i nazwisko prowadzącego musi mieć co najmniej 3 znaki'),
  type: yup.string().required('Tryb szkolenia jest wymagany'),
  location: yup.string().when('type', {
    is: 'on-site',
    then: (schema) => schema.required('Miejsce szkolenia jest wymagane dla trybu stacjonarnego'),
    otherwise: (schema) => schema.optional(),
  }),
  url: yup.string().when('type', {
    is: 'online',
    then: (schema) => schema.required('URL jest wymagany dla trybu online'),
    otherwise: (schema) => schema.optional(),
  }),
  locationDetails: yup.string().required('Szczegóły lokalizacji są wymagane'),
  description: yup
    .string()
    .required('Opis szkolenia jest wymagany')
    .min(10, 'Opis szkolenia musi mieć co najmniej 10 znaków'),
  files: yup.array().of(yup.string()).optional(),
})
