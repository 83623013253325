interface IProps {
  data: {
    id: string
    title: string
  }[]
  defaultChecked: string
  label?: string
  onChange: (value: any) => void
}

export const RadioGroup = ({ data, defaultChecked, label, onChange }: IProps) => {
  return (
    <div>
      <label className='text-base font-bold font-eee text-gray-900'>{label}</label>
      <fieldset className='mt-4'>
        <div className='flex items-center'>
          {data.map((item) => (
            <div key={item.id} className='mr-5 flex items-center'>
              <input
                id={item.id}
                name='notification-method'
                type='radio'
                defaultChecked={item.id === defaultChecked}
                value={item.id}
                onChange={onChange}
                className='h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-600'
              />
              <label
                htmlFor={item.id}
                className='ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-900'
              >
                {item.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}
