import { createSlice } from '@reduxjs/toolkit'

import { changeContexts, fetchUserContexts, fetchUserData } from './authActions'

export type AuthContext = {
  role: string
  unit: { name: string; id: string } | null
}

export type AuthState = {
  loading: boolean
  userInfo: {
    id: string
    email: string
    context: AuthContext
    firstName: string
    lastName: string
  } | null
  userToken: string | null
  availableContexts: AuthContext[] | null
  error: any
  success: boolean
  contextsLoading: boolean
  contextsError: any
  contextsSuccess: boolean
}

const initialState: AuthState = {
  loading: false,
  userInfo: null,
  userToken: null,
  availableContexts: null,
  error: null,
  success: false,
  contextsLoading: false,
  contextsError: null,
  contextsSuccess: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.userToken = action.payload
    },
  },
  extraReducers(builder) {
    // login user
    builder.addCase(fetchUserData.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchUserData.fulfilled, (state, action: any) => {
      state.userInfo = action.payload
    })
    builder.addCase(fetchUserData.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
    builder.addCase(fetchUserContexts.pending, (state) => {
      state.contextsLoading = true
    })
    builder.addCase(fetchUserContexts.fulfilled, (state, action) => {
      state.availableContexts = action.payload
      state.contextsLoading = false
      state.contextsSuccess = true
      state.contextsError = null
    })
    builder.addCase(fetchUserContexts.rejected, (state) => {
      state.contextsLoading = false
      state.contextsSuccess = false
      state.contextsError = true
    })
    builder.addCase(changeContexts.fulfilled, (state, action) => {
      const newToken = action.payload.newToken
      state.userToken = newToken
    })
  },
})

export const { setAuthToken } = authSlice.actions

export const getUserToken = (state: any) => state.authReducer.userToken
export const getUserInfo = (state: any) => state.authReducer.userInfo

export default authSlice.reducer
