import { useState, useEffect } from 'react'

import { Button } from 'components/Atoms/Button'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { IOption } from 'types/form'
import { ILegalHook } from 'types/legal'

import LegalSearchForm from './LegalSearchForm'

type Props = {
  control: any
  register: any
  legalData: ILegalHook
  defaultOption?: IOption
  isRegion?: boolean | undefined
}

export const LegalSearch = ({ control, register, legalData, defaultOption, isRegion }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>(undefined)
  const { legal, unit } = legalData

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  // Function to find the document in the legal list
  const findDocumentInList = (docValue: string | undefined) => {
    if (docValue && legal.list && legal.list.length > 0) {
      const found = legal.list.find((item: IOption) => item.value === docValue)
      if (found) {
        return found
      }
    }
    return undefined
  }

  // Update selected option when legal list is loaded or defaultOption changes
  useEffect(() => {
    // First, try to use the defaultOption if provided
    if (defaultOption && defaultOption.value) {
      // Check if the value is a string or an object
      const optionValue =
        typeof defaultOption.value === 'object' && 'id' in defaultOption.value
          ? (defaultOption.value as { id: string }).id // Extract ID if it's an object
          : defaultOption.value // Use as is if it's a string

      // Create a normalized option with proper value format
      const normalizedOption = {
        label:
          (typeof defaultOption.value === 'object' &&
          (defaultOption.value as { name?: string }).name
            ? (defaultOption.value as { name?: string }).name // Use name from object if available
            : defaultOption.label) || '', // Fall back to the original label
        value: optionValue,
      }

      setSelectedOption(normalizedOption as IOption)

      // Update the form register with the correct value
      const registerProps = register('document')
      if (registerProps.onChange) {
        registerProps.onChange({
          target: { name: 'document', value: optionValue },
        } as React.ChangeEvent<HTMLInputElement>)
      }
    } else {
      // If no defaultOption, try to find the document in legal list using the existing form value
      const registerProps = register('document')
      const docValue = registerProps.value

      const foundOption = findDocumentInList(docValue)
      if (foundOption) {
        setSelectedOption(foundOption)
      }
    }
  }, [legal.list, defaultOption, register])

  // Fetch the document details if we have a document ID but no option details
  useEffect(() => {
    const registerProps = register('document')
    const documentId = registerProps.value

    // If we have a document ID but no selectedOption, try to load it
    if (documentId && !selectedOption && unit) {
      legal.refetch()
    }
  }, [unit, selectedOption, register, legal])

  const initData = {
    name: '',
    identifier: '',
    dateEnactment: new Date(),
    dateEffective: new Date(),
    unit: unit,
  }

  // Handle option selection
  const handleOptionSelect = (option: IOption) => {
    setSelectedOption(option)

    // Update the document field with the ID string
    const registerProps = register('document')
    if (registerProps.onChange) {
      registerProps.onChange({
        target: { name: 'document', value: option.value },
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  // Handle reset (when search is cleared)
  const handleReset = () => {
    setSelectedOption(undefined)

    // Clear the document field
    const registerProps = register('document')
    if (registerProps.onChange) {
      registerProps.onChange({
        target: { name: 'document', value: '' },
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  if (!unit) {
    return <></>
  }

  return (
    <div className="w-full max-w-[299px]">
      <SearchAndFetchInput
        defaultOption={selectedOption}
        searchMore={legal.search}
        isLoading={legal.loading}
        name={'document'}
        label={'Wyszukaj identyfikator uchwały'}
        register={register}
        options={legal.list}
        control={control}
        onValueChange={handleOptionSelect}
        onReset={handleReset}
      >
        <Button
          disabled={!unit && !isRegion}
          className="my-2"
          type="button"
          onClick={openModal}
          label="Dodaj uchwałę"
        />
      </SearchAndFetchInput>

      <LegalSearchForm
        refetch={legal.refetch}
        isOpen={isOpen}
        data={initData}
        handleClose={closeModal}
      />
    </div>
  )
}

export default LegalSearch
