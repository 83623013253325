import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/20/solid'
import { BadgeIcon, EnvelopeClosedIcon, FileTextIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/shadcn/ui/button'
import { Card, CardHeader, CardTitle, CardContent } from 'components/shadcn/ui/card'
import { IListElement } from 'types/form'
import { ISectionClub } from 'types/section'

type Props = {
  data: ISectionClub
  showModalHandler: (id: IListElement) => void
  allowArchive: boolean
}

export const SectionBaseInfo = ({ data, allowArchive, showModalHandler }: Props) => {
  const navigate = useNavigate()
  const subtypePath = data?.subtype === 'club' ? 'club' : 'group'
  const isArchived = data?.archived ?? false

  const actionButtons = !isArchived
    ? [
        {
          label: 'Edytuj',
          handleClick: () => navigate(`/section/${subtypePath}/${data.id}/update`),
          variant: 'default' as const,
        },
        {
          label: 'Archiwizuj',
          variant: 'secondary' as const,
          handleClick: () => showModalHandler(data),
          disabled: !allowArchive,
        },
      ]
    : []

  return (
    // <Card label='Podgląd szczegółów ogniska' actionsButton={actionButtons}>
    //   <div className='grid grid-cols-4'>
    //     <LabelTypography label='Nazwa ogniska' text={data?.name} />
    //     <LabelTypography label='Telefon' text={data?.contact?.phone} />
    //     <LabelTypography label='E-mail kontaktowy' text={data?.contact?.email} />
    //   </div>
    //   {showSecondary && (
    //     <div className='my-5 grid grid-cols-4'>
    //       {data?.contact?.phoneSecondary && (
    //         <LabelTypography label='Telefon dodatkowy' text={data?.contact?.phoneSecondary} />
    //       )}
    //       {data?.contact?.emailSecondary && (
    //         <LabelTypography label='E-mail dodatkowy' text={data?.contact?.emailSecondary} />
    //       )}
    //     </div>
    //   )}
    //   <div className='my-5 grid grid-cols-4'>
    //     <LabelTypography label='Data rozpoczęcia kadencji' text='2020-04-05' />
    //     <LabelTypography label='Data zakończenia kadencji' text='2022-04-05' />
    //     <LabelTypography label='Nr uchwały' text={data?.document?.name} />
    //   </div>

    //   <LabelTypography label='Powiązane placówki' text={institutions} />
    // </Card>

    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle className="flex items-center gap-2">
            <BuildingOffice2Icon className="h-6 w-6" />
            {data.name}
          </CardTitle>
          <span className="text-sm leading-6">
            {
              // data.type may be group_eir for Sekcja and club for Ognisko
              data.subtype === 'group_eir' ? 'Sekcja' : 'Ognisko'
            }
          </span>
        </div>
      </CardHeader>
      <CardContent className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="space-y-2">
          <h3 className="font-eee text-lg font-bold">Informacje kontaktowe</h3>
          <div className="grid grid-cols-1 gap-2">
            {data.contact.email && (
              <div className="flex items-center gap-2">
                <EnvelopeClosedIcon className="h-4 w-4 min-w-4 text-gray-500" />
                <span>{data.contact.email}</span>
              </div>
            )}
            {data.contact.emailSecondary && (
              <div className="flex items-center gap-2">
                <EnvelopeClosedIcon className="h-4 w-4 min-w-4 text-gray-500" />
                <span>{data.contact.emailSecondary}</span>
              </div>
            )}
            {data.contact.phone && (
              <div className="flex items-center gap-2">
                <PhoneIcon className="h-4 w-4 min-w-4 text-gray-500" />
                <span>{data.contact.phone}</span>
              </div>
            )}
            {data.contact.phoneSecondary && (
              <div className="flex items-center gap-2">
                <PhoneIcon className="h-4 w-4 min-w-4 text-gray-500" />
                <span>{data.contact.phoneSecondary}</span>
              </div>
            )}
          </div>
        </div>

        {data.locationDescription && (
          <div className="space-y-2">
            <h3 className="font-eee text-lg font-bold">Lokalizacja</h3>
            <div className="flex gap-2">
              <MapPinIcon className="mt-1 h-4 w-4 min-w-4 text-gray-500" />
              <span>{data.locationDescription}</span>
            </div>
          </div>
        )}

        {data.document && (
          <div className="space-y-2">
            <h3 className="font-eee text-lg font-bold">Dokument</h3>
            <div className="flex items-center gap-2">
              <FileTextIcon className="h-4 w-4 min-w-4 text-gray-500" />
              <span>{data.document.name}</span>
            </div>
          </div>
        )}

        {data.institutions?.length > 0 && (
          <div className="space-y-2">
            <h3 className="font-eee text-lg font-bold">Placówki</h3>
            <div className="flex flex-wrap gap-2">
              {data.institutions.map((institution) => (
                <Button
                  key={institution.id}
                  variant="ghost"
                  className="flex items-center gap-2"
                  onClick={() => navigate(`/institution/${institution.id}`)}
                >
                  <BuildingOfficeIcon className="h-4 w-4 min-w-4 text-gray-500" />
                  <span>{institution.name}</span>
                </Button>
              ))}
            </div>
          </div>
        )}

        <div className="space-y-2 lg:col-span-2">
          <h3 className="font-eee text-lg font-bold">Informacje dodatkowe</h3>
          <div className="text-sm text-gray-500">Jednostka nadrzędna: {data.parent.name}</div>
        </div>

        {actionButtons.length > 0 && (
          <div className="mt-4 flex gap-2 lg:col-span-4">
            {actionButtons.map((button) => (
              <Button
                key={button.label}
                onClick={button.handleClick}
                disabled={button.disabled}
                variant={button.variant}
              >
                {button.label}
              </Button>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default SectionBaseInfo
