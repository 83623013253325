import React, { useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import FormField from 'components/FormField'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportTrainingErrors } from 'error-data/training'
import { addTrainigFile, createTraining } from 'fetchers/trainingFetchers'
import { createTrainingSchema } from 'schemas/trainingCreateSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption } from 'types/form'
import { toast } from 'sonner'

// Define allowed MIME types
const ALLOWED_MIME_TYPES = [
  'application/pdf',
  'application/x-pdf',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

// 5MB in bytes
const MAX_FILE_SIZE = 5 * 1024 * 1024

const TrainingCreate = () => {
  const [fileIsAdding, setFileIsAdding] = useState(false)
  const [file, setFile] = useState<File>()
  const [fileIds, setFileIds] = useState<string[]>([])
  const [finalFiles, setFinalFiles] = useState<IOption[]>([])
  const [fileNames, setFileNames] = useState<Record<string, string>>({}) // Store original file names
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isSending, setIsSending] = useState(false)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createTrainingSchema),
    defaultValues: {
      name: '',
      dateStart: '',
      hourStart: '',
      trainerName: '',
      type: '',
      location: '',
      url: '',
      locationDetails: '',
      description: '',
      files: [],
    },
  })

  const trainingType = watch('type')

  const mutation = useMutation({
    mutationFn: (data: any) => {
      const { redacted, status, ...formData } = data
      return createTraining(formData)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingCreate] })
      const trainingId = data.id
      queryClient.invalidateQueries({
        queryKey: [queryKeys.trainingDetails, trainingId],
      })
      navigate(`/training/${trainingId}/update`)
      setIsSending(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSending(false)

      if (error.response?.status === 403) {
        // Handle 403 errors silently without toasts
        toast.error('Brak uprawnień do wykonania tej akcji')
        return
      }

      const errorMessages = error.response?.data.errors

      if (errorMessages) {
        // Map errors to form fields
        error.response?.data.errors.forEach((item: any) => {
          // setError could be used here if needed
        })
      }

      mutationErrorHandler(
        error,
        exportTrainingErrors.create,
        errorMessages?.map((err) => `${err.property}: ${err.error}`).join('\n')
      )
      setFileIsAdding(false)
    },
  })

  const onSubmit = (data: any) => {
    setIsSending(true)

    const exportData = {
      name: data.name,
      dateStart: data.dateStart,
      hourStart: data.hourStart,
      trainerName: data.trainerName,
      location: trainingType === 'online' ? '' : data.location,
      locationDetails: data.locationDetails,
      type: data.type,
      url: trainingType === 'on-site' ? '' : data.url,
      description: data.description,
      files: fileIds,
    }

    mutation.mutate(exportData)
  }

  const filteredFiles = fileIds.map((fileId) => ({
    value: fileId,
    label: fileNames[fileId] || fileId, // Use original filename if available
  }))

  const validateFile = (file: File | undefined): boolean => {
    if (!file) {
      return false
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      return false
    }

    // Check file type
    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      return false
    }

    return true
  }

  const fileMutation = useMutation({
    mutationFn: (data: File) => {
      return addTrainigFile(data)
    },
    onSuccess: (data) => {
      const fileId = data.id
      const newFile = {
        value: fileId,
        label: file?.name || `Plik ${fileIds.length + 1}`,
      }
      setFileIds((prevFileIds) => [...prevFileIds, fileId])
      setFinalFiles((prevFinalFiles) => [...prevFinalFiles, newFile])
      setFileNames((prev) => ({ ...prev, [fileId]: file?.name || '' }))
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      setFileIsAdding(false)
      setFile(undefined)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      if (error && error.response?.status === 403) {
        // Handle 403 errors silently without toasts
        toast.error('Brak uprawnień do wykonania tej akcji')
        return
      }

      const errorMessages = error.response?.data.errors
        ?.map((err) => `${err.property}: ${err.error}`)
        .join('\n')

      mutationErrorHandler(error, exportTrainingErrors.create, errorMessages)
      setFileIsAdding(false)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined
    setFile(selectedFile)
  }

  const handleAddFile = (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateFile(file)) {
      return
    }
    setFileIsAdding(true)
    if (file) {
      fileMutation.mutate(file)
    }
  }

  return (
    <Card label="Utwórz szkolenie">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4">
          <FormField
            name="name"
            label="Nazwa szkolenia"
            placeholder="Wpisz nazwę szkolenia"
            register={register}
            errors={errors}
            type="text"
          />

          <div className="flex gap-2">
            <div className="flex-1">
              <Controller
                name="dateStart"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value ? new Date(value) : undefined}
                    handleDate={(val) => {
                      onChange(new Date(val).toISOString().split('T')[0])
                    }}
                    label="Data rozpoczęcia"
                    required={true}
                  />
                )}
              />
              {errors.dateStart && (
                <p className="text-sm text-red-500">{errors.dateStart.message}</p>
              )}
            </div>
          </div>

          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="hourStart">
              Godzina szkolenia
              <span className="ml-1 text-red-500">*</span>
            </label>
            <input
              type="time"
              id="hourStart"
              {...register('hourStart')}
              className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            />
            {errors.hourStart && <p className="text-sm text-red-500">{errors.hourStart.message}</p>}
          </div>

          <div>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label className="mb-1 block text-sm font-medium text-gray-700">
                    Tryb szkolenia
                    <span className="ml-1 text-red-500">*</span>
                  </label>
                  <select
                    className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  >
                    <option value="">---</option>
                    <option value="on-site">Stacjonarne</option>
                    <option value="online">Online</option>
                  </select>
                  {errors.type && <p className="text-sm text-red-500">{errors.type.message}</p>}
                </div>
              )}
            />
          </div>

          <FormField
            name="trainerName"
            label="Prowadzący"
            placeholder="Wpisz imię prowadzącego"
            register={register}
            errors={errors}
            type="text"
          />

          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="location">
              Miejsce szkolenia
              {trainingType === 'on-site' && <span className="ml-1 text-red-500">*</span>}
            </label>
            <input
              id="location"
              {...register('location')}
              className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              disabled={trainingType === 'online'}
            />
            {errors.location && <p className="text-sm text-red-500">{errors.location.message}</p>}
          </div>

          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="url">
              URL
              {trainingType === 'online' && <span className="ml-1 text-red-500">*</span>}
            </label>
            <input
              id="url"
              {...register('url')}
              className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              disabled={trainingType === 'on-site'}
            />
            {errors.url && <p className="text-sm text-red-500">{errors.url.message}</p>}
          </div>

          <div className="md:col-span-2">
            <label
              className="mb-1 block text-sm font-medium text-gray-700"
              htmlFor="locationDetails"
            >
              Szczegóły lokalizacji
              <span className="ml-1 text-red-500">*</span>
            </label>
            <textarea
              id="locationDetails"
              {...register('locationDetails')}
              aria-rowspan={4}
              className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              placeholder="Wpisz szczegóły dot. lokalizacji"
            ></textarea>
            {errors.locationDetails && (
              <p className="text-sm text-red-500">{errors.locationDetails.message}</p>
            )}
          </div>

          <div className="md:col-span-2">
            <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="description">
              Opis szkolenia
              <span className="ml-1 text-red-500">*</span>
            </label>
            <textarea
              id="description"
              {...register('description')}
              aria-rowspan={4}
              className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              placeholder="Opisz szkolenie"
            ></textarea>
            {errors.description && (
              <p className="text-sm text-red-500">{errors.description.message}</p>
            )}
          </div>

          <div className="md:col-span-2">
            <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="docs">
              Dokumenty
            </label>
            <MultiSelect
              options={filteredFiles}
              value={finalFiles}
              onChange={setFinalFiles}
              hasSelectAll={false}
              className="text-sm"
              overrideStrings={{
                selectSomeItems: 'Zaznacz dokumenty',
                allItemsAreSelected: 'Wszystkie dokumenty są zaznaczone',
                selectAll: 'Zaznacz wszystkie',
                search: 'Wyszukaj',
                noOptions: 'Brak dokumentów',
              }}
              labelledBy="Wyszukaj"
            />
          </div>

          <div className="md:col-span-3"></div>
          <div className="md:col-span-2">
            <label className="mb-1 block text-sm font-medium text-gray-700">Dodaj plik</label>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  name="file"
                  id="file"
                  ref={fileInputRef}
                  className="mb-1 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  onChange={handleFileChange}
                />
                <Button
                  className="mt-1 flex min-w-[130px] justify-center text-center disabled:cursor-not-allowed"
                  disabled={!file || fileIsAdding}
                  label={
                    fileIsAdding ? (
                      <>
                        Dodaję plik
                        <span className="loader ml-1"></span>
                      </>
                    ) : (
                      'Dodaj plik'
                    )
                  }
                  onClick={handleAddFile}
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Dozwolone typy plików: PDF, PNG, JPEG, DOC, DOCX. Maksymalny rozmiar: 5MB
              </p>
            </div>
          </div>
        </div>

        <hr className="my-4" />
        <div className="my-4 flex justify-center">
          <Button
            variant="secondary"
            label="Anuluj"
            className="mr-4"
            onClick={(e) => {
              e.preventDefault()
              navigate(path.training.list)
            }}
          />
          <Button type="submit" label="Utwórz szkolenie" disabled={isSending} />
        </div>
      </form>
    </Card>
  )
}

export default TrainingCreate
