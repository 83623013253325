import { secureBaseApiURL } from 'api'
import { AxiosResponse } from 'axios'
import { endpoint } from 'constants/endpoints'

export const getMemberExportDictionary = async () => {
  const res = await secureBaseApiURL.get(endpoint.export.member.exportFieldsDict)
  return res?.data
}

export const getMemberExportList = async (page: number, count: number, filters?: string) => {
  const res = await secureBaseApiURL.get(
    endpoint.export.member.exportList(page, count, filters ? filters : '')
  )
  return res?.data
}

export const createExport = async (data: any) => {
  const res = await secureBaseApiURL.post(endpoint.export.member.createExport, data)
  return res?.data
}

export const getExportSingle = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.export.member.exportSingleDetails(id))
  return res?.data
}

export const getExportPassword = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.export.exportPassword(id))
  return res?.data
}


// Completely rewritten getExportFile function based on your working fetch example
export const getExportFile = async (id: string, filename: string): Promise<void> => {
  try {
    const url = `${secureBaseApiURL.defaults.baseURL}${endpoint.export.exportFile(id)}` 

    // Use fetch API directly as it was working in your example
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    // Get the blob directly from the response
    const blob = await response.blob()

    // Create a download link
    const downloadUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = downloadUrl
    a.download = `${filename}.zip`

    // Trigger download
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    // Clean up
    window.URL.revokeObjectURL(downloadUrl)

    return
  } catch (error) {
    console.error('Error downloading file:', error)
    throw error
  }
} 