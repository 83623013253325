import { useState, useRef, useEffect } from 'react'

import * as OutlineIcons from '@heroicons/react/24/outline'
import * as SolidIcons from '@heroicons/react/24/solid'
import clsx from 'clsx'

type Props = {
  className?: string
  iconName: string
  disabled?: boolean
  title: string
  onClick?: () => void
  noMargin?: boolean
  noPointer?: boolean
}

export const HoverIcon = ({
  disabled,
  className,
  noMargin,
  noPointer,
  iconName,
  title,
  onClick,
}: Props) => {
  const [isHover, setIsHover] = useState(false)
  const hoverTimeoutRef = useRef<number | null>(null)
  const iconRef = useRef<HTMLDivElement>(null)

  const iconComponents = {
    outline: (OutlineIcons as any)[iconName],
    solid: (SolidIcons as any)[iconName],
  }
  const IconComponent = isHover ? iconComponents.solid : iconComponents.outline
  const margin = noMargin ? '' : ''
  const pointer = disabled ? 'cursor-default' : noPointer ? 'cursor-default' : 'cursor-pointer'
  const isDisabled = disabled ? 'opacity-50' : ''

  // Clear any existing timeouts when component unmounts
  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current !== null) {
        window.clearTimeout(hoverTimeoutRef.current)
      }
    }
  }, [])

  // Check if mouse is actually over the element
  useEffect(() => {
    const checkMousePosition = (e: MouseEvent) => {
      if (isHover && iconRef.current) {
        const rect = iconRef.current.getBoundingClientRect()
        const isMouseOver =
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom

        if (!isMouseOver) {
          setIsHover(false)
        }
      }
    }

    document.addEventListener('mousemove', checkMousePosition)
    return () => {
      document.removeEventListener('mousemove', checkMousePosition)
    }
  }, [isHover])

  const handleMouseOver = () => {
    if (disabled) return

    // Clear any existing timeout
    if (hoverTimeoutRef.current !== null) {
      window.clearTimeout(hoverTimeoutRef.current)
      hoverTimeoutRef.current = null
    }

    setIsHover(true)
  }

  const handleMouseLeave = () => {
    if (disabled) return

    // Use a small timeout to ensure the state is updated
    // This helps with quick mouse movements
    hoverTimeoutRef.current = window.setTimeout(() => {
      setIsHover(false)
      hoverTimeoutRef.current = null
    }, 50) // Short delay to ensure the event is processed
  }

  const handleBlur = () => {
    setIsHover(false)
  }

  return (
    <div
      ref={iconRef}
      className={clsx('rounded p-1', className, margin, pointer, isDisabled, {
        'bg-gray-200': isHover,
      })}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={() => !disabled && setIsHover(true)}
      onBlur={handleBlur}
      tabIndex={disabled ? -1 : 0} // Make the div focusable, but not if disabled
    >
      {IconComponent && (
        <IconComponent
          color={isHover ? 'rgb(67, 56, 202)' : '#000'}
          title={title}
          className="w-5"
        />
      )}
    </div>
  )
}
