import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Fragment } from 'react/jsx-runtime'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Table } from 'components/Atoms/Table'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getCensusDetails } from 'fetchers/censusFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { errorForbiddenHandler } from 'tools/queryHelpers'

const CensusDetails = () => {
  const { userToken, id } = useAuth()
  const navigate = useNavigate()

  const {
    data: censusData,
    isPending: isCensusDataPending,
    isLoading: isCensusDataLoading,
    error: censusDataError,
    refetch: refetchCensusData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDetails, id],
    queryFn: () => getCensusDetails(id),
    retry: useRetryHandler({
      resourceName: 'censusData',
      maxRetries: 1,
    }),
  })

  // Fetch all regions
  const { data: regions, isLoading: isRegionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 1000, []), // Removed filtering by search
    retry: useRetryHandler({
      resourceName: 'regions',
      maxRetries: 1,
    }),
  })

  // Fetch all branches
  const { data: branches, isLoading: isBranchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesListPrefiltred(1, 1000, ''), // Removed filtering by selected regions
    retry: useRetryHandler({
      resourceName: 'branches',
      maxRetries: 1,
    }),
  })

  const translateBranch = (branchId: string) => {
    const branch = branches?.items?.find((branch: any) => branch.id === branchId)
    return branch?.name || 'Nieznany'
  }

  const translateRegion = (regionId: string) => {
    const region = regions?.items?.find((region: any) => region.id === regionId)
    return region?.name || 'Nieznany'
  }

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'in_progress':
        return 'W trakcie'
      case 'completed':
        return 'Zakończony'
      case 'canceled':
        return 'Anulowany'
      default:
        return 'Brak statusu'
    }
  }

  const regionsColumns = useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'id',
        Cell: ({ row }: any) => {
          return translateRegion(row.original?.id)
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <Link to={`/census/dashboard/${id}/region/${row.original?.id}`}>
              <Button label="Zobacz dashboard okręgu" />
            </Link>
          )
        },
      },
    ],
    [regions]
  )

  const branchesColumns = useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'id',
        Cell: ({ row }: any) => {
          return translateBranch(row.original?.id)
        },
      },
      {
        Header: 'Jednostka nadrzędna',
        accessor: 'parentUnitId',
        Cell: ({ row }: any) => {
          return translateRegion(row.original?.parentUnitId)
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <Link to={`/census/dashboard/${id}/branch/${row.original?.id}`}>
              <Button label="Zobacz dashboard oddziału" />
            </Link>
          )
        },
      },
    ],
    [branches, regions]
  )

  if (isCensusDataLoading || isRegionsLoading || isBranchesLoading) {
    return (
      <Card label="Szczegóły spisu">
        <dl className="grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6">
          {[...Array(6)].map((_, index) => (
            <Fragment key={index}>
              <dt className="col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3">
                <div className="h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200"></div>
              </dt>
              <dd className="pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none">
                <div className="h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200"></div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  if (errorForbiddenHandler(censusDataError)) return <ErrorRoleModal />

  const actionButtons = !isCensusDataPending
    ? [
        {
          disabled: censusData?.status === 'completed' || censusData?.status === 'canceled',

          label: 'Edytuj',
          handleClick: () => navigate(`/census/${id}/edit`),
          variant: 'secondary' as const,
        },
      ]
    : []

  const isDatePassed = (dateString: string) => {
    if (!dateString) return false
    const endDate = new Date(dateString)
    const today = new Date()
    return endDate < today
  }

  const decodedCensusName = censusData?.name?.replace('&#43;', '+') || ''

  return (
    <>
      <Card label={decodedCensusName} actionsButton={actionButtons}>
        <div className="grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4">
          <LabelTypography label="Data początku" text={censusData?.dateFrom} />
          <LabelTypography
            label="Data końca"
            text={
              <>
                <span className="block">{censusData?.dateTo}</span>
                {isDatePassed(censusData?.dateTo) &&
                  censusData?.status !== 'completed' &&
                  censusData?.status !== 'canceled' && (
                    <span className="mt-1 text-xs font-semibold text-red-600">
                      Termin przekroczony
                    </span>
                  )}
              </>
            }
          />
          <div>
            <label htmlFor="" className="mb-1 block text-sm font-medium text-gray-700">
              Status dokumentu
            </label>
            <span
              className={`font-eee inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-bold text-gray-900 focus:ring-2 focus:ring-ring focus:ring-offset-2 ${
                censusData?.status === 'awaiting'
                  ? 'bg-yellow-100 text-yellow-800'
                  : censusData?.status === 'in_progress'
                    ? 'bg-blue-100 text-blue-800'
                    : censusData?.status === 'completed'
                      ? 'bg-green-100 text-green-800'
                      : censusData?.status === 'canceled'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-gray-100 text-gray-800'
              }`}
            >
              {getStatusText(censusData?.status)}
            </span>
          </div>
          <LabelTypography
            label="Dashboard spisu"
            text={
              <Link to={`/census/dashboard/${id}`}>
                <Button label="Zobacz dashboard admina" />
              </Link>
            }
          />
        </div>
      </Card>
      <Card label={'Lista okręgów'}>
        {censusData?.selectedRegions.length === 0 ? (
          <div className="text-sm text-gray-500">Brak okręgów w spisie</div>
        ) : (
          <Table
            maxColumnWidth="300px"
            columns={regionsColumns}
            data={censusData?.selectedRegions}
          />
        )}
      </Card>
      <Card label={'Lista oddziałów'}>
        {censusData?.selectedBranches.length === 0 ? (
          <div className="text-sm text-gray-500">Brak oddziałów w spisie</div>
        ) : (
          <Table
            maxColumnWidth="300px"
            columns={branchesColumns}
            data={censusData?.selectedBranches}
          />
        )}
      </Card>
      <div className="my-4 flex justify-center gap-4">
        <Button
          variant="secondary"
          label="Powrót"
          onClick={(e) => {
            e.preventDefault()
            navigate(path.census.list)
          }}
        />
      </div>
    </>
  )
}

export default CensusDetails
