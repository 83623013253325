import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { IInstitutionSearchQuery } from 'types/institution'

export const getInstitutionsList = async (
  page: number,
  count: number,
  query?: string | IInstitutionSearchQuery
) => {
  const filters = []
  if (typeof query === 'string') {
    filters.push(`filter[branch]=${query}`)
  } else if (query) {
    if (query.name) {
      filters.push(`filter[name]=${query.name}`)
    }
    if (query.city) {
      filters.push(`filter[city]=${query.city}`)
    }

    // Handle branch parameter as array if needed
    if (query.branch) {
      if (Array.isArray(query.branch)) {
        query.branch.forEach((branch) => {
          filters.push(`filter[branch][]=${branch}`)
        })
      } else {
        filters.push(`filter[branch]=${query.branch}`)
      }
    }

    // Handle region parameter
    if (query.region) {
      if (Array.isArray(query.region)) {
        query.region.forEach((region) => {
          filters.push(`filter[region][]=${region}`)
        })
      } else {
        filters.push(`filter[region]=${query.region}`)
      }
    }

    // Handle voivodeship array parameter
    if (query.voivodeship && query.voivodeship.length > 0) {
      query.voivodeship.forEach((voiv) => {
        filters.push(`filter[voivodeship][]=${voiv}`)
      })
    }
  }
  const filterData = filters.join('&')
  const res = await publicBaseApiURL.get(
    `${endpoint.structure.baseInstitutions}?page=${page}&perPage=${count}&${filterData}`,
    {}
  )
  return res?.data
}

export const getInstitutionsListByBranchName = async (
  page: number,
  count: number,
  branchName: string
) => {
  const filter = `filter[branch]=${branchName}`
  const res = await publicBaseApiURL.get(
    `${endpoint.structure.baseInstitutions}?page=${page}&perPage=${count}&${filter}`,
    {}
  )
  return res?.data
}

export const getInstitutionsListByMe = async (
  page: number,
  count: number,
  query?: IInstitutionSearchQuery
) => {
  const filters = []
  if (typeof query === 'string') {
    filters.push(`filter[branch]=${query}`)
  } else if (query) {
    if (query.name) {
      filters.push(`filter[name]=${query.name}`)
    }
    if (query.city) {
      filters.push(`filter[city]=${query.city}`)
    }
    if (query.branch) {
      if (Array.isArray(query.branch)) {
        query.branch.forEach((branch) => {
          filters.push(`filter[branch][]=${branch}`)
        })
      } else {
        filters.push(`filter[branch]=${query.branch}`)
      }
    }
    // Handle region parameter
    if (query.region) {
      if (Array.isArray(query.region)) {
        query.region.forEach((region) => {
          filters.push(`filter[region][]=${region}`)
        })
      } else {
        filters.push(`filter[region]=${query.region}`)
      }
    }
    // Handle voivodeship array parameter
    if (query.voivodeship && query.voivodeship.length > 0) {
      query.voivodeship.forEach((voiv) => {
        filters.push(`filter[voivodeship][]=${voiv}`)
      })
    }
  }
  const filterData = filters.join('&')
  const res = await publicBaseApiURL.get(
    `${endpoint.structure.baseInstitutionsMe}?page=${page}&perPage=${count}&${filterData}`,
    {}
  )
  return res?.data
}

export const getInstitution = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.institution.byID(id), {})
  return res?.data
}

export const createInstitution = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.institution.singleInstitutionCreate, data)
  return res?.data
}

export const updateInstitution = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.institution.singleInstitutionUpdate(id), data)
  return res?.data
}

// TYPES

export const getInstitutionsTypesList = async (page = 1, perPage = 10, filters = '') => {
  const response = await publicBaseApiURL.get(
    endpoint.institution.types.list + getPagination(page, perPage, filters)
  )
  return response.data
}

export const getInstitutionType = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.institution.types.show(id), {})
  return res?.data
}

export const createInstitutionType = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.institution.types.create, data)
  return res?.data
}

export const updateInstitutionType = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.institution.types.update(id), data)
  return res?.data
}

const getPagination = (page: string | number, perPage: string | number, filters = '') => {
  return `?page=${page}&perPage=${perPage}${filters}`
}

// GROUPS
export const getInstitutionsGroupsList = async (page = 1, perPage = 100, filters = '') => {
  const response = await publicBaseApiURL.get(
    endpoint.institution.groups.list + getPagination(page, perPage, filters)
  )
  return response.data
}

export const getInstitutionGroup = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.institution.groups.show(id), {})
  return res?.data
}
export const createInstitutionGroup = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.institution.groups.create, data)
  return res?.data
}
export const updateInstitutionGroup = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.institution.groups.update(id), data)
  return res?.data
}

export const postArchiveInstitute = async (id?: string) => {
  const res = await publicBaseApiURL.post(endpoint.institution.archive(id), {})
  return res?.data
}
