import { useEffect, useState } from 'react'

import { cn } from 'components/lib/utils'
import { Button as ShadcnButton } from 'components/shadcn/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/shadcn/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/shadcn/ui/popover'
import { errorMessage } from 'constants/errorMessage'
import { IOption } from 'types/form'

type ComboboxFieldProps = {
  label?: string
  options: IOption[]
  value: string | number
  onChange: (newValue: string | number) => void
  placeholder?: string
  emptyMessage?: string
  error?: string | boolean
  disabled?: boolean
  onBlur?: () => void
  className?: string
  required?: boolean
  searchPlaceholder?: string
  showResetButton?: boolean
  renderOption?: (option: IOption) => React.ReactNode // Added renderOption prop
}

export const ComboboxField = ({
  label,
  options,
  value,
  onChange,
  placeholder = 'Wybierz...',
  emptyMessage = 'Nie znaleziono.',
  error,
  disabled = false,
  onBlur,
  className = '',
  required = false,
  searchPlaceholder,
  showResetButton = true,
  renderOption, // Accept the new renderOption prop
}: ComboboxFieldProps) => {
  const [open, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  // Find the currently selected option
  const selectedOption = options.find((option) => option.value === value)

  // Filter options based on search query with case-insensitive matching
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // Reset search query when options change significantly
  useEffect(() => {
    setSearchQuery('')
  }, [options.length])

  // Close popover when clicking outside
  useEffect(() => {
    if (!open && onBlur) {
      onBlur()
    }
  }, [open, onBlur])

  // Handler for resetting the selected value
  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent popover from opening
    onChange('') // Reset to empty value
  }

  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      {label && (
        <label className="text-sm font-medium">
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </label>
      )}
      <div className="relative">
        <Popover open={open} onOpenChange={disabled ? undefined : setOpen}>
          <PopoverTrigger asChild>
            <ShadcnButton
              variant="outline"
              role="combobox"
              aria-expanded={open}
              disabled={disabled}
              className={cn(
                'w-full justify-between',
                error ? 'ring-0.5 border-red-500 ring-red-500' : '',
                disabled ? 'cursor-not-allowed opacity-50' : '',
                selectedOption && showResetButton ? 'pr-10' : '' // Add space for reset button
              )}
            >
              <span className="truncate">
                {selectedOption ? selectedOption.label : placeholder}
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 h-4 w-4 shrink-0 opacity-50"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708m0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708"
                ></path>
              </svg>
            </ShadcnButton>
          </PopoverTrigger>

          {/* Reset button */}
          {selectedOption && showResetButton && !disabled && (
            <button
              type="button"
              onClick={handleReset}
              className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-700 focus:outline-none"
              aria-label="Reset selection"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 16 16"
                fill="none"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4L12 12M4 12L12 4"
                />
              </svg>
            </button>
          )}

          <PopoverContent className="w-full max-w-[400px] p-0" align="start">
            <Command shouldFilter={false}>
              <CommandInput
                placeholder={searchPlaceholder || `Wyszukaj ${label?.toLowerCase() || ''}...`}
                onValueChange={setSearchQuery}
                value={searchQuery}
                className="outline-none focus:outline-none focus:ring-0"
              />
              <CommandList className="max-h-60 overflow-y-auto">
                {filteredOptions.length === 0 ? (
                  <CommandEmpty>{emptyMessage}</CommandEmpty>
                ) : (
                  <CommandGroup>
                    {filteredOptions.map((option) => (
                      <CommandItem
                        key={String(option.value)}
                        value={String(option.value)}
                        onSelect={(currentValue) => {
                          // Find the matching option
                          const matchedOption = options.find(
                            (opt) => String(opt.value) === currentValue
                          )

                          // Use the original value type (string or number)
                          if (matchedOption) {
                            onChange(matchedOption.value)
                          }

                          setOpen(false)
                          setSearchQuery('')
                        }}
                      >
                        <svg
                          className={cn(
                            'mr-2 h-4 w-4',
                            value === option.value ? 'opacity-100' : 'opacity-0'
                          )}
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                        >
                          <g fill="currentColor">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                            <path d="m10.97 4.97l-.02.022l-3.473 4.425l-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                          </g>
                        </svg>

                        {/* Use renderOption if provided, otherwise show default label */}
                        {renderOption ? (
                          renderOption(option)
                        ) : (
                          <span className="truncate">{option.label}</span>
                        )}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      {/* Display error message consistently */}
      {error && (
        <p className="mt-1 text-sm text-red-600">
          {typeof error === 'string' ? error : errorMessage.requiredField}
        </p>
      )}
    </div>
  )
}
