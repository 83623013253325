import { useNavigate } from 'react-router-dom'

import { HoverIcon } from 'components/Atoms/HoverIcon'
import { path } from 'constants/path'
import { IListElement } from 'types/form'
import { ISectionClub } from 'types/section'
import { useAppSelector } from 'app/hooks'

type Props = {
  data: ISectionClub
  archive?: boolean
  club?: boolean
  isEditAllow?: boolean
  showModalHandler: (data: IListElement) => void
}

export const SectionActionCell = ({
  showModalHandler,
  data,
  archive,
  club,
  isEditAllow,
}: Props) => {
  const navigate = useNavigate()
  const editPath = data.subtype === 'club' ? 'club' : 'group'
  return (
    <div className="flex">
      <HoverIcon
        iconName="EyeIcon"
        title="Zobacz"
        onClick={() => navigate(`/section/${data.id}`)}
      />
      {!archive && isEditAllow && (
        <>
          <HoverIcon
            iconName="ArchiveBoxIcon"
            title="Archiwizuj"
            onClick={() => showModalHandler(data)}
          />
          <HoverIcon
            iconName="PencilIcon"
            title="Edytuj"
            onClick={() => navigate(`${editPath}/${data.id}/update`)}
          />
          {club && (
            <HoverIcon
              iconName="DocumentDuplicateIcon"
              title="Połącz"
              onClick={() => navigate(`${path.section.merge}?removeId=${data.id}`)}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SectionActionCell
