import {
  BuildingLibraryIcon,
  ChartBarIcon,
  Cog8ToothIcon,
  FolderIcon,
  HomeIcon,
  ListBulletIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'

import { path } from 'constants/path'
import { adminRoles, regionRoles } from 'tools/roleHelpers'
import { INavigation } from 'types/navigation'

export const navigation: INavigation[] = [
  {
    name: 'Dashboard',
    icon: HomeIcon,
    current: false,
    href: '/dashboard',
  },
  {
    name: 'Baza członków',
    icon: UsersIcon,
    current: true,
    href: '/member',
  },
  {
    name: 'Funkcje w okręgu',
    icon: FolderIcon,
    current: false,
    href: '/region-functions',
    limited: [...adminRoles],
  },
  {
    name: 'Funkcje w oddziale',
    icon: FolderIcon,
    current: false,
    href: '/branch-functions',
    limited: [...adminRoles, ...regionRoles],
  },
  // {
  //   name: 'Zarządzanie',
  //   icon: ChartBarIcon,
  //   current: false,
  //   href: path.statisticalObligation.mainManagment.list,
  // },
  {
    name: 'Ustawienia',
    icon: Cog8ToothIcon,
    current: false,
    href: path.settings.main,
  },
  {
    name: 'Struktura ZNP',
    icon: ListBulletIcon,
    current: false,
    children: [
      {
        name: 'Funkcje w strukturze',
        icon: BuildingLibraryIcon,
        current: false,
        href: path.structure.unitPositions.list,
      },
    ],
  },
]
