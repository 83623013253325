import React from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { useTable } from 'react-table'

interface IPagination {
  currentPage: number
  lastPage: number
  onPageChange: (page: number) => void
  onItemsPerPageChange?: (value: number) => void
}

interface IProps {
  columns: any[]
  data: any[]
  maxColumnWidth?: string
  pagination?: IPagination
}

export const Table: React.FC<IProps> = ({ columns, data, maxColumnWidth, pagination }) => {
  const tableInstance = useTable({ columns, data })
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  return (
    <div className="my-4">
      <div className="rounded-md border bg-white">
        <div className="relative w-full overflow-auto rounded">
          <table className="w-full caption-bottom text-sm" {...getTableProps()}>
            <thead className="bg-slate-100 [&_tr]:border-b">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, idx) => (
                    <th
                      {...column.getHeaderProps()}
                      key={idx}
                      className="h-12 whitespace-nowrap p-3.5 py-2.5 text-left align-middle text-sm font-medium text-slate-900 [&:has([role=checkbox])]:pr-0"
                      style={{
                        maxWidth: `${maxColumnWidth}`,
                      }}
                      data-header-name={column.id}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    key={index}
                    className="border-b last:border-0 hover:bg-sky-50"
                  >
                    {row.cells.map((cell, idx) => (
                      <td
                        {...cell.getCellProps()}
                        key={idx}
                        className=" p-3.5 py-2.5 align-top text-sm font-normal text-gray-900"
                        style={{
                          maxWidth: `${maxColumnWidth}`,
                          overflowWrap: 'break-word',
                        }}
                      >
                        {cell.column.id === 'roles' || cell.column.id === 'units'
                          ? cell.value.map((item: any, i: number) => (
                              <div key={i}>
                                {item.role || `${item.unit.name} (${item.unit.id})`}
                              </div>
                            ))
                          : cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {pagination && (
        <div className="mt-4 flex items-center justify-between px-2">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-700">
              Strona {pagination.currentPage} z {pagination.lastPage}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => pagination.onPageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage <= 1}
              className="inline-flex items-center justify-center rounded-md border bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </button>
            <button
              onClick={() => pagination.onPageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage >= pagination.lastPage}
              className="inline-flex items-center justify-center rounded-md border bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <ChevronRightIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
