import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Pagination } from 'components/Atoms/Pagination'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getLegalList } from 'fetchers/legatFetchers'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearchModule from 'hooks/useSearchModule'
import { getLastPage } from 'tools/queryHelpers'

export const LegaList = () => {
  const { currentPage, onPageChange } = useSearchModule({ archive: false })
  const navigate = useNavigate()
  const { userToken, userInfo } = useAppSelector((state: any) => state.authReducer)
  const unitId = userInfo?.context?.unit?.id

  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.declarationList, currentPage, unitId],
    queryFn: () => getLegalList(currentPage, 10),
    retry: useRetryHandler({
      resourceName: 'LegaList data',
      maxRetries: 1,
    }),
  })
  const { lastPage } = getLastPage(data?.pagination)
  const legalList = data?.items || []

  const columns = useMemo(
    () => [
      {
        Header: 'Numer uchwały',
        accessor: 'identifier',
      },
      {
        Header: 'Jednostka',
        accessor: 'unit.name',
      },
      {
        Header: 'Nazwa uchwały',
        accessor: 'name',
      },
      {
        Header: 'Data wystawienia',
        accessor: 'dateEnactment',
      },
      {
        Header: 'Data wejścia w życie',
        accessor: 'dateEffective',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const declarationId = row.row.original.id
          return (
            <div className='flex'>
              <HoverIcon
                iconName='PencilIcon'
                title='Edytuj'
                onClick={() => navigate(`${declarationId}/update`)}
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <div>
      <Typography size='xl' weight='medium'>
        Uchwały
      </Typography>

      <div className='my-5 flex'>
        <Button label='Dodaj uchwałę' onClick={() => navigate('/resolution/create')} />
      </div>
      {isLoading ? <LoadingSection /> : <Table columns={columns} data={legalList} />}
      <div className='flex justify-end'>
        <Pagination lastPage={lastPage} currentPage={currentPage} handlePageChange={onPageChange} />
      </div>
    </div>
  )
}
