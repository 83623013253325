import { Typography } from './Atoms/Typography'

type Props = {
  children: React.ReactNode
  title?: string
}

export const DashboardSection = ({ children, title }: Props) => {
  return (
    <section className="mt-4">
      {title && (
        <Typography size="3xl" weight="bold" className="mt-6 text-2xl uppercase text-center">
          {title}
        </Typography>
      )}
      <div className="grid-cold-1 grid gap-x-4 sm:grid-cols-2">{children}</div>
    </section>
  )
}

export default DashboardSection
