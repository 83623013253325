import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'

import { Button } from './Atoms/Button'
import { Typography } from './Atoms/Typography'

type Props = {
  label: string
  type: string
}

export const SettingGroup = ({ label, type }: Props) => {
  const navigate = useNavigate()
  const { userInfo } = useAppSelector((state: any) => state.authReducer)
  const isAdmin = userInfo?.context?.role === 'ROLE_SUPER_ADMIN'
  return (
    <section className='col-span-2 mb-8'>
      <Typography size='xl' weight='medium'>
        {label}
      </Typography>
      <div className='mt-3 grid grid-cols-5 gap-5'>
        <Button
          label='Lista'
          className='col-span-2'
          onClick={() => navigate(`/settings/${type}/`)}
        />
        {isAdmin && (
          <Button
            label='Dodaj nowy typ'
            className='col-span-2'
            onClick={() => navigate(`/settings/${type}/create`)}
          />
        )}
      </div>
    </section>
  )
}

export default SettingGroup
