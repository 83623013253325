import { useState, useEffect } from 'react'

import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Pagination } from 'components/Atoms/Pagination'
import { Typography } from 'components/Atoms/Typography'
import ErrorCard from 'components/ErrorCard'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getEducationsList } from 'fetchers/educationFetchers'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'

import SettingsBaseList from '../../../components/Settings/SettingsBaseList'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

export const MemberEducations = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { page, setLimit, changePage } = useSearch(searchParams)
  const cancelHandler = () => navigate(path.settings.main)
  const addNewTypeHandler = () => navigate(path.settings.education.create)
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const queryParams = new URLSearchParams(location.search)
  const perPageFromUrl = parseInt(queryParams.get('perPage') || '10', 10)
  const [limit, setLimitState] = useState(perPageFromUrl)

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.educationList, page, limit],
    queryFn: () => getEducationsList(page, limit),
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  })

  const lastPage = useLastPage(data?.pagination, isLoading)
  const educationsList = data?.items || []

  const onLimitHandler = (value: string) => {
    const newLimit = parseInt(value, 10)
    setLimitState(newLimit)
    setLimit(newLimit)
    const newSearchParams = new URLSearchParams(location.search)
    newSearchParams.set('perPage', newLimit.toString())
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true })
  }

  useEffect(() => {
    refetch()
  }, [limit, refetch])

  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania listy wykształceń'}
        onCancel={cancelHandler}
        buttonLabel={'Ustawienia'}
      />
    )
  }

  return (
    <section>
      <Typography size="xl" weight="medium">
        <Link to={path.settings.main}>Ustawienia</Link> / Wykształcenie
      </Typography>
      <Card
        label="Lista stopni wykształcenia"
        actionsButton={[
          {
            label: 'Dodaj nowe wykształcenie',
            handleClick: addNewTypeHandler,
          },
        ]}
      >
        <>
          {isLoading ? (
            <LoadingSection />
          ) : (
            <>
              <div className="mb-4 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <Typography weight="medium" className="whitespace-nowrap text-sm">
                    Liczba wierszy na stronę
                  </Typography>
                  <Select onValueChange={(value) => onLimitHandler(value)}>
                    <SelectTrigger className="w-full max-w-[80px]" id="educationSelect">
                      <SelectValue placeholder={limit.toString()} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className="max-w-sm">
                        <SelectItem value="10">10</SelectItem>
                        <SelectItem value="20">20</SelectItem>
                        <SelectItem value="50">50</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <span>
                  {lastPage > 1 && (
                    <Pagination
                      lastPage={lastPage}
                      currentPage={page}
                      handlePageChange={changePage}
                    />
                  )}
                </span>
              </div>
              <SettingsBaseList baseList={educationsList} type="education" />
            </>
          )}
        </>
      </Card>
    </section>
  )
}

export default MemberEducations
