import { useMemo, useState } from 'react'

import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { xor } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import SearchMultiselect from 'components/Atoms/SearchMultiselect/SearchMultiselect'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import BranchActionCell from 'components/Branch/BranchActionCell'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesList } from 'fetchers/branchFetchers'
import { useArchiveBranch } from 'fetchers/hooks/useArchiveBranch'
import { getRegionsList } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearchModule from 'hooks/useSearchModule'
import { getLastPage, getLoadingHandler } from 'tools/queryHelpers'
import { IBasicSettingForm } from 'types/form'
import { Select } from 'components/Atoms/Select'

type Props = {
  archive?: boolean
}
type FilterQuery = {
  [key: string]: string
}
export const BranchList = ({ archive }: Props) => {
  const { filters, currentPage, search, setSearch, onPageChange, onSearchHandler } =
    useSearchModule({ archive })

  const navigate = useNavigate()
  const [regionSelected, setRegionSelected] = useState<string[]>([])
  const [searchRegion, setSearchRegion] = useState<FilterQuery>({ region: '' })
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [regionSearch, setRegionSearch] = useState('')
  const [regionPerPage, setRegionPerPage] = useState(10)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()
  const limitOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
  ]

  const regionFilters = { region: regionSelected }
  // DATA
  const {
    data,
    isLoading: loadingBranches,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, currentPage, filters, regionFilters, rowsPerPage],
    queryFn: () => getBranchesList(currentPage, rowsPerPage, [...filters, regionFilters]),
    retry: useRetryHandler({
      resourceName: 'BranchList data',
      maxRetries: 1,
    }),
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  })
  const { lastPage } = getLastPage(data?.pagination)

  const {
    data: regions,
    isLoading: loadingRegions,
    refetch: rfReg,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, regionPerPage, regionSearch],
    queryFn: () => getRegionsList(1, regionPerPage, [{ name: regionSearch }]),
    retry: useRetryHandler({
      resourceName: 'BranchList regions',
      maxRetries: 1,
    }),
  })
  // Regions list
  const regionsList = regions?.items
    ? regions.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END REGIONS LOADER

  const searchMoreHandler = () => {
    const count =
      regions?.pagination?.countFiltered > 0
        ? regions?.pagination?.countFiltered
        : regions?.pagination?.count
    if (!loadingRegions && regions?.items?.length < count)
      setRegionPerPage((prev: number) => prev + 10)
  }
  const handleInputCheck = (option: IBasicSettingForm) => {
    if (option?.id) {
      setRegionSelected((prev: string[]) => xor(prev, [String(option.id)]))
    }
  }
  // NAVIGATION HANDLERS
  const createHandler = () => navigate(path.branch.create)
  const archiveListHandler = () => navigate(path.branch.archive)

  // ARCHIVE HANDLERS
  const { showModalHandler, ArchiveModal } = useArchiveBranch({ refetch })

  // TABLE HANDLERS
  const columnsScheme = [
    { Header: 'Nazwa oddziału', accessor: 'name' },
    { Header: 'Ogniska', accessor: 'childrenCnt' },
    {
      Header: 'Akcje',
      accessor: 'action',
      Cell: (row: any) => {
        return (
          <BranchActionCell
            archive={archive}
            data={row.row.original}
            showModalHandler={showModalHandler}
          />
        )
      },
    },
  ]
  const columns = useMemo(() => columnsScheme, [archive])
  const setBoxLimit = (e: number) => setRegionPerPage(e)
  const onLimitHandler = (e: any) => {
    setRowsPerPage(e.value)
  }

  const loadingHandler = getLoadingHandler(error, loadingBranches, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size="xl" weight="medium">
        {archive ? (
          <>
            <Link to="/branch">Lista oddziałów</Link> / Zarchiwizowane
          </>
        ) : (
          'Lista oddziałów'
        )}
      </Typography>

      <Card label="Szukaj">
        <form onSubmit={onSearchHandler}>
          <div className="mb-5 grid grid-cols-4 gap-4">
            <TextField label="Nazwa" type="text" value={search} onChange={setSearch} />
            <div className="flex w-full">
              <SearchMultiselect
                type="region"
                label={'Okręg'}
                options={regionsList}
                isLoading={loadingRegions}
                query={regionSearch}
                searchMore={searchMoreHandler}
                queryHandler={setRegionSearch}
                changeLimit={setBoxLimit}
                refetch={refetch}
                inputCheck={regionSelected}
                handleInputCheck={handleInputCheck}
              />
              {/* <FilterSection 
                label='Okręg'
                filterQuery={searchRegion.region}
                setSearchQuery={setSearchRegion}
                dataList={regionsList}
                selectedList={regionSelected}
                setSelectedList={setRegionSelected}/> */}
            </div>
          </div>
          <div className="mb-2 flex justify-center">
            <Button label="Szukaj" />
          </div>
        </form>
      </Card>
      {!archive && (
        <div className="mt-5 flex justify-between">
          <Button label="Utwórz oddział" onClick={createHandler} />
          <Button label="Zarchiwizowane" onClick={archiveListHandler} />
        </div>
      )}
      {loadingBranches ? <Spinner /> : <Table columns={columns} data={data?.items} />}
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <Typography weight="medium" className="text-sm">
            Liczba wierszy na stronę
          </Typography>
          <Select
            handleSelect={onLimitHandler}
            className='bg-white'
            options={limitOptions}
            value={String(rowsPerPage)} // ADDED VALUE PROP
          />
        </div>
        <Pagination lastPage={lastPage} currentPage={currentPage} handlePageChange={onPageChange} />
      </div>
      <ArchiveModal />
    </div>
  )
}
