import { useEffect, useState } from 'react'

import { useSearchParams } from 'react-router-dom'

import { setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { IFilters, IMultiFilters } from 'types/form'
import {
  IArrayState,
  IQueryParamsState,
  ISearchArray,
  ISearchPage,
  ISearchValue,
  ISingleState,
} from 'types/searchModule'

type IState = {
  [key: string]: string | number | boolean | undefined | null
}

const getParams = (
  page: number,
  perPage: number,
  singleStates: ISingleState,
  arrayStates: IArrayState
) => {
  const queryParamsObj: IState = { page, perPage }
  Object.keys(singleStates).forEach((key) => {
    const value = singleStates[key]
    queryParamsObj[key] = String(value)
  })

  Object.keys(arrayStates).forEach((key) => {
    const value = arrayStates[key]
    queryParamsObj[key] = JSON.stringify(value)
  })
  const urlSearchParams = new URLSearchParams()
  Object.entries(queryParamsObj).forEach(([key, value]) => {
    urlSearchParams.append(key, String(value))
  })
  return urlSearchParams
}

export const useSearch = ({
  simpleParams = [],
  arrayParams = [],
  limit = 10,
}: IQueryParamsState) => {
  const ISS: ISingleState = {}
  const IAS: IArrayState = {}
  simpleParams.forEach((key) => {
    ISS[key] = ''
  })
  arrayParams.forEach((key) => {
    IAS[key] = []
  })
  const [queryParams, setQueryParams] = useSearchParams()
  const [customParam, setCustomParam] = useState<string>('')
  const currentPage = Number(queryParams.get('page'))
  const [page, setPage] = useState<ISearchPage>({ current: currentPage, limit: limit })
  const [singleStates, setSingleStates] = useState<ISingleState>(ISS)
  const [arrayStates, setArrayStates] = useState<IArrayState>(IAS)
  const [filters, setFilters] = useState<string>('')

  // FILTERS (ARRAY OF OBJECTS)
  const singleFilters: IFilters[] = []
  for (const [key, value] of Object.entries(singleStates)) {
    const validType = typeof value === 'string' || typeof value === 'number'
    if (value && validType) {
      singleFilters.push({ [key]: value })
    }
  }
  const arrayFilters: IMultiFilters[] = []
  for (const [key, value] of Object.entries(arrayStates)) {
    if (value && Array.isArray(value) && value.length > 0) {
      arrayFilters.push({ [key]: value })
    }
  }

  // SETUP SINGLE PARAM STATE
  const singleParamHandler = (key: keyof ISingleState, value: ISearchValue) => {
    setSingleStates((curr) => ({ ...curr, [key]: value }))
  }
  // SETUP ARRAY PARAM STATE
  const arrayParamHandler = (key: keyof IArrayState, value: ISearchArray) => {
    setArrayStates((curr) => ({ ...curr, [key]: value }))
  }

  // PAGE HANDLERS
  const onPageChange = (newPage: number | string) => {
    setPage((curr) => ({ ...curr, page: Number(newPage) }))
    const params = getParams(Number(newPage), page.limit, singleStates, arrayStates)
    setQueryParams(params)
  }

  const onLimitChange = (newLimit: number | string) => {
    setPage((curr) => ({ ...curr, limit: Number(newLimit) }))
    const params = getParams(1, Number(newLimit), singleStates, arrayStates)
    setQueryParams(params)
  }

  // SEARCH HANDLERS
  const onSearchHandler = () => {
    setFilters(setupSearchFilters(singleFilters) + setupSearchMultiFilters(arrayFilters))
    setPage((curr) => ({ ...curr, current: 1 }))
    const params = getParams(1, page.limit, singleStates, arrayStates)
    setQueryParams(params)
  }
  // CLEAR QUERY
  const onClearQuery = () => {
    setSingleStates(ISS)
    setArrayStates(IAS)
    setFilters('')
    const params = getParams(1, page.limit, ISS, IAS)
    setQueryParams(params)
  }

  const setParam = {
    single: singleParamHandler,
    array: arrayParamHandler,
    custom: setCustomParam,
  }

  const removeParam = (key: string) => {
    setQueryParams((params) => {
      params.delete(key)
      return params
    })
  }

  // LOAD QUERY ON MOUNT
  useEffect(() => {
    const initSingleFilters: IFilters[] = []
    const initArrayFilters: IMultiFilters[] = []
    const querySingle: ISingleState = {}
    const queryArray: IArrayState = {}
    queryParams.forEach((value, key) => {
      if (simpleParams.includes(key)) {
        querySingle[key] = value
        if (value) {
          initSingleFilters.push({ [key]: value })
        }
      }
      if (arrayParams.includes(key)) {
        const arrayValue = JSON.parse(value)
        queryArray[key] = arrayValue
        if (arrayValue.length > 0) {
          initArrayFilters.push({ [key]: arrayValue })
        }
      }
    })

    setSingleStates(querySingle)
    setArrayStates(queryArray)

    setFilters(setupSearchFilters(initSingleFilters) + setupSearchMultiFilters(initArrayFilters))
  }, [queryParams])

  return {
    filters: filters + customParam,
    page: currentPage > 0 ? currentPage : 1,
    perPage: page.limit,
    param: {
      single: singleStates,
      array: arrayStates,
      custom: customParam,
    },
    setParam,
    setLimit: onLimitChange,
    searchQuery: onSearchHandler,
    clearQuery: onClearQuery,
    changePage: onPageChange,
    removeParam,
    setFilters, // Export the setFilters function
  }
}

export default useSearch
