import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { queryKeys } from 'constants/queryKeys'
import { getMemberChangelog } from 'fetchers/membersFetchers'
import { getUserById } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { setupDate, setupLatestUpdate } from 'tools/formTools'

type Props = {
  admittedAt: string
}

export const RecordData = ({ admittedAt }: Props) => {
  const [userId, setUserId] = useState<string>('')
  const [lastChangeInfo, setLastChangeInfo] = useState<{
    date: string | null
    issuer: string | null
    changeDescription: string | null
  }>({ date: null, issuer: null, changeDescription: null })
  const { id, userToken } = useAuth()

  // CHANGES
  const { data: secure, isLoading: secureLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberChangelog, id, 'secure'],
    queryFn: () => getMemberChangelog(1, 10, id, '', 'secure'),
    retry: useRetryHandler({
      resourceName: 'RecordData secure',
      maxRetries: 1,
    }),
  })

  const { data: simple, isLoading: simpleLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberChangelog, id, 'simple'],
    queryFn: () => getMemberChangelog(1, 10, id, '', 'simple'),
    retry: useRetryHandler({
      resourceName: 'RecordData simple',
      maxRetries: 1,
    }),
  })
  // ISSUER
  const { data: user, isLoading: userIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.member, userId],
    queryFn: () => {
      if (userId) return getUserById(userId)
      return null
    },
    // enabled: !!userId && typeof userId === 'string',
    retry: useRetryHandler({
      resourceName: 'RecordData user',
      maxRetries: 1,
    }),
  })
  const issuer = user?.displayName || user?.email || '-'

  const secureLast = secure?.items?.[0]?.createdAt
  const simpleLast = simple?.items?.[0]?.createdAt

  const latestChange = secureLast && simpleLast ? setupLatestUpdate(secureLast, simpleLast) : null

  const lastDate = latestChange ? setupDate(new Date(latestChange)) : '-'

  const navigate = useNavigate()

  useEffect(() => {
    if (secureLast || simpleLast) {
      let latestChange
      let changeUserId
      let changeDate
      let changeDescription = null // Initialize changeDescription

      if (secureLast && simpleLast) {
        latestChange = setupLatestUpdate(secureLast, simpleLast)
        const source = latestChange === secureLast ? secure?.items?.[0] : simple?.items?.[0]
        changeUserId = source?.user
        changeDate = latestChange
        changeDescription = source?.changes?.[0]?.label || null // Extract label
      } else if (secureLast) {
        latestChange = secureLast
        changeUserId = secure?.items?.[0]?.user
        changeDate = secureLast
        changeDescription = secure?.items?.[0]?.changes?.[0]?.label || null // Extract label
      } else {
        latestChange = simpleLast
        changeUserId = simple?.items?.[0]?.user
        changeDate = simpleLast
        changeDescription = simple?.items?.[0]?.changes?.[0]?.label || null // Extract label
      }

      if (typeof changeUserId === 'string') {
        getUserById(changeUserId)
          .then((user) => {
            setLastChangeInfo({
              date: setupDate(new Date(changeDate)),
              issuer: user?.displayName || user?.email || '-',
              changeDescription: changeDescription,
            })
          })
          .catch((error) => {
            console.error('Error fetching user:', error)
            setLastChangeInfo({
              date: setupDate(new Date(changeDate)),
              issuer: '-',
              changeDescription: changeDescription,
            })
          })
      } else {
        // Handle case where changeUserId is not a string (e.g., null)
        setLastChangeInfo({
          date: setupDate(new Date(changeDate)),
          issuer: 'System',
          changeDescription: changeDescription,
        })
      }
    } else {
      // If no changes, reset the state
      setLastChangeInfo({ date: null, issuer: null, changeDescription: null })
    }
  }, [
    secureLast,
    simpleLast,
    secure?.items,
    simple?.items,
    getUserById,
    setupLatestUpdate,
    setupDate,
  ])

  return (
    <Card
      label="Dane rekordu"
      actionsButton={[
        {
          label: 'Pokaż historię',
          handleClick: () => navigate('history'),
        },
      ]}
    >
      <div className="flex flex-col">
        <div className="flex">
          <div className="mr-6">
            <LabelTypography
              label="Data ostatniej zmiany"
              text={lastChangeInfo.date ? `${lastChangeInfo.date}` : '-'}
            />
          </div>
          <div className="mx-6">
            <LabelTypography label="Co zmieniono?" text={lastChangeInfo.changeDescription || '-'} />
          </div>
          <div className="mx-6">
            <LabelTypography label="Autor zmiany" text={`${lastChangeInfo.issuer}`} />
          </div>
          <div className="mx-6">
            <LabelTypography label="Data utworzenia członka" text={admittedAt} />
          </div>
        </div>
      </div>
    </Card>
  )
}
