import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import { InstitutionForm } from 'components/Institution/InstitutionForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { institutionErrors } from 'error-data/institution'
import { getBranchesList } from 'fetchers/branchFetchers'
import { useArchiveInstitution } from 'fetchers/hooks/useArchiveInstitution'
import {
  getInstitution,
  getInstitutionsGroupsList,
  getInstitutionsTypesList,
  updateInstitution,
} from 'fetchers/institutionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import {
  institutionSchemaWithBranchRequired,
  institutionSchemaWithoutBranch,
  institutionSchemaWithBranchOptional,
} from 'schemas/institutionSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IInstitutionCreate } from 'types/institution'

export const InstitutionUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  // Determine the user role for logic
  const isRegion = isAllOK()
  const isAdmin = isAllAdmin()
  const isBranch = isAllOD()

  // If user is Region (OK) or Admin, they should see branch field
  const showBranch = isRegion || isAdmin

  // Choose appropriate validation schema based on user role and update context
  // For update operations, we want to be more lenient with the branch field
  // since it might already be set and not changeable in some contexts
  const validationSchema = isBranch
    ? institutionSchemaWithoutBranch // Branch users don't need branch field
    : showBranch
      ? institutionSchemaWithBranchRequired
      : institutionSchemaWithoutBranch

  const institutionId = id as string

  const {
    data: institution,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.institution, institutionId],
    queryFn: () => getInstitution(institutionId),
    retry: useRetryHandler({
      resourceName: 'InstitutionUpdate institution',
      maxRetries: 1,
    }),
  })

  const { data: institutionTypes, isLoading: loadingInsitutions } = useQuery({
    queryKey: [userToken, queryKeys.institutionTypesList],
    queryFn: () => getInstitutionsTypesList(1, 100),
    retry: useRetryHandler({
      resourceName: 'InstitutionUpdate institutionTypes',
      maxRetries: 1,
    }),
  })

  const { data: institutionGroups, isLoading: loadingGroups } = useQuery({
    queryKey: [userToken, queryKeys.institutionGroupsList],
    queryFn: () => getInstitutionsGroupsList(),
    retry: useRetryHandler({
      resourceName: 'InstitutionUpdate institutionGroups',
      maxRetries: 1,
    }),
  })

  const { data: branchData, isLoading: branchLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 100),
    retry: useRetryHandler({
      resourceName: 'branchData',
      maxRetries: 1,
    }),
    // Only fetch branch data if we need to show branch selector
    enabled: showBranch,
  })

  const mutation = useMutation({
    mutationFn: (data: IInstitutionCreate) => updateInstitution(institutionId, data),
    onSuccess: () => {
      successToast('Zaktualizowano placówkę.')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.institution.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, institutionErrors.create, 'Nie udało się zaktualizować placówki.')
    },
  })
  const { showModalHandler, ArchiveModal } = useArchiveInstitution({
    refetch,
    navigatePath: path.institution.list,
  })

  const onSubmit = async (data: IInstitutionCreate) => {
    try {
      setIsSending(true)

      // Create a clean payload with only the expected fields
      const submissionData: Omit<IInstitutionCreate, 'branch'> & { branch?: string } = {
        name: data.name,
        type: data.type,
        headmasterPhone: data.headmasterPhone,
        headmasterFirstName: data.headmasterFirstName,
        headmasterLastName: data.headmasterLastName,
        headmasterEmail: data.headmasterEmail,
        city: data.city,
        postalCode: data.postalCode,
        streetName: data.streetName,
        houseNr: data.houseNr,
        apartmentNr: data.apartmentNr,
        groups: data.groups,
      }

      // Only add branch field if user is admin or region (not branch)
      // if (!isBranch && showBranch && data.branch) {
      //   submissionData.branch = data.branch
      // }

      console.log('Submitting data:', submissionData)

      // Submit the filtered data
      mutation.mutate(submissionData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować placówki.')
    }
  }

  const groupList = institutionGroups?.items
    ? institutionGroups?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []
  const typeList = institutionTypes?.items
    ? institutionTypes?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []
  const branchList = branchData?.items
    ? branchData?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  // Prepare initial form data from the fetched institution
  const formData: IInstitutionCreate = {
    name: institution?.name || '',
    type: institution?.type?.id || '',
    headmasterPhone: institution?.headmaster?.contact?.phone || '',
    headmasterFirstName: institution?.headmaster?.firstName || '',
    headmasterLastName: institution?.headmaster?.lastName || '',
    headmasterEmail: institution?.headmaster?.contact?.email || '',
    city: institution?.address?.city || '',
    postalCode: institution?.address?.postalCode || '',
    streetName: institution?.address?.streetName || '',
    houseNr: institution?.address?.houseNr || '',
    apartmentNr: institution?.address?.apartmentNr || '',
    groups: institution?.groups?.length
      ? (institution?.groups || []).map((e: { id: string; name: string }) => e?.id)
      : [],
    // Only include branch if showBranch is true
    ...(showBranch ? { branch: institution?.branch?.id || '' } : {}),
  }

  const initType = { label: institution?.type?.name, value: institution?.type?.id }
  const initGroup = institution?.groups?.length
    ? (institution?.groups || []).map((e: { id: string; name: string }) => ({
        label: e?.name,
        value: e?.id,
      }))
    : []

  // Initial branch value if it exists
  const initBranch = institution?.branch
    ? { label: institution.branch.name, value: institution.branch.id }
    : undefined

  const isLoadingState =
    isLoading || loadingInsitutions || loadingGroups || (showBranch && branchLoading)
  const loadingHandler = getLoadingHandler(error, isLoadingState, !isAllow)

  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <Typography size="xl" weight="medium">
        <Link to="/institution">Placówki</Link> / Edytuj placówkę
      </Typography>
      <Card
        actionsButton={[
          {
            label: 'Archiwizuj',
            handleClick: () => showModalHandler(institution),
          },
        ]}
      >
        <InstitutionForm
          isSending={isSending}
          onSubmit={onSubmit}
          initData={formData}
          initType={initType}
          branchList={branchList}
          initBranch={initBranch}
          initGroup={initGroup}
          groupList={groupList}
          typeList={typeList}
          showBranch={false}
          validationSchema={validationSchema}
        />
      </Card>
      <ArchiveModal />
    </>
  )
}
