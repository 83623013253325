import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const createOrder = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.orders.create, data)
  return res?.data
}

export const getOrdersIssuedList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.orders.issuedList(page, count, filters ? filters : '')
  )
  return res?.data
}

export const getOrdersReceivedList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.orders.receivedList(page, count, filters ? filters : '')
  )
  return res?.data
}

export const getOrdersReceivedDetails = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.orders.receivedDetails(id))
  return res?.data
}

export const getOrdersIssuedDetails = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.orders.issuedDetails(id))
  return res?.data
}

// export const addOrderFile = async (data: any) => {
//   const formData = new FormData()
//   formData.append('file', data)
//   const res = await publicBaseApiURL.post(endpoint.orders.createFile, data)
//   return res?.data
// }

export const addOrderFile = async (file: any) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}order/file/create`, {
      method: 'POST',
      headers: {
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }
    const data = await response.json()
    console.log('Success:', data)
    return data
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const getOrderFile = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.orders.getFile(id), {
    responseType: 'blob',
  })

  // Extract filename from headers
  const contentDisposition = res.headers['content-disposition']
  let filename = 'downloaded_file' // Default filename
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/)
    if (filenameMatch && filenameMatch.length > 1) {
      filename = filenameMatch[1]
    }
  }

  return { data: res.data, filename, type: res.data.type }
}

export const confirmOrderReceived = async (id: string) => {
  const res = await publicBaseApiURL.post(endpoint.orders.confirmOrder(id))
  return res?.data
}

export const archiveOrder = async (id: string) => {
  const res = await publicBaseApiURL.post(endpoint.orders.archive(id))
  return res?.data
}
