import { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueries } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import {
  getMemberChangelog,
  getMemberChangelogDetails,
  getMembersDetails,
} from 'fetchers/membersFetchers'
import { getUserById } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { mutationErrorHandler } from 'tools/errorHandler'
import { setupDate, setupTime } from 'tools/formTools'
import { exportJson } from 'tools/jsonExport'
import { errorQuery } from 'tools/queryHelpers'
import { IOption } from 'types/form'
import { TMemberChangelogType } from 'types/member'

// Type definitions to improve type safety
interface ChangeItem {
  label: string
  newValue: any
  oldValue: any
}

interface ChangelogItem {
  id: string
  user: {
    id: string
    displayName?: string
    email?: string
  }
  createdAt: string
  changes: ChangeItem[]
}

interface ProcessedChangelogItem {
  id: string
  issuer: string
  userId: string
  date: string
  time: string
  changes: ChangeItem[]
  fields: string[]
  after: any[]
  before: any[]
  archived?: boolean
}

interface UnveiledItem {
  id: string
  data: {
    changes: ChangeItem[]
  }
}

export const MemberHistoryDetails = () => {
  const [unveiled, setUnveiled] = useState<UnveiledItem[]>([])
  const [type, setType] = useState<TMemberChangelogType>('simple')
  const [isVisible, setIsVisible] = useState(false)
  const { filters, perPage, page, setLimit, changePage } = useSearch({
    simpleParams: ['full'],
  })
  const { control } = useForm()
  const { userToken, id: memberId } = useAuth()
  const navigate = useNavigate()

  // Construct query filters
  const visibleFilter = isVisible ? '&showValues=true' : ''
  const queryFilters = filters + visibleFilter

  // Fetch member changelog data
  const {
    data: changelogData,
    isLoading: isChangelogLoading,
    error: changelogError,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberChangelog, memberId, page, perPage, queryFilters, type],
    queryFn: () => getMemberChangelog(page, perPage, memberId, queryFilters, type),
    retry: useRetryHandler({
      resourceName: 'MemberHistoryDetails data',
      maxRetries: 1,
    }),
  })

  // Process changelog data
  const changesList: ProcessedChangelogItem[] = useMemo(() => {
    if (!changelogData?.items || !Array.isArray(changelogData.items)) {
      return []
    }

    return changelogData.items.map((item: ChangelogItem) => ({
      id: item.id,
      issuer: item.user?.displayName || item.user?.email || '-',
      userId: item.user?.id || '',
      date: setupDate(new Date(item.createdAt)),
      time: setupTime(new Date(item.createdAt)),
      changes: item.changes || [],
      fields: (item.changes || []).map((change) => change.label),
      after: (item.changes || []).map((change) => change.newValue || '-'),
      before: (item.changes || []).map((change) => change.oldValue || '-'),
    }))
  }, [changelogData])

  // Calculate last page for pagination
  const lastPage = useLastPage(changelogData?.pagination, isChangelogLoading)

  // Fetch member details
  const {
    data: member,
    isLoading: memberIsLoading,
    error: memberError,
  } = useQuery({
    queryKey: [userToken, queryKeys.member, memberId],
    queryFn: () => getMembersDetails(memberId),
    retry: useRetryHandler({
      resourceName: 'MemberHistoryDetails member',
      maxRetries: 1,
    }),
  })

  // Format member name
  const memberName = useMemo(() => {
    if (!member) return ''
    return `${member.firstName || ''}${
      member.secondName ? ` ${member.secondName}` : ''
    } ${member.lastName || ''}`.trim()
  }, [member])

  // Pre-fetch user data for complex records
  const userQueries = useQueries({
    queries:
      type === 'simple'
        ? []
        : changesList
            .filter((item) => item.userId && !item.issuer)
            .map((item) => ({
              queryKey: [userToken, queryKeys.user, item.userId],
              queryFn: () => getUserById(item.userId),
              retry: useRetryHandler({
                resourceName: `User data for ${item.userId}`,
                maxRetries: 1,
              }),
            })),
  })

  // Map userIds to fetched user data
  const userMap = useMemo(() => {
    const map = new Map()
    userQueries.forEach((query) => {
      if (query.data && query.data.id) {
        map.set(query.data.id, query.data)
      }
    })
    return map
  }, [userQueries])

  // Mutation for revealing change details
  const mutateSingle = useMutation({
    mutationFn: (changeId: string) => getMemberChangelogDetails(memberId, changeId, type),
    onSuccess: (responseData, changeId) => {
      const alreadyUnveiled = unveiled.some((item) => item.id === changeId)
      if (!alreadyUnveiled) {
        setUnveiled((prev) => [...prev, { id: changeId, data: responseData }])
      }
    },
    onError: (error: AxiosError) => {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się odsłonić danych.')
    },
    retry: errorQuery,
  })

  // Handler for revealing change details
  const revealHandler = (changeId: string) => {
    mutateSingle.mutate(changeId)
  }

  // Toggle visibility handler
  const handleVisibility = () => {
    setIsVisible((prev) => !prev)
  }

  // Per page limit handler
  const onLimitHandler = (option: IOption) => {
    setLimit(option.value)
  }

  // Export data handler
  const exportHandler = () => {
    if (changelogData) {
      exportJson(changelogData)
    }
  }

  // Function to safely get change values
  const getChangeValues = (
    rowData: ProcessedChangelogItem,
    type: 'newValue' | 'oldValue'
  ): any[] => {
    if (!rowData || !rowData.changes) {
      return []
    }

    const revealedItem = unveiled.find((item) => item.id === rowData.id)

    if (isVisible) {
      return (rowData.changes || []).map((change) => change[type] || '-')
    }

    if (revealedItem?.data?.changes) {
      return revealedItem.data.changes.map((change) => change[type] || '-')
    }

    return (rowData.changes || []).map((change) => change[type] || '-')
  }

  // Table columns definition
  const columns = useMemo(
    () => [
      {
        Header: 'Zmieniający',
        accessor: 'issuer',
        Cell: ({ row }: { row: { original: ProcessedChangelogItem } }) => {
          if (type === 'simple') {
            return row.original.issuer
          }

          const userId = row.original.userId
          if (!userId) {
            return '-'
          }

          const user = userMap.get(userId)
          return user?.displayName || user?.email || row.original.issuer || '-'
        },
      },
      {
        Header: 'Data',
        accessor: 'date',
      },
      {
        Header: 'Czas',
        accessor: 'time',
      },
      {
        Header: 'Zmieniane pola',
        accessor: 'fields',
        Cell: ({ row }: { row: { original: ProcessedChangelogItem } }) => {
          if (!row.original.fields || row.original.fields.length === 0) {
            return '-'
          }

          return (
            <ul>
              {row.original.fields.map((item, idx) => (
                <li key={`${item}-${idx}-fields`}>{item}</li>
              ))}
            </ul>
          )
        },
      },
      {
        Header: 'Treść po zmianie',
        accessor: 'after',
        Cell: ({ row }: { row: { original: ProcessedChangelogItem } }) => {
          const source = getChangeValues(row.original, 'newValue')

          if (!source || source.length === 0) {
            return '-'
          }

          return (
            <ul className="flex flex-col">
              {source.map((item, idx) => {
                if (typeof item === 'object' && item !== null) {
                  const itemKey = item?.id || `item-${idx}`
                  const itemData = item?.name || JSON.stringify(item)
                  return <li key={`${itemKey}-${idx}-after`}>{itemData}</li>
                }
                return <li key={`item-${idx}-after`}>{item}</li>
              })}
            </ul>
          )
        },
      },
      {
        Header: 'Treść przed zmianą',
        accessor: 'before',
        Cell: ({ row }: { row: { original: ProcessedChangelogItem } }) => {
          const source = getChangeValues(row.original, 'oldValue')

          if (!source || source.length === 0) {
            return '-'
          }

          return (
            <ul className="flex flex-col">
              {source.map((item, idx) => {
                if (typeof item === 'object' && item !== null) {
                  const itemKey = item?.id || `item-${idx}`
                  const itemData = item?.name || JSON.stringify(item)
                  return <li key={`${itemKey}-${idx}-before`}>{itemData}</li>
                }
                return <li key={`item-${idx}-before`}>{item}</li>
              })}
            </ul>
          )
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: { row: { original: ProcessedChangelogItem } }) => {
          const isUnveiled = unveiled.some((item) => item.id === row.original.id)

          return (
            <div className="flex">
              {!isUnveiled && (
                <HoverIcon
                  disabled={!!row.original?.archived}
                  iconName="LockOpenIcon"
                  title="Ujawnij dane"
                  onClick={() => revealHandler(row.original.id)}
                />
              )}
              <HoverIcon
                disabled={!!row.original?.archived}
                iconName="EyeIcon"
                title="Podejrzyj"
                onClick={() => navigate(`${row.original.id}/${type}`)}
              />
            </div>
          )
        },
      },
    ],
    [type, unveiled, isVisible, userMap, navigate]
  )

  // Loading state message
  const renderLoadingState = () => {
    if (isChangelogLoading) {
      return (
        <div className="flex justify-center p-4 align-middle">
          <Spinner />
        </div>
      )
    }
    return null
  }

  // Error state message
  const renderErrorState = () => {
    if (changelogError || memberError) {
      return (
        <div className="p-4 text-red-500">
          Wystąpił błąd podczas ładowania danych. Proszę odświeżyć stronę lub spróbować później.
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <Typography size="xl" weight="medium">
        Historia zmian {memberName && `dla ${memberName}`}
      </Typography>
      <Card
        actionsButton={[
          {
            label: 'Przejdź do profilu',
            handleClick: () => {
              navigate(`/member/${memberId}`)
            },
          },
          {
            label: 'Eksportuj dane',
            handleClick: exportHandler,
          },
        ]}
        label={`Historia zmian - dane ${type === 'simple' ? 'podstawowe' : 'wrażliwe'}`}
      >
        <div className="mb-4 flex items-end justify-between">
          <Select
            handleSelect={onLimitHandler}
            options={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
            ]}
          />
          <div className="flex items-center">
            <Controller
              name="completeData"
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  label="Pokazuj kompletne dane"
                  id="Pokazuj-kompletne-dane"
                  checked={isVisible}
                  onChange={(e) => {
                    onChange(e.target.checked)
                    handleVisibility()
                    refetch()
                  }}
                />
              )}
            />
            <div className="ml-4 w-48">
              <Select
                handleSelect={(val) => setType(val.value as TMemberChangelogType)}
                options={[
                  { label: 'Podstawowe', value: 'simple' },
                  { label: 'Wrażliwe', value: 'secure' },
                ]}
              />
            </div>
          </div>
        </div>

        {renderErrorState()}

        <Table maxColumnWidth="300px" columns={columns} data={changesList} />

        {renderLoadingState()}

        <div className="flex justify-end">
          {lastPage > 1 && (
            <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
          )}
        </div>
      </Card>
    </div>
  )
}
