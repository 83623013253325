import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { ISearchRemote } from 'types/userlist'

export const getRoleListMe = async () => {
  const res = await publicBaseApiURL.get(endpoint.user.role)
  return res?.data
}

export const getRoleListGlobal = async () => {
  const res = await publicBaseApiURL.get(endpoint.user.roleGlobal)
  return res?.data
}

export const getRemoteSearch = async (params: ISearchRemote) => {
  const res = await publicBaseApiURL.post(endpoint.users.remoteSearch, params)
  return res?.data
}
