import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ControllerGroup } from 'components/Atoms/ControllerGroup'
import { TextareaField } from 'components/Atoms/TextareaField/TextareaField'
import { TextField } from 'components/Atoms/TextField'
import LegalSearch from 'components/Legal/LegalSearch'
import { branchForm } from 'schemas/branchSchema'
import { errorRequires } from 'tools/ToastHelpers'
import { ICreateBranch } from 'types/branch'
import { IOption } from 'types/form'
import { ILegalHook } from 'types/legal'

interface IProps {
  id?: string
  regions?: IOption[]
  isSending?: boolean
  onSubmit: (formData: any) => void
  data: any
  isCreate?: boolean
  legalData: ILegalHook
  defaultDocument?: IOption
}

export const BranchForm: React.FC<IProps> = ({
  legalData,
  regions = [],
  isCreate,
  onSubmit,
  data,
  isSending,
  defaultDocument,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setError,
  } = useForm<ICreateBranch>({
    defaultValues: data,
    resolver: yupResolver(branchForm),
  })
  const onValidationFailure = () => errorRequires(errors)

  const navigate = useNavigate()
  const label = isCreate ? 'Dodawanie oddziału' : 'Edycja oddziału'
  return (
    <Card label={label}>
      <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
        {isCreate && (
          <div className="mb-5 grid grid-cols-4 gap-5">
            <ControllerGroup
              externalHandler={legalData.setUnit}
              defaultOption={undefined}
              name="region"
              label="Okręg"
              control={control}
              options={regions}
              error={errors?.region?.message}
              setError={setError}
            />
          </div>
        )}
        <div className="mb-5 grid grid-cols-4 gap-5">
          <TextField
            id="name"
            {...register('name')}
            error={errors.name?.message}
            label="Nazwa oddziału"
            placeholder="Nazwa"
          />
          <TextField
            {...register('email')}
            label="E-mail"
            error={errors.email?.message}
            placeholder="Podaj e-mail"
          />
          <TextField
            {...register('phonePrimary')}
            error={errors.phonePrimary?.message}
            label="Numer telefonu"
            placeholder="Podaj numer"
          />
          <TextField
            {...register('phoneSecondary')}
            error={errors.phoneSecondary?.message}
            label="Numer telefonu dodatkowy"
            placeholder="Podaj numer"
          />
        </div>

        <div className="mb-5 grid grid-cols-4 gap-5">
          <TextField
            {...register('postalCode')}
            error={errors.postalCode?.message}
            label="Kod pocztowy"
            placeholder="XX-XXX"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const input = e.target.value.replace(/\D/g, '')
              if (input.length > 2) {
                setValue('postalCode', input.slice(0, 2) + '-' + input.slice(2, 5))
              } else {
                setValue('postalCode', input)
              }
            }}
          />
          <TextField
            {...register('city')}
            error={errors.city?.message}
            label="Miejscowość"
            placeholder="Miejscowość"
          />
          <div></div>
        </div>

        <div className="mb-5 grid grid-cols-4 gap-5">
          <TextField
            {...register('streetName')}
            // error={errors.streetName?.message}
            label="Ulica"
            placeholder="Ulica"
          />
          <TextField
            {...register('houseNr')}
            error={errors.houseNr?.message}
            label="Numer budynku"
            placeholder="Numer budynku"
          />
          <TextField {...register('apartmentNr')} label="Numer lokalu" placeholder="Numer lokalu" />
        </div>

        <div className="mb-5 grid w-3/4 grid-cols-1 gap-5">
          <div>
            <label
              htmlFor="locationDescription"
              className="mb-1 block text-sm font-medium text-gray-700"
            >
              Lokalizacja
            </label>
            <textarea
              id="locationDescription"
              {...register('locationDescription')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              rows={4}
            />
            {errors?.locationDescription?.message && (
              <p className="mt-2 text-sm text-red-600">{errors.locationDescription.message}</p>
            )}
          </div>
        </div>
        <div>
          <LegalSearch
            control={control}
            legalData={legalData}
            register={register}
            defaultOption={defaultDocument}
          />
          <p className="mt-2 text-sm text-red-600">{errors?.document?.message ? 'Wybierz' : ''}</p>
        </div>
        <div className="mt-5 flex justify-center">
          <div className="mr-5">
            <span
              className="base-button inline-flex cursor-pointer items-center rounded border border-transparent bg-indigo-100 px-3 py-2 text-center text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-[1px] disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none"
              onClick={() => navigate('/branch')}
            >
              Anuluj
            </span>
          </div>
          <Button disabled={isSending} label="Zatwierdź" />
        </div>
      </form>
    </Card>
  )
}
