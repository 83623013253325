import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Typography } from 'components/Atoms/Typography'
import { LegalForm } from 'components/Legal/LegalForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { legalErrors } from 'error-data/legal'
import { getBranchesList } from 'fetchers/branchFetchers'
import { createLegal } from 'fetchers/legatFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearchModule from 'hooks/useSearchModule'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { ILegal } from 'types/legal'

export const LegalCreate = () => {
  const { perPage, setPerPage, filters } = useSearchModule({})
  const { userToken } = useAuth()

  const [type, setType] = useState('region')
  const [isSending, setIsSending] = useState(false)
  const { isAdmins, unitContext, userLoaded } = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isAdmin = isAdmins()
  const setTypeHandler = (value: string) => {
    setType(value)
    setPerPage(10)
  }
  const mutation = useMutation({
    mutationFn: (data: ILegal) => createLegal(data),
    onSuccess: () => {
      successToast('Dodano dokument.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.legalList] })
      navigate(path.resolution.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, legalErrors.create, 'Nie udało się utworzyć dokumentu.')
    },
  })

  const onSubmit = async (data: ILegal) => {
    try {
      const dataForm = {
        dateEffective: data.dateEffective,
        dateEnactment: data.dateEnactment,
        identifier: data.identifier,
        name: data.name,
        unit: data.unit,
      }
      setIsSending(true)
      mutation.mutate(dataForm)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się utworzyć dokumentu.')
    }
  }

  const { data: division, isLoading } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      queryKeys.regionList,
      queryKeys.sectionsList,
      filters,
      perPage,
      type,
    ],
    queryFn: () => {
      if (type === 'branch') {
        return getBranchesList(1, perPage, filters)
      }
      if (type === 'region') {
        return getRegionsList(1, perPage, filters)
      }
      if (type === 'section') {
        return getSectionsList(1, perPage, filters)
      }
      return null
    },

    retry: useRetryHandler({
      resourceName: 'LegalCreate division',
      maxRetries: 1,
    }),
  })

  const divisionList = (division?.items || []).map(
    (e: { name: string; id: string; parent: { name: string } }) => {
      return {
        label: `${e.name}${e.parent?.name && type !== 'region' ? ` (${e.parent?.name})` : ''}`,
        value: e.id,
      }
    },
  )

  const searchMore = () => {
    const count =
      division?.pagination?.countFiltered > 0
        ? division?.pagination?.countFiltered
        : division?.pagination?.count
    if (!isLoading && division?.items?.length < count) setPerPage((prev) => prev + 10)
  }
  const initData: ILegal = {
    name: '',
    identifier: '',
    dateEnactment: new Date(),
    dateEffective: new Date(),
    unit: unitContext ? unitContext.id : '',
  }
  if (!userLoaded) return <LoadingSection />
  return (
    <section>
      <Typography size='xl' weight='medium'>
        Uchwały / {unitContext?.name ? `${unitContext?.name} /` : ''} Dodaj
      </Typography>
      <Card label='Dodaj uchwałę'>
        <LegalForm
          isAdmin={isAdmin}
          divisionLoading={isLoading}
          searchMore={searchMore}
          setTypeHandler={setTypeHandler}
          onSubmit={onSubmit}
          divisionList={divisionList}
          isSending={isSending}
          data={initData}
        />
      </Card>
    </section>
  )
}

export default LegalCreate
