import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { setupFilters } from 'tools/queryHelpers'
import { IFilters } from 'types/form'
import { IMergeData } from 'types/section'

export const getSectionsListPrefiltred = async (page: number, count: number, filters: string) => {
  const res = await publicBaseApiURL.get(endpoint.section.list(page, count, filters), {})
  return res?.data
}

export const getSectionsList = async (page: number, count: number, filters?: IFilters[]) => {
  const filterData = setupFilters(filters)
  const res = await publicBaseApiURL.get(endpoint.section.list(page, count, filterData), {})
  return res?.data
}

export const getSectionsListGlobal = async (page: number, count: number, filters?: IFilters[]) => {
  const filterData = setupFilters(filters)
  const res = await publicBaseApiURL.get(endpoint.section.listGlobal(page, count, filterData), {})
  return res?.data
}

export const getSection = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.section.byId(id), {})
  return res?.data
}

export const getSectionGroups = async () => {
  const res = await publicBaseApiURL.get(endpoint.section.subtype.group, {})
  return res?.data
}

export const createSectionClub = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.section.club.create, data)
  return res?.data
}

export const updateSectionClub = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.section.club.update(id), data)
  return res?.data
}

export const createSectionGroup = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.section.group.create, data)
  return res?.data
}
export const updateSectionGroup = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.section.group.update(id), data)
  return res?.data
}

export const postArchiveSection = async (id?: string) => {
  const res = await publicBaseApiURL.post(endpoint.section.archive(id), {})
  return res?.data
}

export const sectionClubMerge = async (targetId: string, removeId: string, data: IMergeData) => {
  const res = await publicBaseApiURL.post(endpoint.section.club.merge(targetId, removeId), data)
  return res?.data
}

// export const getSectionsMerge = async (page: number, count: number, filters?: IFilters[]) => {
//     const filterData = setupFilters(filters)
//     const res = await publicBaseApiURL.get(`${endpoint.structure.baseSections}?page=${page}&perPage=${count}${filterData}`, {
//     })
//     return res?.data
// }
