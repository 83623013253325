import { useState, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup' // If you want validation
import { useForm } from 'react-hook-form' // Import useForm
import * as yup from 'yup'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { TextareaField } from 'components/Atoms/TextareaField/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import {
  IDeclaration,
  IRejectDeclarationRequest,
} from 'features/Member/declaration/declarationSlice'

interface IProps {
  rejectedDeclarationList: IDeclaration[]
  isOpen: boolean
  handleSubmit: (data: IRejectDeclarationRequest) => void
  handleCancel: () => void
}

const MIN_REASON_LENGTH = 10

// Optional: Validation Schema
const rejectSchema = yup.object({
  reason: yup
    .string()
    .required('Pole wymagane')
    .min(MIN_REASON_LENGTH, `Uzasadnienie musi zawierać minimum ${MIN_REASON_LENGTH} znaków`),
})

export const DeclarationsRejectModal = ({
  rejectedDeclarationList,
  isOpen,
  handleSubmit,
  handleCancel,
}: IProps) => {
  const {
    register,
    handleSubmit: hookFormHandleSubmit,
    formState: { errors },
    watch, // Add watch
  } = useForm<IRejectDeclarationRequest>({
    resolver: yupResolver(rejectSchema), // Optional: Add validation
    defaultValues: { reason: '' }, // Initialize reason to empty string
  })

  const reason = watch('reason') // Get value from react-hook-form using watch
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    const trimmedReason = reason ? reason.trim() : '' // Check if reason is defined
    setCharCount(trimmedReason.length)
  }, [reason])

  const onSubmit = (data: any) => {
    const requestData = {
      items: rejectedDeclarationList.map(({ id }) => ({ id })),
      reason: data.reason,
    }
    handleSubmit(requestData)
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleCancel}>
      <Card label="Odrzucenie deklaracji">
        <Typography>
          Czy napewno chcesz odrzucić deklaracje:{' '}
          {rejectedDeclarationList.length > 0 && (
            <strong>
              {rejectedDeclarationList.map(({ firstName, lastName }, index) => (
                <span key={index}>
                  {index > 0 && ', '}
                  {firstName} {lastName}
                </span>
              ))}
            </strong>
          )}
          ?
        </Typography>
        <form onSubmit={hookFormHandleSubmit(onSubmit)}>
          <div className="mt-4">
            <div>
              <textarea
                id="reason"
                {...register('reason')}
                placeholder="Opisz powód odrzucenia (minimum 10 znaków)"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                rows={4}
              />
              {errors.reason && (
                <p className="mt-2 text-sm text-red-600">{errors.reason.message}</p>
              )}
              <p className="mt-1 flex justify-end text-xs text-gray-500">{charCount} znaków</p>
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <div className="mr-2">
              <Button label="Anuluj odrzucenie" variant="secondary" onClick={handleCancel} />
            </div>
            <Button
              label="Zatwierdź odrzucenie"
              type="submit" // Make it a submit button
              // disabled={isSubmitDisabled} // No need to disable here, yup will handle it
            />
          </div>
        </form>
      </Card>
    </Modal>
  )
}

export default DeclarationsRejectModal
