import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getTrainingList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.list(page, count, filters), {})
  return res?.data
}

export const createTraining = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.training.create, data)
  return res?.data
}

export const updateTraining = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.training.updateDetails(id), data)
  return res?.data
}

export const archiveTraining = async (id: string) => {
  const res = await publicBaseApiURL.post(endpoint.training.archive(id))
  return res?.data
}

export const getTrainingDetails = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.details(id), {})
  return res?.data
}

export const getTrainingMembersList = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.trainingMemberList(id), {})
  return res?.data
}

export const addMemberToTraining = async (id: string, memberId: string) => {
  const res = await publicBaseApiURL.post(endpoint.training.addMember(id, memberId))
  return res?.data
}

export const removeMemberFromTraining = async (id: string, memberId: string) => {
  const res = await publicBaseApiURL.delete(endpoint.training.removeMember(id, memberId))
  return res?.data
}

export const addTrainigFile = async (file: any) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}training/file/create`, {
      method: 'POST',
      headers: {
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
    if (!response.ok) {
      const errorText = await response.text()
      console.error(
        `Error uploading file: ${response.status} ${response.statusText} - ${errorText}`
      )
      throw new Error(`File upload failed: ${response.status} ${response.statusText}`)
    }
    const data = await response.json()
    console.log('Success:', data)
    return data
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const getTrainigFile = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.getFile(id), {
    responseType: 'blob',
  })

  // Extract filename from headers
  const contentDisposition = res.headers['content-disposition']
  let filename = 'downloaded_file' // Default filename
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/)
    if (filenameMatch && filenameMatch.length > 1) {
      filename = filenameMatch[1]
    }
  }

  return { data: res.data, filename }
}
