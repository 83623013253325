import { useEffect, useMemo, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { AppDispatch } from 'app/store'
import { Checkbox } from 'components/Atoms/Checkbox'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { DeclarationAccept } from 'components/Declaration/DeclarationsAccept'
import { DeclarationsRejectModal } from 'components/Declaration/DeclarationsRejectModal'
import { Button as ShadcnButton } from 'components/shadcn/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { queryKeys } from 'constants/queryKeys'
import { declarationErrors } from 'error-data/declaration'
import {
  fetchDeclarations,
  getDeclarationStatus,
  IAcceptDeclarationRequest,
  IDeclaration,
  IRejectDeclarationRequest,
} from 'features/Member/declaration/declarationSlice'
import { getBranchesList, getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import {
  acceptDeclaration,
  getDeclarationsList,
  rejectDeclaration,
} from 'fetchers/declarationFetcher'
import { getInstitution } from 'fetchers/institutionFetchers'
import { getPosition } from 'fetchers/positionFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import useSearchModule from 'hooks/useSearchModule'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, wipMessage } from 'tools/ToastHelpers'
import { IFilters } from 'types/form'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

export const Declarations = () => {
  const { filters, currentPage } = useSearchModule({ archive: false })
  const { page, setLimit, changePage } = useSearch(searchParams)
  const [isOpenAcceptStep, setIsOpenAcceptStep] = useState(false)
  const [isOpenRejectStep, setIsOpenRejectStep] = useState(false)
  const [showCompleteData, setShowCompleteData] = useState(false)
  const [isRegionSelected, setIsRegionSelected] = useState<boolean>(false)
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [acceptList, setAcceptList] = useState<IDeclaration[]>([])
  const [filteredByStatus, setFilteredByStatus] = useState<string>('')
  const [limit, setLimitState] = useState(10)
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false)

  const { isAllAdmin, isAllOD, userLoaded } = useAuth()
  const { control } = useForm()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const perPageFromUrl = parseInt(queryParams.get('perPage') || '10', 10)
  const pageFromUrl = parseInt(queryParams.get('page') || '1', 10)

  const legalData = useLegalFetcher()
  const isAdmin = isAllAdmin()
  const idBranch = isAllOD()
  const queryClient = useQueryClient()

  const dispatch = useDispatch<AppDispatch>()
  const declarationStatus = useSelector(getDeclarationStatus)

  const unredacted = 'unredacted=true'

  const buildFilters = (currentFilters: IFilters[]) => {
    let additionalFilters = showCompleteData ? unredacted : ''

    if (filteredByStatus) {
      additionalFilters += `${additionalFilters ? '&' : ''}filter[status][]=${filteredByStatus}`
    } else {
      additionalFilters += `${additionalFilters ? '&' : ''}filter[status][]=awaiting&filter[status][]=accepted&filter[status][]=rejected`
    }

    return `${currentFilters}&${additionalFilters}`
  }

  const runBuildFilters = buildFilters(filters)

  // Reset to page 1 when filter status changes
  useEffect(() => {
    if (filteredByStatus) {
      const newSearchParams = new URLSearchParams(location.search)
      newSearchParams.set('page', '1')
      navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true })
      changePage(1)
    }
  }, [filteredByStatus])

  useEffect(() => {
    setLimitState(perPageFromUrl)
  }, [perPageFromUrl])

  const {
    data: declarationData,
    isLoading: declarationLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.declarationList, currentPage, filters, runBuildFilters, limit],
    queryFn: () => getDeclarationsList(currentPage, limit, runBuildFilters),
    retry: useRetryHandler({
      resourceName: 'Declarations declarationData',
      maxRetries: 1,
    }),
    meta: {
      errorMessage: 'Nie udało się pobrać deklaracji.',
    },
  })

  // Handle errors globally
  useEffect(() => {
    if (error) {
      // You can handle errors here if needed instead of using onError callback
      console.error('Error fetching declarations:', error)
    }
  }, [error])

  // Handle empty results
  useEffect(() => {
    if (declarationData?.items?.length === 0 && currentPage > 1) {
      const newSearchParams = new URLSearchParams(location.search)
      newSearchParams.set('page', '1')
      navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true })
      changePage(1)
    }
  }, [declarationData])

  const lastPage = useLastPage(declarationData?.pagination, declarationLoading)

  // GET REGIONS AND BRANCHES
  const { data: branches, isLoading: branchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, userLoaded, selectedRegion],
    queryFn: () => {
      if (!userLoaded || (isAdmin && !selectedRegion)) return []
      if (!isAdmin) return getBranchesList(1, 1000)
      return getBranchesListPrefiltred(1, 1000, `&filter[region][]=${selectedRegion}`)
    },
    retry: useRetryHandler({
      resourceName: 'Declarations branches',
      maxRetries: 1,
    }),
  })

  const branchOptions = branches?.items
    ? branches?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!userLoaded || !isAdmin) return []
      return getRegionsList(1, 1000)
    },
    retry: useRetryHandler({
      resourceName: 'Declarations regions',
      maxRetries: 1,
    }),
  })

  const regionOptions = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  // END GET REGIONS AND BRANCHES
  const isLoading = declarationLoading || branchesLoading || !userLoaded

  const onLimitHandler = (value: string) => {
    const newLimit = parseInt(value, 10)
    setLimitState(newLimit)
    setLimit(newLimit)
    const newSearchParams = new URLSearchParams(location.search)
    newSearchParams.set('perPage', newLimit.toString())
    newSearchParams.set('page', '1') // Reset to page 1 when changing limit
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true })
    changePage(1)
  }

  useEffect(() => {
    refetch()
  }, [limit, showCompleteData])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const isChecked = e.currentTarget.checked
    setAcceptList((prevList) => {
      if (isChecked) {
        return [...prevList, row.row.original]
      } else {
        return prevList.filter((item) => item.id !== row.row.original.id)
      }
    })
  }

  const handleConfirm = () => setIsOpenAcceptStep(true)
  const handleOpenRejectModal = () => setIsOpenRejectModal(true)
  const handleCloseRejectModal = () => setIsOpenRejectModal(false)

  const handleCheckAll = () => {
    if (declarationData?.items) {
      setAcceptList([...declarationData.items])
    }
  }

  const toggleCheckAll = () => {
    if (acceptList.length === declarationData?.items?.length) {
      setAcceptList([])
    } else {
      handleCheckAll()
    }
  }

  const mutation = useMutation({
    mutationFn: (data: IAcceptDeclarationRequest) => acceptDeclaration(data),
    onSuccess: () => {
      successToast('Deklaracja została zaakceptowana.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.declarationList] })
      refetch()
      setIsOpenAcceptStep(false)
      setAcceptList([])
    },
    onError: (error: AxiosError, data) => {
      console.error('error', error)
      const noDeclaration = typeof data?.resolution !== 'string'
      const messageArr = []
      const noItems = data?.items?.length === 0
      if (noDeclaration) messageArr.push('Nie przypisano dokumentu')
      if (noItems) messageArr.push('Nie wybrano deklaracji')
      if (messageArr.length === 0) messageArr.push('Nie zaaakceptowano deklaracji.')
      const message = messageArr.join(', ')
      mutationErrorHandler(error, declarationErrors.accept, message)
    },
  })

  const mutationReject = useMutation({
    mutationFn: (data: IRejectDeclarationRequest) => rejectDeclaration(data),
    onSuccess: () => {
      successToast('Deklaracja została odrzucona.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.declarationList] })
      refetch()
      setIsOpenRejectModal(false)
      setAcceptList([])
    },
    onError: (error: AxiosError) => {
      setIsOpenRejectModal(false)
      console.error('error', error)
      mutationErrorHandler(error, declarationErrors.reject, 'Nie udało się odrzucić deklaracji.')
    },
  })

  const rejectHandler = (data: IRejectDeclarationRequest) => {
    try {
      mutationReject.mutate(data)
    } catch (error) {
      setIsOpenRejectModal(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się odrzucić deklaracji.')
    }
  }

  useEffect(() => {
    dispatch(fetchDeclarations(filteredByStatus))
  }, [filteredByStatus, declarationStatus, dispatch])

  const translateStatusEnum = (status: string) => {
    switch (status) {
      case 'awaiting':
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Oczekująca
          </span>
        )
      case 'accepted':
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Zaakceptowana
          </span>
        )
      case 'rejected':
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Odrzucona
          </span>
        )
      default:
        return 'Nieznana'
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: (
          <Checkbox
            label=""
            id="selectAll"
            checked={acceptList.length === declarationData?.items?.length}
            onChange={toggleCheckAll}
          />
        ),
        accessor: 'id',
        Cell: (row: any) => (
          <Controller
            name="unitId"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={acceptList.some((item) => item.id === row.row.original.id)}
                onChange={(e) => {
                  onChange(e.target.checked)
                  handleCheckboxChange(e, row)
                }}
              />
            )}
          />
        ),
      },
      {
        Header: 'Imię i nazwisko',
        accessor: 'firstName',
        Cell: (row: any) => {
          const firstName = row.row.original.firstName
          const lastName = row.row.original.lastName
          return <p>{`${firstName} ${lastName}`}</p>
        },
      },
      {
        Header: 'Telefon',
        accessor: 'phoneNumber',
      },
      {
        Header: 'PESEL',
        accessor: 'pesel',
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
        Cell: (row: any) => {
          const positionId = row.row.original.position
          const { data: position } = useQuery({
            queryKey: [userToken, queryKeys.position, positionId],
            queryFn: () => getPosition(positionId),
            retry: useRetryHandler({
              resourceName: 'Declarations Stanowisko',
              maxRetries: 1,
            }),
          })

          const displayName = position?.name
            ? showCompleteData
              ? position.name
              : position.name.charAt(0) + '...'
            : ''

          return <p>{displayName}</p>
        },
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Placówka',
        accessor: 'basePlace',
        Cell: (row: any) => {
          const basePlaceId = row.row.original.basePlace

          const { data: institution } = useQuery({
            queryKey: [userToken, queryKeys.institution, basePlaceId],
            queryFn: () => getInstitution(basePlaceId),
            retry: useRetryHandler({
              resourceName: 'Declarations Główna placówka',
              maxRetries: 1,
            }),
          })

          const displayName = institution?.name
            ? showCompleteData
              ? institution.name
              : institution.name.charAt(0) + '...'
            : ''

          return <p>{displayName}</p>
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (row: any) => {
          const status = row.row.original.status
          return <span>{translateStatusEnum(status)}</span>
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const declarationId = row.row.original.id
          return (
            <div className="flex">
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName="EyeIcon"
                title="Zobacz"
                onClick={() => navigate(`/declaration/${declarationId}`)}
              />
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName="PencilIcon"
                title="Edytuj"
                onClick={() => navigate(`/declaration/${declarationId}/update`)}
              />
            </div>
          )
        },
      },
    ],
    [declarationData?.items, acceptList, showCompleteData, runBuildFilters]
  )

  const verified = isAdmin || idBranch
  if (!verified) {
    return <LoadingModal isLoading={isLoading} forbidden={!verified} route={false} />
  }
  if (isOpenAcceptStep) {
    return (
      <DeclarationAccept
        choosenDeclaration={acceptList}
        handleAcceptDeclaration={mutation.mutate}
        handleCloseList={() => setIsOpenAcceptStep(false)}
        legalData={legalData}
        branchId={selectedBranch}
      />
    )
  }

  const editIsActive = acceptList.length === 0 || selectedBranch === ''

  if (isLoading) {
    return (
      <div>
        <div className="mb-4 h-4 w-full max-w-lg animate-pulse rounded-md bg-slate-300"></div>
        <div className="flex justify-between">
          <div className="mb-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300"></div>
          <div className="mb-4 h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300"></div>
        </div>
        <Card>
          <CardContent>
            <div className="my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300"></div>
            <div className="mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300"></div>
            <div className="mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300"></div>
            <div className="h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300"></div>
          </CardContent>
        </Card>
      </div>
    )
  }

  // Display a message when no declarations are found
  const noDeclarations = declarationData?.items?.length === 0

  return (
    <>
      <Card>
        <CardContent>
          <CardHeader className="flex flex-row items-center justify-between px-0">
            <CardTitle className="text-2xl">Deklaracje członkowskie</CardTitle>
            <ShadcnButton
              variant="outline"
              className=""
              onClick={() => navigate('/declaration/create')}
            >
              Utwórz deklarację
            </ShadcnButton>
          </CardHeader>

          {isAdmin ? (
            <div className="mb-4 grid w-full max-w-2xl grid-cols-2 gap-4">
              <div>
                <Controller
                  name="regionId"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      value={selectedRegion || undefined}
                      onValueChange={(value) => {
                        onChange(value)
                        setSelectedRegion(value === 'none' ? null : value)
                        setSelectedBranch('')
                        setIsRegionSelected(value !== 'none')
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Wskaż okręg" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="none">---</SelectItem>
                        {regionOptions.map((option: any) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
              {isRegionSelected && regionOptions.length > 0 && (
                <div>
                  <Controller
                    name="branchId"
                    control={control}
                    defaultValue={{}}
                    render={({ field: { onChange } }) => (
                      <Select
                        value={selectedBranch || undefined}
                        onValueChange={(value) => {
                          onChange(value)
                          setSelectedBranch(value)
                          legalData.setUnit(value)
                        }}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Wskaż oddział" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="none">---</SelectItem>
                          {branchOptions.map((option: any) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="mb-4 grid w-full max-w-2xl grid-cols-2 gap-4">
              <Controller
                name="unitId"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    value={selectedBranch || undefined}
                    onValueChange={(value) => {
                      onChange(value)
                      setSelectedBranch(value === 'none' ? '' : value)
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Wskaż oddział" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="none">---</SelectItem>
                      {branchOptions.map((option: any) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Typography weight="medium" className="whitespace-nowrap text-sm">
                Liczba wierszy na stronę
              </Typography>
              <Select onValueChange={(value) => onLimitHandler(value)} value={limit.toString()}>
                <SelectTrigger className="w-full max-w-[80px]" id="declarationsSelect">
                  <SelectValue placeholder={limit.toString()} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className="max-w-sm">
                    <SelectItem value="10">10</SelectItem>
                    <SelectItem value="20">20</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div className="flex items-center">
              <Controller
                name="completeData"
                control={control}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    label="Pokazuj kompletne dane"
                    id="Pokazuj kompletne dane"
                    checked={showCompleteData}
                    onChange={(e) => {
                      onChange(e.target.checked)
                      setShowCompleteData((prev) => !prev)
                    }}
                  />
                )}
              />
              <div className="ml-4 w-48">
                <Select
                  value={filteredByStatus || undefined}
                  onValueChange={(value) => setFilteredByStatus(value === 'all' ? '' : value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">Wszystkie</SelectItem>
                    <SelectItem value="awaiting">Oczekujące</SelectItem>
                    <SelectItem value="accepted">Zaakceptowane</SelectItem>
                    <SelectItem value="rejected">Odrzucone</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <span className="ml-4">
                {lastPage > 1 && (
                  <Pagination
                    lastPage={lastPage}
                    currentPage={page}
                    handlePageChange={changePage}
                  />
                )}
              </span>
            </div>
          </div>

          {!declarationData?.items ? (
            <p>Wczytuję...</p>
          ) : noDeclarations ? (
            <div className="my-10 text-center">
              <Typography weight="medium" className="text-gray-600">
                {filteredByStatus
                  ? `Brak deklaracji o statusie "${
                      filteredByStatus === 'awaiting'
                        ? 'Oczekujące'
                        : filteredByStatus === 'accepted'
                          ? 'Zaakceptowane'
                          : filteredByStatus === 'rejected'
                            ? 'Odrzucone'
                            : ''
                    }"`
                  : 'Brak deklaracji'}
              </Typography>
            </div>
          ) : (
            <Table maxColumnWidth="300px" data={declarationData.items} columns={columns} />
          )}

          <div className="mt-4 flex justify-end gap-4">
            <ShadcnButton
              variant="destructive"
              onClick={handleOpenRejectModal}
              disabled={acceptList.length === 0}
            >
              Odrzuć deklarację
            </ShadcnButton>
            <ShadcnButton variant="default" onClick={handleConfirm} disabled={editIsActive}>
              Zatwierdź deklarację
            </ShadcnButton>
          </div>
        </CardContent>
      </Card>
      <DeclarationsRejectModal
        rejectedDeclarationList={acceptList}
        isOpen={isOpenRejectModal}
        handleSubmit={rejectHandler}
        handleCancel={handleCloseRejectModal}
      />
    </>
  )
}

export default Declarations
