import { useState } from 'react'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { EditMemberData } from 'components/StatisticalObligation/EditMemberData'

const leftMember = [
  {
    name: 'Whitney Francis',
  },
  {
    name: 'Courtney Henry',
  },
]

export const ConfirmData = () => {
  const [isEditDataView, setIsEditDataView] = useState(false)

  if (isEditDataView) return <EditMemberData />

  return (
    <>
      <Card label='Wypełnianie statystyk'>
        <div className='flex items-center justify-between'>
          <div className='flex'>
            <div className='mr-5'>
              <Checkbox label='Pokaż wszystkich' />
            </div>
            <Checkbox label='Pokaż niepotwierdzonych' />
          </div>
          {/* <Pagination /> */}
        </div>
      </Card>

      <Card>
        <Typography size='xl' weight='medium' className='text-center'>
          Opisz powody usunięcia wskazanych osób
        </Typography>

        {leftMember.map((member, index) => (
          <div key={member.name} className='mb-5 grid w-1/2 grid-cols-3 gap-5 border-b p-5'>
            <Typography size='base'>{member.name}</Typography>
            <Select
              label='Powód usunięcia'
              options={[
                { label: 'powód1', value: '1' },
                { label: 'powód2', value: '2' },
              ]}
            />
            <TextField label='Komentarz' />
          </div>
        ))}
      </Card>

      <Card>
        <Typography size='xl' weight='medium' className='text-center'>
          Czy któryś z członków zmienił dane kontaktowe?
        </Typography>
        <div className='mt-5 flex justify-center'>
          <Button onClick={() => setIsEditDataView(true)} label='TAK' className='mr-5' />
          <Button label='NIE' variant='secondary' />
        </div>
      </Card>

      <Card>
        <div className='mt-5 flex flex-col justify-center'>
          <div className='mx-auto mb-5'>
            <Button label='Zatwierdź' />
          </div>
          <Typography size='xl' weight='medium' className='text-center'>
            Zgłoszone zmiany 2 z 15 osób
          </Typography>
        </div>
      </Card>
    </>
  )
}
