import React from 'react'

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl'
  weight?: 'normal' | 'medium' | 'bold'
  children: React.ReactNode
}

export const Typography: React.FC<IProps> = ({
  children,
  size = 'base',
  weight = 'normal',
  className,
  ...props
}) => (
  <p className={`text-${size} font-${weight} ${className ? className : ''} break-words`} {...props}>
    {children}
  </p>
)
