import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { TextField } from 'components/Atoms/TextField'
import FilterSection from 'components/Utilites/FilterSection'
import { queryKeys } from 'constants/queryKeys'
import { getVoivodeship } from 'fetchers/voivodeshipFetchers'
import { IInstitutionSearchQuery } from 'types/institution'

interface Props {
  archive?: boolean
  queryHandler: (query: IInstitutionSearchQuery) => void
}

const defaultValues = {
  name: '',
  city: '',
}

type FilterQuery = {
  [key: string]: string
}

export const InstitutionSearch = ({ archive, queryHandler }: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [query, setSearchQuery] = useState<FilterQuery>({
    name: '',
    city: '',
    voivodeship: '',
  })
  const [voivodeshipSelected, setVoivodeshipSelected] = useState<string[]>([])
  const { register, setValue, handleSubmit } = useForm<typeof defaultValues>({ defaultValues })
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const {
    data: voivodeship,
    isLoading,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.voivodeships],
    queryFn: () => getVoivodeship(),
  })

  const voivodeshipList = voivodeship?.items
    ? voivodeship?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  const submitHandler = (e: any) => {
    queryHandler({
      name: e.name,
      city: e.city,
      voivodeship: voivodeshipSelected.length > 0 ? voivodeshipSelected : undefined,
    })
  }

  useEffect(() => {
    if (!isLoaded && voivodeshipList.length > 0) {
      setIsLoaded(true)
      setVoivodeshipSelected(voivodeshipList.map((e: any) => e.value))
    }
  }, [voivodeshipList, isLoading])

  return (
    <Card label="Szukaj">
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="grid gap-5 md:grid-cols-3">
          <TextField label="Nazwa placówki" {...register('name')} />
          <TextField label="Miasto" {...register('city')} />
          <FilterSection
            label="Województwo"
            filterQuery={query.voivodeship || ''}
            setSearchQuery={setSearchQuery}
            dataList={voivodeshipList}
            selectedList={voivodeshipSelected}
            setSelectedList={setVoivodeshipSelected}
          />
        </div>
        <div className="mt-6 flex items-center justify-center">
          <Button label="Szukaj" />
        </div>
      </form>
    </Card>
  )
}

export default InstitutionSearch
