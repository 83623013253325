import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import LegalSearch from 'components/Legal/LegalSearch'
import { createSectionSchemaGroup } from 'schemas/sectionSchema'
import { errorRequires } from 'tools/ToastHelpers'
import { ILegalHook } from 'types/legal'
import { ICreateSectionGroup } from 'types/section'

interface IProps {
  isSending?: boolean
  onSubmit: (data: any) => void
  navigate: (path: string) => void
  data: ICreateSectionGroup
  branchesList?: IOption[]
  typesList: IOption[]
  createRoot?: boolean
  legalData: ILegalHook
}
interface IOption {
  label: string
  value: number | string
}

export const SectionFormGroup: React.FC<IProps> = ({
  legalData,
  isSending,
  onSubmit,
  navigate,
  data,
  branchesList = [],
  typesList,
  createRoot,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ICreateSectionGroup>({
    resolver: yupResolver(createSectionSchemaGroup),
    defaultValues: data,
    context: { isEditing: !createRoot },
  })

  // Log form values when they change (helpful for debugging)
  const watchedValues = watch()
  console.log('Form values:', watchedValues)

  // Ensure document is handled as a string
  useEffect(() => {
    const documentValue = watchedValues.document
    // If document is an object with an id property, convert it to a string
    if (documentValue && typeof documentValue === 'object') {
      // Use type assertion to tell TypeScript about the structure
      const docObj = documentValue as { id: string; name?: string }
      if (docObj.id) {
        setValue('document', docObj.id)
      }
    }
  }, [watchedValues.document, setValue])

  const onValidationFailure = () => errorRequires(errors)

  const handleFormSubmit = (formData: ICreateSectionGroup) => {
    // Ensure document is a string before submission
    const submissionData = { ...formData }
    if (submissionData.document && typeof submissionData.document === 'object') {
      // Use type assertion to access the id property
      const docObj = submissionData.document as { id: string; name?: string }
      if (docObj.id) {
        submissionData.document = docObj.id
      }
    }
    onSubmit(submissionData)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit, onValidationFailure)}>
      {createRoot && (
        <div className="mb-5 grid grid-cols-4 gap-5">
          <div>
            <p className="mb-1 block text-sm font-medium text-gray-700">Oddział</p>
            <Controller
              name={'branch'}
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => {
                    onChange(value)
                    legalData && legalData.setUnit(String(value))
                  }}
                  options={branchesList}
                  withEmpty
                />
              )}
            />
            <p className="mt-2 text-sm text-red-600">{errors?.branch?.message}</p>
          </div>
          <div>
            <p className="mb-1 block text-sm font-medium text-gray-700">Typ sekcji</p>
            <Controller
              name={'subtype'}
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => onChange(value)}
                  options={typesList}
                  withEmpty
                />
              )}
            />
            <p className="mt-2 text-sm text-red-600">{errors?.subtype?.message}</p>
          </div>
        </div>
      )}
      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          {...register('phonePrimary')}
          error={errors.phonePrimary?.message}
          label="Numer telefonu"
          placeholder="Podaj numer"
        />
        <TextField
          {...register('phoneSecondary')}
          error={errors.phoneSecondary?.message}
          label="Numer telefonu dodatkowy"
          placeholder="Podaj numer"
        />
        <TextField
          {...register('email')}
          error={errors.email?.message}
          label="E-mail kontaktowy"
          placeholder="Podaj e-mail"
        />
        <div>
          <LegalSearch control={control} legalData={legalData} register={register} />
          <p className="mt-2 text-sm text-red-600">
            {errors?.document ? errors.document.message || 'Wskazanie uchwały jest wymagane' : ''}
          </p>
        </div>
      </div>

      <div className="my-5 grid w-3/4 grid-cols-1 gap-5">
        <div>
          <label
            htmlFor="locationDescription"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Lokalizacja
          </label>
          <textarea
            id="locationDescription"
            {...register('locationDescription')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={4}
          />
          {errors.locationDescription && (
            <p className="mt-2 text-sm text-red-600">{errors.locationDescription.message}</p>
          )}
        </div>
      </div>

      <div className="mt-10 flex">
        <div className="mr-5">
          <Button variant="secondary" label="Anuluj" onClick={() => navigate('/section')} />
        </div>
        <Button
          disabled={isSending}
          label="Zatwierdź"
          onClick={() => {
            // Log form data before submission (helpful for debugging)
            console.log('Pre-submission form data:', watch())
          }}
        />
      </div>
    </form>
  )
}
