// DeclarationCreate.tsx - Updated version
import { useState, useEffect, useCallback } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAppSelector } from 'app/hooks'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Typography } from 'components/Atoms/Typography'
import { DeclarationForm } from 'components/Declaration/DeclarationForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { declarationErrors } from 'error-data/declaration'
import { IRequestDeclaration } from 'features/Member/declaration/declarationSlice'
import { getBranchesList } from 'fetchers/branchFetchers'
import { addDeclarationDoc, createDeclaration } from 'fetchers/declarationFetcher'
import { getEducationsList } from 'fetchers/educationFetchers'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { isAllOD } from 'tools/roleHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { IDeclaration, IDeclarationForm } from 'types/declaration'

import { declarationInit } from './initData'

export const DeclarationCreate = () => {
  const [file, setFile] = useState<File>()
  const [isSending, setIsSending] = useState(false)
  const [selectedBranchId, setSelectedBranchId] = useState<string>('')
  const [formInitData, setFormInitData] = useState<any>(null)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const user = useAppSelector((state: any) => state?.authReducer)
  const { userToken, userLoaded, unitContext, isAllAdmin } = useAuth()
  const isAdmin = isAllAdmin()
  const verified = userLoaded && (isAdmin || isAllOD(userLoaded))

  // Initialize selectedBranchId with the unitContext.id if user is a branch admin
  useEffect(() => {
    if (!isAdmin && unitContext?.id) {
      setSelectedBranchId(unitContext.id)
    }
  }, [isAdmin, unitContext])

  // Initialize form data properly after user data is loaded
  useEffect(() => {
    if (userLoaded) {
      const initialFormData = {
        ...declarationInit,
        branch: isAdmin ? '' : unitContext?.id || '',
        declarationDoc: '',
      }
      setFormInitData(initialFormData)
    }
  }, [userLoaded, isAdmin, unitContext])

  const { data, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, 1, 1000, userLoaded],
    queryFn: () => {
      if (!isAdmin) return { items: [] }
      return getBranchesList(1, 1000, [])
    },
    retry: useRetryHandler({
      resourceName: 'DeclarationCreate data',
      maxRetries: 1,
    }),
  })

  const branchList = data?.items
    ? data?.items?.map((item: any) => {
        return { label: `${item.name} | ${item?.parent?.name}`, value: item.id }
      })
    : []

  const { data: education, isLoading: loadingEducation } = useQuery({
    queryKey: [userToken, queryKeys.educationList],
    queryFn: () => getEducationsList(1, 100),
    retry: useRetryHandler({
      resourceName: 'DeclarationCreate education',
      maxRetries: 1,
    }),
  })

  const { data: institution, isLoading: loadingInstitution } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, 1, 1000, selectedBranchId],
    queryFn: () => getInstitutionsList(1, 1000, selectedBranchId),
    retry: useRetryHandler({
      resourceName: 'DeclarationCreate institution',
      maxRetries: 1,
    }),
    enabled: !!selectedBranchId,
  })

  const { data: position, isLoading: positionInstitution } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, 1, 1000],
    queryFn: () => getPositionsList(1, 1000),
    retry: useRetryHandler({
      resourceName: 'DeclarationCreate position',
      maxRetries: 1,
    }),
  })

  const { data: subject, isLoading: subjectInstitution } = useQuery({
    queryKey: [userToken, queryKeys.subjectsList, 1, 1000],
    queryFn: () => getSubjectsList(1, 1000),
    retry: useRetryHandler({
      resourceName: 'DeclarationCreate subject',
      maxRetries: 1,
    }),
  })

  const educationList = education?.items
    ? education?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const institutionList = institution?.items
    ? institution?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const positionList = position?.items
    ? position?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const subjectList = subject?.items
    ? subject?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  // Helper function to transform the nested job object structure to the flat structure expected by the API
  const transformJobsData = (jobs: any[]) => {
    if (!jobs || !Array.isArray(jobs)) return []

    return jobs.map((job) => {
      // Extract just the value from the nested objects
      return {
        institution: job.institution?.value || '',
        position: job.position?.value || '',
        subject: job.subject?.value || '',
        isMain: job.isMain || false,
      }
    })
  }

  const mutation = useMutation({
    mutationFn: (data: IDeclarationForm) => {
      const { redacted, status, ...formData } = data

      // Create a new object with transformed jobs data
      const transformedData = {
        ...formData,
        jobs: transformJobsData(formData.jobs),
      }

      return createDeclaration(transformedData)
    },
    onSuccess: () => {
      successToast('Dodano nową deklarację.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.declaration.list)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSending(false)
      error.response?.data.errors.forEach((item: any) => {
        toast.error(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, declarationErrors.create, 'Nie udało się dodać deklaracji.')
    },
  })

  const fileUpload = useMutation({
    mutationFn: (data: IDeclarationForm) => {
      const { redacted, status, ...formData } = data
      return addDeclarationDoc(file)
    },
    onSuccess: (res, data) => {
      mutation.mutate({ ...data, declarationFile: res?.id })
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        declarationErrors.file,
        'Nie udało się dodać deklaracji. Załącznik odrzucony.'
      )
    },
  })

  const onSubmit = async (data: any) => {
    setIsSending(true)
    try {
      const hasFile = !!file
      const { declarationDoc, validationResult, ...formData } = data

      // We don't need to transform jobsSimple as it already has the correct structure
      const prepData = {
        ...formData,
        jobs: formData.jobs || [],
        jobsSimple: formData.jobsSimple || [],
      }

      if (hasFile) {
        fileUpload.mutate(prepData)
      } else {
        mutation.mutate(prepData)
      }
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać deklaracji.')
    }
  }

  // Use useCallback to prevent unnecessary re-renders
  const handleBranchChange = useCallback((branchId: string) => {
    setSelectedBranchId(branchId)
  }, [])

  if (
    !userLoaded ||
    isLoading ||
    loadingEducation ||
    loadingInstitution ||
    positionInstitution ||
    subjectInstitution ||
    !formInitData
  ) {
    return <LoadingSection />
  }

  if (!verified) return <ErrorRoleModal />

  return (
    <div>
      <Typography size="xl" weight="medium">
        Deklaracje członkowskie / Utwórz
      </Typography>
      <DeclarationForm
        onFileChange={setFile}
        branchList={branchList}
        educationList={educationList}
        institutionList={institutionList}
        positionList={positionList}
        subjectList={subjectList}
        isAdmin={isAdmin}
        initData={formInitData}
        onSubmit={onSubmit}
        isEditView={false}
        isSending={isSending}
        onBranchChange={handleBranchChange}
        selectedBranchId={selectedBranchId}
      />
    </div>
  )
}
