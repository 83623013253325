import React from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getBranch } from 'fetchers/branchFetchers'
import { getDeclaration } from 'fetchers/declarationFetcher'
import { getInstitution } from 'fetchers/institutionFetchers'
import { getPosition } from 'fetchers/positionFetchers'
import { peselToBirthDate } from 'hooks/peselToBirthDate'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { getLoadingHandler } from 'tools/queryHelpers'

export const DeclarationDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const declarationId = id as string
  const { userToken, isAllAdmin, isAllOD, userLoaded } = useAuth()
  const isAllow = isAllAdmin() || isAllOD()

  const {
    data: declaration,
    isLoading: declarationLoader,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, 1, 1000],
    queryFn: () => getDeclaration(declarationId),
    retry: useRetryHandler({
      resourceName: 'DeclarationDetails declaration',
      maxRetries: 1,
    }),
  })

  const { data: branch, isLoading: branchLoading } = useQuery({
    queryKey: [userToken, queryKeys.branch, declaration?.branch],
    queryFn: () => {
      if (declarationLoader) return { name: '', parent: { name: '' } }
      return getBranch(declaration?.branch)
    },
    retry: useRetryHandler({
      resourceName: 'DeclarationDetails branch',
      maxRetries: 1,
    }),
  })

  const employmentScheme = React.useMemo(
    () => [
      {
        Header: 'Miejsce zatrudnienia',
        accessor: 'institution',
        Cell: ({ row }: any) => {
          const institutionId = row.original.institution

          const { data: institution } = useQuery({
            queryKey: [userToken, queryKeys.institution, institutionId],
            queryFn: () => getInstitution(institutionId),
            retry: useRetryHandler({
              resourceName: 'DeclarationDetails Miejsce zatrudnienia',
              maxRetries: 1,
            }),
          })

          const displayName = institution?.name || institutionId

          return <p>{displayName}</p>
        },
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
        Cell: ({ row }: any) => {
          const positionId = row.original.position
          const { data: position } = useQuery({
            queryKey: [userToken, queryKeys.position, positionId],
            queryFn: () => getPosition(positionId),
            retry: useRetryHandler({
              resourceName: 'DeclarationDetails Stanowisko',
              maxRetries: 1,
            }),
          })

          const displayName = position?.name || positionId

          return <p>{displayName}</p>
        },
      },
      {
        Header: 'Przedmiot',
        accessor: 'subject',
        Cell: ({ row }: any) => {
          const subjectId = row.original.subject
          const { data: institution } = useQuery({
            queryKey: [userToken, queryKeys.institution, subjectId],
            queryFn: () => {
              if (!subjectId) return { name: '-' }
              return getInstitution(subjectId)
            },
            retry: useRetryHandler({
              resourceName: 'DeclarationDetails Przedmiot',
              maxRetries: 1,
            }),
          })

          const displayName = institution?.name || subjectId

          return <p>{displayName}</p>
        },
      },
    ],
    [declaration, userToken]
  )
  const jobs = declaration?.jobs || []
  const jobsSimple = declaration?.jobsSimple || []
  const employment = declaration ? [...jobs, ...jobsSimple] : []

  const isLoading = declarationLoader || !userLoaded || branchLoading
  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <div className="flex justify-between">
        <Typography size="xl" weight="medium">
          Deklaracja członka: {declaration?.firstName} {declaration?.lastName}
        </Typography>
      </div>
      <div className="mt-6 flex gap-5">
        <Button label="Edytuj" onClick={() => navigate('update')} />
      </div>
      <Card label="Dane podstawowe">
        <div className="grid grid-cols-5 gap-4">
          <LabelTypography label="Numer identyfikacyjny" text={declaration?.referenceId} />
          <LabelTypography label="Okręg" text={branch?.parent?.name} />
          <LabelTypography label="Oddział" text={branch?.name} />
          {/* <LabelTypography label='Status aplikacji' text={declaration?.status} /> */}
        </div>
      </Card>
      <div className="grid grid-cols-5 gap-5">
        <div className="col-span-3">
          <Card label="Dane osobowe">
            <div className="grid grid-cols-4 gap-4">
              <LabelTypography label="Imię" text={declaration?.firstName} />
              <LabelTypography label="Drugie imię" text={declaration?.secondName} />
              <LabelTypography label="Nazwisko" text={declaration?.lastName} />
              <LabelTypography
                label="Płeć"
                text={declaration?.sex === 'male' ? 'Mężczyzna' : 'Kobieta'}
              />
              <LabelTypography label="Data urodzenia" text={declaration?.birthDate} />
              <LabelTypography label="PESEL" text={declaration?.pesel} />
              <LabelTypography label="Adres e-mail" text={declaration?.email} />
              <LabelTypography label="Telefon" text={declaration?.phoneNumber} />
            </div>
          </Card>
        </div>
        <div className="col-span-2">
          <Card label="Dane kontaktowe">
            <div className="grid grid-cols-3 gap-4">
              <LabelTypography label="Ulica" text={declaration?.street} />
              <LabelTypography label="Numer domu" text={declaration?.houseNr} />
              <LabelTypography label="Numer mieszkania" text={declaration?.apartmentNr} />
              <LabelTypography label="Miejscowość" text={declaration?.city} />
              <LabelTypography label="Kod pocztowy" text={declaration?.zipCode} />
            </div>
          </Card>
        </div>
      </div>
      <Card label="Edukacja i zatrudnienie">
        <Table maxColumnWidth="300px" columns={employmentScheme} data={employment} />
      </Card>
    </>
  )
}
