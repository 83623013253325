import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Pagination } from 'components/Atoms/Pagination'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { SectionActionCell } from 'components/Section/SectionActionCell'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { useArchiveSection } from 'fetchers/hooks/useArchiveSection'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import useSearchModule from 'hooks/useSearchModule'
import { getLastPage } from 'tools/queryHelpers'
import { useAppSelector } from 'app/hooks'

type Props = {
  archive?: boolean
}

export const SectionList = ({ archive }: Props) => {
  const { filters, currentPage, search, setSearch, onPageChange, onSearchHandler } =
    useSearchModule({ archive })

  const { userInfo } = useAppSelector((state: any) => state.authReducer)

  const navigate = useNavigate()
  const { userToken, isAllAdmin, isAllOD } = useAuth()
  const isEditAllow = isAllAdmin() || isAllOD()

  // DATA
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, currentPage, filters],
    queryFn: () => getSectionsList(Number(currentPage), 100, filters),
  })
  const { lastPage } = getLastPage(data?.pagination)

  // NAVIGATION HANDLERS
  const createClubHandler = () => navigate(path.section.createClub)
  const createGroupHandler = () => navigate(path.section.createGroup)
  const archiveListHandler = () => navigate(path.section.archive)
  const mergeHandler = () => navigate(path.section.merge)

  // MODAL HANDLER
  const { showModalHandler, ArchiveModal } = useArchiveSection({
    refetch,
  })


  // TABLE COLUMNS
  const columnsScheme = [
    {
      Header: 'Nazwa',
      accessor: 'name',
      Cell: (row: any) => <TableName name={row.row.original.name} />,
    },
    {
      Header: 'Oddział',
      accessor: 'parent',
      Cell: (row: any) => <p>{row.row.original.parent.name}</p>,
    },
    {
      Header: 'Typ',
      accessor: 'subtype',
      Cell: (row: any) => <p>{row.row.original.subtype === 'club' ? 'Ognisko' : 'Sekcja'}</p>,
    },
    { Header: 'Liczba członków', accessor: 'memberCnt' },

    ...(userInfo?.context?.role === 'ROLE_REGION_BOARD' ||
    userInfo?.context?.role === 'ROLE_REGION_PRESIDENT'
      ? []
      : [
          {
            Header: 'Akcje',
            accessor: 'action',
            Cell: (row: any) => {
              return (
                <SectionActionCell
                  isEditAllow={isEditAllow}
                  archive={archive}
                  club={row.row.original.subtype === 'club'}
                  data={row.row.original}
                  showModalHandler={showModalHandler}
                />
              )
            },
          },
        ]),
  ]
  const columns = useMemo(() => columnsScheme, [archive, isEditAllow])

  if (isLoading) {
    return <LoadingSection />
  }

  if (error) {
    console.error(error)
    return <>{error?.message}</>
  }

  return (
    <div>
      <Typography size="xl" weight="medium">
        {archive ? (
          <>
            <Link to="/section">Lista ognisk</Link> / Zarchiwizowane
          </>
        ) : (
          'Lista ognisk'
        )}
      </Typography>
      <Card label="Szukaj">
        <form onSubmit={onSearchHandler}>
          <div className="mb-5 grid w-3/4 grid-cols-2 gap-4">
            <TextField label="Szukaj ogniska" value={search} type="text" onChange={setSearch} />
            <div className="flex items-end">
              <Button label="Szukaj" />
            </div>
          </div>
        </form>
      </Card>
      {!archive && (
        <div className="mt-5 flex justify-between">
          <div className="flex gap-5">
            <Button label="Utwórz ognisko" onClick={createClubHandler} />
            <Button label="Utwórz sekcję" onClick={createGroupHandler} />
          </div>
          <div className="flex gap-5">
            <Button label="Połącz" onClick={mergeHandler} />
            <Button label="Zarchiwizowane" onClick={archiveListHandler} />
          </div>
        </div>
      )}
      <Table columns={columns} data={data?.items} maxColumnWidth="8rem" />
      <div className="flex justify-end">
        <Pagination lastPage={lastPage} currentPage={currentPage} handlePageChange={onPageChange} />
      </div>
      <ArchiveModal />
    </div>
  )
}
