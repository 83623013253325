import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { BranchForm } from 'components/Branch/BranchForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { branchErrors } from 'error-data/branch'
import { getBranch, updateBranch } from 'fetchers/branchFetchers'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { ICreateBranch } from 'types/branch'

export const BranchUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { userToken } = useAppSelector((state) => state.authReducer)
  const branchId = id as string
  const legalData = useLegalFetcher()

  const {
    data: branch,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.branch, branchId],
    queryFn: () => getBranch(branchId),
    retry: useRetryHandler({
      resourceName: 'BranchUpdate branch',
      maxRetries: 1,
    }),
  })

  const mutation = useMutation({
    mutationFn: (data: ICreateBranch) => updateBranch(branchId, data),
    onSuccess: () => {
      successToast('Zaktualizowano oddział.')
      refetch()
      queryClient.invalidateQueries({
        queryKey: [queryKeys.branchesList, branchId],
      })
      navigate(path.branch.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, branchErrors.update, 'Nie udało się zaktualizować oddziału.')
    },
  })

  const onSubmit = async (data: ICreateBranch) => {
    const { id, region, ...formData } = data
    try {
      setIsSending(true)
      mutation.mutate(formData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować oddziału.')
    }
  }

  const newData = {
    id: branch?.id,
    name: branch?.name,
    region: branch?.parent?.id ? branch?.parent?.id : '',
    email: branch?.contact?.email ? branch?.contact?.email : '',
    phonePrimary: branch?.contact?.phone ? branch?.contact?.phone : '',
    phoneSecondary: branch?.contact?.phoneSecondary ? branch?.contact?.phoneSecondary : '',
    postalCode: branch?.address?.postalCode ? branch?.address?.postalCode : '',
    city: branch?.address?.city ? branch?.address?.city : '',
    streetName: branch?.address?.streetName ? branch?.address?.streetName : '',
    houseNr: branch?.address?.houseNr ? branch?.address?.houseNr : '',
    apartmentNr: branch?.address?.apartmentNr ? branch?.address?.apartmentNr : '',
    locationDescription: branch?.locationDescription ? branch?.locationDescription : '',
    // Convert document object to string ID if it exists
    document: branch?.document?.id || '',
  }

  // Derive defaultDocument from branch data
  const defaultDocument = branch?.document
    ? {
        label:
          branch.document.identifier + (branch.document.name ? ` (${branch.document.name})` : ''),
        value: branch.document.id,
      }
    : undefined

  useEffect(() => {
    if (branch?.parent?.id) {
      legalData.setUnit(branch.parent.id)
    }
  }, [branch, legalData])

  if (isLoading) {
    return <LoadingSection />
  }

  return (
    <BranchForm
      legalData={legalData}
      isSending={isSending}
      onSubmit={onSubmit}
      data={newData}
      defaultDocument={defaultDocument} // Pass the derived defaultDocument
    />
  )
}
