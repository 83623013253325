import React, { useEffect, useMemo, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportTrainingErrors } from 'error-data/training'
import {
  archiveTraining,
  getTrainigFile,
  getTrainingDetails,
  getTrainingMembersList,
} from 'fetchers/trainingFetchers'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption } from 'types/form'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const TrainingDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const cardId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const [pickedFileID, setPickedFileID] = useState<string>('') // ID of the selected file
  const [fileURL, setFileURL] = useState<string>('') // URL of the blob
  const [downloadFilename, setDownloadFilename] = useState<string>('')
  const queryClient = useQueryClient()

  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)

  const { data: trainingDetails, isLoading: isTrainingDetailsLoading } = useQuery({
    queryKey: [userToken, queryKeys.trainingDetails, cardId],
    queryFn: () => getTrainingDetails(cardId),
  })

  const { data: trainingMembersList, isLoading: isTrainingMembersListLoading } = useQuery({
    queryKey: [userToken, queryKeys.trainingMembersList, cardId],
    queryFn: () => getTrainingMembersList(cardId),
  })

  interface TrainingDetailsFile {
    data: any
    filename: string
    type?: string
  }

  const { data: trainingDetailsFile, isLoading: isTrainingDetailsFileLoading } =
    useQuery<TrainingDetailsFile>({
      queryKey: [userToken, queryKeys.trainingDetailsFile, pickedFileID],
      queryFn: () => getTrainigFile(pickedFileID),
      enabled: !!pickedFileID,
    })

  useEffect(() => {
    if (trainingDetailsFile && pickedFileID) {
      const fileURL = URL.createObjectURL(
        new Blob([trainingDetailsFile.data], {
          type: trainingDetailsFile?.type || 'application/octet-stream',
        })
      )
      setFileURL(fileURL)

      // Get filename from trainingDetails.files
      if (trainingDetails?.files) {
        // Find the file object that matches the selected ID
        const fileInfo = trainingDetails.files.find((file: any) => file.id === pickedFileID)

        if (fileInfo) {
          // Set the downloadFilename to the filename from the file object
          setDownloadFilename(fileInfo.filename)
        } else {
          // If no match is found, use a default filename
          setDownloadFilename('downloaded_file')
        }
      } else {
        // If trainingDetails.files is missing, use a default filename
        setDownloadFilename('downloaded_file')
      }
    }
  }, [trainingDetailsFile, pickedFileID, trainingDetails])

  const handleDownload = () => {
    // Create a new anchor element
    const link = document.createElement('a')
    link.href = fileURL
    link.download = downloadFilename // Use the extracted filename

    // For Safari: add these attributes and events
    link.target = '_blank'
    link.rel = 'noopener noreferrer'

    // Safari requires the link to be in the DOM
    document.body.appendChild(link)

    // Use a small timeout to ensure the browser processes the link addition
    setTimeout(() => {
      // For Safari we need a click event rather than programmatically clicking
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      link.dispatchEvent(event)

      // Safari needs more time before removing the link
      setTimeout(() => {
        document.body.removeChild(link)
        // For Safari: we should also revoke the object URL to free memory
        URL.revokeObjectURL(fileURL)
      }, 100)
    }, 0)
  }

  const lastPage = useLastPage(trainingMembersList?.pagination, isTrainingMembersListLoading)
  const generatedExportsList = trainingMembersList?.items || []

  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const archiveTrainingMutation = useMutation({
    mutationFn: () => archiveTraining(cardId),
    onSuccess: () => {
      successToast('Zarchiwizowano szkolenie.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingArchive] })
      navigate(path.training.list)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      const errorMessages = error.response?.data.errors
        .map((item: any) => `${item.property}: ${item.error}`)
        .join('\n')
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(errorMessages)
      })
      mutationErrorHandler(error, exportTrainingErrors.create, errorMessages)
    },
  })

  const [isModalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const confirmArchiveTrainingHandler = () => {
    archiveTrainingMutation.mutate()
    closeModal()
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Imię i nazwisko',
        accessor: ' name',
        Cell: ({ row }: any) => {
          return (
            <>
              {row.original?.firstName} {row.original?.lastName}{' '}
              {row.original?.archived === true && (
                <span className="font-eee inline-flex cursor-default items-center rounded-full border border-transparent bg-red-200 px-2.5 py-0.5 text-xs font-bold text-red-900 transition-colors hover:bg-red-200/80 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
                  zarchiwizowany
                </span>
              )}
            </>
          )
        },
      },
      {
        Header: 'Numer legitymacji',
        accessor: ' number',
        Cell: ({ row }: any) => {
          return row.original?.card
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className="flex">
              <HoverIcon
                disabled={row.original?.archived}
                iconName="EyeIcon"
                title="Zobacz dane uczestnika"
                onClick={() => {
                  navigate(`/member/${row.original.id}`)
                }}
              />
            </div>
          )
        },
      },
    ],
    []
  )

  const translateType = (type: string) => {
    switch (type) {
      case 'on-site':
        return 'Stacjonarne'
      case 'online':
        return 'Online'
      default:
        return 'Nieznany'
    }
  }

  const translateArchiveStatus = (archived: boolean) => {
    return archived ? 'Tak' : 'Nie'
  }

  if (isTrainingDetailsLoading || isTrainingMembersListLoading) {
    return (
      <Card label="Kurs">
        <dl className="grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6">
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className="col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3">
                <div className="h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200"></div>
              </dt>
              <dd className="pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none">
                <div className="h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200"></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <Card
        label={`${trainingDetails?.name}`}
        actionsButton={[
          {
            label: 'Edytuj',
            handleClick: () => navigate('update'),
          },
          {
            label: 'Archiwizuj',
            handleClick: () => openModal(),
          },
        ]}
      >
        <div className="grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4">
          <LabelTypography label="Data szkolenia" text={trainingDetails?.dateStart} />
          <LabelTypography label="Godzina szkolenia" text={trainingDetails?.hourStart} />
          <LabelTypography label="Prowadzący" text={trainingDetails?.trainerName} />
          <div className="row-span-2">
            <LabelTypography label="Miejsce szkolenia" text={trainingDetails?.location} />
          </div>
          <LabelTypography label="Szczegóły lokalizacji" text={trainingDetails?.locationDetails} />
          <LabelTypography label="Tryb szkolenia" text={translateType(trainingDetails?.type)} />
          <LabelTypography
            label="Dokumenty"
            text={
              trainingDetails?.files?.length > 0
                ? trainingDetails.files.map((file: any) => file.filename).join(', ')
                : 'Brak plików'
            }
          />
          <LabelTypography label="URL" text={trainingDetails?.url} />
          <LabelTypography label="Opis szkolenia" text={trainingDetails?.description} />
          <LabelTypography
            label="Archiwalny"
            text={translateArchiveStatus(trainingDetails?.archived)}
          />
        </div>
      </Card>
      <Card label="Lista uczestników">
        <div className="flex items-center justify-between gap-4">
          {lastPage && (
            <>
              <div className="flex flex-col justify-end gap-4 md:flex-row">
                <div className="flex items-center gap-4">
                  <Typography weight="medium" className="text-sm">
                    Liczba wierszy na stronę
                  </Typography>
                  <Select
                    handleSelect={onLimitHandler}
                    options={[
                      { label: '10', value: 10 },
                      { label: '20 ', value: 20 },
                    ]}
                  />
                </div>
              </div>

              <span className="flex flex-col items-center gap-2 md:flex-row md:gap-4">
                {lastPage > 1 && (
                  <Pagination
                    lastPage={lastPage}
                    currentPage={page}
                    handlePageChange={changePage}
                  />
                )}
              </span>
            </>
          )}
        </div>
        {!isTrainingMembersListLoading && (
          <>
            <Table maxColumnWidth="300px" columns={columns} data={generatedExportsList || []} />
          </>
        )}
        {isTrainingMembersListLoading && <TableSkeleton />}
      </Card>

      {isModalOpen && (
        <div className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 flex items-center justify-center bg-black/80">
          <div className="rounded bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl">Potwierdzenie archiwizacji</h2>
            <p>Czy na pewno chcesz zarchiwizować to szkolenie?</p>
            <div className="mt-4 flex justify-end space-x-4">
              <button onClick={closeModal} className="rounded bg-gray-300 px-4 py-2">
                Anuluj
              </button>
              <button
                onClick={confirmArchiveTrainingHandler}
                className="rounded bg-red-500 px-4 py-2 text-white"
              >
                Archiwizuj
              </button>
            </div>
          </div>
        </div>
      )}

      {trainingDetails?.files?.length > 0 && (
        <Card label="Pobierz plik">
          <p className="mb-1 block text-sm font-medium text-gray-700">
            Wybierz plik, który chcesz pobrać
          </p>
          <select
            className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            name=""
            id=""
            onChange={(e) => setPickedFileID(e.target.value)}
          >
            <option value="">Wybierz</option>
            {trainingDetails?.files?.map((file: any) => (
              <option key={file.id} value={file.id}>
                {file.filename}
              </option>
            ))}
          </select>
          {isTrainingDetailsFileLoading && (
            <a className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white opacity-30 shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
              Wczytuję plik <span className="loader"></span>
            </a>
          )}
          {!isTrainingDetailsFileLoading && pickedFileID && fileURL && (
            <a
              href="#"
              onClick={handleDownload}
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Pobierz plik{' '}
              {trainingDetails?.files?.find((file: any) => file.id === pickedFileID)?.filename}
            </a>
          )}
        </Card>
      )}

      <div className="my-4 flex justify-center gap-4">
        <Button
          variant="secondary"
          label="Powrót"
          onClick={(e) => {
            e.preventDefault()
            navigate(path.training.list)
          }}
        />
      </div>
    </>
  )
}

export default TrainingDetails
