import { useState } from 'react'

import { XCircleIcon } from '@heroicons/react/24/outline'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'

import { Calendar } from '../Calendar'
import { TextareaField } from '../TextareaField/TextareaField'
import { Typography } from '../Typography'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

export const ConfirmDeleteMemberModal: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const [startDate, setStartDate] = useState(new Date())

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} className="w-1/2">
      <Card
        label="Usuwanie członka"
        className="px-10 py-5"
        actionsButton={[{ icon: <XCircleIcon className="w-5" />, handleClick: handleClose }]}
      >
        <Typography>Czy napewno chcesz usunąć dane członka ?</Typography>

        <div className="my-5">
          <textarea
            placeholder="Opisz pokrótce sytuację."
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={4}
          />
        </div>

        <div className="mb-10 flex justify-between">
          <Typography>Wybierz datę wykreślenia</Typography>

          <Calendar date={startDate} handleDate={setStartDate} />
        </div>

        <div className="flex justify-end">
          <div className="mr-5">
            <Button label="Anuluj" variant="secondary" onClick={handleClose} />
          </div>
          <Button label="Zatwierdź" onClick={handleClose} />
        </div>
      </Card>
    </Modal>
  )
}
