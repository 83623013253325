import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { ComboboxField } from 'components/ComboboxField/ComboboxField'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { sectionErrors } from 'error-data/section'
import { getSectionsList, sectionClubMerge } from 'fetchers/sectionFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, errorToast } from 'tools/ToastHelpers'
import { IOption } from 'types/form'
import { ISectionMerge } from 'types/section'
import { AxiosError } from 'axios'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'

export const SectionMerge = () => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const { userToken, userInfo } = useAppSelector((state: any) => state.authReducer)

  // Form state
  const [removeSection, setRemoveSection] = useState<string | number>('')
  const [targetSection, setTargetSection] = useState<string | number>('')
  const [newSectionName, setNewSectionName] = useState<string>('')
  const [formErrors, setFormErrors] = useState<{
    removeSection?: string
    targetSection?: string
    newSectionName?: string
  }>({})

  // Get removeId from URL params if available
  const removeClubId = queryParams.get('removeId') || ''

  // Filter for fetching sections
  const filters = [{ subtype: 'club' }]

  // Fetch sections list
  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, filters],
    queryFn: () => getSectionsList(1, 1000, filters),
  })

  // Transform sections data for combobox
  const sections =
    data?.items?.map((e: { name: string; id: string; parent: { id: string } }) => ({
      label: e?.name,
      value: e?.id,
      parent: e?.parent?.id,
    })) || []

  // Set initial selected section if removeId is in URL
  useState(() => {
    if (removeClubId) {
      setRemoveSection(removeClubId)
    }
  })

  // Get remove section details
  const selectedRemoveSection = sections.find((item: IOption) => item.value === removeSection)

  // Filter target sections to only show ones with the same parent as the selected remove section
  const targetSections = selectedRemoveSection
    ? sections.filter(
        (section: { parent: any; value: string | number }) =>
          section.parent === selectedRemoveSection.parent && section.value !== removeSection
      )
    : []

  const queryClient = useQueryClient()

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Validate form
    const errors: {
      removeSection?: string
      targetSection?: string
      newSectionName?: string
    } = {}

    if (!removeSection) {
      errors.removeSection = 'Wybór usuwane ognisko jest wymagany'
    }

    if (!targetSection) {
      errors.targetSection = 'Wybór ognisko docelowe jest wymagany'
    }

    if (!newSectionName.trim()) {
      errors.newSectionName = 'Nazwa nowego ogniska jest wymagana'
    }

    if (removeSection === targetSection && removeSection) {
      errorToast('Wybrane ogniska są takie same.')
      return
    }

    setFormErrors(errors)

    // If no errors, submit form
    if (Object.keys(errors).length === 0) {
      const data = {
        removeId: String(removeSection),
        targetId: String(targetSection),
        newSection: newSectionName,
      }
      onSubmit(data)
    }
  }

  // Section merge mutation
  const mutation = useMutation({
    mutationFn: (data: ISectionMerge) => {
      const { removeId, targetId, newSection } = data
      return sectionClubMerge(targetId, removeId, { name: newSection })
    },
    onSuccess: () => {
      successToast('Połączono ogniska.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.section.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, sectionErrors.clubMerge, 'Nie udało się połączyć ognisk.')
    },
  })

  const onSubmit = async (data: ISectionMerge) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się połączyć ognisk.')
    }
  }

  // Update new section name when target section changes
  const handleTargetSectionChange = (value: string | number) => {
    setTargetSection(value)

    if (!newSectionName && value) {
      const selectedTarget = sections.find(
        (section: { value: string | number }) => section.value === value
      )
      if (selectedTarget) {
        setNewSectionName(selectedTarget.label)
      }
    }
  }

  const userCurrentRole = userInfo?.context?.role

  if (userCurrentRole === 'ROLE_REGION_BOARD') {
    return <ErrorRoleModal />
  }

  // Loading state
  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <div className="my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300"></div>
          <div className="mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300"></div>
          <div className="mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300"></div>
          <div className="h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300"></div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl">Połącz ogniska</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <Typography weight="medium" className="mb-2">
                Wybierz usuwane ognisko
              </Typography>
              <ComboboxField
                options={sections}
                value={removeSection}
                onChange={setRemoveSection}
                placeholder="Wybierz usuwane ognisko..."
                emptyMessage="Nie znaleziono ogniska."
                error={formErrors.removeSection}
              />
            </div>

            <div>
              <Typography weight="medium" className="mb-2">
                Wybierz ognisko docelowe
              </Typography>
              <ComboboxField
                options={targetSections}
                value={targetSection}
                onChange={handleTargetSectionChange}
                placeholder="Wybierz ognisko docelowe..."
                emptyMessage="Nie znaleziono ogniska."
                error={formErrors.targetSection}
              />
            </div>

            <div className="md:col-span-2">
              <TextField
                label="Nazwa nowego ogniska"
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
                error={formErrors.newSectionName}
              />
            </div>
          </div>

          <div className="mt-10 flex justify-center gap-4">
            <Button
              variant="secondary"
              label="Anuluj"
              onClick={() => navigate(path.section.list)}
            />
            <Button type="submit" disabled={isSending} label="Zatwierdź" />
          </div>
        </form>
      </CardContent>
    </Card>
  )
}
