import { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { path } from 'constants/path'
import { setupDate } from 'tools/formTools'
import { errorRequires } from 'tools/ToastHelpers'

type Props = {
  closeHandler?: () => void
  onSubmit: (data: any) => void
  isSending?: boolean
  label?: string
  isModal?: boolean
  initData: {
    startedAt?: Date
    endedAt?: Date
  }
}

export const BranchFunctionUpdateForm = ({
  onSubmit,
  closeHandler,
  isModal,
  label = '',
  isSending,
  initData,
}: Props) => {
  const [showEndDate, setShowEndDate] = useState(!!initData?.endedAt)
  
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: initData,
  })
  
  const navigate = useNavigate()
  const endDate = watch('endedAt')

  const cancelHandler = () => {
    if (isModal && closeHandler) {
      closeHandler()
    } else {
      navigate(path.branchFunctions.list)
    }
  }
  
  const onValidationFailure = () => errorRequires(errors)
  
  const handleToggleEndDate = () => {
    setShowEndDate(prev => {
      if (!prev) {
        // When enabling, set a default end date if none exists
        if (!endDate) {
          setValue('endedAt', new Date())
        }
      } else {
        // When disabling, clear the end date
        setValue('endedAt', undefined)
      }
      return !prev
    })
  }
  
  const containerClass = isModal
    ? 'grid grid-cols-1 gap-5 mb-5'
    : 'grid grid-cols-2 gap-5 mb-5 w-2/3'
  
  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      <Card label={`Zaktualizuj kadencję ${label}`}>
        <div className={containerClass}>
          <Controller
            name='startedAt'
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <Calendar
                  date={value}
                  handleDate={(val) => onChange(val)}
                  label='Data rozpoczęcia kadencji'
                />
                <p className='mt-2 text-xs font-bold opacity-50'>
                  Aktualna data: {setupDate(initData?.startedAt)}
                </p>
              </div>
            )}
          />

          <div>
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id="endDateToggle"
                checked={showEndDate}
                onChange={handleToggleEndDate}
                className="mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="endDateToggle" className="text-sm font-medium text-gray-700">
                Zakończona kadencja
              </label>
            </div>
            
            {showEndDate && (
              <Controller
                name='endedAt'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <Calendar
                      date={value}
                      handleDate={(val) => onChange(val)}
                      label='Data zakończenia kadencji'
                    />
                    <p className='mt-2 text-xs font-bold opacity-50'>
                      Aktualna data: {setupDate(initData?.endedAt)}
                    </p>
                  </div>
                )}
              />
            )}
          </div>
        </div>
        
        <div className='mt-5 flex justify-center gap-5'>
          <Button label='Anuluj' variant='secondary' onClick={cancelHandler} />
          <Button disabled={isSending} label='Zatwierdź' type='submit' />
        </div>
      </Card>
    </form>
  )
}

export default BranchFunctionUpdateForm