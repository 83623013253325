import { useEffect, useState } from 'react'

import { Navigate, Outlet } from 'react-router-dom'

import { Sidebar } from 'components/Atoms/Sidebar'
import { TopHeader } from 'components/Atoms/TopHeader'
import { navigation } from 'routes'

export const ProtectedRoute = () => {
  const storedIsSidebarVisible = localStorage.getItem('isSidebarVisible')
  const initialSidebarVisible =
    storedIsSidebarVisible !== null ? JSON.parse(storedIsSidebarVisible) : window.innerWidth > 1024

  const [isSidebarVisible, setSidebarVisible] = useState(initialSidebarVisible)

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible)
  }

  const userInfo = localStorage.getItem('userInfo')
  const token = localStorage.getItem('token')

  useEffect(() => {
    localStorage.setItem('isSidebarVisible', JSON.stringify(isSidebarVisible))
  }, [isSidebarVisible])

  useEffect(() => {
    const handleResize = () => {
      setSidebarVisible(window.innerWidth > 1024)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (userInfo !== undefined || (userInfo !== null && token !== undefined)) {
    const user = JSON.parse(userInfo || '')
    return (
      <div className='antialiased'>
        <aside
          className={`fixed inset-0 z-20 h-screen border-r border-gray-300 lg:inset-y-0 lg:flex lg:flex-col ${
            isSidebarVisible ? 'w-64 opacity-100' : 'w-0 opacity-0 sm:w-16 sm:opacity-100'
          }`}
        >
          <Sidebar
            navigation={navigation}
            toggleSidebar={toggleSidebar}
            isSidebarVisible={isSidebarVisible}
          />
        </aside>

        <div
          className={`${isSidebarVisible ? 'sm:pl-64 lg:pl-64' : 'pl-0 sm:pl-16'} z-10`}
          id='scrollable-content'
        >
          <TopHeader
            email={`${user.email || ''}`}
            toggleSidebar={toggleSidebar}
            isSidebarVisible={isSidebarVisible}
          />
          <main className='p-6'>
            <div className='mx-auto max-w-7xl'>
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    )
  }
  return <Navigate to='/login' />
}
