import { useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { Table } from 'components/Atoms/Table'
import { TextareaField } from 'components/Atoms/TextareaField/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { migrationErrors } from 'error-data/member-migration'
import { getBranch, getBranchesListPrefiltredGlobal } from 'fetchers/branchFetchers'
import { moveMassMemberToBranch } from 'fetchers/migrationFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { moveMassBranchSchema } from 'schemas/unitChangeSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler, setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { errorRequires, successToast } from 'tools/ToastHelpers'
import { IBranchMassMoveRequest } from 'types/branch'
import { IMemberListing } from 'types/member'

type Props = {
  closeModal: () => void
  members: IMemberListing[]
  refetch: () => void
}

export const MemberMassBranchModal = ({ closeModal, members, refetch }: Props) => {
  const [isSending, setIsSending] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>()
  const [branchPerPage, setBranchPerPage] = useState(10)
  const { userToken, userLoaded, isAllAdmin, isAllOD, unitContext } = useAuth()
  const { param } = useSearch({ simpleParams: ['name'] })
  const isAdmin = isAllAdmin()
  const isBranch = isAllOD()
  const isValid = isAdmin || isBranch

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      items: members.map((member) => ({ id: member.id })),
      comment: '',
      branchId: '',
      regionId: '',
    },
    resolver: yupResolver(moveMassBranchSchema),
  })
  const onValidationFailure = () => errorRequires(errors)
  // GET REGIONS
  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!isAdmin) return []
      return getRegionsList(1, 1000)
    },
    retry: useRetryHandler({
      resourceName: 'MemberMassBranchModal regions',
      maxRetries: 1,
    }),
  })

  const regionsList = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  // END GET REGIONS

  // GET BRANCHES
  const { data: branch, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.branch, unitContext?.id],
    queryFn: () => {
      if (isAdmin) return []
      return getBranch(unitContext?.id)
    },
    retry: useRetryHandler({
      resourceName: 'MemberMassBranchModal branch',
      maxRetries: 1,
    }),
  })

  const currentRegion = isBranch ? branch?.parent?.id : selectedRegion

  const branchesFilters =
    setupSearchFilters([{ name: param?.single?.name || '' }]) +
    setupSearchMultiFilters([{ region: [currentRegion] }])
  // setupSearchMultiFilters([{ region: [regionId] }])
  const {
    data: branches,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, selectedRegion, branchPerPage, branchesFilters],
    queryFn: () => {
      return getBranchesListPrefiltredGlobal(1, branchPerPage, branchesFilters)
    },
    retry: useRetryHandler({
      resourceName: 'MemberMassBranchModal branches',
      maxRetries: 1,
    }),
  })
  const branchesList = (branches?.items || []).map((e: { name: string; id: string }) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  const searchMoreBranchHandler = () => {
    const count =
      branches?.pagination?.countFiltered > 0
        ? branches?.pagination?.countFiltered
        : branches?.pagination?.count
    if (!branchesLoading && branches?.items?.length < count)
      setBranchPerPage((prev: number) => prev + 10)
  }
  // END GET BRANCHES

  // SEND MOVE REQUEST
  const moveBranch = useMutation({
    mutationFn: (data: IBranchMassMoveRequest) => {
      return moveMassMemberToBranch(data)
    },
    onSuccess: () => {
      const message = isAdmin
        ? 'Przeniesiono członka do nowego oddziału.'
        : 'Zgłoszenie zostało wysłane do oddziału docelowego.'
      successToast(message)
      closeModal()
      refetch()
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        migrationErrors.mass,
        'Nie udało się przenieść członka do oddziału.'
      )
    },
  })
  const onSubmit = async (data: IBranchMassMoveRequest) => {
    try {
      const { regionId, ...form } = data
      setIsSending(true)
      moveBranch.mutate(form)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        null,
        migrationErrors.mass,
        'Nie udało się przenieść członka do oddziału.'
      )
    }
  }
  // END SEND MOVE REQUEST

  const columns = useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Okręg',
        accessor: 'region',
        Cell: ({ row }: any) => row.original.region.name,
      },
      {
        Header: 'Oddział',
        accessor: 'branch',
        Cell: ({ row }: any) => row.original.branch.name,
      },
    ],
    []
  )
  const loadingHandler = getLoadingHandler(branchesError, !userLoaded, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <section>
      <Typography size="xl" weight="medium">
        Lista członków / Zmień oddział
      </Typography>
      <Card label="Przenieś członków do innego oddziału">
        <Table columns={columns} data={members} />
        <form className="grid grid-cols-4" onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
          <div className="col-start-2 col-end-4 mb-4">
            {isAdmin ? (
              <div className="grid grid-cols-2 gap-4">
                <SearchAndFetchInput
                  filterBox
                  externalChangeValue={setSelectedRegion}
                  isLoading={regionsLoading}
                  name={'regionId'}
                  label={'Wyszukaj region'}
                  register={register}
                  options={regionsList}
                  control={control}
                />
                {selectedRegion && (
                  <SearchAndFetchInput
                    searchMore={searchMoreBranchHandler}
                    isLoading={branchesLoading}
                    name={'branchId'}
                    label={'Wyszukaj oddział'}
                    register={register}
                    options={branchesList}
                    control={control}
                  />
                )}
              </div>
            ) : (
              <SearchAndFetchInput
                searchMore={searchMoreBranchHandler}
                isLoading={branchesLoading}
                name={'branchId'}
                label={'Wyszukaj oddział'}
                register={register}
                options={branchesList}
                control={control}
              />
            )}
          </div>
          <div className="col-start-2 col-end-4">
            <div>
              <label htmlFor="comment" className="mb-1 block text-sm font-medium text-gray-700">
                Powód przeniesienia
              </label>
              <textarea
                id="comment"
                {...register('comment')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                rows={4}
              />
              {errors.comment?.message && (
                <p className="mt-2 text-sm text-red-600">{errors.comment.message}</p>
              )}
            </div>
          </div>
          <div className="col-span-4 mt-5 flex items-center justify-center gap-5">
            <Button label="Wróć" variant="secondary" onClick={closeModal} />
            <Button disabled={isSending} type="submit" label="Przenieś" />
          </div>
        </form>
      </Card>
    </section>
  )
}

export default MemberMassBranchModal
