import { useEffect, useMemo, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { flatten } from 'lodash'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { publicBaseApiURL } from 'api'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ComboboxField } from 'components/ComboboxField/ComboboxField'
import { Spinner } from 'components/Atoms/Spinner'
import { TextField } from 'components/Atoms/TextField'
import { fetchErrors } from 'constants/fetchErrors'
import { queryKeys } from 'constants/queryKeys'
import { endpoint } from 'constants/endpoints'
import { Roles, getRolesAsync } from 'features/Role/roleSlice'
import { getRemoteSearch, getRoleListGlobal } from 'fetchers/authFetchers'
import { getBranchesList } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList, getSectionsListGlobal } from 'fetchers/sectionFetchers'
import { createUser, getUsersList } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { roleById } from 'tools/dictionaryHelper'
import { errorToast } from 'tools/ToastHelpers'

interface IRole {
  label: string
  value: string | number
}
interface IUnit {
  label: string
  value: string | number
}
interface IRoles {
  id: string
  role: IRole
  unit: IUnit
}
interface IForm {
  fullName: string
  phoneNumber: string
  email: string
}
const defaultValueSelector = {
  label: 'Wybierz',
  value: '',
}
const defaultValues = {
  fullName: '',
  phoneNumber: '',
  email: '',
}

// Roles that don't require a structure unit
const noStructureRoles = ['ROLE_SUPER_ADMIN', 'ROLE_IOD', 'ROLE_SYSTEM_INSPECTOR']

export const CopyMemberForm: React.FC = () => {
  const [isSending, setIsSending] = useState(false)
  const [roles, setRoles] = useState<IRoles[]>([])
  const [resetKey, setResetKey] = useState(0)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useState<IForm | null>(null)
  const [selected, setSelected] = useState('')
  const [selectedEmail, setSelectedEmail] = useState('')
  const [selectedRole, setSelectedRole] = useState<IRole>(defaultValueSelector)
  const [selectedParentUnit, setSelectedParentUnit] = useState<IRole>(defaultValueSelector)
  const [selectedBranchUnit, setSelectedBranchUnit] = useState<IRole>(defaultValueSelector)
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(defaultValueSelector)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { userInfo } = useAppSelector((state: any) => state.authReducer)

  const { userToken, isAllAdmin, isAllOK, isAllOD, isPOG, isPOK, isZOK, isPOD, isZOD } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
  })

  // Role list query - force fresh data from server
  const { data: roleList, isLoading: roleListLoading } = useQuery({
    queryKey: [userToken, 'roleList'],
    queryFn: () => getRoleListGlobal(),
    staleTime: 0, // Force fresh data every time
  })

  // Remote search query
  const { data: remoteUsers, isLoading: remoteUsersIsLoading } = useQuery({
    queryKey: [userToken, 'remoteSearch', searchParams],
    queryFn: () => {
      if (!searchParams) return { items: [] }
      return getRemoteSearch({
        name: searchParams.fullName,
        phone: searchParams.phoneNumber,
        email: searchParams.email,
      })
    },
  })

  // Current user roles query
  const { data: userData } = useQuery({
    queryKey: [userToken, 'usersList', selectedEmail],
    queryFn: () => {
      if (!selectedEmail) return { items: [] }
      return getUsersList(1, 100)
    },
  })

  const isBranchPresidentOrBoard =
    userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
    userInfo?.context?.role === 'ROLE_BRANCH_BOARD'

  // Fetch branchesList for ROLE_BRANCH_PRESIDENT or ROLE_BRANCH_BOARD
  const { data: branchPresidentList, isLoading: isLoadingBranchPresidentList } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 1000, []),
    enabled: isBranchPresidentOrBoard,
  })

  // Determine the type of unit required for the selected role
  const selectedRoleUnit = useMemo(() => {
    if (!selectedRole.value || !roleList) return null

    // If role doesn't require a structure unit, return null
    if (noStructureRoles.includes(selectedRole.value as string)) return null

    // Special case for section administrator
    if (selectedRole.value === 'ROLE_SECTION_ADMIN') {
      return 'section'
    }

    for (const [key, value] of Object.entries(roleList)) {
      const role = (value as { role: string; name: string }[]).find(
        (i) => i.role === selectedRole.value
      )
      if (role) return key
    }

    return null
  }, [selectedRole, roleList])

  // Determine if the selected role requires a parent unit (region for branch roles)
  const requiresParentUnit = useMemo(() => {
    return selectedRoleUnit === 'branch' || selectedRoleUnit === 'section'
  }, [selectedRoleUnit])

  // Determine if the selected role requires a branch selection (for section roles)
  const requiresBranchSelection = useMemo(() => {
    return selectedRoleUnit === 'section'
  }, [selectedRoleUnit])

  // Check if unit selection is required for the selected role
  const requiresUnitSelection = useMemo(() => {
    return selectedRole.value && !noStructureRoles.includes(selectedRole.value as string)
  }, [selectedRole])

  // Fetch regions list for parent unit selection if needed
  const { data: regionList, isLoading: isLoadingRegions } = useQuery({
    queryKey: [userToken, queryKeys.regionList, 'parent-unit'],
    queryFn: () => getRegionsList(1, 1000, []),
    enabled: requiresParentUnit && !isBranchPresidentOrBoard,
  })

  useEffect(() => {
    if (
      (userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
        userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
      selectedRole.value === 'ROLE_SECTION_PRESIDENT' &&
      userInfo?.context?.unit
    ) {
      // Auto-select the branch president's branch
      setSelectedBranchUnit({
        label: userInfo.context.unit.name,
        value: userInfo.context.unit.id,
      })
    }
  }, [selectedRole, userInfo?.context?.role, userInfo?.context?.unit])

  // Add a query for sections specifically
  const { data: sections, isLoading: isLoadingSections } = useQuery({
    queryKey: [userToken, 'sections-list', selectedBranchUnit.value],
    queryFn: () => {
      if (selectedBranchUnit.value) {
        // Get sections from the selected branch
        const url = `${endpoint.section.listGlobal(1, 1000, '')}&filter[branch][]=${selectedBranchUnit.value}`
        return publicBaseApiURL.get(url, {}).then((res) => res.data)
      }
      return { items: [] }
    },
    enabled: !!(selectedRoleUnit === 'section' && selectedBranchUnit.value),
  })

  // Fetch branches for the selected region
  const { data: branchList, isLoading: isLoadingBranches } = useQuery({
    queryKey: [
      userToken,
      'branch-list',
      isBranchPresidentOrBoard ? userInfo?.context?.unit?.id : selectedParentUnit.value,
      selectedRole.value,
    ],
    queryFn: () => {
      // For branch presidents/board members selecting section president role,
      // we should use their branch ID
      if (
        (userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
          userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
        selectedRole.value === 'ROLE_SECTION_PRESIDENT'
      ) {
        // Return only the branch president's branch
        return {
          items: [
            {
              id: userInfo?.context?.unit?.id,
              name: userInfo?.context?.unit?.name,
            },
          ],
        }
      }

      // Original logic for other cases
      const regionId = isBranchPresidentOrBoard
        ? userInfo?.context?.unit?.id
        : selectedParentUnit.value
      const url = `${endpoint.branch.list(1, 1000, '')}&filter[region][]=${regionId}`
      return publicBaseApiURL.get(url, {}).then((res) => res.data)
    },
    enabled:
      !!((isBranchPresidentOrBoard || selectedParentUnit.value) && requiresBranchSelection) ||
      ((userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
        userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
        selectedRole.value === 'ROLE_SECTION_PRESIDENT'),
  })

  // Fetch appropriate divisions based on selected role
  const { data: division, isLoading: isLoadingDivisions } = useQuery({
    queryKey: [
      userToken,
      selectedRoleUnit,
      selectedParentUnit.value,
      selectedBranchUnit.value,
      'division-list',
    ],
    queryFn: () => {
      if (selectedRoleUnit === 'branch' && selectedParentUnit.value) {
        // Build URL manually for branches under a region
        const url = `${endpoint.branch.list(
          1,
          1000,
          ''
        )}&filter[region][]=${selectedParentUnit.value}`
        return publicBaseApiURL.get(url, {}).then((res) => res.data)
      }

      if (selectedRoleUnit === 'section' && selectedBranchUnit.value) {
        // Build URL manually for sections under a branch
        const url = `${endpoint.section.listGlobal(
          1,
          1000,
          ''
        )}&filter[branch][]=${selectedBranchUnit.value}`
        return publicBaseApiURL.get(url, {}).then((res) => res.data)
      }

      // Use existing functions for other cases
      if (selectedRoleUnit === 'region') return getRegionsList(1, 1000, [])
      if (selectedRoleUnit === 'section') return getSectionsListGlobal(1, 1000, [])
      return { items: [] }
    },
    enabled:
      !!selectedRoleUnit &&
      ((!requiresParentUnit && !requiresBranchSelection) ||
        (requiresParentUnit && !!selectedParentUnit.value && !requiresBranchSelection) ||
        (requiresBranchSelection && !!selectedBranchUnit.value)),
  })

  // Create user mutation
  const createUserMutation = useMutation({
    mutationFn: (data: any) => createUser(selected, data),
    onSuccess: () => {
      setSuccess('Pomyślnie dodano rolę')
      setIsSending(false)
      setLoading(false)

      if (selectedRole.value) {
        const newRole: IRoles = {
          id: String(roles.length + 1),
          role: selectedRole,
          unit: requiresUnitSelection ? selectedUnit : { label: '', value: '' },
        }
        setRoles((prevRoles) => [...prevRoles, newRole])
      }

      // Reset form state
      resetSelects()

      // Invalidate queries to ensure fresh data
      queryClient.invalidateQueries({ queryKey: [userToken, 'roleList'] })
      queryClient.invalidateQueries({ queryKey: [userToken, 'usersList'] })

      // Navigate back to users list
      navigate('/user')
      toast.success('Pomyślnie dodano rolę')
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      setLoading(false)
      const message = mutationErrorHandler(error, fetchErrors.users)
      setError(message)
      errorToast(message)
    },
  })

  // Transform roles into a list for dropdown
  const rolesList = useMemo(() => {
    if (!roleList) return []

    // Define the role type explicitly to avoid TypeScript errors
    interface RoleType {
      role: string
      name: string
    }

    // Cast the flat array to the correct type
    const flatRoles = Object.values(roleList).flat() as RoleType[]

    let filteredRoles = flatRoles

    if (isPOK() || isZOK()) {
      // AOK/ZOK cannot assign SuperAdmin, IOD, and Inspector roles
      filteredRoles = filteredRoles.filter(
        (role) =>
          role.role !== 'ROLE_SUPER_ADMIN' &&
          role.role !== 'ROLE_IOD' &&
          role.role !== 'ROLE_SYSTEM_INSPECTOR'
      )
    } else if (isPOD() || isZOD()) {
      // AOD/ZOD cannot assign SuperAdmin, IOD, Inspector, AOK/ZOK roles
      filteredRoles = filteredRoles.filter(
        (role) =>
          role.role !== 'ROLE_SUPER_ADMIN' &&
          role.role !== 'ROLE_IOD' &&
          role.role !== 'ROLE_SYSTEM_INSPECTOR' &&
          role.role !== 'ROLE_POK' &&
          role.role !== 'ROLE_ZOK'
      )
    }

    // Modified: Allow branch presidents to assign section presidents
    const userRole = userInfo?.context?.role
    if (userRole === 'ROLE_BRANCH_PRESIDENT' || userRole === 'ROLE_BRANCH_BOARD') {
      filteredRoles = filteredRoles.filter(
        (role: { role: string; name: string }) =>
          // REMOVED: role.role !== 'ROLE_SECTION_PRESIDENT' &&
          role.role !== 'ROLE_REGION_PRESIDENT' && role.role !== 'ROLE_REGION_BOARD'
      )
    }
    if (userRole === 'ROLE_REGION_PRESIDENT' || userRole === 'ROLE_REGION_BOARD') {
      filteredRoles = filteredRoles.filter(
        (role: { role: string; name: string }) => role.role !== 'ROLE_SECTION_PRESIDENT'
      )
    }

    return filteredRoles.map((i) => ({
      label: i.name,
      value: i.role,
    }))
  }, [roleList, isPOK, isZOK, isPOD, isZOD, userInfo?.context?.role])

  // Reset all selections and form fields
  const resetSelects = () => {
    setSelected('')
    setSelectedEmail('')
    setSelectedRole(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
    setSelectedParentUnit(defaultValueSelector)
    setSelectedBranchUnit(defaultValueSelector)
    setRoles([])
    setResetKey((prev) => prev + 1)
    setError(null)
    setSuccess(null)
    reset()
  }

  // Reset only role selection state, not the search form
  const resetRoleSelectionOnly = () => {
    setSelectedRole(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
    setSelectedParentUnit(defaultValueSelector)
    setSelectedBranchUnit(defaultValueSelector)
    setResetKey((prev) => prev + 1)
    setError(null)
    setSuccess(null)
  }

  const handleSubmitRole = async () => {
    setError(null)
    setSuccess(null)
    setLoading(true)
    setIsSending(true)

    if (!selectedRole.value || (requiresUnitSelection && !selectedUnit.value)) {
      setError('Wybierz rolę i jednostkę')
      setLoading(false)
      setIsSending(false)
      return
    }

    try {
      // Check if role already exists
      const currentUser = userData?.items
        ? userData?.items.find((i: any) => i.email === selectedEmail)
        : null

      const currentUserRoles = currentUser?.contexts
        ? currentUser?.contexts.map((i: any) => ({
            role: i.role,
            unit: i?.unit?.id,
          }))
        : []

      const hasExistingRole = currentUserRoles.some(
        (userRole: any) =>
          userRole.role === selectedRole.value &&
          (noStructureRoles.includes(selectedRole.value as string) ||
            userRole.unit === selectedUnit.value)
      )

      if (hasExistingRole) {
        setError(
          noStructureRoles.includes(selectedRole.value as string)
            ? 'Użytkownik ma już przypisaną tę rolę'
            : 'Użytkownik ma już przypisaną tę rolę w tej jednostce'
        )
        setLoading(false)
        setIsSending(false)
        return
      }

      const payload =
        selectedRoleUnit === 'systemwide' || noStructureRoles.includes(selectedRole.value as string)
          ? { systemwideRoles: [selectedRole.value] }
          : { unitRoles: [{ role: selectedRole.value, unit: selectedUnit.value }] }

      createUserMutation.mutate(payload)
    } catch (error) {
      setIsSending(false)
      setLoading(false)
      setError('Wystąpił nieoczekiwany błąd')
      console.error(error)
    }
  }

  // Effects to reset selectors when their dependencies change
  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
    setSelectedParentUnit(defaultValueSelector)
    setSelectedBranchUnit(defaultValueSelector)
  }, [selectedRole])

  useEffect(() => {
    setSelectedBranchUnit(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
  }, [selectedParentUnit])

  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
  }, [selectedBranchUnit])

  // Synchronize Redux store with our React Query data
  useEffect(() => {
    if (roleList && Object.keys(roleList).length > 0) {
      // Ensure the Redux store has the latest role data
      dispatch({ type: 'role/list/me/fulfilled', payload: roleList })
    }
  }, [roleList, dispatch])

  // Initial fetch of roles
  useEffect(() => {
    // Invalidate and refetch roles to ensure fresh data
    queryClient.invalidateQueries({ queryKey: [userToken, 'roleList'] })

    // Also update Redux store for consistency
    dispatch(getRolesAsync())
  }, [dispatch, userToken, queryClient])

  const onSubmit = (data: IForm) => {
    setSearchParams(data)
  }

  const rolesData = useAppSelector((state) => state.rolesReducer.roles)
  const fRoles = flatten(Object.values(rolesData))

  const currentUser = userData?.items
    ? userData?.items.find((i: any) => i.email === selectedEmail)
    : null

  const currentUserRoles = currentUser?.contexts
    ? currentUser?.contexts.map((i: any) => ({
        id: i.role,
        role: {
          label: roleById(i.role, fRoles),
          value: i.role,
        },
        unit: {
          label: i?.unit?.name,
          value: i?.unit?.id,
        },
      }))
    : []

  const isSubmitDisabled =
    !selectedRole.value ||
    (requiresUnitSelection && !selectedUnit.value) ||
    (requiresParentUnit && !selectedParentUnit.value && !isBranchPresidentOrBoard) ||
    (requiresBranchSelection && !selectedBranchUnit.value) ||
    isSending

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card label="Przypisz nowego użytkownika">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
            <div>
              <TextField
                {...register('fullName')}
                label="Imię i nazwisko"
                placeholder="Imię i nazwisko"
                error={errors.fullName?.message}
              />
            </div>
            <div>
              <TextField
                label="Numer telefonu"
                {...register('phoneNumber')}
                error={errors.phoneNumber?.message}
              />
            </div>
            <div>
              <TextField label="Email" {...register('email')} />
            </div>
            <div className="flex items-end">
              <Button
                onClick={() => {
                  // Don't reset the form fields, only clear selection-related state
                  setSelected('')
                  setSelectedEmail('')
                  setSelectedRole(defaultValueSelector)
                  setSelectedUnit(defaultValueSelector)
                  setSelectedParentUnit(defaultValueSelector)
                  setSelectedBranchUnit(defaultValueSelector)
                  setRoles([])
                  setError(null)
                  setSuccess(null)
                }}
                label="Wyszukaj użytkownika"
                type="submit"
                className="mb-1 w-full"
              />
            </div>
            {remoteUsersIsLoading && (
              <div className="col-span-4 mb-4 flex flex-col gap-4">
                <div className="h-4 w-full animate-pulse rounded-md bg-slate-300"></div>
                <div className="h-4 w-full max-w-[90%] animate-pulse rounded-md bg-slate-300"></div>
              </div>
            )}
          </div>

          {remoteUsers?.items && remoteUsers.items.length > 0 ? (
            <div className="mt-6 overflow-x-auto">
              <table className="min-w-full divide-y-reverse divide-slate-300">
                <thead>
                  <tr>
                    <th className="font-eee py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0">
                      Imię i nazwisko
                    </th>
                    <th className="font-eee px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                      Email
                    </th>
                    <th className="font-eee px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                      Telefon
                    </th>
                    <th className="font-eee px-3 py-3.5 text-right text-sm font-bold text-gray-900">
                      Akcje
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y border-t border-gray-300">
                  {(
                    remoteUsers?.items?.filter((i: any) =>
                      selected ? i.remoteId === selected : i
                    ) || []
                  ).map((item: any) => {
                    return (
                      <tr key={item.remoteId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {item.displayName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.phone}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm">
                          {!selected && (
                            <Button
                              label="Wybierz"
                              variant="primary"
                              onClick={() => {
                                setSelected(item.remoteId)
                                setSelectedEmail(item.email)
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            searchParams &&
            !remoteUsersIsLoading && (
              <div className="mt-4 rounded-md bg-yellow-50 p-4">
                <p className="text-sm text-yellow-700">Nie znaleziono użytkowników.</p>
              </div>
            )
          )}

          {selected && (
            <>
              <div className="mb-4 mt-8">
                <h3 className="font-eee text-md font-bold">Przypisane role:</h3>
                {currentUserRoles.length > 0 ? (
                  <div className="mt-2 grid grid-cols-1 gap-2 md:grid-cols-2">
                    {currentUserRoles.map((role: any, index: any) => (
                      <div key={index} className="rounded-md bg-gray-50 p-3">
                        <p className="font-medium">{role.role.label}</p>
                        {role.unit.value && (
                          <p className="text-sm text-gray-600">{role.unit.label}</p>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="mt-2 text-sm text-gray-500">Brak przypisanych ról</p>
                )}
              </div>

              <div className="mt-6 border-t border-gray-200 pt-6">
                <h3 className="font-eee text-md mb-4 font-bold">Przypisz nową rolę</h3>
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                  {/* Role selection */}
                  <div>
                    {roleListLoading ? (
                      <div className="flex h-full items-center justify-center">
                        <Spinner />
                      </div>
                    ) : (
                      <ComboboxField
                        key={`role-${resetKey}`}
                        label="Rola"
                        options={rolesList}
                        value={selectedRole.value}
                        onChange={(newValue) => {
                          const matchingRole = rolesList.find((r) => r.value === newValue)
                          setSelectedRole(matchingRole || { label: '', value: '' })
                        }}
                        placeholder="Wybierz rolę..."
                        emptyMessage="Nie znaleziono roli."
                      />
                    )}
                  </div>

                  {/* Parent Unit (Region) Selection */}
                  {requiresParentUnit && !isBranchPresidentOrBoard && (
                    <div>
                      {isLoadingRegions ? (
                        <div className="flex h-full items-center justify-center">
                          <Spinner />
                        </div>
                      ) : (
                        <ComboboxField
                          key={`parent-unit-${resetKey}`}
                          label="Jednostka nadrzędna (Region)"
                          options={(regionList?.items || []).map((i: any) => ({
                            label: i.name,
                            value: i.id,
                          }))}
                          value={selectedParentUnit.value}
                          onChange={(newValue) => {
                            const matchingParentUnit = (regionList?.items || []).find(
                              (i: any) => i.id === newValue
                            )
                            setSelectedParentUnit(
                              matchingParentUnit
                                ? { label: matchingParentUnit.name, value: matchingParentUnit.id }
                                : { label: '', value: '' }
                            )
                          }}
                          placeholder="Wybierz region..."
                          emptyMessage="Nie znaleziono regionu."
                        />
                      )}
                    </div>
                  )}

                  {/* Branch Unit Selection for Section Admin */}
                  {requiresBranchSelection && (
                    <div
                      className={
                        requiresParentUnit && !isBranchPresidentOrBoard ? 'sm:col-span-2' : ''
                      }
                    >
                      {isLoadingBranches ? (
                        <div className="flex h-full items-center justify-center">
                          <Spinner />
                        </div>
                      ) : (
                        <ComboboxField
                          key={`branch-unit-${resetKey}`}
                          label="Jednostka nadrzędna (Oddział)"
                          options={(branchList?.items || []).map((i: any) => ({
                            label: i.name,
                            value: i.id,
                          }))}
                          value={selectedBranchUnit.value}
                          onChange={(newValue) => {
                            const matchingBranch = (branchList?.items || []).find(
                              (i: any) => i.id === newValue
                            )
                            setSelectedBranchUnit(
                              matchingBranch
                                ? { label: matchingBranch.name, value: matchingBranch.id }
                                : { label: '', value: '' }
                            )
                          }}
                          placeholder="Wybierz oddział..."
                          emptyMessage="Nie znaleziono oddziału."
                          disabled={
                            // For branch presidents assigning section president, branch should be pre-selected and disabled
                            (userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
                              userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
                            selectedRole.value === 'ROLE_SECTION_PRESIDENT'
                              ? true
                              : !isBranchPresidentOrBoard && !selectedParentUnit.value
                          }
                        />
                      )}
                    </div>
                  )}

                  {/* Structure Unit Selection - Updated */}
                  {requiresUnitSelection && (
                    <div
                      className={
                        requiresParentUnit && !requiresBranchSelection ? 'sm:col-span-2' : ''
                      }
                    >
                      {isBranchPresidentOrBoard &&
                      selectedRole.value !== 'ROLE_SECTION_PRESIDENT' ? (
                        // If the user is a branch president or board member (but not assigning section president),
                        // show branchesList
                        isLoadingBranchPresidentList ? (
                          <div className="flex h-full items-center justify-center">
                            <Spinner />
                          </div>
                        ) : (
                          <ComboboxField
                            key={`unit-${resetKey}`}
                            label="Jednostka struktury"
                            options={(branchPresidentList?.items || []).map((i: any) => ({
                              label: i.name,
                              value: i.id,
                            }))}
                            value={selectedUnit.value}
                            onChange={(newValue) => {
                              const matchingUnit = (branchPresidentList?.items || []).find(
                                (i: any) => i.id === newValue
                              )
                              setSelectedUnit(
                                matchingUnit
                                  ? { label: matchingUnit.name, value: matchingUnit.id }
                                  : { label: '', value: '' }
                              )
                            }}
                            placeholder="Wybierz jednostkę..."
                            emptyMessage="Nie znaleziono jednostki."
                          />
                        )
                      ) : selectedRoleUnit === 'section' ? (
                        // For section president role, show sections from the selected branch
                        isLoadingSections ? (
                          <div className="flex h-full items-center justify-center">
                            <Spinner />
                          </div>
                        ) : (
                          <ComboboxField
                            key={`unit-${resetKey}`}
                            label="Jednostka struktury"
                            options={(sections?.items || []).map((i: any) => ({
                              label: i.name,
                              value: i.id,
                            }))}
                            value={selectedUnit.value}
                            onChange={(newValue) => {
                              const matchingUnit = (sections?.items || []).find(
                                (i: any) => i.id === newValue
                              )
                              setSelectedUnit(
                                matchingUnit
                                  ? { label: matchingUnit.name, value: matchingUnit.id }
                                  : { label: '', value: '' }
                              )
                            }}
                            placeholder="Wybierz sekcję..."
                            emptyMessage="Nie znaleziono sekcji."
                            disabled={!selectedBranchUnit.value}
                          />
                        )
                      ) : // Default behavior for other roles
                      isLoadingDivisions ? (
                        <div className="flex h-full items-center justify-center">
                          <Spinner />
                        </div>
                      ) : (
                        <ComboboxField
                          key={`unit-${resetKey}`}
                          label="Jednostka struktury"
                          options={(division?.items || []).map((i: any) => ({
                            label: i.name,
                            value: i.id,
                          }))}
                          value={selectedUnit.value}
                          onChange={(newValue) => {
                            const matchingUnit = (division?.items || []).find(
                              (i: any) => i.id === newValue
                            )
                            setSelectedUnit(
                              matchingUnit
                                ? { label: matchingUnit.name, value: matchingUnit.id }
                                : { label: '', value: '' }
                            )
                          }}
                          placeholder="Wybierz jednostkę..."
                          emptyMessage="Nie znaleziono jednostki."
                          disabled={
                            (requiresParentUnit &&
                              !selectedParentUnit.value &&
                              !isBranchPresidentOrBoard) ||
                            (requiresBranchSelection && !selectedBranchUnit.value)
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Error and success messages */}
              {error && (
                <div className="mt-4 rounded-md bg-red-50 p-4">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              )}
              {success && (
                <div className="mt-4 rounded-md bg-green-50 p-4">
                  <p className="text-sm text-green-700">{success}</p>
                </div>
              )}

              {/* Action buttons */}
              <div className="mt-6 flex justify-end gap-3">
                <Button
                  label="Anuluj"
                  variant="secondary"
                  onClick={() => navigate('/user')}
                  disabled={isSending}
                />
                <Button
                  label={
                    isSending
                      ? 'Przypisuję...'
                      : isSubmitDisabled
                        ? 'Wybierz rolę i jednostkę'
                        : 'Przypisz rolę'
                  }
                  disabled={isSubmitDisabled}
                  onClick={handleSubmitRole}
                >
                  {loading && <Spinner />}
                </Button>
              </div>
            </>
          )}
        </Card>
      </form>

      <div className="mt-4 flex items-center justify-center">
        <Button
          className="mx-auto"
          label="Powrót do listy"
          onClick={() => navigate('/user')}
          variant="secondary"
        />
      </div>
    </>
  )
}
