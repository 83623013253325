import React, { useState, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm, UseFormRegister } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'components/Atoms/Button'
import { EmploymentSection } from 'components/EmploymentSection/EmploymentSection'
import FormField from 'components/FormField'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { Input } from 'components/shadcn/ui/input'
import { Label } from 'components/shadcn/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { path } from 'constants/path'
import { sex } from 'constants/sex'
import { createDeclarationUserSchema } from 'schemas/declarationsSchema'
import { setupPostal } from 'tools/formTools'
import { IDeclaration, IDeclarationFileType } from 'types/declaration'
import { IOption } from 'types/form'

type Props = {
  initData: IDeclaration
  onSubmit: (data: any) => void
  onFileChange: (file: any) => void
  onBranchChange?: (branchId: string) => void
  isEditView?: boolean
  isAdmin?: boolean
  isSending?: boolean
  branchList: IOption[]
  educationList: IOption[]
  institutionList: IOption[]
  positionList: IOption[]
  initJobs?: any[]
  subjectList: IOption[]
  selectedBranchId?: string // Add this prop to track branch selection
}

export const DeclarationForm = ({
  isAdmin,
  branchList,
  educationList,
  institutionList,
  positionList,
  subjectList,
  initData,
  isSending,
  onSubmit,
  onFileChange,
  onBranchChange,
  isEditView,
  initJobs = [],
  selectedBranchId, // Use this prop
}: Props) => {
  const [postalCode, setPostalCode] = useState(initData.zipCode)
  const navigate = useNavigate()

  // Track currently selected branch label from the list
  const [selectedBranchLabel, setSelectedBranchLabel] = useState<string>(() => {
    if (selectedBranchId) {
      const branch = branchList.find((b) => b.value === selectedBranchId)
      return branch?.label || ''
    }
    return ''
  })

  // Update selected branch label when branch list or selectedBranchId changes
  useEffect(() => {
    if (selectedBranchId) {
      const branch = branchList.find((b) => b.value === selectedBranchId)
      if (branch) {
        setSelectedBranchLabel(branch.label)
      }
    }
  }, [branchList, selectedBranchId])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    defaultValues: initData,
    resolver: yupResolver(createDeclarationUserSchema),
  })

  // Sync form value with selectedBranchId
  useEffect(() => {
    if (selectedBranchId) {
      setValue('branch', selectedBranchId)
    }
  }, [selectedBranchId, setValue])

  const { fields, append, remove } = useFieldArray({ control, name: 'jobs' })
  const {
    fields: jobsSimpleFields,
    append: appendJobsSimple,
    remove: removeJobsSimple,
  } = useFieldArray({
    control,
    name: 'jobsSimple',
  })

  const handleCancel = () => {
    navigate(path.declaration.list)
  }

  const addWork = () => {
    append({
      institution: { value: '', label: '' },
      position: { value: '', label: '' },
      isMain: false,
      subject: { value: '', label: '' },
    })
  }

  const addSimpleWork = () => {
    appendJobsSimple({
      institution: '',
      position: '',
      isMain: false,
      subject: '',
    })
  }

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0]

    if (!selectedFile) {
      return
    }

    const allowedTypes = [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ]
    const maxSizeMB = 5
    const maxSize = maxSizeMB * 1024 * 1024

    if (!allowedTypes.includes(selectedFile.type)) {
      toast.error('Dozwolone typy plików: PDF, PNG, JPEG, DOC, DOCX.')
      event.target.value = '' // reset the input
      return
    }

    if (selectedFile.size > maxSize) {
      toast.error(`Maksymalny rozmiar pliku: ${maxSizeMB}MB.`)
      event.target.value = '' // reset the input
      return
    }

    onFileChange(selectedFile)
  }

  const handleBranchSelection = (branchId: string) => {
    // Find the branch label for the selected branch
    const selectedBranch = branchList.find((branch) => branch.value === branchId)
    if (selectedBranch) {
      setSelectedBranchLabel(selectedBranch.label)
    } else {
      setSelectedBranchLabel('')
    }

    // Call the parent's onBranchChange if it exists
    if (onBranchChange) {
      onBranchChange(branchId)
    }
  }

  const showBranchSelector = isAdmin && branchList && !isEditView
  const declarationFile: IDeclarationFileType | null =
    typeof initData.declarationFile === 'string'
      ? null
      : (initData.declarationFile as IDeclarationFileType)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {showBranchSelector && (
        <Card className="mt-4">
          <CardHeader>
            <CardTitle>Wybierz oddział</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-2">
                <Controller
                  name="branch"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="space-y-2">
                      <Label htmlFor="branch" className="text-sm font-medium">
                        Oddział
                        <span className="ml-1 text-red-500">*</span>
                      </Label>
                      <Select
                        value={value || undefined}
                        onValueChange={(newValue) => {
                          if (newValue === 'none') {
                            onChange('')
                            handleBranchSelection('')
                          } else {
                            onChange(newValue)
                            handleBranchSelection(newValue)
                          }
                        }}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue
                            placeholder="Wybierz oddział"
                            // Use the stored label when we have a selected branch
                            defaultValue={selectedBranchLabel || undefined}
                          >
                            {selectedBranchLabel || 'Wybierz oddział'}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="none">---</SelectItem>
                          {branchList.map((branch) => (
                            <SelectItem key={branch.value} value={String(branch.value)}>
                              {branch.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {errors?.branch?.message && (
                        <p className="text-sm text-destructive">{errors.branch.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <div className={`grid grid-cols-1 gap-6 md:grid-cols-4 ${!showBranchSelector && 'mt-4'}`}>
        <div className="md:col-span-2">
          <Card>
            <CardHeader>
              <CardTitle>Dane osobowe</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <FormField
                  name="firstName"
                  label="Imię"
                  register={register}
                  errors={errors}
                  placeholder="Imię"
                  autocomplete="given-name"
                />
                <FormField
                  name="secondName"
                  label="Drugie imię"
                  register={register}
                  errors={errors}
                  placeholder="Drugie imię"
                  optional={true}
                  autocomplete="additional-name"
                />
                <FormField
                  name="lastName"
                  label="Nazwisko"
                  register={register}
                  errors={errors}
                  placeholder="Nazwisko"
                  autocomplete="family-name"
                />
                <FormField
                  name="pesel"
                  label="PESEL"
                  register={register}
                  errors={errors}
                  placeholder="PESEL"
                />
                <div className="space-y-2">
                  <Label htmlFor="sex" className="text-sm font-medium">
                    Płeć
                    <span className="ml-1 text-red-500">*</span>
                  </Label>
                  <Controller
                    name="sex"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value || undefined}
                        onValueChange={(newValue) => onChange(newValue)}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Wybierz płeć" />
                        </SelectTrigger>
                        <SelectContent>
                          {sex.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                  {errors?.sex?.message && (
                    <p className="text-sm text-destructive">{errors.sex.message}</p>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="md:col-span-2">
          <Card>
            <CardHeader>
              <CardTitle>Adres korespondencyjny</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <FormField
                  name="zipCode"
                  label="Kod pocztowy"
                  register={register}
                  errors={errors}
                  placeholder="np. 01-123"
                  value={postalCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setupPostal(e, setPostalCode)
                  }}
                />
                <FormField
                  name="city"
                  label="Miasto"
                  register={register}
                  errors={errors}
                  placeholder="np. Warszawa"
                />
                <FormField
                  name="street"
                  label="Ulica"
                  register={register}
                  errors={errors}
                  placeholder="Ulica"
                  optional={true}
                />
                <div className="grid grid-cols-2 gap-4">
                  <FormField
                    name="houseNr"
                    label="Nr domu"
                    register={register}
                    errors={errors}
                    placeholder="nr domu"
                  />
                  <FormField
                    name="apartmentNr"
                    label="Nr mieszkania"
                    register={register}
                    errors={errors}
                    placeholder="nr mieszkania"
                    optional={true} // This is optional per schema
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle>Dane kontaktowe</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <FormField
                name="phoneNumber"
                label="Nr telefonu"
                register={register}
                errors={errors}
                placeholder="Nr telefonu"
              />
              <FormField
                name="email"
                label="Adres email"
                register={register}
                errors={errors}
                placeholder="np. jan.kowalski@email.com"
                optional={true}
              />
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Wykształcenie</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="space-y-2">
                <Label htmlFor="education" className="text-sm font-medium">
                  Wykształcenie
                  {/* Education is optional based on the schema */}
                </Label>
                <Controller
                  name="education"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value || undefined}
                      onValueChange={(newValue) => onChange(newValue)}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Wybierz wykształcenie" />
                      </SelectTrigger>
                      <SelectContent className="max-h-64 max-w-[400px]">
                        <SelectItem value="none">---</SelectItem>
                        {educationList.map((education) => (
                          <SelectItem key={education.value} value={String(education.value)}>
                            {education.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
                {errors?.education?.message && (
                  <p className="text-sm text-destructive">{errors.education.message}</p>
                )}
              </div>
              <FormField
                name="educationSimple"
                label="Wykształcenie dodatkowe"
                register={register}
                errors={errors}
                placeholder="Wykształcenie dodatkowe"
                optional={true}
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Zatrudnienie</CardTitle>
        </CardHeader>
        <CardContent>
          <EmploymentSection
            fields={fields}
            control={control}
            institutionList={institutionList}
            positionList={positionList}
            subjectList={subjectList}
            setError={setError}
            errors={errors}
            register={register}
            initJobs={initJobs}
            remove={remove}
            addWork={addWork}
          />
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        <div className="md:col-span-2">
          <Card>
            <CardHeader>
              <CardTitle>Zatrudnienie dodatkowe</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-6">
                {jobsSimpleFields.map((field, index) => (
                  <li
                    key={`job-${index}-${field.id}`}
                    className="border-t border-gray-200 pt-4 first:border-0 first:pt-0"
                  >
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                      <FormField
                        name={`jobsSimple.${index}.institution`}
                        label={index === 0 ? 'Miejsce pracy' : ''}
                        register={register}
                        errors={(errors?.jobsSimple?.[index] || {}) as any}
                        placeholder="Miejsce pracy"
                        optional={true}
                      />
                      <FormField
                        name={`jobsSimple.${index}.position`}
                        label={index === 0 ? 'Stanowisko' : ''}
                        register={register}
                        errors={(errors?.jobsSimple?.[index] || {}) as any}
                        placeholder="Stanowisko"
                        optional={true}
                      />
                      <FormField
                        name={`jobsSimple.${index}.subject`}
                        label={index === 0 ? 'Przedmiot' : ''}
                        register={register}
                        errors={(errors?.jobsSimple?.[index] || {}) as any}
                        placeholder="Przedmiot"
                        optional={true}
                      />
                    </div>
                    <div className="mt-4 flex justify-end">
                      <Button type="button" label="usuń" onClick={() => removeJobsSimple(index)} />
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-6 flex justify-center">
                <Button type="button" label="Dodaj" onClick={addSimpleWork} />
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="md:col-span-1">
          <Card>
            <CardHeader>
              <CardTitle>Załącznik</CardTitle>
            </CardHeader>
            <CardContent>
              {declarationFile && (
                <div className="mb-6 space-y-2">
                  <p className="text-sm">
                    Wgrano plik: <span className="font-medium">{declarationFile.filename}</span>
                  </p>
                </div>
              )}
              <div className="space-y-4">
                <Label htmlFor="declarationDoc" className="block text-sm font-medium">
                  {initData.declarationFile
                    ? 'Podmień skan deklaracji członkowskiej'
                    : 'Dodaj skan deklaracji członkowskiej'}
                </Label>
                <p className="text-sm text-gray-500">
                  Dozwolone typy plików: PDF, PNG, JPEG, DOC, DOCX. Maksymalny rozmiar: 5MB
                </p>
                <Input id="declarationDoc" type="file" onChange={handleFileChange} />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="flex justify-center gap-4 pt-6">
        <Button label="Anuluj" variant="secondary" onClick={handleCancel} />
        <Button
          disabled={isSending}
          label={isEditView ? 'Zaktualizuj deklarację' : 'Utwórz deklarację'}
        />
      </div>
    </form>
  )
}
