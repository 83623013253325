import { useNavigate } from 'react-router-dom'

import { HoverIcon } from 'components/Atoms/HoverIcon'
import { wipMessage } from 'tools/ToastHelpers'

type Props = {
  type: string
  id: string
}

export const SettingsActionCell = ({ type, id }: Props) => {
  const navigate = useNavigate()
  const editPathHandler = () => navigate(`/settings/${type}/${id}/update`)
  return (
    <div className='flex cursor-pointer'>
      <HoverIcon iconName='PencilIcon' title='Edytuj' onClick={editPathHandler} />
      {/* <HoverIcon iconName='TrashIcon' title='Usuń' onClick={() => wipMessage()} /> */}
    </div>
  )
}

export default SettingsActionCell
