import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { setupPostal } from 'tools/formTools'
import { errorRequires } from 'tools/ToastHelpers'
import { IInstitutionCreate } from 'types/institution'

interface IProps {
  onSubmit: (data: any) => void
  initData: IInstitutionCreate
  groupList: IOption[]
  typeList: IOption[]
  initType?: IOption
  branchList?: IOption[]
  initGroup?: IOption[]
  initBranch?: IOption // Add this prop for initialization
  isSending?: boolean
  showBranch?: boolean
  validationSchema?: any
}

interface IOption {
  label: string
  value: number | string
}

export const InstitutionForm = ({
  onSubmit,
  initData,
  groupList,
  typeList,
  initType,
  branchList = [],
  initGroup = [],
  initBranch, // New prop for branch initialization
  isSending = false,
  showBranch = false,
  validationSchema,
}: IProps) => {
  const navigate = useNavigate()
  const [selected, setSelected] = useState(initGroup)
  const [postalCode, setPostalCode] = useState(initData.postalCode || '')

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setError,
    clearErrors,
  } = useForm<IInstitutionCreate>({
    defaultValues: initData,
    resolver: yupResolver(validationSchema),
  })

  // Debug errors - uncomment if needed
  // useEffect(() => {
  //   console.log('Current form errors:', errors);
  // }, [errors]);

  const handleSelectType = (val: IOption) => {
    setValue('type', String(val.value))
    clearErrors('type')
  }

  const handleBranchSelect = (val: IOption) => {
    setValue('branch', String(val.value))
    clearErrors('branch')
  }

  useEffect(() => {
    if (selected?.length) {
      setValue(
        'groups',
        selected.map((el: IOption) => el.value)
      )
    }
  }, [selected, setValue])

  const onValidationFailure = () => {
    // Log errors to help with debugging
    console.log('Validation failed, errors:', errors)
    errorRequires(errors)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          label="Nazwa placówki"
          {...register('name')}
          error={errors.name?.message}
          placeholder="np. nazwa szkoły"
        />
        <div>
          <Select
            defaultOption={initType}
            handleSelect={(val) => handleSelectType(val)}
            options={typeList}
            label="Typ placówki"
            selectLabel="---"
            withEmpty
            error={errors.type?.message}
          />
          <p className="mt-2 text-sm text-red-600">{errors?.type?.message}</p>
        </div>
        <div>
          <p className="mb-1 block text-sm font-medium text-gray-700">Zespół szkół</p>
          <MultiSelect
            options={groupList}
            value={selected}
            hasSelectAll={false}
            className="text-sm"
            onChange={setSelected}
            overrideStrings={{ selectSomeItems: 'Opcjonalne', search: 'Wyszukaj' }}
            labelledBy="Select"
          />
        </div>
        {showBranch && (
          <div>
            <Select
              defaultOption={initBranch}
              handleSelect={(val) => handleBranchSelect(val)}
              options={branchList}
              label="Oddział"
              selectLabel="---"
              withEmpty
              error={errors.branch?.message}
            />
            <p className="mt-2 text-sm text-red-600">{errors?.branch?.message}</p>
          </div>
        )}
      </div>
      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          label="Numer telefonu"
          placeholder="Podaj numer"
          {...register('headmasterPhone')}
          error={errors.headmasterPhone?.message}
          maxLength={14}
        />
        <TextField
          label="E-mail kontaktowy"
          placeholder="Podaj e-mail"
          {...register('headmasterEmail')}
          error={errors.headmasterEmail?.message}
        />
      </div>
      <div className="grid grid-cols-4 gap-5">
        <Typography className="mb-1 text-sm font-medium text-gray-700">
          Dyrektor placówki:
        </Typography>
      </div>
      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          placeholder="Imię dyrektora"
          {...register('headmasterFirstName')}
          error={errors.headmasterFirstName?.message}
        />
        <TextField
          placeholder="Nazwisko dyrektora"
          {...register('headmasterLastName')}
          error={errors.headmasterLastName?.message}
        />
      </div>
      <div className="mb-5 grid grid-cols-4 gap-5">
        <Typography weight="normal" size="sm">
          Adres:
        </Typography>
      </div>

      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          {...register('postalCode')}
          error={errors.postalCode?.message}
          label="Kod pocztowy"
          placeholder="np. 01-123"
          value={postalCode}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            setupPostal(e, setPostalCode)
          }}
        />
        <TextField
          {...register('city')}
          error={errors.city?.message}
          label="Miejscowość"
          placeholder="Miejscowość"
        />
        <div></div>
      </div>

      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          {...register('streetName')}
          error={errors.streetName?.message}
          label="Ulica"
          placeholder="Ulica"
        />
        <TextField
          {...register('houseNr')}
          error={errors.houseNr?.message}
          label="Numer budynku"
          placeholder="Numer budynku"
        />
        <TextField {...register('apartmentNr')} label="Numer lokalu" placeholder="Numer lokalu" />
      </div>
      <div className="mt-10 flex justify-start">
        <Button
          label="Anuluj"
          variant="secondary"
          className="mr-5"
          onClick={() => navigate(path.institution.list)}
        />
        <Button disabled={isSending} label="Zatwierdź" />
      </div>
    </form>
  )
}
