import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { houseNrRegExp, phoneRegExp } from 'constants/regexs'

export const createDeclarationUserSchema = yup.object({
  firstName: yup
    .string()
    .required(errorMessage.requiredField)
    .min(2, 'Imię musi mieć co najmniej 2 znaki.'),
  branch: yup.string().required(errorMessage.requiredField).min(2),
  secondName: yup.string().nullable().optional(),
  lastName: yup
    .string()
    .required(errorMessage.requiredField)
    .min(2, 'Nazwisko musi mieć co najmniej 2 znaki.'),
  pesel: yup
    .string()
    .required('Numer PESEL jest wymagany.')
    .matches(/^\d{11}$/, 'Numer PESEL musi składać się z 11 cyfr.'),
  sex: yup.string().required('Płeć jest wymagana.'),
  phoneNumber: yup
    .string()
    .required('Numer telefonu jest wymagany.')
    .matches(phoneRegExp, 'Nieprawidłowy format numeru telefonu.'),

  email: yup.string().nullable().optional(),
  zipCode: yup
    .string()
    .required('Kod pocztowy jest wymagany.')
    .min(6, 'Kod pocztowy musi mieć 6 znaków.')
    .max(6, 'Kod pocztowy musi mieć 6 znaków.'),
  city: yup
    .string()
    .test('no-numbers', 'Nazwa miasta nie może zawierać cyfr.', (value) => {
      // Custom validation function
      return !/\d/.test(String(value))
    })
    .required('Nazwa miasta jest wymagana.'),
  street: yup.string().nullable().optional(),
  houseNr: yup
    .string()
    .matches(houseNrRegExp, 'Nieprawidłowy format numeru domu.')
    .required('Numer domu jest wymagany.'),
  apartmentNr: yup.string().nullable().optional(),
  education: yup.string().nullable().optional(),
  educationSimple: yup.string().nullable().optional(),
  jobs: yup
    .array()
    .of(
      yup.object().shape({
        institution: yup.object().shape({
          value: yup
            .mixed()
            .test('institution-required', 'Miejsce pracy jest wymagane.', function (value) {
              return value !== undefined && value !== null && value !== ''
            }),
          label: yup.string(),
        }),
        position: yup.object().shape({
          value: yup
            .mixed()
            .test('position-required', 'Stanowisko jest wymagane.', function (value) {
              return value !== undefined && value !== null && value !== ''
            }),
          label: yup.string(),
        }),
        isMain: yup.boolean(),
      })
    )
    .min(1, errorMessage.job), // At least one job is required
  jobsSimple: yup.array().of(
    yup
      .object()
      .shape({
        institution: yup.string().nullable().default(''),
        position: yup.string().nullable().default(''),
        isMain: yup.boolean(),
      })
      .test('fill-at-least-one-field', 'Wypełnij przynajmniej jedno pole.', function (value) {
        return Object.values(value).some(
          (field) => field !== undefined && field !== null && field !== ''
        )
      })
  ),
})
