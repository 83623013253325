import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import { SearchAndFetchInput } from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { IMergeBranch, IMergeBranchFetcher } from 'types/branch'
import { IOption } from 'types/form'

type Props = {
  id?: string
  isSending?: boolean
  currentName?: string
  removedList: IOption[]
  setQuery: (e: string) => void
  sumbitHandler?: (e: any) => void
  loadingRemoved?: boolean
  searchMore: () => void
  onSubmit: (data: IMergeBranchFetcher) => void
  defaultRemoved?: {
    label: string
    value: string
  }
}

export const BranchMergeForm = ({
  onSubmit,
  isSending,
  id,
  currentName,
  searchMore,
  loadingRemoved,
  removedList,
  setQuery,
  defaultRemoved,
}: Props) => {
  const navigate = useNavigate()
  const { register, handleSubmit, control, setValue } = useForm<IMergeBranch>({
    defaultValues: {
      toRemove: defaultRemoved,
      name: '',
      mergeGroupPA: false,
      mergeGroupEiR: false,
      mergeClubs: false,
    },
  })

  const setName = (e: string) => {
    setValue('name', e)
  }
  const onSubmitHandler = (data: any) => {
    onSubmit({ ...data, toRemove: id })
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <div className='grid grid-cols-4'>
          <Typography weight='medium'>Oddział do połączenia: {currentName}</Typography>
        </div>
      </div>

      <div className='mt-8'>
        <div className='grid grid-cols-4'>
          <SearchAndFetchInput
            searchMore={searchMore}
            externalChangeHandler={setName}
            isLoading={loadingRemoved}
            name={'target'}
            label={'Wybierz oddział docelowy'}
            register={register}
            defaultOption={defaultRemoved}
            options={removedList}
            control={control}
          />
        </div>
      </div>
      <div className='mb-4 mt-8'>
        <div className='grid grid-cols-4'>
          <TextField {...register('name')} label='Nazwa nowego oddziału' />
        </div>
      </div>
      <div className='grid grid-cols-4'>
        <div className='mb-4 flex flex-col gap-3'>
          <Checkbox {...register('mergeClubs')} id={'mergeClubs'} label='Przenieś ogniska' />
          <Checkbox
            {...register('mergeGroupEiR')}
            id={'mergeGroupEiR'}
            label='Przenieś/scal Sekcję emerytów i rencistów'
          />
          <Checkbox
            {...register('mergeGroupPA')}
            id={'mergeGroupPA'}
            label='Przenieś/scal Sekcję pracowników administracji i obsługi'
          />
        </div>
      </div>
      <Button disabled={isSending} label='Połącz' type='submit' />
    </form>
  )
}
