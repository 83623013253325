import { Dispatch, SetStateAction } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { TextField } from 'components/Atoms/TextField'
import { sexType } from 'constants/sex'
import useAuth from 'hooks/useAuth'
import { personalMemberSchema } from 'schemas/memberSchema'
import { IMemberContact, IMemberPersonal } from 'types/member'

type Props = {
  data: IMemberContact
  personalData: IMemberPersonal
  isSending?: boolean
  isEdit?: boolean
  isReveal?: boolean
  setIsEdit: (val: boolean) => void
  onReveal: Dispatch<SetStateAction<boolean>>
  onSubmit: (data: any) => void
  generalIsReveal?: boolean
}

export const MemberContactData = ({
  data,
  isSending,
  isEdit,
  isReveal,
  personalData,
  setIsEdit,
  onReveal,
  onSubmit,
  generalIsReveal,
}: Props) => {
  const { isAllAdmin, isAllOD } = useAuth()
  const isEditAllow = isAllAdmin() || isAllOD()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { 
      phone: data?.phone,
      email: data?.email,
      emailSecondary: data?.emailSecondary,
    },
    resolver: yupResolver(personalMemberSchema),
  })

  const handleVisibleEditField = () => {
    onReveal(true)
    setIsEdit(true)
  }

  const colsNum = isEditAllow ? 'grid-cols-3' : 'grid-cols-4'
  const contactCols = isEditAllow ? 'col-span-2' : ''
  const labelButtons = isEditAllow
    ? [
        {
          disabled: isSending || isEdit,
          label: 'Edytuj dane',
          handleClick: handleVisibleEditField,
        },
      ]
    : []

  const shouldShowBirthDate = isReveal

  if (isEdit) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card label="Dane osobowe" actionsButton={labelButtons}>
          <div className={clsx('grid gap-4', colsNum)}>
            <div>
              <LabelTypography
                label="Data urodzenia"
                text={shouldShowBirthDate ? personalData?.birthDate : '••••-••-••'}
              />
            </div>
            <div>
              <TextField {...register('email')} label="Adres e-mail" />
            </div>
            <div>
              <TextField {...register('emailSecondary')} label="Adres e-mail (dodatkowy)" />
            </div>
            <div>
              <LabelTypography
                label="Płeć"
                text={personalData?.sex === 'male' ? 'Mężczyzna' : 'Kobieta'}
              />
            </div>
            <div className={contactCols}>
              <TextField {...register('phone')} error={errors.phone?.message} label="Telefon" />
            </div>
          </div>
          <div className="mt-5 flex justify-center gap-5">
            <Button label="Anuluj" variant="secondary" onClick={() => setIsEdit(false)} />
            <Button label="Zapisz" variant="primary" type="submit" />
          </div>
        </Card>
      </form>
    )
  }

  return (
    <Card label="Dane osobowe" actionsButton={labelButtons}>
      <div className={clsx('grid gap-4', colsNum)}>
        <div>
          <LabelTypography
            label="Data urodzenia"
            text={shouldShowBirthDate ? personalData?.birthDate : '••••-••-••'}
          />
        </div>
        {!isEditAllow && (
          <LabelTypography label="Płeć" text={sexType[personalData?.sex as 'female' | 'male']} />
        )}
        <div>
          <LabelTypography label="Adres e-mail" text={data?.email} />
        </div>
        <div>
          <LabelTypography label="Adres e-mail (dodatkowy)" text={data?.emailSecondary} />
        </div>
        {isEditAllow && (
          <LabelTypography label="Płeć" text={sexType[personalData?.sex as 'female' | 'male']} />
        )}
        <div className={contactCols}>
          <LabelTypography label="Telefon" text={data?.phone} />
        </div>
      </div>
    </Card>
  )
}
