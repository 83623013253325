import { useEffect, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import { SectionFormClub } from 'components/Section/SectionFormClub'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { sectionErrors } from 'error-data/section'
import { useArchiveSection } from 'fetchers/hooks/useArchiveSection'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getInstitutionsListByBranchName } from 'fetchers/institutionFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSection, updateSectionClub } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { ICreateSectionClub } from 'types/section'
import { IOption } from 'types/form'

export const SectionUpdateClub = () => {
  const [isSending, setIsSending] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState<string>('')
  const [legalDefaultOption, setLegalDefaultOption] = useState<IOption | undefined>(undefined)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, isAllAdmin, isAllOD } = useAuth()
  const legalData = useLegalFetcher()
  const isAllow = isAllAdmin() || isAllOD()

  const { id } = useParams()
  const sectionId = id as string

  const {
    data: section,
    error: sectionError,
    isLoading: sectionIsLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.section, sectionId],
    queryFn: () => getSection(sectionId),
  })

  // Set initial region if section has a parent with regionId
  useEffect(() => {
    if (section?.parent?.regionId) {
      setSelectedRegion(section.parent.regionId)
    }
  }, [section?.parent?.regionId])

  // Set legal document default option
  useEffect(() => {
    if (section?.document) {
      // First check if we have a name for the document
      if (section.documentName) {
        setLegalDefaultOption({
          label: section.documentName,
          value: section.document,
        })
      } else {
        // If no name is available, use the ID temporarily
        // The actual name will be fetched when legal data loads
        setLegalDefaultOption({
          label: `Document ID: ${section.document}`,
          value: section.document,
        })
      }
    }
  }, [section])

  // Update the legal document option when legal data is fetched
  useEffect(() => {
    if (section?.document && legalData.legal.list && legalData.legal.list.length > 0) {
      // Try to find the document in the list by ID
      const foundDocument = legalData.legal.list.find(
        (item: IOption) => item.value === section.document
      )

      if (foundDocument) {
        setLegalDefaultOption(foundDocument)
      }
    }
  }, [section?.document, legalData.legal.list])

  // Set the branch unit ID for legal fetcher when section data loads
  useEffect(() => {
    if (section?.parent?.id) {
      legalData.setUnit(section.parent.id)
    }
  }, [section?.parent?.id])

  // Fetch regions list
  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 1000, []),
  })

  // Fetch branches filtered by region if selected
  const { data: branches, isLoading: branchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, selectedRegion],
    queryFn: () => {
      if (selectedRegion) {
        // If a region is selected, fetch branches filtered by that region
        const filterString = `&filter[region][]=${selectedRegion}`
        return getBranchesListPrefiltred(1, 1000, filterString)
      } else if (section?.parent?.regionId) {
        // Use section's parent region as fallback
        const filterString = `&filter[region][]=${section.parent.regionId}`
        return getBranchesListPrefiltred(1, 1000, filterString)
      }
      // Empty result if no region is selected yet
      return { items: [] }
    },
    enabled: !!selectedRegion || !!section?.parent?.regionId,
    // Keep previous data while loading new data to avoid UI flicker
    placeholderData: (previousData) => previousData,
    // Don't refetch on window focus to avoid unnecessary loading
    refetchOnWindowFocus: false,
  })

  const {
    data: institutions,
    error: institutionsError,
    isLoading: institutionsLoading,
  } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, section?.parent?.id],
    queryFn: () => getInstitutionsListByBranchName(1, 100, section?.parent?.id),
    enabled: !!section?.parent?.name,
  })

  // Handle region selection change
  const handleRegionChange = (regionId: string) => {
    setSelectedRegion(regionId)
  }

  const regionsList: IOption[] = (regions?.items || []).map((e: { name: string; id: string }) => ({
    label: e.name,
    value: e.id,
  }))

  const branchesList: IOption[] = (branches?.items || []).map(
    (e: { name: string; id: string }) => ({
      label: e.name,
      value: e.id,
    })
  )

  const institutionsList: IOption[] = (institutions?.items || []).map(
    (e: { name: string; id: string }) => ({
      label: e.name,
      value: e.id,
    })
  )

  const instit = Array.isArray(section?.institutions) ? section?.institutions : []

  const mutation = useMutation({
    mutationFn: (data: ICreateSectionClub) => updateSectionClub(sectionId, data),
    onSuccess: () => {
      successToast('Zaktualizowano ognisko.')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.section.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, sectionErrors.update, 'Nie udało się zaktualizować sekcji.')
    },
  })

  const onSubmit = async ({ region, branch, ...updateData }: ICreateSectionClub) => {
    try {
      // Log form data before processing
      console.log('Original form data:', { region, branch, ...updateData })

      // Ensure locationDescription is included in the payload
      const dataToSend = {
        ...updateData, 
        locationDescription: updateData.locationDescription || '',
      }

      console.log('Processed data to send:', dataToSend)

      setIsSending(true)
      mutation.mutate(dataToSend)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować sekcji.')
    }
  }

  // MODAL HANDLER
  const { showModalHandler, ArchiveModal } = useArchiveSection({
    refetch,
    navigatePath: path.section.list,
  })

  const initData = {
    region: section?.parent?.regionId || '',
    branch: section?.parent?.id || '',
    name: section?.name || '',
    phonePrimary: section?.contact?.phone ? section?.contact?.phone : '',
    phoneSecondary: section?.contact?.phoneSecondary ? section?.contact?.phoneSecondary : '',
    email: section?.contact?.email || '',
    locationDescription: section?.locationDescription ? section?.locationDescription : '',
    document: section?.document ? section?.document : '',
    institutions:
      instit.map((e: { name: string; id: string }) => ({
        name: e.name,
        id: e.id,
      })) || [],
  }

  const isLoading = sectionIsLoading || institutionsLoading || branchesLoading || regionsLoading
  const loadingHandler = getLoadingHandler(sectionError, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <Typography size="xl" weight="medium">
        <Link to="/section">Ognisko</Link> / Edytuj ognisko
      </Typography>
      <p className="font-eee mb-5 mt-1 text-xs font-bold text-gray-500">{section?.parent?.name}</p>
      <Card
        actionsButton={[
          {
            label: 'Archiwizuj',
            handleClick: () => showModalHandler(section),
          },
        ]}
      >
        <SectionFormClub
          legalData={legalData}
          isSending={isSending}
          data={initData}
          onSubmit={onSubmit}
          navigate={navigate}
          regionsList={regionsList}
          branchesList={branchesList}
          institutionsList={institutionsList}
          onRegionChange={handleRegionChange}
          legalDefaultOption={legalDefaultOption}
          createRoot={false} // Make sure to explicitly set createRoot to false for updates
        />
      </Card>
      <ArchiveModal />
    </>
  )
}
