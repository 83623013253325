import React from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getOrdersReceivedList } from 'fetchers/ordersFetchers'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { IOption } from 'types/form'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const OrdersReceived = () => {
  const { userToken, unitContext } = useAppSelector((state: any) => state.authReducer)
  const id = unitContext?.id
  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const navigate = useNavigate()

  const {
    data: ordersReceivedList,
    isLoading: ordersReceivedLoading,
    refetch: ordersReceivedRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.ordersReceivedList, filters, perPage, page, id],
    queryFn: () => getOrdersReceivedList(page, perPage, filters),
    retry: useRetryHandler({
      resourceName: 'OrdersReceived ordersReceivedList',
      maxRetries: 1,
    }),
  })

  const lastPage = useLastPage(ordersReceivedList?.pagination, ordersReceivedLoading)
  const generatedExportsList = ordersReceivedList?.items || []

  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa polecenia',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.name
        },
      },
      {
        Header: 'Treść polecenia',
        accessor: 'content',
        Cell: ({ row }: any) => {
          return row.original?.content
        },
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          return row.original?.createdAt.slice(0, 10)
        },
      },
      {
        Header: 'Jednostka nadawcza',
        accessor: 'issuingUnit',
        Cell: ({ row }: any) => {
          return row.original?.unit?.name
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz polecenie'
                onClick={() => navigate(`/orders/received/${row.original.id}`)}
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <Card label='Lista poleceń otrzymanych'>
      {!ordersReceivedLoading && (
        <>
          {generatedExportsList.length === 0 ? (
            <Typography className='text-center' weight='medium'>
              Brak poleceń do wyświetlenia
            </Typography>
          ) : (
            <>
              {' '}
              <div className='flex items-center justify-between gap-4'>
                {lastPage && (
                  <>
                    <div className='flex flex-col justify-end gap-4 md:flex-row'>
                      <div className='flex items-center gap-4'>
                        <Typography weight='medium' className='text-sm'>
                          Liczba wierszy na stronę
                        </Typography>
                        <Select
                          handleSelect={onLimitHandler}
                          options={[
                            { label: '10', value: 10 },
                            { label: '20 ', value: 20 },
                          ]}
                        />
                      </div>
                    </div>

                    <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
                      {lastPage > 1 && (
                        <Pagination
                          lastPage={lastPage}
                          currentPage={page}
                          handlePageChange={changePage}
                        />
                      )}
                    </span>
                  </>
                )}
              </div>{' '}
              <Table maxColumnWidth='300px' columns={columns} data={generatedExportsList || []} />
            </>
          )}
        </>
      )}
      {ordersReceivedLoading && <TableSkeleton />}
    </Card>
  )
}

export default OrdersReceived
