import { useMemo } from 'react'

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import SectionBaseInfo from 'components/Section/SectionBaseInfo'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { useArchiveSection } from 'fetchers/hooks/useArchiveSection'
import { getMembersSectionList } from 'fetchers/membersFetchers'
import { getSection } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import { IOption } from 'types/form'

const searchParams = {
  simpleParams: ['search', 'email', 'phone', 'sex', 'institutionLimit'],
  arrayParams: ['institution', 'subject'],
}

export const SectionDetails = () => {
  const navigate = useNavigate()
  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const { id, userToken, isAllAdmin, isAllOD } = useAuth()
  const isAllow = isAllAdmin() || isAllOD()

  // SECTION INFO
  const {
    data: section,
    error: sectionError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.section, id],
    queryFn: () => getSection(id),
    retry: errorQuery,
  })

  console.log({section})

  // MEMBERS LOADER
  const { data: members, isLoading: subLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberSectionList, page, perPage, filters, id],
    queryFn: () => {
      if (!id) return { items: [], pagination: {} }
      return getMembersSectionList(id, page, perPage, filters)
    },
    retry: errorQuery,
  })
  // Setup members list
  const membersList = members?.items || []
  // Setup member loading state
  const membersLoading = subLoading
  // Setup last page
  const lastPage = useLastPage(members?.pagination, membersLoading)
  // END MEMBERS LOADER

  // PAGE LIMIT HANDLER
  const onLimitHandler = (e: IOption) => setLimit(e.value)
  // END PAGE LIMIT HANDLER

  // MODAL HANDLER
  const { showModalHandler, ArchiveModal } = useArchiveSection({
    refetch,
    navigatePath: path.section.list,
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
        Cell: ({ row }: any) => row.original?.card?.number,
      },
      {
        Header: 'Pełnione funkcje',
        accessor: 'functions',
        Cell: ({ row }: any) => {
          return (
            <ul>
              {row.original?.unitPositions?.map((e: any, i: number) => (
                <li key={`${row?.original?.id}-fn-${i}-${e.unit.id}`}>
                  <p>
                    {e?.position?.name} ({e?.unit?.name})
                    {i !== row.original?.unitPositions?.length - 1 && ', '}
                  </p>
                </li>
              ))}
            </ul>
          )
        },
      },
      {
        Header: 'Akcje użytkowników',
        accessor: 'userActions',
        Cell: (row: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(`/member/${row.row.original.id}`)}
              />
              {/* <HoverIcon
                iconName='ArchiveBoxIcon'
                title='Archiwizuj'
                onClick={() => wipMessage()}
              /> */}
            </div>
          )
        },
      },
    ],
    [],
  )

  const allowArchive = membersList?.length === 0 && !section?.archived

  const loadingHandler = getLoadingHandler(sectionError, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <>
      <SectionBaseInfo
        data={section}
        showModalHandler={showModalHandler}
        allowArchive={allowArchive}
      />
      <Card label='Użytkownicy przypisani do ogniska'>
        {/* <div className='flex justify-between'>
          <div className='flex items-center'>
            <Select
              options={[
                { value: 10, label: '10' },
                { value: 20, label: '20' },
              ]}
            />
          </div>
        </div> */}
        <div className='mt-5'>
          <Table maxColumnWidth='300px' columns={columns} data={membersList} />
          {membersLoading && (
            <div className='flex justify-center p-4 align-middle'>
              <Spinner />
            </div>
          )}
          {lastPage > 1 && (
            <div className='flex justify-end'>
              <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
            </div>
          )}
        </div>
      </Card>
      <div className='my-10 flex justify-center'>
        <Button
          label='Powrót'
          icon={<ArrowLeftCircleIcon className='w-5' />}
          onClick={() => navigate(-1)}
        />
      </div>

      <Card label='Historia zmian'>
        <p>Work in progress...</p>
        {/* <Table data={data} columns={columns} /> */}
      </Card>
      <ArchiveModal />
    </>
  )
}
