import { useMemo, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import ErrorRouteModal from 'components/Atoms/ErrorRouteModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import MemberFunctionModal from 'components/Atoms/MemberFunctionModal'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { unitTermErrors } from 'error-data/unit'
import { updateMemberFunction } from 'fetchers/membersFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getStructureFunctionList, getStructureFunctionStatus } from 'fetchers/structureFetcher'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import useAuth from 'hooks/useAuth'
import { getTermFlex } from 'tools/dictionaryHelper'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getStatusObject } from 'tools/mapData'
import { errorForbiddenHandler, errorNotExistsHandler, errorQuery } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IMemberFunctionsFull, IStructureFunction, IUnitPositionUpdate } from 'types/structure'

interface PositionLimits {
  [key: string]: number
}

export const RegionFunctions = () => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const [functionsIsSending, setFunctionsIsSending] = useState(false)
  const [functionsModalData, setFunctionsModalData] = useState<any>(undefined)
  const navigate = useNavigate()
  const { userToken, userLoaded, isAllAdmin, unitContext, user } = useAuth()
  const { control } = useForm()
  const isAdmin = isAllAdmin()
  const unitId = unitContext?.id

  // GET REGIONS
  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
    refetch: refetchRegions,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!userLoaded || !isAdmin) return { items: [] }
      return getRegionsList(1, 1000)
    },
  })
  const regionOptions = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []
  // END GET REGIONS

  // GET POSITIONS
  const {
    data: positions,
    isLoading: positionsLoading,
    error: positionsError,
    refetch: refetchPositions,
  } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, userLoaded],
    queryFn: () => {
      if (!userLoaded) return { items: [] }
      return getUnitPositionsList(1, 1000)
    },
  })

  const { data: functionStatus, refetch: refetchFunctionStatus } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctionsStatus, userLoaded, selectedRegion],
    queryFn: () => {
      if (!selectedRegion) return { items: [] }
      return getStructureFunctionStatus(selectedRegion)
    },
  })

  const statusList = functionStatus?.items ? functionStatus?.items : []
  const positionLimits: PositionLimits = getStatusObject(statusList)

  const positionList = positions?.items
    ? positions?.items?.filter((item: IStructureFunction) => item.unitType === 'region')
    : []
  // END GET POSITIONS

  // GET CURRENT FUNCTIONS
  const {
    data: functions,
    isLoading: functionsLoading,
    error: functionError,
    refetch: refetchFunctions,
  } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctions, selectedRegion, unitId],
    queryFn: () => {
      if (unitId) {
        return getStructureFunctionList(unitId)
      } else if (selectedRegion && !unitId) {
        return getStructureFunctionList(selectedRegion)
      }
      return { items: [] }
    },
    retry: errorQuery,
  })

  // GET HISTORICAL FUNCTIONS
  const {
    data: historicalFunctions,
    isLoading: historicalFunctionsLoading,
    error: historicalFunctionError,
    refetch: refetchHistoricalFunctions,
  } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctionsHistorical, selectedRegion, unitId],
    queryFn: () => {
      if (!selectedRegion && !unitId) return { items: [] }

      const filters = '?filter[onlyCurrent]=false'
      if (unitId) {
        return getStructureFunctionList(unitId, filters)
      } else if (selectedRegion) {
        return getStructureFunctionList(selectedRegion, filters)
      }
      return { items: [] }
    },
    retry: errorQuery,
    enabled: !!selectedRegion || !!unitId,
  })

  const notExists = errorNotExistsHandler(functionError)
  const forbiddenBranch = errorForbiddenHandler(functionError)

  const functionsFullList = functions?.items ? functions?.items : []
  const historicalFunctionsFullList = historicalFunctions?.items ? historicalFunctions?.items : []

  const functionsList = functionsFullList.flatMap((item: IMemberFunctionsFull) =>
    item.unitPositions.map((position: any) => ({
      member: `${item.firstName} ${item.lastName}`,
      memberId: item.id,
      startedAt: position.startedAt,
      endedAt: position.endedAt,
      calculatedTermEndAt: position.calculatedTermEndAt,
      name: position.unitPosition.name,
      id: position.id,
      unitPosition: position.unitPosition,
      unitId: selectedRegion || unitId,
    }))
  )

  // Process historical functions and filter out current ones to avoid duplication
  const historicalFunctionsList = historicalFunctionsFullList.flatMap(
    (item: IMemberFunctionsFull) =>
      item.unitPositions
        .filter((position) => {
          // Check if this position has an endedAt date in the past
          return position.endedAt && new Date(position.endedAt) < new Date()
        })
        .map((position: any) => ({
          member: `${item.firstName} ${item.lastName}`,
          memberId: item.id,
          startedAt: position.startedAt,
          endedAt: position.endedAt,
          calculatedTermEndAt: position.calculatedTermEndAt,
          name: position.unitPosition.name,
          id: position.id,
          unitPosition: position.unitPosition,
          unitId: selectedRegion || unitId,
        }))
  )
  // END GET FUNCTIONS

  const schemeColumns = useMemo(
    () => [
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Przypisanych członków',
        accessor: 'count',
        Cell: ({ row }: any) => {
          return row.original.id ? positionLimits[row.original.id] : '-'
        },
      },
      {
        Header: 'Limit',
        accessor: 'maxNumber',
        Cell: ({ row }: any) => {
          return <p>{!row.original.maxNumber ? '-' : row.original.maxNumber}</p>
        },
      },
      {
        Header: 'Kadencja',
        accessor: 'lengthOfTermUnit',
        Cell: ({ row }: any) => {
          const { lengthOfTermUnit, lengthOfTerm } = row.original
          return <p>{getTermFlex(lengthOfTerm, lengthOfTermUnit)}</p>
        },
      },
    ],
    [positionList, positionLimits]
  )

  const openModal = (data: any) => setFunctionsModalData(data)
  const closeModal = () => setFunctionsModalData(undefined)

  const submitHandler = (data: IUnitPositionUpdate) => {
    // We need to ensure we pass the original function data including memberId
    const updateData = {
      ...data,
      memberId: functionsModalData.memberId,
      termId: functionsModalData.id,
    }
    updateFunction(updateData)
  }

  // UPDATE FUNCTIONS INFO
  const mutateFunction = useMutation({
    mutationFn: (data: any) => updateMemberFunction(data),
    onSuccess: () => {
      successToast('Zaktualizowano funkcję członka.')
      refetchFunctions()
      refetchHistoricalFunctions()
      setFunctionsIsSending(false)
      setFunctionsModalData(undefined)
    },
    onError: (error: AxiosError) => {
      setFunctionsIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        unitTermErrors.update,
        'Nie udało się zaktualizować funkcji członka.'
      )
    },
  })

  const updateFunction = async (data: any) => {
    setFunctionsIsSending(true)
    try {
      // Pass data directly to mutation - we've already formatted it correctly in submitHandler
      mutateFunction.mutate(data)
    } catch (error) {
      setFunctionsIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować funkcji członka.')
    }
  }
  // END UPDATE FUNCTIONS INFO

  const dataColumns = useMemo(
    () => [
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Członek',
        accessor: 'member',
      },
      {
        Header: 'Kadencja od',
        accessor: 'startedAt',
      },
      {
        Header: 'Kadencja do',
        accessor: 'endedAt',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className="flex">
              <HoverIcon
                iconName="PencilIcon"
                title="Edytuj"
                onClick={() => openModal(row.original)}
              />
            </div>
          )
        },
      },
    ],
    [selectedRegion, unitId]
  )

  // Historical data columns - same as current but without actions
  const historicalDataColumns = useMemo(
    () => [
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Członek',
        accessor: 'member',
      },
      {
        Header: 'Kadencja od',
        accessor: 'startedAt',
      },
      {
        Header: 'Kadencja do',
        accessor: 'endedAt',
      },
    ],
    []
  )

  const createHandler = () => {
    if (isAdmin) navigate(`${selectedRegion}/create`)
    if (unitId) navigate(`${unitId}/create`)
  }

  const addButtonDisabled = !selectedRegion && isAdmin
  const showSelector = regionOptions.length > 0 && isAdmin
  const showSelectorLoader = regionsLoading && isAdmin
  const functionsLoaded =
    (selectedRegion && !functionsLoading && functionsList.length > 0) ||
    (unitId && !functionsLoading && functionsList.length > 0 && !isAdmin)
  const functionsIsLoading = selectedRegion && functionsLoading
  const noRegionSelected = !selectedRegion && isAdmin
  const noFunctions =
    (selectedRegion && !functionsLoading && functionsList.length === 0 && isAdmin) ||
    (!functionsLoading && functionsList.length === 0 && !isAdmin)

  const historicalFunctionsLoaded =
    (selectedRegion && !historicalFunctionsLoading && historicalFunctionsList.length > 0) ||
    (unitId && !historicalFunctionsLoading && historicalFunctionsList.length > 0 && !isAdmin)
  const historicalFunctionsIsLoading = (selectedRegion || unitId) && historicalFunctionsLoading
  const noHistoricalFunctions =
    (selectedRegion || unitId) &&
    !historicalFunctionsLoading &&
    historicalFunctionsList.length === 0

  if (!userLoaded) return <LoadingSection />
  if (notExists) return <ErrorRouteModal />
  if (forbiddenBranch) return <ErrorRoleModal />
  if (!isAdmin) return <ErrorRoleModal />

  return (
    <div>
      <Typography size="xl" weight="medium">
        Funkcje pełnione w okręgu
      </Typography>
      <div className="mt-2 w-80">
        {showSelectorLoader && <Spinner />}
        {showSelector && (
          <Controller
            name="unitId"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                handleSelect={({ value }) => {
                  onChange(value)
                  setSelectedRegion(String(value))
                }}
                label="Wskaż okręg"
                withEmpty
                selectLabel="---"
                options={regionOptions}
                isLoading={regionsLoading}
              />
            )}
          />
        )}
      </div>
      <div className="my-5 flex">
        <div className="mr-5">
          <Button disabled={addButtonDisabled} label="Dodaj funkcję" onClick={createHandler} />
        </div>
      </div>
      <Card label="Funkcje dostępne w okręgu">
        {positionsLoading ? (
          <Spinner />
        ) : (
          <section>
            <Table columns={schemeColumns} data={positionList} />
          </section>
        )}
      </Card>
      <Card label="Funkcje członków okręgu">
        <section>
          {noRegionSelected && <p className="text-center">Wybierz okręg</p>}
          {noFunctions && <p className="text-center">Nie przypisano żadnych funkcji</p>}
          {functionsIsLoading && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}
          {functionsLoaded && (
            <>
              <Table columns={dataColumns} data={functionsList} />
              {/* <Pagination /> */}
            </>
          )}
        </section>
      </Card>

      <Card label="Historia funkcji w okręgu">
        <section>
          {noRegionSelected && <p className="text-center">Wybierz okręg</p>}
          {noHistoricalFunctions && <p className="text-center">Brak historycznych funkcji</p>}
          {historicalFunctionsIsLoading && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}
          {historicalFunctionsLoaded && (
            <>
              <Table columns={historicalDataColumns} data={historicalFunctionsList} />
              {/* <Pagination /> */}
            </>
          )}
        </section>
      </Card>

      {functionsModalData && (
        <MemberFunctionModal
          functionData={functionsModalData}
          isOpen={!!functionsModalData}
          isSending={functionsIsSending}
          closeModal={closeModal}
          submitHandler={submitHandler}
        />
      )}
    </div>
  )
}

export default RegionFunctions
