import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Link, useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import { InstitutionForm } from 'components/Institution/InstitutionForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { institutionErrors } from 'error-data/institution'
import { getBranchesList } from 'fetchers/branchFetchers'
import {
  createInstitution,
  getInstitutionsGroupsList,
  getInstitutionsTypesList,
} from 'fetchers/institutionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import {
  institutionSchemaWithBranchRequired,
  institutionSchemaWithoutBranch,
} from 'schemas/institutionSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IInstitutionCreate } from 'types/institution'

export const InstitutionCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  // Determine the user role for logic
  const isRegion = isAllOK()
  const isAdmin = isAllAdmin()
  const isBranch = isAllOD()

  // If user is Region (OK) or Admin, they should see branch field
  const showBranch = isRegion || isAdmin

  // If user is Branch (OD), they don't need branch field in validation
  // If user is Region (OK) or Admin, they need branch field in validation
  const validationSchema = isBranch
    ? institutionSchemaWithoutBranch // Branch users don't need to select branch
    : institutionSchemaWithBranchRequired // Admin/Region users must select branch

  const {
    data: institutionTypes,
    isLoading: loadingInsitutions,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.institutionTypesList],
    queryFn: () => getInstitutionsTypesList(1, 100),
    retry: useRetryHandler({
      resourceName: 'institutionTypes',
      maxRetries: 1,
    }),
  })

  const { data: institutionGroups, isLoading: loadingGroups } = useQuery({
    queryKey: [userToken, queryKeys.institutionGroupsList],
    queryFn: () => getInstitutionsGroupsList(),
    retry: useRetryHandler({
      resourceName: 'institutionGroups',
      maxRetries: 1,
    }),
  })

  console.log({institutionGroups})

  const { data: branchData, isLoading: branchLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 100),
    retry: useRetryHandler({
      resourceName: 'branchData',
      maxRetries: 1,
    }),
    // Only fetch branch data if we need to show branch selector
    enabled: showBranch,
  })

  const mutation = useMutation({
    mutationFn: (data: IInstitutionCreate) => createInstitution(data),
    onSuccess: () => {
      successToast('Dodano placówkę.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.institution.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, institutionErrors.create, 'Nie udało się dodać placówki.')
    },
  })

  const onSubmit = async (data: IInstitutionCreate) => {
    try {
      setIsSending(true)

      // Create a clean payload with only the expected fields
      const submissionData = {
        name: data.name,
        type: data.type,
        headmasterPhone: data.headmasterPhone,
        headmasterFirstName: data.headmasterFirstName,
        headmasterLastName: data.headmasterLastName,
        headmasterEmail: data.headmasterEmail,
        city: data.city,
        postalCode: data.postalCode,
        streetName: data.streetName,
        houseNr: data.houseNr,
        apartmentNr: data.apartmentNr,
        groups: data.groups,
        branch: data.branch,
      }

      // Only add branch field if user is admin or region (not branch)
      if (!isBranch && showBranch && data.branch) {
        submissionData.branch = data.branch
      }

      console.log('Submitting data:', submissionData)

      mutation.mutate(submissionData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać placówki.')
    }
  }

  // Default form data
  const initData = {
    name: '',
    type: '',
    headmasterPhone: '',
    headmasterFirstName: '',
    headmasterLastName: '',
    headmasterEmail: '',
    city: '',
    postalCode: '',
    streetName: '',
    houseNr: '',
    apartmentNr: '',
    groups: [],
    ...(showBranch ? { branch: '' } : {}), // Only include branch if needed
  }

  const groupList = institutionGroups?.items
    ? institutionGroups?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const typeList = institutionTypes?.items
    ? institutionTypes?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const branchList = branchData?.items
    ? branchData?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const isLoading = loadingInsitutions || loadingGroups || (showBranch && branchLoading)
  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)

  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <Typography size="xl" weight="medium">
        <Link to="/institution">Placówki</Link> / Dodaj placówkę
      </Typography>
      <Card label="Nowa placówka">
        <InstitutionForm
          isSending={isSending}
          onSubmit={onSubmit}
          initData={initData}
          branchList={branchList}
          groupList={groupList}
          typeList={typeList}
          showBranch={showBranch}
          validationSchema={validationSchema}
        />
      </Card>
    </>
  )
}
