import { Fragment, useMemo, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Modal } from 'components/Atoms/Modal'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { documentArchiveErrors, documentGetFileErrors } from 'error-data/document'
import { getBranchesList } from 'fetchers/branchFetchers'
import {
  archiveDocument,
  getDocument,
  getDocumentFile,
  getDocumentFilePassword,
} from 'fetchers/documentFetchers'
import { getInstitutionsListByMe } from 'fetchers/institutionFetchers'
import { getMembersList } from 'fetchers/membersFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { getUsersList } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'

const DocumentDetails = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [documentID, setDocumentID] = useState('')
  const { userToken, id } = useAuth()
  const cardId = id as string
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [passwordId, setPasswordId] = useState('')
  const [startDownloading, setStartDownloading] = useState(false)

  const {
    data: documentData,
    isPending: isDocumentDataPending,
    isLoading: isDocumentDataLoading,
    error: documentDataError,
    refetch: refetchDocumentData,
  } = useQuery({
    queryKey: [userToken, queryKeys.documentDetails, id],
    queryFn: () => getDocument(id),
  })

  const documentType = documentData?.templateType

  const { data: userData, isLoading: userDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.usersList],
    queryFn: () => getUsersList(1, 100),
    retry: errorQuery,
  })

  const getUserEmailById = (userId: string) => {
    if (!userData) return 'Loading...'
    const user = userData?.items?.find((user: any) => user.id === userId)
    return user?.email || user?.displayName || user?.firstName + ' ' + user?.lastName || 'Nieznany'
  }

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'completed':
        return 'Gotowy do pobrania'
      case 'error':
        return 'Konflikt'
      default:
        return ''
    }
  }

  const openArchiveModal = () => setModalOpen(true)
  const closeArchiveModal = () => setModalOpen(false)

  const openPasswordModal = () => {
    setIsPasswordModalOpen(true)
  }

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false)
    setPasswordId('')
  }

  const archiveDocumentMutation = useMutation({
    mutationFn: () => archiveDocument(cardId),
    onSuccess: () => {
      successToast('Zarchiwizowano dokument.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.documentArchive] })
      navigate(path.document.list)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      const errorMessages = error.response?.data.errors
        .map((item: any) => `${item.property}: ${item.error}`)
        .join('\n')
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(errorMessages)
      })
      mutationErrorHandler(error, documentArchiveErrors.archive, errorMessages)
    },
  })

  const downloadFile = useMutation({
    mutationFn: () => getDocumentFile(documentID, documentData?.template.name),
    onSuccess: () => {
      successToast('Pobrano plik')
      setStartDownloading(false)
    },
    onError: (error: AxiosError) => {
      console.error(error)
      mutationErrorHandler(error, null, documentGetFileErrors.get.message)
      setStartDownloading(false)
    },
  })

  const password = useMutation({
    mutationFn: (id: any) => getDocumentFilePassword(id),
    onSuccess: (req) => {
      setPasswordId(req.password)
    },
    onError: (error: AxiosError) => {
      console.error(error)
      mutationErrorHandler(error, null, documentGetFileErrors.get.message)
    },
  })

  const passwordHandler = (id: string) => {
    try {
      openPasswordModal()
      password.mutate(id)
    } catch (error) {
      console.error('Error', error)
      mutationErrorHandler(null, null, documentArchiveErrors.archive.message)
    }
  }

  const confirmArchiveDocumentHandler = () => {
    archiveDocumentMutation.mutate()
    closeArchiveModal()
  }

  const translateArchiveStatus = (archived: boolean) => {
    return archived ? 'Tak' : 'Nie'
  }

  const { data: getRegionsListData } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 100, []),
    enabled: documentType === 'region',
  })

  const { data: getBranchesListData } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 100, []),
    enabled: documentType === 'branch',
  })

  const { data: getSectionsListData } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList],
    queryFn: () => getSectionsList(1, 100, []),
    enabled: documentType === 'section',
  })

  const { data: getInstitutionsListByMeData } = useQuery({
    queryKey: [userToken, queryKeys.institutionListByMe],
    queryFn: () => getInstitutionsListByMe(1, 100),
    enabled: documentType === 'institution',
  })

  const { data: membersListData } = useQuery({
    queryKey: [userToken, queryKeys.membersList],
    queryFn: () => getMembersList(1, 100, ''),
    enabled: documentType === 'member',
  })

  const translateInstitution = (institutionId: string) => {
    if (!getInstitutionsListByMeData) return 'Wczytuję...'
    const institution = getInstitutionsListByMeData?.items?.find(
      (institution: any) => institution.id === institutionId,
    )
    return institution?.name || 'Nieznany'
  }

  const translateBranch = (branchId: string) => {
    if (!getBranchesListData) return 'Wczytuję...'
    const branch = getBranchesListData?.items?.find((branch: any) => branch.id === branchId)
    return branch?.name || 'Nieznany'
  }

  const translateRegion = (regionId: string) => {
    if (!getRegionsListData) return 'Wczytuję...'
    const region = getRegionsListData?.items?.find((region: any) => region.id === regionId)
    return region?.name || 'Nieznany'
  }

  const translateSection = (sectionId: string) => {
    if (!getSectionsListData) return 'Wczytuję...'
    const section = getSectionsListData?.items?.find((section: any) => section.id === sectionId)
    return section?.name || 'Nieznany'
  }

  const translateMember = (memberId: string) => {
    if (!membersListData) return 'Wczytuję...'
    const member = membersListData?.items?.find((member: any) => member.id === memberId)
    return member?.firstName + ' ' + member?.lastName || 'Nieznany'
  }

  const translateDocumentType = (type: string) => {
    switch (type) {
      case 'institution':
        return 'Placówka'
      case 'branch':
        return 'Oddział'
      case 'region':
        return 'Okręg'
      case 'section':
        return 'Ognisko'
      case 'member':
        return 'Członek'
      default:
        return ''
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'name',
        Cell: ({ row }: any) => {
          switch (documentData?.templateType) {
            case 'institution':
              return translateInstitution(row.original?.relatedItemId)
            case 'branch':
              return translateBranch(row.original?.relatedItemId)
            case 'region':
              return translateRegion(row.original?.relatedItemId)
            case 'section':
              return translateSection(row.original?.relatedItemId)
            case 'member':
              return translateMember(row.original?.relatedItemId)
            default:
              return ''
          }
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <div className='flex gap-2'>
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='InboxArrowDownIcon'
                title='Pobierz plik'
                onClick={() => {
                  setDocumentID(row.row.original?.file)
                  downloadFile.mutate()
                }}
              />
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='LockClosedIcon'
                title='Pobierz hasło'
                onClick={() => {
                  passwordHandler(row.row.original?.file)
                }}
              />
            </div>
          )
        },
      },
    ],
    [documentData?.items],
  )

  if (isDocumentDataLoading) {
    return (
      <Card label='Szczegóły dokumentu'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  if ((documentDataError as AxiosError)?.request?.status === 403) {
    return (
      <>
        <Card label='Szczegoły dokumentu'>
          <Typography className='text-center'>Brak dostępu</Typography>
        </Card>
        <div className='my-4 flex justify-center gap-4'>
          <Button
            variant='secondary'
            label='Powrót'
            onClick={(e) => {
              e.preventDefault()
              navigate(path.document.list)
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Card
        label={`${documentData?.template.name}`}
        actionsButton={[
          {
            variant: 'secondary',
            label: 'Archiwizuj',
            handleClick: openArchiveModal,
          },
          {
            label: (
              <>
                {startDownloading ? (
                  <span className='flex items-center'>
                    <span className='loader'></span> Pobieram...
                  </span>
                ) : (
                  'Pobierz plik zbiorczy'
                )}
              </>
            ),
            disabled: startDownloading,
            handleClick: () => {
              setStartDownloading(true)
              setDocumentID(documentData?.overallFile)
              downloadFile.mutate()
            },
          },
          {
            label: 'Pobierz hasło do pliku zbiorczego',
            handleClick: () => {
              passwordHandler(documentData?.overallFile)
            },
          },
        ]}
      >
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
          <LabelTypography label='Data utworzonia' text={documentData?.createdAt} />
          <LabelTypography
            label='Utworzony przez'
            text={getUserEmailById(documentData?.createdBy)}
          />
          <div>
            <label htmlFor='' className='mb-1 block text-sm font-medium text-gray-700'>
              Status dokumentu
            </label>
            <span
              className={`inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-bold font-eee text-gray-900 focus:ring-2 focus:ring-ring focus:ring-offset-2 ${
                documentData?.status === 'completed'
                  ? 'border-green-500 bg-green-400'
                  : documentData?.status === 'error'
                    ? 'border-green-500 bg-red-400'
                    : 'border-green-500 bg-yellow-400'
              }`}
            >
              {getStatusText(documentData?.status)}
            </span>
          </div>
          <LabelTypography label='Nazwa szablonu' text={documentData?.template.name} />
          <LabelTypography
            label='Typ szablonu'
            text={translateDocumentType(documentData?.templateType)}
          />
          <LabelTypography label='Liczba dokumentów' text={documentData?.itemsCnt} />
          <LabelTypography
            label='Archiwalny'
            text={translateArchiveStatus(documentData?.archived)}
          />
        </div>
      </Card>

      <Card
        label={`Lista ${
          documentData?.templateType === 'member'
            ? 'członków'
            : documentData?.templateType === 'institution'
              ? 'placówek'
              : documentData?.templateType === 'branch'
                ? 'oddziałów'
                : documentData?.templateType === 'region'
                  ? 'okręgów'
                  : documentData?.templateType === 'section'
                    ? 'ognisk'
                    : ''
        }`}
      >
        {!isDocumentDataLoading && (
          <Table maxColumnWidth='300px' columns={columns} data={documentData?.items || []} />
        )}

        {isModalOpen && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/80'>
            <div className='rounded bg-white p-6 shadow-lg'>
              <h2 className='mb-4 text-xl'>Potwierdzenie archiwizacji</h2>
              <p>Czy na pewno chcesz zarchiwizować ten dokument?</p>
              <div className='mt-4 flex justify-end space-x-4'>
                <button onClick={closeArchiveModal} className='rounded bg-gray-300 px-4 py-2'>
                  Anuluj
                </button>
                <button
                  onClick={confirmArchiveDocumentHandler}
                  className='rounded bg-red-500 px-4 py-2 text-white'
                >
                  Archiwizuj
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Password Modal */}
        <Modal isOpen={isPasswordModalOpen} handleClose={closePasswordModal}>
          <Card
            actionsButton={[
              {
                label: 'Skopiuj hasło',
                handleClick: () => {
                  navigator.clipboard.writeText(passwordId)
                  successToast('Hasło skopiowane do schowka')
                },
              },
            ]}
            label={password.isPending ? 'Pobieranie hasła' : 'Hasło do pliku'}
          >
            <div className=''>
              <div className='pb-6 pt-4'>
                {password.isPending ? <Spinner /> : <p className='text-center'>{passwordId}</p>}
              </div>
              <div className='flex justify-center'>
                <Button variant='secondary' label={'Zamknij'} onClick={closePasswordModal} />
              </div>
            </div>
          </Card>
        </Modal>
      </Card>
      <div className='my-4 flex justify-center gap-4'>
        <Button
          variant='secondary'
          label='Powrót'
          onClick={(e) => {
            e.preventDefault()
            navigate(path.document.list)
          }}
        />
      </div>
    </>
  )
}

export default DocumentDetails
