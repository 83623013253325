import { useState } from 'react'

import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Link, useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import { SectionFormClub } from 'components/Section/SectionFormClub'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { sectionErrors } from 'error-data/section'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getInstitutionsListByMe } from 'fetchers/institutionFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { createSectionClub } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IOption } from 'types/form'
import { ICreateSectionClub, ICreateSectionClubAPI } from 'types/section'

export const SectionCreateClub = () => {
  const [isSending, setIsSending] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState<string>('')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, isAllAdmin, isAllOD } = useAuth()
  const legalData = useLegalFetcher()
  const isAllow = isAllAdmin() || isAllOD()

  // Fetch regions list
  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 1000, []),
  })

  // Fetch institutions
  const { data: institutions, isLoading: institutionLoading } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList],
    queryFn: () => getInstitutionsListByMe(1, 1000),
  })

  // Fetch branches with region filter if a region is selected
  const {
    data: branches,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, selectedRegion],
    queryFn: () => {
      if (selectedRegion) {
        // If a region is selected, fetch branches filtered by that region
        const filterString = `&filter[region][]=${selectedRegion}`
        return getBranchesListPrefiltred(1, 1000, filterString)
      } else {
        // Return empty results if no region is selected to avoid unnecessary fetches
        return { items: [] }
      }
    },
    retry: errorQuery,
    // Don't refetch when regionId is empty
    enabled: !!selectedRegion,
    // Keep previous data while loading new data to avoid UI flicker
    placeholderData: keepPreviousData,
  })

  // Transform data for select components
  const regionsList: IOption[] = (regions?.items || []).map((e: { name: string; id: string }) => ({
    label: e.name,
    value: e.id,
  }))

  const institutionsList: IOption[] = (institutions?.items || []).map(
    (e: { name: string; id: string }) => ({
      label: e.name,
      value: e.id,
    })
  )

  const branchesList: IOption[] = (branches?.items || []).map(
    (e: { name: string; id: string }) => ({
      label: e.name,
      value: e.id,
    })
  )

  // Handle region selection change
  const handleRegionChange = (regionId: string) => {
    if (regionId !== selectedRegion) {
      setSelectedRegion(regionId)
    }
  }

  const mutation = useMutation({
    mutationFn: (data: ICreateSectionClubAPI) => createSectionClub(data),
    onSuccess: () => {
      successToast('Stworzono nowe ognisko.')

      // Invalidate queries first
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })

      // Add a small delay before navigation to ensure state updates are processed
      setTimeout(() => {
        navigate(path.section.list)
      }, 100)

      // Reset sending state
      setIsSending(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, sectionErrors.createClub, 'Nie udało się stworzyć ogniska.')
    },
  })

  const onSubmit = async (data: ICreateSectionClub) => {
    try {
      setIsSending(true)

      console.log('Form data being submitted:', data)
      console.log('Institutions from form:', data.institutions)

      // Transform the data from UI format to API format
      const apiData: ICreateSectionClubAPI = {
        name: data.name,
        email: data.email,
        branch: data.branch,
        phonePrimary: data.phonePrimary,
        phoneSecondary: data.phoneSecondary,
        document: data.document,
        locationDescription: data.locationDescription,
        // Handle both cases: if institutions are IInstitution objects or if they're string IDs
        institutions: data.institutions
          .map((institution) =>
            // If it's an IInstitution object
            typeof institution === 'object' && institution !== null
              ? institution.id
              : // If it's already a string
                (institution as unknown as string)
          )
          .filter(Boolean), // Filter out any null/undefined/empty values
      }

      console.log('API data being sent:', apiData)

      mutation.mutate(apiData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się stworzyć ogniska.')
    }
  }

  const initData: ICreateSectionClub = {
    branch: '',
    name: '',
    email: '',
    phonePrimary: '',
    phoneSecondary: '',
    locationDescription: '',
    document: '',
    institutions: [],
    // We're adding region to the form data for internal use
    region: '',
  }

  const isLoading = institutionLoading || regionsLoading
  const error = branchesError || regionsError
  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <Typography size="xl" weight="medium" className="mb-5">
        <Link to="/section">Ognisko</Link> / Utwórz ognisko
      </Typography>
      <Card label="Nowe ognisko">
        <SectionFormClub
          legalData={legalData}
          isSending={isSending}
          createRoot
          onSubmit={onSubmit}
          data={initData}
          navigate={navigate}
          branchesList={branchesList}
          institutionsList={institutionsList}
          regionsList={regionsList}
          onRegionChange={handleRegionChange}
          branchesLoading={branchesLoading} // Pass the loading state to the form
        />
      </Card>
    </>
  )
}
