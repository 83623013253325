import { useNavigate } from 'react-router-dom'

import ErrorCard from 'components/ErrorCard'

export const ErrorRoleModal = () => {
  const navigate = useNavigate()
  return (
    <ErrorCard
      roleError
      label={'Brak uprawnień'}
      message={'Nie masz uprawnień, aby przeglądać zawartość tej sekcji.'}
      onCancel={() => navigate('/')}
      buttonLabel={'Strona główna'}
    />
  )
}

export default ErrorRoleModal

import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

import { publicBaseApiURL } from 'api'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Spinner } from 'components/Atoms/Spinner'
import { ComboboxField } from 'components/ComboboxField/ComboboxField'
import { Button } from 'components/shadcn/ui/button'
import { Card } from 'components/shadcn/ui/card'
import { queryKeys } from 'constants/queryKeys'
import { getRoles, getRolesAsync } from 'features/Role/roleSlice'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList, getSectionsListGlobal } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { IOption } from 'types/form'
import { IContext, ISingleUserData } from 'types/userlist'
import { endpoint } from 'constants/endpoints'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { getBranchesList } from 'fetchers/branchFetchers'

interface IProps {
  handleVisibleEditField: () => void
  userData: ISingleUserData
  mutate: (id: string) => void
}

interface IRoles {
  id: string | number
  role: IOption
  unit: IOption
  displayLabel?: string
}

const defaultValueSelector: IOption = {
  label: 'Wybierz',
  value: '',
}

// Roles that don't require a structure unit
const noStructureRoles = ['ROLE_SUPER_ADMIN', 'ROLE_IOD', 'ROLE_SYSTEM_INSPECTOR']

/**
 * Component for displaying the list of assigned roles
 */
const AssignedRolesList: React.FC<{
  roles: IRoles[]
  onRoleDel: (role: IOption, unit: IOption, id: string | number) => void
  isDeleting: boolean
}> = ({ roles, onRoleDel, isDeleting }) => {
  if (roles.length === 0) {
    return <p className="text-gray-500">Brak przypisanych ról</p>
  }

  return (
    <div className="space-y-2">
      {roles.map((role) => (
        <div key={role.id} className="flex items-center justify-between rounded-lg bg-gray-50 p-3">
          <div>
            <p className="font-medium">{role.role.label}</p>
            {role.unit.value && <p className="text-sm text-gray-600">{role.unit.label}</p>}
          </div>
          <Button
            variant="ghost"
            size="lg"
            onClick={() => onRoleDel(role.role, role.unit, role.id)}
            disabled={isDeleting}
            className="text-red-600 hover:text-red-700"
          >
            {isDeleting ? <Spinner /> : 'Usuń'}
          </Button>
        </div>
      ))}
    </div>
  )
}

/**
 * Component for editing user roles
 */
export const EditUser: React.FC<IProps> = ({ userData, handleVisibleEditField, mutate }) => {
  const [roles, setRoles] = useState<IRoles[]>([])
  const [selectedRole, setSelectedRole] = useState<IOption>(defaultValueSelector)
  const [selectedParentUnit, setSelectedParentUnit] = useState<IOption>(defaultValueSelector)
  const [selectedBranchUnit, setSelectedBranchUnit] = useState<IOption>(defaultValueSelector)
  const [selectedUnit, setSelectedUnit] = useState<IOption>(defaultValueSelector)
  const [resetKey, setResetKey] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const { userInfo } = useAppSelector((state: any) => state.authReducer)

  const r = useSelector(getRoles)
  const dispatch = useAppDispatch()
  const { userToken, isPOG, isPOK, isZOK, isPOD, isZOD } = useAuth()

  // Load roles on component mount
  useEffect(() => {
    dispatch(getRolesAsync())
  }, [dispatch])

  // Reset all selections when role changes
  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
    setSelectedParentUnit(defaultValueSelector)
    setSelectedBranchUnit(defaultValueSelector)
  }, [selectedRole])

  // Reset branch and unit when parent unit changes
  useEffect(() => {
    setSelectedBranchUnit(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
  }, [selectedParentUnit])

  // Reset unit when branch changes
  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
  }, [selectedBranchUnit])

  // Determine the type of unit required for the selected role
  const selectedRoleUnit = useMemo(() => {
    if (!selectedRole.value || !r) return null

    // If role doesn't require a structure unit, return null
    if (noStructureRoles.includes(selectedRole.value as string)) return null

    // Special case for section administrator
    if (selectedRole.value === 'ROLE_SECTION_ADMIN') {
      return 'section'
    }

    for (const [key, value] of Object.entries(r)) {
      const role = value.find((i: { role: string; name: string }) => i.role === selectedRole.value)
      if (role) return key
    }

    return null
  }, [selectedRole, r])

  // Determine if the selected role requires a parent unit (region for branch roles)
  const requiresParentUnit = useMemo(() => {
    return selectedRoleUnit === 'branch' || selectedRoleUnit === 'section'
  }, [selectedRoleUnit])

  // Determine if the selected role requires a branch selection (for section roles)
  const requiresBranchSelection = useMemo(() => {
    return selectedRoleUnit === 'section'
  }, [selectedRoleUnit])

  // Check if unit selection is required for the selected role
  const requiresUnitSelection = useMemo(() => {
    return selectedRole.value && !noStructureRoles.includes(selectedRole.value as string)
  }, [selectedRole])

  const skipParentUnit = useMemo(() => {
    const userRole = userInfo?.context?.role
    return userRole === 'ROLE_BRANCH_PRESIDENT' || userRole === 'ROLE_BRANCH_BOARD'
  }, [userInfo?.context?.role])

  // Fetch regions list for parent unit selection if needed
  const { data: regionList, isLoading: isLoadingRegions } = useQuery({
    queryKey: [userToken, queryKeys.regionList, 'parent-unit'],
    queryFn: () => getRegionsList(1, 1000, []),
    enabled: requiresParentUnit && !skipParentUnit,
  })

  // Fetch branches for the selected region
  // Fixed code for the branch selection issue

  // The main issue is that for ROLE_BRANCH_PRESIDENT and ROLE_BRANCH_BOARD users
  // when they select ROLE_SECTION_PRESIDENT, we need to:
  // 1. Skip the parent unit (region) selection since they're branch-level
  // 2. Use their branch as the default branch in "Jednostka nadrzędna (Oddział)"
  // 3. Only show sections from their branch in "Jednostka struktury"

  // Modify the fetch branches query for section president role
  const { data: branchList, isLoading: isLoadingBranches } = useQuery({
    queryKey: [
      userToken,
      'branch-list',
      skipParentUnit ? userInfo?.context?.unit?.id : selectedParentUnit.value,
    ],
    queryFn: () => {
      // For branch presidents/board members selecting section president role,
      // we should use their branch ID
      if (
        (userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
          userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
        selectedRole.value === 'ROLE_SECTION_PRESIDENT'
      ) {
        // Use the branch president's branch
        return {
          items: [
            {
              id: userInfo?.context?.unit?.id,
              name: userInfo?.context?.unit?.name,
            },
          ],
        }
      }

      // Original logic for other cases
      const regionId = skipParentUnit ? userInfo?.context?.unit?.id : selectedParentUnit.value
      const url = `${endpoint.branch.list(1, 1000, '')}&filter[region][]=${regionId}`
      return publicBaseApiURL.get(url, {}).then((res) => res.data)
    },
    enabled:
      !!((skipParentUnit || selectedParentUnit.value) && requiresBranchSelection) ||
      ((userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
        userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
        selectedRole.value === 'ROLE_SECTION_PRESIDENT'),
  })

  useEffect(() => {
    if (
      (userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
        userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
      selectedRole.value === 'ROLE_SECTION_PRESIDENT' &&
      branchList?.items?.length > 0 &&
      !selectedBranchUnit.value
    ) {
      // Auto-select the branch president's branch
      setSelectedBranchUnit({
        label: branchList.items[0].name,
        value: branchList.items[0].id,
      })
    }
  }, [selectedRole, branchList, userInfo?.context?.role])

  const { data: sections, isLoading: isLoadingSections } = useQuery({
    queryKey: [userToken, 'sections-list', selectedBranchUnit.value],
    queryFn: () => {
      if (selectedRoleUnit === 'section' && selectedBranchUnit.value) {
        // Get sections from the selected branch
        const url = `${endpoint.section.listGlobal(1, 1000, '')}&filter[branch][]=${selectedBranchUnit.value}`
        return publicBaseApiURL.get(url, {}).then((res) => res.data)
      }
      return { items: [] }
    },
    enabled: !!(selectedRoleUnit === 'section' && selectedBranchUnit.value),
  })

  const isBranchPresidentOrBoard =
    userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
    userInfo?.context?.role === 'ROLE_BRANCH_BOARD'

  // Fetch branchesList for ROLE_BRANCH_PRESIDENT or ROLE_BRANCH_BOARD
  const { data: branchPresidentList, isLoading: isLoadingBranchPresidentList } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 1000, []),
    enabled: isBranchPresidentOrBoard,
  })
  // Fetch appropriate divisions based on selected role
  const { data: division, isLoading: isLoadingDivisions } = useQuery({
    queryKey: [
      userToken,
      selectedRoleUnit,
      selectedParentUnit.value,
      selectedBranchUnit.value,
      'division-list',
    ],
    queryFn: () => {
      if (selectedRoleUnit === 'branch' && selectedParentUnit.value) {
        // Build URL manually for branches under a region
        const url = `${endpoint.branch.list(
          1,
          1000,
          ''
        )}&filter[region][]=${selectedParentUnit.value}`
        return publicBaseApiURL.get(url, {}).then((res) => res.data)
      }

      if (selectedRoleUnit === 'section' && selectedBranchUnit.value) {
        // Build URL manually for sections under a branch
        const url = `${endpoint.section.listGlobal(
          1,
          1000,
          ''
        )}&filter[branch][]=${selectedBranchUnit.value}`
        return publicBaseApiURL.get(url, {}).then((res) => res.data)
      }

      // Use existing functions for other cases
      if (selectedRoleUnit === 'region') return getRegionsList(1, 1000, [])
      if (selectedRoleUnit === 'section') return getSectionsListGlobal(1, 1000, [])
      return { items: [] }
    },
    enabled:
      !!selectedRoleUnit &&
      ((!requiresParentUnit && !requiresBranchSelection) ||
        (requiresParentUnit && !!selectedParentUnit.value && !requiresBranchSelection) ||
        (requiresBranchSelection && !!selectedBranchUnit.value)),
  })

  // Transform roles into a list for dropdown
  const rolesList = useMemo(() => {
    if (!r || !Array.isArray(Object.values(r))) return []

    let filteredRoles = Object.values(r).flat()

    if (isPOK() || isZOK()) {
      // AOK/ZOK cannot assign SuperAdmin, IOD, and Inspector roles
      filteredRoles = filteredRoles.filter(
        (role: { role: string; name: string }) =>
          role.role !== 'ROLE_SUPER_ADMIN' &&
          role.role !== 'ROLE_IOD' &&
          role.role !== 'ROLE_SYSTEM_INSPECTOR'
      )
    } else if (isPOD() || isZOD()) {
      // AOD/ZOD cannot assign SuperAdmin, IOD, Inspector, AOK/ZOK roles
      filteredRoles = filteredRoles.filter(
        (role: { role: string; name: string }) =>
          role.role !== 'ROLE_SUPER_ADMIN' &&
          role.role !== 'ROLE_IOD' &&
          role.role !== 'ROLE_SYSTEM_INSPECTOR' &&
          role.role !== 'ROLE_POK' &&
          role.role !== 'ROLE_ZOK'
      )
    }

    // New condition: if user has ROLE_REGION_PRESIDENT or ROLE_BRANCH_PRESIDENT,
    // filter out ROLE_SECTION_PRESIDENT
    const userRole = userInfo?.context?.role
    if (userRole === 'ROLE_BRANCH_PRESIDENT' || userRole === 'ROLE_BRANCH_BOARD') {
      filteredRoles = filteredRoles.filter(
        (role: { role: string; name: string }) =>
          // role.role !== 'ROLE_SECTION_PRESIDENT' &&
          role.role !== 'ROLE_REGION_PRESIDENT' && role.role !== 'ROLE_REGION_BOARD'
      )
    }
    if (userRole === 'ROLE_REGION_PRESIDENT' || userRole === 'ROLE_REGION_BOARD') {
      filteredRoles = filteredRoles.filter(
        (role: { role: string; name: string }) => role.role !== 'ROLE_SECTION_PRESIDENT'
      )
    }

    return filteredRoles.map((i: { role: string; name: string }) => ({
      label: i.name,
      value: i.role,
    }))
  }, [r, isPOK(), isZOK(), isPOD(), isZOD(), userInfo?.context?.role])

  // Reset all selections
  const resetSelects = () => {
    setSelectedRole(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
    setSelectedParentUnit(defaultValueSelector)
    setSelectedBranchUnit(defaultValueSelector)
    setResetKey((prev) => prev + 1)
  }

  // Load user's existing roles when userData changes
  useEffect(() => {
    if (!userData?.contexts || !rolesList.length) {
      setRoles([])
      return
    }

    // Use a stable comparison to prevent unnecessary updates
    const contextIdsString = userData.contexts.map((c) => c?.role + '|' + c?.unit?.id).join(',')

    // Create a reference for checking if we actually need to update
    const updatedRoles = userData.contexts.map((e: IContext, index: number) => {
      const matchingRole = rolesList.find((role) => role.value === e?.role)
      return {
        id: index, // Or better, use e.id if available
        role: {
          label: matchingRole?.label || e?.role,
          value: e?.role,
        },
        unit: {
          label: e?.unit?.name || '',
          value: e?.unit?.id || '',
        },
      }
    })

    // Only set if different to avoid loops
    const rolesString = roles.map((r) => r.role.value + '|' + r.unit.value).join(',')
    const updatedRolesString = updatedRoles.map((r) => r.role.value + '|' + r.unit.value).join(',')

    if (rolesString !== updatedRolesString) {
      setRoles(updatedRoles)
    }
  }, [userData?.contexts, rolesList])

  // Add a new role to user - integrated from paste-2.txt
  const handleSubmitRole = async () => {
    if (!selectedRole.value) return
    if (requiresUnitSelection && !selectedUnit.value) return

    setIsSubmitting(true)
    setLoading(true)

    try {
      // Check if role already exists
      const hasExistingRole = roles.some(
        (role) =>
          role.role.value === selectedRole.value &&
          (noStructureRoles.includes(selectedRole.value as string) ||
            role.unit.value === selectedUnit.value)
      )

      if (hasExistingRole) {
        toast.error(
          noStructureRoles.includes(selectedRole.value as string)
            ? 'Użytkownik ma już przypisaną tę rolę'
            : 'Użytkownik ma już przypisaną tę rolę w tej jednostce'
        )
        return
      }

      const payload = {
        role: selectedRole.value,
        unit: requiresUnitSelection ? selectedUnit.value : null,
      }

      await publicBaseApiURL.put(`user/add-context/${userData?.id}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      const newRole: IRoles = {
        id: String(roles.length + 1),
        role: {
          label: selectedRole.label,
          value: selectedRole.value,
        },
        unit: requiresUnitSelection ? selectedUnit : { label: '', value: '' },
      }

      setRoles((prevRoles) => [...prevRoles, newRole])
      toast.success('Pomyślnie dodano rolę')

      resetSelects()
      mutate(userData.id)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          toast.error(
            noStructureRoles.includes(selectedRole.value as string)
              ? 'Użytkownik ma już przypisaną tę rolę'
              : 'Użytkownik ma już przypisaną tę rolę w tej jednostce'
          )
        } else {
          toast.error('Nie udało się dodać roli')
        }
      } else {
        toast.error('Wystąpił nieoczekiwany błąd')
      }
      console.error(error)
    } finally {
      setLoading(false)
      setIsSubmitting(false)
    }
  }

  // Remove role from user - integrated from paste-2.txt
  const handleRoleDel = async (role: IOption, unit: IOption, id: string | number) => {
    setLoading(true)
    setIsDeleting(true)

    try {
      await publicBaseApiURL.delete(`user/remove-context/${userData?.id}`, {
        data: {
          role: role.value,
          unit: noStructureRoles.includes(role.value as string) ? null : unit.value,
        },
        headers: {
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      toast.success('Pomyślnie usunięto rolę')
      setRoles((prevRoles) => prevRoles.filter((r) => String(r.id) !== String(id)))
      mutate(userData?.id)
    } catch (error) {
      toast.error('Nie udało się usunąć roli')
      console.error(error)
    } finally {
      setLoading(false)
      setIsDeleting(false)
    }
  }

  const isSubmitDisabled =
    !selectedRole.value ||
    (requiresUnitSelection && !selectedUnit.value) ||
    (requiresParentUnit && !selectedParentUnit.value && !skipParentUnit) ||
    (requiresBranchSelection && !selectedBranchUnit.value) ||
    isSubmitting

  // AOG cannot assign roles
  if (isPOG()) {
    return <ErrorRoleModal />
  }
  return (
    <>
      <div className="mt-4 space-y-6">
        {/* Section 1: Current Roles */}
        <Card className="p-6">
          <h2 className="font-eee mb-4 text-lg font-bold">Przypisane role</h2>
          <AssignedRolesList roles={roles} onRoleDel={handleRoleDel} isDeleting={isDeleting} />
        </Card>

        {/* Section 2: Assign New Role */}
        <Card className="p-6">
          <h2 className="font-eee mb-4 text-lg font-bold">Przypisz nową rolę</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <ComboboxField
                key={`role-${resetKey}`}
                label="Rola"
                options={rolesList}
                value={selectedRole.value}
                onChange={(newValue) => {
                  const matchingRole = rolesList.find((r) => r.value === newValue)
                  setSelectedRole(matchingRole || { label: '', value: '' })
                }}
                placeholder="Wybierz rolę..."
                emptyMessage="Nie znaleziono roli."
              />
            </div>

            {/* Parent Unit (Region) Selection */}
            {requiresParentUnit && !skipParentUnit && (
              <div>
                {isLoadingRegions ? (
                  <div className="flex h-full items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <ComboboxField
                    key={`parent-unit-${resetKey}`}
                    label="Jednostka nadrzędna (Region)"
                    options={(regionList?.items || []).map((i: any) => ({
                      label: i.name,
                      value: i.id,
                    }))}
                    value={selectedParentUnit.value}
                    onChange={(newValue) => {
                      const matchingParentUnit = (regionList?.items || []).find(
                        (i: any) => i.id === newValue
                      )
                      setSelectedParentUnit(
                        matchingParentUnit
                          ? { label: matchingParentUnit.name, value: matchingParentUnit.id }
                          : { label: '', value: '' }
                      )
                    }}
                    placeholder="Wybierz region..."
                    emptyMessage="Nie znaleziono regionu."
                  />
                )}
              </div>
            )}

            {/* Branch Unit Selection for Section Admin */}
            {requiresBranchSelection && (
              <div className={requiresParentUnit && !skipParentUnit ? 'sm:col-span-2' : ''}>
                {isLoadingBranches ? (
                  <div className="flex h-full items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <ComboboxField
                    key={`branch-unit-${resetKey}`}
                    label="Jednostka nadrzędna (Oddział)"
                    options={(branchList?.items || []).map((i: any) => ({
                      label: i.name,
                      value: i.id,
                    }))}
                    value={selectedBranchUnit.value}
                    onChange={(newValue) => {
                      const matchingBranch = (branchList?.items || []).find(
                        (i: any) => i.id === newValue
                      )
                      setSelectedBranchUnit(
                        matchingBranch
                          ? { label: matchingBranch.name, value: matchingBranch.id }
                          : { label: '', value: '' }
                      )
                    }}
                    placeholder="Wybierz oddział..."
                    emptyMessage="Nie znaleziono oddziału."
                    disabled={
                      // For branch presidents assigning section president, branch should be pre-selected
                      (userInfo?.context?.role === 'ROLE_BRANCH_PRESIDENT' ||
                        userInfo?.context?.role === 'ROLE_BRANCH_BOARD') &&
                      selectedRole.value === 'ROLE_SECTION_PRESIDENT'
                        ? true
                        : !skipParentUnit && !selectedParentUnit.value
                    }
                  />
                )}
              </div>
            )}

            {requiresUnitSelection && (
              <div
                className={requiresParentUnit && !requiresBranchSelection ? 'sm:col-span-2' : ''}
              >
                {isBranchPresidentOrBoard && selectedRole.value !== 'ROLE_SECTION_PRESIDENT' ? (
                  // If the user is a branch president or board member (but not assigning section president),
                  // show branchesList
                  isLoadingBranchPresidentList ? (
                    <div className="flex h-full items-center justify-center">
                      <Spinner />
                    </div>
                  ) : (
                    <ComboboxField
                      key={`unit-${resetKey}`}
                      label="Jednostka struktury"
                      options={(branchPresidentList?.items || []).map((i: any) => ({
                        label: i.name,
                        value: i.id,
                      }))}
                      value={selectedUnit.value}
                      onChange={(newValue) => {
                        const matchingUnit = (branchPresidentList?.items || []).find(
                          (i: any) => i.id === newValue
                        )
                        setSelectedUnit(
                          matchingUnit
                            ? { label: matchingUnit.name, value: matchingUnit.id }
                            : { label: '', value: '' }
                        )
                      }}
                      placeholder="Wybierz jednostkę..."
                      emptyMessage="Nie znaleziono jednostki."
                    />
                  )
                ) : selectedRoleUnit === 'section' ? (
                  isLoadingSections ? (
                    <div className="flex h-full items-center justify-center">
                      <Spinner />
                    </div>
                  ) : (
                    <ComboboxField
                      key={`unit-${resetKey}`}
                      label="Jednostka struktury"
                      options={(sections?.items || []).map((i: any) => ({
                        label: i.name,
                        value: i.id,
                      }))}
                      value={selectedUnit.value}
                      onChange={(newValue) => {
                        const matchingUnit = (sections?.items || []).find(
                          (i: any) => i.id === newValue
                        )
                        setSelectedUnit(
                          matchingUnit
                            ? { label: matchingUnit.name, value: matchingUnit.id }
                            : { label: '', value: '' }
                        )
                      }}
                      placeholder="Wybierz sekcję..."
                      emptyMessage="Nie znaleziono sekcji."
                      disabled={!selectedBranchUnit.value}
                    />
                  )
                ) : // Default behavior for other roles
                isLoadingDivisions ? (
                  <div className="flex h-full items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <ComboboxField
                    key={`unit-${resetKey}`}
                    label="Jednostka struktury"
                    options={(division?.items || []).map((i: any) => ({
                      label: i.name,
                      value: i.id,
                    }))}
                    value={selectedUnit.value}
                    onChange={(newValue) => {
                      const matchingUnit = (division?.items || []).find(
                        (i: any) => i.id === newValue
                      )
                      setSelectedUnit(
                        matchingUnit
                          ? { label: matchingUnit.name, value: matchingUnit.id }
                          : { label: '', value: '' }
                      )
                    }}
                    placeholder="Wybierz jednostkę..."
                    emptyMessage="Nie znaleziono jednostki."
                    disabled={
                      (requiresParentUnit && !selectedParentUnit.value && !skipParentUnit) ||
                      (requiresBranchSelection && !selectedBranchUnit.value)
                    }
                  />
                )}
              </div>
            )}
          </div>
          <div className="mt-4 flex justify-end space-x-3">
            <Button
              variant="outline"
              onClick={handleVisibleEditField}
              disabled={isSubmitting || isDeleting}
            >
              Powrót
            </Button>
            <Button onClick={handleSubmitRole} disabled={isSubmitDisabled}>
              {loading ? <Spinner /> : null}
              Przypisz rolę
            </Button>
          </div>
        </Card>
      </div>
    </>
  )
}
