import * as yup from 'yup'

export const orderCreateSchema = yup.object({
  name: yup
    .string()
    .required('Nazwa polecenia jest wymagana')
    .min(3, 'Nazwa polecenia musi mieć co najmniej 3 znaki'),
  content: yup
    .string()
    .required('Treść polecenia jest wymagana')
    .min(10, 'Treść polecenia musi mieć co najmniej 10 znaków'),
  units: yup
    .array()
    .of(yup.string())
    .min(1, 'Musisz wybrać co najmniej jedną sekcję')
    .required('Wybierz przynajmniej jedną sekcję'),
})
