import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { phoneRegExp } from 'constants/regexs'

// Common fields for both section types (club and group)
const commonFields = {
  // Region field - required only when creating a new section, not for updates
  region: yup.string().when('$isEditing', {
    is: false,
    then: (schema) => schema.required(errorMessage.requiredField),
    otherwise: (schema) => schema.optional(),
  }),

  // Branch - required for both create and update
  branch: yup.string().required(errorMessage.selectBranch),

  // Management person
  president: yup.string(),

  // Term start and end dates
  startDate: yup.string(),
  endDate: yup.string(),

  // Resolution number
  resolutionNumber: yup.string(),

  // Contact information with format validation
  phonePrimary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  phoneSecondary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  email: yup.string().email(errorMessage.invalidEmail).nullable(),

  // Document handling - accepts both string and object with id
  // Behavior depends on context (editing or creating)
  document: yup.mixed().when('$isEditing', {
    is: true,
    then: (schema) =>
      schema.required(errorMessage.requiredDocument).transform((value) => {
        // Convert object to string if needed
        if (value && typeof value === 'object' && value.id) {
          return value.id
        }
        return value
      }),
    otherwise: (schema) => schema.optional(),
  }),

  // Location description
  locationDescription: yup.string().optional(),
}

// Schema for clubs
// Contains all common fields plus club name
export const createSectionSchemaClub = yup.object({
  ...commonFields,
  // Club name - required, minimum 3 characters
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
})

// Schema for groups
// Contains all common fields plus group type
export const createSectionSchemaGroup = yup.object({
  ...commonFields,
  // Group type - required
  subtype: yup.string().required(errorMessage.selectSectionType),
})
