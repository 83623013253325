import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { houseNrRegExp } from 'constants/regexs'

// Base schema with common validations for both create and update
const baseInstitutionSchema = {
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  type: yup.string().required(errorMessage.requiredField),
  headmasterFirstName: yup
    .string()
    .test('headmasterFirstName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterLastName !== '' ? value !== '' : true
    }),
  headmasterLastName: yup
    .string()
    .test('headmasterLastName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterFirstName !== '' ? value !== '' : true
    }),
  headmasterPhone: yup.string(),
  headmasterEmail: yup.string().email(errorMessage.invalidEmail),
  postalCode: yup.string().required(errorMessage.requiredField),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => {
      return !/\d/.test(String(value))
    })
    .required(errorMessage.requiredField)
    .min(2, errorMessage.min2Chars),
  streetName: yup.string(),
  houseNr: yup.string().matches(houseNrRegExp, errorMessage.invalidHouseNr),
  apartmentNr: yup.string(),
  groups: yup.array().of(yup.string()),
}

// Schema with branch field required for users with appropriate role
export const institutionSchemaWithBranchRequired = yup.object({
  ...baseInstitutionSchema,
  branch: yup.string().required(errorMessage.requiredField),
})

// Schema without branch field - for users who can't select branch
export const institutionSchemaWithoutBranch = yup.object({
  ...baseInstitutionSchema,
  // branch is not included since it's not applicable
})

// Schema where branch is optional - use this when branch is shown but optional
export const institutionSchemaWithBranchOptional = yup.object({
  ...baseInstitutionSchema,
  branch: yup.string().nullable(), // Branch is optional
})
