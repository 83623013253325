import { ChangeEventHandler, useEffect, useState } from 'react'

import pl from 'date-fns/locale/pl'
import { xor } from 'lodash'
import DatePicker, { registerLocale } from 'react-datepicker'
import { MultiSelect } from 'react-multi-select-component'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import SearchMultiselect from 'components/Atoms/SearchMultiselect/SearchMultiselect'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { sex } from 'constants/sex'
import { useSearch } from 'hooks/useSearch'
import {
  IBasicSettingForm,
  IBooleans,
  IOption,
  IPageHandler,
  IQuery,
  IQueryHandler,
  IVoidObject,
} from 'types/form'
import { IQueryParamsState } from 'types/searchModule'
import { IUnitSelectedUnits } from 'types/structure'
registerLocale('pl', pl)

type Props = {
  isRegion?: boolean
  isBranch?: boolean
  searchParams: IQueryParamsState
  selectedUnits: IUnitSelectedUnits
  setSelectedUnits: React.Dispatch<React.SetStateAction<IUnitSelectedUnits>>
  setCustomAgeParam: (param: string) => void
  options: any
  loadings: IBooleans
  query: IQuery
  refetch: IVoidObject
  queryHandler: IQueryHandler
  searchMore: IVoidObject
  limitSetter: IPageHandler
  // React.Dispatch<React.SetStateAction<number>>
}

export const MemberSearchBarAll = ({
  selectedUnits,
  setSelectedUnits,
  setCustomAgeParam,
  searchParams,
  options,
  loadings,
  query,
  refetch,
  queryHandler,
  searchMore,
  limitSetter,
  isRegion,
  isBranch,
}: Props) => {
  const [showMore, setShowMore] = useState(false)
  const [globalSearchShow, setGlobalSearchShow] = useState(false)
  const [unitPosition, setUnitPosition] = useState([])
  const [positions, setPositions] = useState([])
  const [subjects, setSubjects] = useState([])
  const [tags, setTags] = useState([])
  const [ageFrom, setAgeFrom] = useState<string | undefined>()
  const [ageTo, setAgeTo] = useState<string | undefined>()
  const [baseSearchDate, setBaseSearchDate] = useState(new Date())
  const [birthDateFrom, setBirthDateFrom] = useState<Date | null>(null)
  const [birthDateTo, setBirthDateTo] = useState<Date | null>(null)
  const [birthParamType, setBirthParamType] = useState<string>('')

  const { param, setParam, searchQuery, removeParam } = useSearch(searchParams)

  const singleSetter: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.currentTarget
    setParam.single(name, value)
  }

  const signleSelector = (type: string, value: string | number) => {
    setParam.single(type, value)
  }

  const submitHandler = (e: any) => {
    e.preventDefault()
    const filter = []
    if (ageFrom) filter.push(`filter[age][gte]=${ageFrom}`)
    if (ageTo) filter.push(`filter[age][lte]=${ageTo}`)
    if (birthDateFrom) {
      filter.push(`filter[birthDate][gte]=${birthDateFrom.toISOString().substring(0, 10)}`)
    }
    if (birthDateTo) {
      filter.push(`filter[birthDate][lte]=${birthDateTo.toISOString().substring(0, 10)}`)
    }
    setCustomAgeParam(`&${filter.join('&')}`)
    searchQuery()
  }

  const handleInputCheck = (
    option: IBasicSettingForm,
    setter: React.Dispatch<React.SetStateAction<IUnitSelectedUnits>>,
    paramType: keyof IUnitSelectedUnits
  ) => {
    if (option?.id) {
      setParam.array(paramType, xor(param.array[paramType] as string[], [option?.id]))
      setter((prev: IUnitSelectedUnits) => ({
        ...prev,
        [paramType]: xor(prev[paramType], [option?.id]),
      }))
    }
  }

  const birthParam = [
    { value: '', label: '---' },
    { value: 'lt', label: '<' },
    { value: 'lte', label: '<=' },
    { value: 'gt', label: '>' },
    { value: 'gte', label: '>=' },
    { value: 'eq', label: '===' },
  ]

  useEffect(() => {
    const values = unitPosition.map((e: IOption) => String(e.value))
    setParam.array('unitPosition', values)
  }, [unitPosition])
  useEffect(() => {
    const values = positions.map((e: IOption) => String(e.value))
    setParam.array('position', values)
  }, [positions])
  useEffect(() => {
    const values = subjects.map((e: IOption) => String(e.value))
    setParam.array('subject', values)
  }, [subjects])
  useEffect(() => {
    setParam.array('institution', selectedUnits.institution)
  }, [selectedUnits.institution])

  useEffect(() => {
    const values = tags.map((e: IOption) => String(e.value))
    setParam.array('tag', values)
  }, [tags])

  const moreStyle = showMore
    ? { maxHeight: 'unset', overflow: 'unset' }
    : { maxHeight: '0px', overflow: 'hidden' }
  const branchSubLabel = !selectedUnits?.region?.length ? 'Zaznacz przynajmniej jeden okręg' : '---'
  const sectionSubLabel = !selectedUnits?.branch?.length
    ? 'Zaznacz przynajmniej jeden oddział'
    : '---'

  const showRegionSelector = !isRegion && !isBranch
  const showBranchSelector = !isBranch

  const optionsUnitPositions: any = options.unitPositions
  const optionsPositions: any = options.positions
  const optionSubjects: any = options.subjects
  const optionsTags: any = options.tags

  const updateAgeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.currentTarget.value ? e.currentTarget.value : undefined
    setAgeFrom(data)
  }
  const updateAgeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.currentTarget.value ? e.currentTarget.value : undefined
    setAgeTo(data)
  }
  const showSearchModule = () => {
    setGlobalSearchShow(true)
    removeParam('searchExtended')
  }
  const formIsVisible =
    (param.single.searchExtended && globalSearchShow) || !param.single.searchExtended
  const globalIsVisible = param.single.searchExtended && !globalSearchShow
  return (
    <Card label="Szukaj">
      {globalIsVisible && (
        <div>
          <p className="text-center">
            Wyniki wyszukiwania dla hasła: {param.single.searchExtended}
          </p>
          <hr className="my-4" />
          <div className="flex justify-center">
            <Button onClick={showSearchModule} label="Wyszukiwanie zzawansowane" />
          </div>
        </div>
      )}

      {formIsVisible && (
        <form onSubmit={submitHandler}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
            <TextField
              onChange={singleSetter}
              label="Legitymacja"
              placeholder="Numer legitymacji"
              name="card"
              type="text"
            />
            <TextField
              onChange={singleSetter}
              label="Imię/Nazwisko/E-mail"
              name="search"
              type="text"
            />
            <TextField onChange={singleSetter} label="PESEL" name="pesel" type="text" />
            <div>
              <p className="mb-1 block text-sm font-medium text-gray-700">Funkcja</p>
              <MultiSelect
                options={optionsUnitPositions}
                value={unitPosition}
                hasSelectAll={false}
                className="text-sm"
                onChange={setUnitPosition}
                overrideStrings={{
                  selectSomeItems: 'Zaznacz funkcje',
                  search: 'Wyszukaj',
                }}
                labelledBy="Wyszukaj"
              />
            </div>
          </div>
          <hr className="my-8" />

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
            {showRegionSelector && (
              <SearchMultiselect
                type="region"
                label={'Okręg'}
                options={options.regions}
                isLoading={loadings.regions}
                query={query.regions}
                searchMore={searchMore.regions}
                queryHandler={queryHandler.regions}
                changeLimit={limitSetter.regions}
                refetch={refetch.regions}
                inputCheck={selectedUnits.region}
                handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'region')}
              />
            )}
            {showBranchSelector && (
              <SearchMultiselect
                type="branch"
                label={'Oddział'}
                resultLabel={branchSubLabel}
                options={options.branches}
                isLoading={loadings.branches}
                query={query.branches}
                searchMore={searchMore.branches}
                queryHandler={queryHandler.branches}
                changeLimit={limitSetter.branches}
                refetch={refetch.branches}
                inputCheck={selectedUnits.branch}
                handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'branch')}
              />
            )}
            <SearchMultiselect
              type="section"
              label={'Ognisko / Sekcja'} // Changed label from "Sekcja" to "Ognisko / Sekcja"
              resultLabel={sectionSubLabel}
              options={options.sections}
              isLoading={loadings.sections}
              query={query.sections}
              searchMore={searchMore.sections}
              queryHandler={queryHandler.sections}
              changeLimit={limitSetter.sections}
              refetch={refetch.sections}
              inputCheck={selectedUnits.section}
              handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'section')}
            />
          </div>

          <div className="mt-4 flex justify-center">
            <span
              className="base-button inline-flex cursor-pointer items-center rounded border border-transparent bg-indigo-100 px-3 py-2 text-center text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-[1px] disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none"
              onClick={(e) => {
                setShowMore((prev) => !prev)
              }}
            >
              {showMore ? 'Mniej filtrów' : 'Więcej filtrów'}
            </span>
          </div>
          <hr className="mt-4" />
          <div className="" style={moreStyle}>
            <div className="grid grid-cols-1 gap-2 py-4 sm:grid-cols-3" style={moreStyle}>
              <div className="grid grid-cols-2 gap-2 align-top">
                <TextField
                  min={18}
                  onChange={updateAgeFrom}
                  label="Wiek"
                  placeholder="od"
                  type="number"
                />
                <TextField
                  min={18}
                  onChange={updateAgeTo}
                  addClass="self- mt-5"
                  placeholder="do"
                  type="number"
                />
                <div className="col-span-2 flex flex-col">
                  <label
                    className="mb-1 block text-sm font-medium text-gray-700"
                    htmlFor="baseSearchDate"
                  >
                    W dniu:
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    id="baseSearchDate"
                    locale="pl"
                    selected={baseSearchDate}
                    onChange={(date: Date) => setBaseSearchDate(date)}
                    className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  />
                </div>
              </div>
              <div className="col-span-1 grid grid-cols-2 gap-2">
                <div>
                  <label
                    className="mb-1 block text-sm font-medium text-gray-700"
                    htmlFor="birthDateFrom"
                  >
                    Data urodzenia od
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    id="birthDateFrom"
                    locale="pl"
                    selected={birthDateFrom}
                    onChange={(date: Date) => setBirthDateFrom(date)}
                    className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  />
                </div>
                <div>
                  <label
                    className="mb-1 block text-sm font-medium text-gray-700"
                    htmlFor="birthDateTo"
                  >
                    Data urodzenia do
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    id="birthDateTo"
                    locale="pl"
                    selected={birthDateTo}
                    onChange={(date: Date) => setBirthDateTo(date)}
                    className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  />
                </div>
              </div>
              <Select
                handleSelect={(e) => signleSelector('sex', e.value)}
                placeholder="Wybierz płeć"
                options={[{ value: '', label: '---' }, ...sex]}
                label="Płeć"
              />
            </div>
            <hr className="my-8" />
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
              <SearchMultiselect
                type="institution"
                label="Miejsce zatrudnienia"
                options={options.institutions}
                isLoading={loadings.institutions}
                query={query.institutions}
                searchMore={searchMore.institutions}
                queryHandler={queryHandler.institutions}
                changeLimit={limitSetter.institutions}
                refetch={refetch.institutions}
                inputCheck={selectedUnits.institution}
                handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'institution')}
              />
              <div>
                <p className="mb-1 block text-sm font-medium text-gray-700">Tagi</p>
                <MultiSelect
                  options={optionsTags}
                  value={tags}
                  hasSelectAll={false}
                  className="text-sm"
                  onChange={setTags}
                  overrideStrings={{ search: 'Wyszukaj', select: 'Wybierz' }}
                  labelledBy="Wyszukaj"
                />
              </div>

              <div>
                <p className="mb-1 block text-sm font-medium text-gray-700">Przedmiot</p>
                <MultiSelect
                  options={optionSubjects}
                  value={subjects}
                  hasSelectAll={false}
                  className="text-sm"
                  onChange={setSubjects}
                  overrideStrings={{ search: 'Wyszukaj' }}
                  labelledBy="Wyszukaj"
                />
              </div>

              <div>
                <p className="mb-1 block text-sm font-medium text-gray-700">Stanowisko</p>
                <MultiSelect
                  options={optionsPositions}
                  value={positions}
                  hasSelectAll={false}
                  className="text-sm"
                  onChange={setPositions}
                  overrideStrings={{ search: 'Wyszukaj' }}
                  labelledBy="Wyszukaj"
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <Button label="Szukaj" />
          </div>
        </form>
      )}
    </Card>
  )
}

export default MemberSearchBarAll
