import React from 'react'

import { BuildingOffice2Icon, MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { EnvelopeClosedIcon, HomeIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/shadcn/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { IListElement } from 'types/form'
import { IRegion } from 'types/region'
import { useAppSelector } from 'app/hooks'
interface IProps {
  data: IRegion
  showModalHandler: (id: IListElement) => void
  allowArchive: boolean
}
export const BranchBaseInfo: React.FC<IProps> = ({ data, allowArchive, showModalHandler }) => {
  const navigate = useNavigate()

  const { userInfo } = useAppSelector((state: any) => state.authReducer)

  console.log({ userInfo })
  // { "id": "018f05c4-5794-7a06-af09-fc9f8218a14f", "email": "mikolaj.dutkiewicz@nxtmgroup.com", "firstName": null, "lastName": null, "context": { "role": "ROLE_BRANCH_BOARD", "unit": { "id": "018f7b9d-0199-7666-ae3e-bedae3149ab4", "name": "Burżuazyjny" } } }

  return (
    <>
      <div className="mx-auto">
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle className="flex items-center gap-2">
                <BuildingOffice2Icon className="h-6 w-6" />
                {data.name}
              </CardTitle>
              <span className="text-sm leading-6">{data.type && 'Oddział'}</span>
            </div>
          </CardHeader>
          <CardContent className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="space-y-2">
              <h3 className="font-eee text-lg font-bold">Informacje kontaktowe</h3>
              <div className="space-y-2">
                {data.contact.email && (
                  <div className="flex items-center gap-2">
                    <EnvelopeClosedIcon className="h-4 w-4 text-gray-500" />
                    <span>{data.contact.email}</span>
                  </div>
                )}
                {data.contact.phone && (
                  <div className="flex items-center gap-2">
                    <PhoneIcon className="h-4 w-4 text-gray-500" />
                    <span>{data.contact.phone}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <h3 className="font-eee text-lg font-bold">Adres</h3>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <MapPinIcon className="h-4 w-4 text-gray-500" />
                  <span>
                    {data.address.streetName} {data.address.houseNr}
                    {data.address.apartmentNr && `/${data.address.apartmentNr}`}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <HomeIcon className="h-4 w-4 text-gray-500" />
                  <span>
                    {data.address.postalCode} {data.address.city}
                  </span>
                </div>
              </div>
            </div>

            <div className="space-y-2 lg:col-span-2">
              <h3 className="font-eee text-lg font-bold">Informacje dodatkowe</h3>
              <p>{data.locationDescription}</p>
              <p className="text-sm text-gray-500">Jednostka nadrzędna: {data.parent.name}</p>
            </div>

            <div className="flex gap-2 lg:col-span-2 lg:mt-6">
              <Button
                disabled={userInfo?.context?.role === 'ROLE_BRANCH_BOARD' ? true : false}
                onClick={() => navigate('update')}
              >
                Edytuj
              </Button>
              <Button
                onClick={() => showModalHandler(data)}
                disabled={!allowArchive}
                variant="secondary"
              >
                Archiwizuj
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* <Card
        label="Dane ogólne"
        actionsButton={[
          {
            label: 'Edytuj',
            handleClick: () => navigate('update'),
          },
          {
            label: 'Archiwizuj',
            handleClick: () => showModalHandler(data),
            disabled: allowArchive ? false : true,
          },
        ]}
      >
        <div className="mb-5 grid grid-cols-4 gap-5">
          <LabelTypography label="Nazwa oddziału" text={data?.name} />
          <LabelTypography label="Prezes oddziału" text={data?.regionPresident} />
          <LabelTypography label="Telefon" text={data?.contact.phone} />
          {data?.contact.phoneSecondary && (
            <LabelTypography label="Telefon dodatkowy" text={data?.contact?.phoneSecondary} />
          )}
          <LabelTypography label="Email" text={data?.contact?.email} />
          {data?.contact.emailSecondary && (
            <LabelTypography label="Telefon dodatkowy" text={data?.contact?.emailSecondary} />
          )}
        </div>
        <div className="grid grid-cols-4 gap-5">
          <LabelTypography label="Adres" text={address} />
          <LabelTypography label="Lokalizacja" text={data?.locationDescription} />
          <LabelTypography label="Godziny otwarcia" text={data?.workHours} />
        </div>
      </Card> */}
    </>
  )
}
