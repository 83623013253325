import { useNavigate } from 'react-router-dom'

import { HoverIcon } from 'components/Atoms/HoverIcon'
import { IListElement } from 'types/form'
import { ISectionClub } from 'types/section'
import { useAppSelector } from 'app/hooks'

type Props = {
  data: ISectionClub
  archive?: boolean
  showModalHandler: (data: IListElement) => void
}

export const BranchActionCell = ({ data, archive, showModalHandler }: Props) => {
  const navigate = useNavigate()

  const { userInfo } = useAppSelector((state: any) => state.authReducer) 
  return (
    <div className="flex">
      <HoverIcon iconName="EyeIcon" title="Zobacz" onClick={() => navigate(`/branch/${data.id}`)} />
      {!archive && (
        <>
          {userInfo?.context?.role !== 'ROLE_BRANCH_BOARD' && (
            <HoverIcon
              iconName="PencilIcon"
              title="Edytuj"
              onClick={() => navigate(`/branch/${data.id}/update`)}
            />
          )}
          {userInfo?.context?.role !== 'ROLE_BRANCH_BOARD' && (
            <HoverIcon
              iconName="ArchiveBoxIcon"
              title="Archiwizuj"
              onClick={() => showModalHandler(data)}
            />
          )}
          {userInfo?.context?.role !== 'ROLE_BRANCH_BOARD' && (
            <HoverIcon
              iconName="ArrowDownOnSquareStackIcon"
              title="Połącz"
              onClick={() => navigate(`/branch/${data.id}/merge`)}
            />
          )}
        </>
      )}
    </div>
  )
}

export default BranchActionCell
