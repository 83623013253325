import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'
import { CreateMemberForm } from 'components/Members/CreateMemberForm'
import { successToast } from 'tools/ToastHelpers'

export const UserCreate = () => {
  const navigate = useNavigate()

  const handleChange = () => {
    navigate('/user')
    successToast('Użytkownik został zapisany.')
  }

  const onSubmit = (data: any) => {
    console.info({ data })
  }

  return (
    <div>
      <Typography size='xl' weight='medium'>
        Baza członków / Dodaj
      </Typography>
      <Card label='Dodawanie nowego członka'>
        <CreateMemberForm onSubmit={onSubmit} />
      </Card>
    </div>
  )
}
