import React from 'react'

import DatePicker from 'react-datepicker'

export interface IProps {
  label?: string
  date?: Date
  handleDate: (date: Date) => void
  disabled?: boolean
  required?: boolean
}

export const Calendar: React.FC<IProps> = ({ label, date, handleDate, disabled, required }) => {
  const uniqueID =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  return (
    <>
      <label htmlFor={uniqueID} className="mb-1 block text-sm font-medium text-gray-700">
        {label}
        {required && <span className="ml-1 text-red-500">*</span>}
      </label>
      <DatePicker
        id={uniqueID}
        locale={'pl'}
        disabled={disabled}
        selected={date}
        dateFormat="yyyy-MM-dd"
        onChange={(date: Date) => handleDate(date)}
        className="mb-1 block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
      />
    </>
  )
}
