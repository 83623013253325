import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Table } from 'components/Atoms/Table'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getOrderFile, getOrdersIssuedDetails } from 'fetchers/ordersFetchers'
import { useRetryHandler } from 'hooks/useRetryHandler'

const OrderIssuedDetails = () => {
  const { id } = useParams()
  const cardId = id as string
  const navigate = useNavigate()
  const [pickedFileID, setPickedFileID] = useState<string>('')
  const [fileURL, setFileURL] = useState<string>('')
  const [downloadFilename, setDownloadFilename] = useState<string>('')

  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const {
    data: issuedDetails,
    isLoading: isIssuedDetailsLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.ordersIssuedDetails, cardId],
    queryFn: () => getOrdersIssuedDetails(cardId),
    retry: useRetryHandler({
      resourceName: 'OrderIssuedDetails issuedDetails',
      maxRetries: 1,
    }),
  })

  const { data: issuedDetailFile, isLoading: isIssuedDetailFileLoading } = useQuery({
    queryKey: [userToken, queryKeys.orderFileByID, pickedFileID],
    queryFn: () => getOrderFile(pickedFileID),
    enabled: !!pickedFileID,
    retry: useRetryHandler({
      resourceName: 'OrderIssuedDetails issuedDetailFile',
      maxRetries: 1,
    }),
  })

  useEffect(() => {
    if (issuedDetailFile && pickedFileID) {
      const fileURL = URL.createObjectURL(
        new Blob([issuedDetailFile.data], {
          type: issuedDetailFile.type || 'application/octet-stream',
        })
      )
      setFileURL(fileURL)

      // Get filename from issuedDetails.files
      if (issuedDetails?.files) {
        // Find the file object that matches the selected ID
        const fileInfo = issuedDetails.files.find((file: any) => file.id === pickedFileID)

        if (fileInfo) {
          // Set the downloadFilename to the filename from the file object
          setDownloadFilename(fileInfo.filename)
        } else {
          // If no match is found, use filename from API response or default
          setDownloadFilename(issuedDetailFile.filename || 'downloaded_file')
        }
      } else {
        // If issuedDetails.files is missing, use filename from API response
        setDownloadFilename(issuedDetailFile.filename || 'downloaded_file')
      }
    }
  }, [issuedDetailFile, pickedFileID, issuedDetails])

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = fileURL
    link.download = downloadFilename // Use the extracted filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const hasAnyoneRead = issuedDetails?.acks?.some((ack: any) => ack.readAt)
  const listOfAcks = issuedDetails?.acks?.map((ack: any) => {
    return {
      name: ack.readBy?.displayName || ack.readBy?.firstName || ack.readBy?.email || '-',
      unit: ack.unit.name || '-',
      readAt: ack.readAt ? ack.readAt.replace('T', ' ').replace('+00:00', '') : '-',
      ackAt: ack.ackAt ? ack.ackAt.replace('T', ' ').replace('+00:00', '') : '-',
    }
  })
  const columns = React.useMemo(
    () => [
      {
        Header: 'Kto przeczytał',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.name
        },
      },

      {
        Header: 'Nazwa jednostki',
        accessor: 'unit',
        Cell: ({ row }: any) => {
          return row.original?.unit
        },
      },
      {
        Header: 'Data odczytu',
        accessor: 'readAt',
        Cell: ({ row }: any) => {
          return row.original?.readAt
        },
      },
      {
        Header: 'Data potwierdzenia',
        accessor: 'ackAt',
        Cell: ({ row }: any) => {
          return row.original?.ackAt
        },
      },
    ],
    []
  )

  const translatedStatus = (status: boolean) => {
    return status ? 'Tak' : 'Nie'
  }

  if (isIssuedDetailsLoading) {
    return (
      <Card label="Szczegóły wysłanego polecenia">
        <dl className="grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6">
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className="col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3">
                <div className="h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200"></div>
              </dt>
              <dd className="pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none">
                <div className="h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200"></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <Card label="Szczegóły wysłanego polecenia">
        <div className="grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4">
          <LabelTypography label="Nazwa polecenia" text={issuedDetails?.name || '-'} />
          <LabelTypography
            label="Data utworzenia polecenia"
            text={
              issuedDetails?.createdAt
                ? issuedDetails.createdAt.replace('T', ' ').replace('+00:00', '')
                : '-'
            }
          />
          <div className="md:col-span-2">
            <LabelTypography
              label="Pliki"
              text={
                issuedDetails?.files?.length > 0
                  ? issuedDetails.files.map((file: any) => file.filename).join(', ')
                  : 'Brak plików'
              }
            />
          </div>
          <LabelTypography
            label="Liczba osób, które otrzymały"
            text={issuedDetails?.acks?.filter((ack: any) => ack.readAt || ack.ackAt).length || '-'}
          />
          <LabelTypography
            label="Liczba osób, które przeczytały"
            text={issuedDetails?.acks?.filter((ack: any) => ack.readAt).length || '-'}
          />
          <LabelTypography
            label="Liczba osób, które potwierdziły"
            text={issuedDetails?.acks?.filter((ack: any) => ack.ackAt).length || '-'}
          />
          <LabelTypography
            label="Archiwalne"
            text={translatedStatus(issuedDetails?.archived) || '-'}
          />
          <div className="md:col-span-4">
            <LabelTypography label="Treść polecenia" text={issuedDetails?.content || '-'} />
          </div>
        </div>
      </Card>
      {issuedDetails?.files?.length > 0 && (
        <Card label="Pobierz plik">
          <p className="mb-1 block text-sm font-medium text-gray-700">
            Wybierz plik, który chcesz pobrać
          </p>
          <select
            className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            name=""
            id=""
            onChange={(e) => setPickedFileID(e.target.value)}
          >
            <option value="">Wybierz</option>
            {issuedDetails?.files?.map((file: any) => (
              <option key={file.id} value={file.id}>
                {file.filename}
              </option>
            ))}
          </select>
          {isIssuedDetailFileLoading && (
            <a className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white opacity-30 shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
              Wczytuję plik <span className="loader"></span>
            </a>
          )}
          {!isIssuedDetailFileLoading && pickedFileID && fileURL && (
            <a
              href="#"
              onClick={handleDownload}
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Pobierz plik {downloadFilename}
            </a>
          )}
        </Card>
      )}

      {hasAnyoneRead && (
        <Card label="Szczegóły dotyczące odczytu">
          <Table maxColumnWidth="300px" columns={columns} data={listOfAcks || []} />
        </Card>
      )}
      <div className="my-4 flex justify-center">
        <Button label="Powrót" variant="secondary" onClick={() => navigate(path.orders.issued)} />
      </div>
    </>
  )
}

export default OrderIssuedDetails
