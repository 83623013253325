import React from 'react'

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import InstitutionBaseInfo from 'components/Institution/InstitutionBaseInfo'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { useArchiveInstitution } from 'fetchers/hooks/useArchiveInstitution'
import { getInstitution } from 'fetchers/institutionFetchers'
import { getInstitutionMembersList } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { getLoadingHandler } from 'tools/queryHelpers'

export const InstitutionDetails = () => {
  const { id, userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const { perPage, page, setLimit, changePage } = useSearch({ arrayParams: ['institution'] })
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const navigate = useNavigate()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
        Cell: ({ row }: any) => row.original?.card?.number,
      },
      {
        Header: 'Pełnione funkcje',
        accessor: 'functions',
        Cell: ({ row }: any) => {
          return (
            <ul>
              {row.original?.unitPositions?.map((e: any, i: number) => (
                <li key={`${row?.original?.id}-fn-${i}-${e.unit.id}`}>
                  <p>
                    {e?.position?.name} ({e?.unit?.name})
                    {i !== row.original?.unitPositions?.length - 1 && ', '}
                  </p>
                </li>
              ))}
            </ul>
          )
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className="flex">
              <HoverIcon
                iconName="EyeIcon"
                title="Zobacz"
                onClick={() => navigate(`/member/${row.original.id}`)}
              />
            </div>
            // <div onClick={() => alert('Edytuj')} className='w-5 cursor-pointer'>
            //   <EyeIcon color='#000' />
            // </div>
          )
        },
      },
    ],
    []
  )

  const {
    data: institution,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.institution, id],
    queryFn: () => getInstitution(id),
    retry: useRetryHandler({
      resourceName: 'InstitutionDetails institution',
      maxRetries: 1,
    }),
  })
  // MEMBERS LOADER
  const { data: members, isLoading: subLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberInstitutionList, page, perPage, id],
    queryFn: () => {
      if (!id) return { items: [], pagination: {} }
      return getInstitutionMembersList(id, page, perPage)
    },
    retry: useRetryHandler({
      resourceName: 'InstitutionDetails members',
      maxRetries: 1,
    }),
  })
  // Setup members list
  const membersList = members?.items || []
  // Setup member loading state
  const membersLoading = subLoading
  // Setup last page
  const lastPage = useLastPage(members?.pagination, membersLoading)
  // END MEMBERS LOADER

  const { showModalHandler, ArchiveModal } = useArchiveInstitution({
    refetch,
    navigatePath: path.institution.list,
  })

  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <div>
      {/* <Typography size='xl' weight='medium'>
        <Link to='/institution'>Placówki</Link> / {institution?.name}
      </Typography> */}
      <InstitutionBaseInfo data={institution} showModal={showModalHandler} />
      <Card label="Użytkownicy przypisani do placówki">
        {membersList.length >= 1 ? (
          <div className="mt-5">
            <Table maxColumnWidth="300px" columns={columns} data={membersList} />
            {membersLoading && (
              <div className="flex justify-center p-4 align-middle">
                <Spinner />
              </div>
            )}
            {lastPage > 1 && (
              <div className="flex justify-end">
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              </div>
            )}
          </div>
        ) : (
          <p className="text-sm leading-6">Brak użytkowników</p>
        )}
      </Card>
      <div className="mt-5 flex justify-center">
        <Button
          label="Powrót"
          icon={<ArrowLeftCircleIcon className="w-5" />}
          onClick={() => navigate(-1)}
        />
      </div>
      <Card label="Historia zmian">
        Work in progress...
        {/* <History /> */}
      </Card>
      <ArchiveModal />
    </div>
  )
}
