import React, { useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Modal } from 'components/Atoms/Modal'
import { Spinner } from 'components/Atoms/Spinner'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportFileErrors, exportPasswordErrors } from 'error-data/export'
import {
  getExportFile,
  getExportPassword,
  getExportSingle,
  getMemberExportDictionary,
} from 'fetchers/exportFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { getTagsList } from 'fetchers/tagsFetchers'
import { getUsersList } from 'fetchers/userFetchers'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler' 
import { successToast } from 'tools/ToastHelpers'

const MemberExportDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const cardId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const [isSending, setIsSending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [passwordId, setPasswordId] = useState('')

  const {
    data: exportDetail,
    isLoading: isExportDetailLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberExportSingle, cardId],
    queryFn: () => getExportSingle(cardId),
    retry: useRetryHandler({
      resourceName: 'MemberExportDetails exportDetail',
      maxRetries: 1,
    }),
  })

  const fileId = exportDetail?.resultsFile
  const filePasswordId = exportDetail?.resultsFile?.id

  const closeModal = () => {
    setIsOpen(false)
    setPasswordId('')
  }

  const { data: userData, isLoading: userDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.usersList],
    queryFn: () => getUsersList(1, 100),
    retry: useRetryHandler({
      resourceName: 'MemberExportDetails userData',
      maxRetries: 1,
    }),
  })

  const { data: sectionData, isLoading: isLoadingSectionId } = useQuery({
    queryKey: [userToken, queryKeys.section],
    queryFn: () => getSectionsList(0, 1000),
    retry: useRetryHandler({
      resourceName: 'MemberExportDetails sectionData',
      maxRetries: 1,
    }),
  })

  const { data: tagsData, isLoading: tagsLoading } = useQuery({
    queryKey: [userToken, queryKeys.tagsList],
    queryFn: () => getTagsList(),
    retry: useRetryHandler({
      resourceName: 'MemberExportDetails tagsData',
      maxRetries: 1,
    }),
  })

  const { data: exportDictionary, isLoading: exportDictionaryLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberExportDictionary],
    queryFn: () => getMemberExportDictionary(),
    retry: useRetryHandler({
      resourceName: 'MemberExportDetails exportDictionary',
      maxRetries: 1,
    }),
  })

  const { data: positionData, isLoading: positionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.positionsList],
    queryFn: () => getPositionsList(0, 1000),
    retry: useRetryHandler({
      resourceName: 'MemberExportDetails positionData',
      maxRetries: 1,
    }),
  })

  const translateTagNames = (tags: string[]) => {
    const tagMap = tagsData?.items.reduce((acc: any, item: any) => {
      acc[item.id] = item.name
      return acc
    }, {})
    return tags.map((tag) => (tagMap && tagMap[tag]) || tag).join(', ')
  }

  const translateSectionNames = (units: string[]) => {
    const sectionMap = sectionData?.items.reduce((acc: any, item: any) => {
      acc[item.id] = item.name
      return acc
    }, {})
    return units.map((unit) => (sectionMap && sectionMap[unit]) || unit).join(', ')
  }

  const translateFieldNames = (fields: string[]) => {
    const fieldMap = exportDictionary?.items.reduce((acc: any, item: any) => {
      acc[item.id] = item.name
      return acc
    }, {})
    return fields.map((field) => (fieldMap && fieldMap[field]) || field).join(', ')
  }

  const translatePositionNames = (positions: string[]) => {
    const positionMap = positionData?.items.reduce((acc: any, item: any) => {
      acc[item.id] = item.name
      return acc
    }, {})
    return positions
      .map((position) => (positionMap && positionMap[position]) || position)
      .join(', ')
  }

  const getUserEmailById = (userId: string) => {
    if (!userData) return 'Loading...'
    const user = userData?.items?.find((user: any) => user.id === userId)
    return user?.email || user?.displayName || user?.firstName + ' ' + user?.lastName || 'Nieznany'
  }

  const translateSex = (sex: string) => {
    if (sex === 'female') return 'Kobieta'
    if (sex === 'male') return 'Mężczyzna'
    return 'Nie podano'
  }

  const translateStatus = (status: string) => {
    if (status === 'archive') return 'Archiwalny'
    if (status === 'awaiting') return 'Oczekujący'
    if (status === 'pending') return 'Oczekujący'
    if (status === 'processing') return 'Przetwarzanie'
    if (status === 'completed') return 'Zakończony'
    if (status === 'failed') return 'Niepowodzenie'
    return 'Nieznany'
  }

  const password = useMutation({
    mutationFn: (id: any) => getExportPassword(id),
    onSuccess: (req) => {
      setPasswordId(req.password)
      setIsSending(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, exportPasswordErrors.get.message)
    },
  })

  const file = useMutation({
    mutationFn: ({ id, filename }: { id: string; filename: string }) => getExportFile(id, filename),
    onSuccess: (res) => {
      setIsSending(false) 
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, exportFileErrors.get.message)
    },
  })

  const passwordHandler = (id: string) => {
    try {
      setIsOpen(true)
      setIsSending(true)
      password.mutate(id)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, exportPasswordErrors.get.message)
    }
  }

  const fileHandler = ({ id, filename }: { id: string; filename: string }) => {
    try {
      setIsSending(true)
      file.mutate({ id, filename })
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, exportFileErrors.get.message)
    }
  }

  if (isExportDetailLoading) {
    return (
      <Card label='Szczegóły eksportowanego pliku'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <Card
        label='Dane ogólne eksportowanego pliku'
        actionsButton={[
          {
            label: 'Pobierz plik',
            disabled: !fileId,
            handleClick: () => {
              fileHandler(fileId)
              successToast('Rozpoczęto pobieranie pliku.')
            },
          },
          {
            disabled: !filePasswordId,
            label: 'Pobierz hasło',
            handleClick: () => {
              passwordHandler(filePasswordId)
            },
          },
        ]}
      >
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
          <LabelTypography label='ID' text={exportDetail?.id || '-'} />
          <LabelTypography label='Nazwa' text={exportDetail?.name || '-'} />
          <LabelTypography
            label='Data utworzenia'
            text={exportDetail?.createdAt.slice(0, 10) || '-'}
          />
          <LabelTypography label='Data wygaśnięcia' text={exportDetail?.expiresAt || '-'} />
          <LabelTypography label='Status' text={translateStatus(exportDetail?.status) || '-'} />
          <LabelTypography
            label='Utworzone przez'
            text={getUserEmailById(exportDetail?.createdBy) || '-'}
          />
          <LabelTypography label='Liczba wyników' text={exportDetail?.resultsCount || '-'} />
          <LabelTypography label='ID pliku' text={exportDetail?.resultsFile?.id || '-'} />
          <LabelTypography label='Nazwa pliku' text={exportDetail?.resultsFile?.filename || '-'} />
        </div>
      </Card>
      <Card label='Pola'>
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
          <div className='sm:col-span-2'>
            <LabelTypography label='Pola' text={translateFieldNames(exportDetail?.fields) || '-'} />
          </div>
          <div className='sm:col-span-2'>
            <LabelTypography
              label='Jednostki'
              text={translateSectionNames(exportDetail?.units) || '-'}
            />
          </div>
        </div>
      </Card>
      <Card label='Filtry'>
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
          <span className='sm:col-span-2'>
            <LabelTypography
              label='Pozycje'
              text={translatePositionNames(exportDetail?.positions) || '-'}
            />
          </span>
          <span className='sm:col-span-2'>
            <LabelTypography label='Tagi' text={translateTagNames(exportDetail?.tags) || '-'} />
          </span>

          <LabelTypography
            label='Płeć'
            text={translateSex(exportDetail?.sex) || 'Nie podano' || '-'}
          />
          <LabelTypography label='Wiek większy niż' text={exportDetail?.ageGt?.toString() || '-'} />
          <LabelTypography
            label='Wiek mniejszy niż'
            text={exportDetail?.ageLt?.toString() || '-'}
          />
          <LabelTypography
            label='Data obliczenia wieku'
            text={exportDetail?.ageDate?.slice(0, 10) || '-'}
          />
        </div>
      </Card>
      <div className='my-4 flex justify-center'>
        <Button variant='secondary' label='Powrót' onClick={() => navigate(path.export.list)} />
      </div>
      <Modal isOpen={isOpen} handleClose={closeModal}>
        <Card
          actionsButton={[
            {
              label: 'Skopiuj hasło',
              handleClick: () => {
                navigator.clipboard.writeText(passwordId)
                successToast('Hasło skopiowane do schowka')
              },
            },
          ]}
          label={password.isPending ? 'Pobieranie hasła' : 'Hasło do pliku'}
        >
          <div className=''>
            <div className='pb-6 pt-4'>
              {password.isPending ? <Spinner /> : <p className='text-center'>{passwordId}</p>}
            </div>
            <div className='flex justify-center'>
              <Button variant='secondary' label={'Zamknij'} onClick={closeModal} />
            </div>
          </div>
        </Card>
      </Modal>
    </>
  )
}

export default MemberExportDetails
