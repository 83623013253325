import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { TextareaField } from 'components/Atoms/TextareaField/TextareaField'
import { TextField } from 'components/Atoms/TextField'
import LegalSearch from 'components/Legal/LegalSearch'
import { createSectionSchemaClub } from 'schemas/sectionSchema'
import { errorRequires } from 'tools/ToastHelpers'
import { IOption } from 'types/form'
import { ILegalHook } from 'types/legal'
import { ICreateSectionClub } from 'types/section'

interface IProps {
  isSending?: boolean
  onSubmit: (data: any) => void
  navigate: (path: string) => void
  data: ICreateSectionClub
  institutionsList: IOption[]
  regionsList?: IOption[]
  branchesList?: IOption[]
  branchesLoading?: boolean
  createRoot?: boolean
  legalData: ILegalHook
  onRegionChange?: (regionId: string) => void
  legalDefaultOption?: IOption
}

export const SectionFormClub: React.FC<IProps> = ({
  onSubmit,
  navigate,
  data,
  institutionsList,
  regionsList = [],
  branchesList = [],
  branchesLoading = false,
  createRoot = false, // Default to false (editing mode)
  isSending,
  legalData,
  onRegionChange = () => {
    // Default function does nothing
  },
  legalDefaultOption,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<ICreateSectionClub>({
    resolver: yupResolver(createSectionSchemaClub),
    defaultValues: data,
    context: { isEditing: !createRoot }, // This is crucial - sets the context for the validation schema
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'institutions',
  })

  const onValidationFailure = () => {
    console.log('Validation errors:', errors)
    errorRequires(errors)
  }

  // Watch for region field change
  const region = watch('region')

  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      {createRoot && (
        <div className="mb-5 grid grid-cols-4 gap-5">
          {/* Region Selection - Only shown in create mode */}
          <div>
            <p className="mb-1 block text-sm font-medium text-gray-700">Wybierz region</p>
            <Controller
              name="region"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  handleSelect={({ value }) => {
                    onChange(value)
                    onRegionChange(String(value))
                  }}
                  options={regionsList}
                  defaultOption={value ? regionsList.find((r) => r.value === value) : undefined}
                  withEmpty
                />
              )}
            />
            <p className="mt-2 text-sm text-red-600">{errors?.region?.message}</p>
          </div>

          {/* Branch Selection - Only shown in create mode */}
          <div>
            <div className="mb-1 flex items-center justify-between">
              <p className="block text-sm font-medium text-gray-700">Wybierz oddział</p>
              {branchesLoading && region && (
                <span className="inline-block text-xs text-blue-500">Ładowanie...</span>
              )}
            </div>
            <Controller
              name="branch"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  handleSelect={({ value }) => {
                    onChange(value)
                    legalData && legalData.setUnit(String(value))
                  }}
                  options={branchesList}
                  defaultOption={value ? branchesList.find((b) => b.value === value) : undefined}
                  withEmpty
                  disabled={!region || branchesLoading}
                  className={
                    !region || branchesLoading ? 'cursor-not-allowed bg-gray-50 opacity-50' : ''
                  }
                  placeholder={
                    !region
                      ? 'Wybierz najpierw region'
                      : branchesLoading
                        ? 'Ładowanie oddziałów...'
                        : 'Wybierz oddział'
                  }
                />
              )}
            />
            <p className="mt-2 text-sm text-red-600">{errors?.branch?.message}</p>
          </div>
        </div>
      )}

      {/* Hidden branch field for update mode */}
      {!createRoot && <input type="hidden" {...register('branch')} />}

      <div className="mb-5 grid grid-cols-4 gap-5">
        <TextField
          id="name"
          {...register('name')}
          error={errors.name?.message}
          label="Nazwa ogniska"
          placeholder="Nazwa"
        />
        <TextField
          {...register('phonePrimary')}
          error={errors.phonePrimary?.message}
          label="Numer telefonu"
          placeholder="Podaj numer"
        />
        <TextField
          {...register('phoneSecondary')}
          error={errors.phoneSecondary?.message}
          label="Numer telefonu dodatkowy"
          placeholder="Podaj numer"
        />
        <TextField
          {...register('email')}
          error={errors.email?.message}
          label="E-mail kontaktowy"
          placeholder="Podaj e-mail"
        />
      </div>

      <div className="mb-5">
        <label htmlFor="" className="mb-1 block text-sm font-medium text-gray-700">
          Powiązane placówki
        </label>
        <ul>
          {(fields || []).map((item, index) => (
            <li className="mb-5 grid grid-cols-4 gap-5" key={item.id}>
              <Controller
                name={`institutions.${index}`}
                control={control}
                render={({ field: { onChange } }) => {
                  const defaultOption = item?.name
                    ? {
                        label: String(item?.name),
                        value: data?.institutions?.[index]?.id
                          ? data?.institutions?.[index]?.id
                          : '',
                      }
                    : undefined
                  return (
                    <Select
                      defaultOption={defaultOption}
                      handleSelect={({ value }) => onChange(value)}
                      options={institutionsList}
                    />
                  )
                }}
              />
              <Button
                className="ml-5 w-10"
                icon={<MinusCircleIcon className="w-5" />}
                onClick={() => remove(index)}
              />
            </li>
          ))}
        </ul>
        <div className="flex">
          <Button
            onClick={(e) => {
              e.preventDefault()
              append({ id: '' })
            }}
            label="Dodaj kolejny"
            icon={<PlusCircleIcon className="mr-2 w-5" />}
          />
        </div>
      </div>
      <div className="mb-5 grid w-3/4 grid-cols-1 gap-5">
        <div>
          <label
            htmlFor="locationDescription"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Lokalizacja
          </label>
          <textarea
            id="locationDescription"
            {...register('locationDescription')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={4}
          />
          {errors.locationDescription && (
            <p className="mt-2 text-sm text-red-600">{errors.locationDescription.message}</p>
          )}
        </div>
      </div>
      <div>
        <LegalSearch
          control={control}
          legalData={legalData}
          register={register}
          defaultOption={legalDefaultOption}
        />
        <p className="mt-2 text-sm text-red-600">
          {errors?.document ? errors.document.message || 'Wskazanie uchwały jest wymagane' : ''}
        </p>
      </div>
      <div className="mt-10 flex">
        <div className="mr-5">
          <Button variant="secondary" label="Anuluj" onClick={() => navigate('/section')} />
        </div>
        <Button disabled={isSending} label="Zatwierdź" />
      </div>
    </form>
  )
}
