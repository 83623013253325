import React from 'react'

import { BuildingOffice2Icon, MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { EnvelopeClosedIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/shadcn/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { path } from 'constants/path'
import { IRegion } from 'types/region'
import { useAppSelector } from 'app/hooks'

interface IProps {
  data: IRegion
}

const iconProps = {
  className: 'min-w-4 h-4 w-4 text-gray-500',
}

export const RegionBaseInfo: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate()

  const { userInfo } = useAppSelector((state: any) => state.authReducer)

  const isRoleBranchBoard = userInfo?.context?.role
  const actionButtons =
    isRoleBranchBoard === 'ROLE_BRANCH_BOARD'
      ? []
      : [
          {
            label: 'Edytuj',
            variant: 'default',
            handleClick: () => navigate(`${path.region.update}${data?.id}`),
          },
        ]

  return (
    <>
      <div className="w-full">
        <Card className="w-full">
          <CardHeader className="flex justify-between">
            <div className="flex items-center justify-between">
              <CardTitle className="flex items-center gap-2">
                <BuildingOffice2Icon className="h-6 w-6" />
                {data.name}
              </CardTitle>
              <span className="text-sm leading-6">{data.type && 'Okręg'}</span>
            </div>
          </CardHeader>

          <CardContent className="grid grid-cols-1 gap-4 px-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <h3 className="font-eee pb-2 text-lg font-bold">Informacje kontaktowe</h3>
                <div className="space-y-2">
                  {data.contact.email && (
                    <div className="flex items-center gap-2">
                      <EnvelopeClosedIcon {...iconProps} />
                      <span>{data.contact.email}</span>
                    </div>
                  )}
                  {data.contact.emailSecondary && (
                    <div className="flex items-center gap-2">
                      <EnvelopeClosedIcon {...iconProps} />
                      <span>{data.contact.emailSecondary}</span>
                    </div>
                  )}
                  {data.contact.phone && (
                    <div className="flex items-center gap-2">
                      <PhoneIcon {...iconProps} />
                      <span>{data.contact.phone}</span>
                    </div>
                  )}
                  {data.contact.phoneSecondary && (
                    <div className="flex items-center gap-2">
                      <PhoneIcon {...iconProps} />
                      <span>{data.contact.phoneSecondary}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {data.address && (
                <div className="space-y-2">
                  <h3 className="font-eee pb-2 text-lg font-bold">Adres</h3>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <MapPinIcon {...iconProps} />
                      <span>
                        {data.address.streetName} {data.address.houseNr}
                        {data.address.apartmentNr && `/${data.address.apartmentNr}`}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <BuildingOffice2Icon {...iconProps} />
                      <span>
                        {data.address.postalCode} {data.address.city}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {data.locationDescription && (
              <div className="space-y-4 xl:col-span-2">
                <div className="space-y-2">
                  <h3 className="font-eee pb-2 text-lg font-bold">Informacje dodatkowe</h3>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <span>{data.locationDescription}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-span-full">
              {actionButtons.length > 0 && (
                <div className="flex gap-2 pt-4">
                  {actionButtons.map((button) => (
                    <Button key={button.label} onClick={button.handleClick}>
                      {button.label}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}
