import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import CardReqDetailsInfo from 'components/CardReq/CardReqDetailsInfo'
import CardReqDetailsLifecycle from 'components/CardReq/CardReqDetailsLifecycle'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getBranch } from 'fetchers/branchFetchers'
import {
  getCardReqEventDict,
  getCardReqStatusDict,
  getCardReqTypeDict,
  getCardRequest,
} from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { getLoadingHandler } from 'tools/queryHelpers'
import { IDict } from 'types/dictionary'

const CardReqDetails = () => {
  const [showDetails, setShowDetails] = useState(false)
  const { id, userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isValid = isAllAdmin() || isAllOD() || isAllOK()
  const navigate = useNavigate()

  // REQUEST DATA
  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.cartReq, id, showDetails],
    queryFn: () => getCardRequest(id, showDetails),
    retry: useRetryHandler({
      resourceName: 'CardReqDetails data',
      maxRetries: 1,
    }),
  })
  const branchId = data?.branch?.id
  const lifecycle = data?.lifecycle || []
  // END REQUEST DATA

  // REQUEST BRANCH (FOR REGION FETCHING)
  const { data: branch, isLoading: branchLoading } = useQuery({
    queryKey: [userToken, queryKeys.branch, branchId],
    queryFn: () => (branchId ? getBranch(branchId) : null),
    retry: useRetryHandler({
      resourceName: 'CardReqDetails branch',
      maxRetries: 1,
    }),
  })
  // END REQUEST BRANCH

  // REQEUST TYPE
  const { data: reqType, isLoading: typeLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqTypeDict],
    queryFn: () => getCardReqTypeDict(),
    retry: useRetryHandler({
      resourceName: 'CardReqDetails reqType',
      maxRetries: 1,
    }),
  })
  const typeList = reqType?.items || []
  const currentType = typeList.find((type: IDict) => type.id === data?.type)
  // END REQEUST TYPE

  // REQUEST STATUS
  const { data: reqStatus, isLoading: statusLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqStatusDict],
    queryFn: () => getCardReqStatusDict(),
    retry: useRetryHandler({
      resourceName: 'CardReqDetails reqStatus',
      maxRetries: 1,
    }),
  })
  const statusList = reqStatus?.items || []
  const currentStatus = statusList.find((status: IDict) => status.id === data?.status)
  // END REQUEST STATUS

  // REQUEST EVENT
  const { data: reqEvent, isLoading: eventLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqEventDict],
    queryFn: () => getCardReqEventDict(),
    retry: useRetryHandler({
      resourceName: 'CardReqDetails reqEvent',
      maxRetries: 1,
    }),
  })
  const eventList = reqEvent?.items || []
  // END REQUEST EVENT
  const toggleDetails = () => setShowDetails((p) => !p)
  const loadingData = isLoading || branchLoading || typeLoading || statusLoading || eventLoading
  const loadingHandler = getLoadingHandler(error, loadingData, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <section>
      <Typography size='xl' weight='medium'>
        Wniosek o legitymację / {data?.member?.firstName} {data?.member?.lastName}
      </Typography>
      <CardReqDetailsInfo
        showDetails={showDetails}
        toggleDetails={toggleDetails}
        data={data}
        type={currentType?.name}
        status={currentStatus?.name}
        branch={branch?.parent?.name}
      />

      <div className='my-4 flex justify-center'>
        <Button label='Powrót' onClick={() => navigate(path.card.request.list)} />
      </div>
      <CardReqDetailsLifecycle
        data={lifecycle}
        typeList={typeList}
        statusList={statusList}
        eventList={eventList}
      />
    </section>
  )
}

export default CardReqDetails
