import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { setupFilters } from 'tools/queryHelpers'
import { IMergeBranchFetcher } from 'types/branch'
import { IFilters } from 'types/form'

export const getBranchesListPrefiltredGlobal = async (
  page: number,
  count: number,
  filters: string,
) => {
  const res = await publicBaseApiURL.get(endpoint.branch.listGlobal(page, count, filters), {})
  return res?.data
}

export const getBranchesListPrefiltred = async (page: number, count: number, filters: string) => {
  const res = await publicBaseApiURL.get(endpoint.branch.list(page, count, filters), {})
  return res?.data
}

export const getBranchesList = async (page: number, count: number, filters?: IFilters[]) => {
  const filterData = setupFilters(filters)
  const res = await publicBaseApiURL.get(endpoint.branch.list(page, count, filterData), {})
  return res?.data
}

export const getBranchesListGlobal = async (page: number, count: number, filters?: IFilters[]) => {
  const filterData = setupFilters(filters)
  const res = await publicBaseApiURL.get(endpoint.branch.listGlobal(page, count, filterData), {})
  return res?.data
}

export const postArchiveBranch = async (id?: string) => {
  const res = await publicBaseApiURL.post(endpoint.branch.archive(id), {})
  return res?.data
}

export const getBranch = async (id?: string) => {
  const res = await publicBaseApiURL.get(endpoint.branch.byId(id), {})
  return res?.data
}

export const createBranch = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.branch.create, data)
  return res?.data
}

export const updateBranch = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.branch.update(id), data)
  return res?.data
}

export const mergeBranch = async (data: IMergeBranchFetcher) => {
  const { toRemove, target, ...formData } = data
  const res = await publicBaseApiURL.post(endpoint.branch.merge(target, toRemove), formData)
  return res?.data
}

export const getBranchesDecorationsList = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.branch.listDecorations(id), {})
  return res?.data
}
