import { useMemo, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { ConfirmDeleteNotifyModal } from 'components/Atoms/ConfirmDeleteNotifyModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { notificationErrors } from 'error-data/notification'
import { getNotificationsList, removeNotification } from 'fetchers/notifyFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler, setupSearchMultiFilters } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IOption } from 'types/form'

export const MemberNotifications = () => {
  const [isSending, setIsSending] = useState(false)
  const [requestId, setRequestId] = useState('')
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const { perPage, param, page, setLimit, changePage } = useSearch({
    arrayParams: ['issuer'],
    simpleParams: ['onlyUnhandled'],
  })

  const { userToken, unitContext, isAllAdmin, isAllOD, isPOG } = useAuth()
  const sectionAllow = isPOG()

  const contextId = unitContext?.id && sectionAllow ? [unitContext.id] : []
  const paramIssuers = param?.array?.issuer || []
  const isValid = isAllAdmin() || isAllOD() || sectionAllow
  const navigate = useNavigate()

  // HANDLERS
  const closeDeleteModalHandler = () => {
    setRequestId('')
    setIsOpenDeleteModal(false)
  }
  const openDeleteModalHandler = (id: string) => {
    setRequestId(id)
    setIsOpenDeleteModal(true)
  }

  // GET NOTIFICATIONS LIST
  const sectionFilters = setupSearchMultiFilters([{ issuer: [...contextId, ...paramIssuers] }])
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.notificationsList, page, perPage, sectionFilters],
    queryFn: () => getNotificationsList(page, perPage, sectionFilters),
    retry: useRetryHandler({
      resourceName: 'MemberNotifications data',
      maxRetries: 1,
    }),
  })
  const notificationsList = data?.items
    ? data?.items.map((item: any) => {
        return {
          id: item.id,
          name: item.member?.firstName,
          lastName: item.member?.lastName,
          unit: item.issuer?.name,
          type: item.type === 'change' ? 'Zmiana danych' : 'Usunięcie danych',
        }
      })
    : []
  const lastPage = useLastPage(data?.pagination, isLoading)
  const onLimitHandler = (e: IOption) => setLimit(e.value)

  // DELETE HANDLER
  const mutate = useMutation({
    mutationFn: async (id: string) => removeNotification(id),
    onSuccess: () => {
      successToast('Usunięto zgłoszenie.')
      setIsSending(false)
      closeDeleteModalHandler()
      refetch()
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, notificationErrors.remove, 'Nie udało się usunąć zgłoszenia')
    },
  })
  const handleDelete = async () => {
    try {
      setIsSending(true)
      mutate.mutate(requestId)
    } catch (error) {
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(null, null, 'Nie udało się usunąć zgłoszenia')
    }
  }

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Jednostka',
        accessor: 'unit',
      },
      {
        Header: 'Rodzaj',
        accessor: 'type',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.original?.archived}
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(row.original.id)}
              />
              {sectionAllow && (
                <HoverIcon
                  iconName='TrashIcon'
                  title='Usuń'
                  onClick={() => openDeleteModalHandler(row.original.id)}
                />
              )}
            </div>
          )
        },
      },
    ],
    [],
  )

  // LOADING HANDLER
  const loadingHandler = getLoadingHandler(error, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='medium'>
        Zgłoszenia zmian osobowych
      </Typography>
      <Card label='Lista aktualnych zgłoszeń'>
        <>
          {notificationsList.length === 0 && <Typography>Brak zgłoszeń</Typography>}
          {notificationsList.length > 0 && (
            <>
              <div className='w-20'>
                <Select
                  handleSelect={onLimitHandler}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20', value: 20 },
                  ]}
                />
              </div>
              <Table data={notificationsList} columns={columns} />
              {lastPage > 1 && (
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              )}
            </>
          )}
        </>
      </Card>
      <ConfirmDeleteNotifyModal
        isSending={isSending}
        isOpen={isOpenDeleteModal}
        handleDelete={handleDelete}
        handleClose={closeDeleteModalHandler}
      />
    </div>
  )
}

export default MemberNotifications
