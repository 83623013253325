import { useMemo, useState, useEffect, ChangeEvent, SetStateAction } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import CardReqUpdate from 'components/CardReq/CardReqUpdate'
import CardReqUpdateType from 'components/CardReq/CardReqUpdateType'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import {
  getCardReqStatusDict,
  getCardReqTypeDict,
  getCardsRequestList,
} from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { getLoadingHandler } from 'tools/queryHelpers'
import { ICardReqSingle, ICardReqTypeDict } from 'types/cardRequest'
import { IDict } from 'types/dictionary'
import { IOption } from 'types/form'

// Define branch interface
interface IBranch {
  id: string | number
  name: string
}

export const CardsListRequest = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [typeModalIsOpen, setTypeModalIsOpen] = useState(false)
  const [typeModalId, setTypeModalId] = useState<ICardReqSingle | null>(null)
  const [selectedIds, setSelectedIds] = useState<ICardReqSingle[]>([])
  const [showCompleteData, setShowCompleteData] = useState(false)
  const [showArchiveData, setShowArchiveData] = useState(false)
  const [showReadyItems, setShowReadyItems] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedAction, setSelectedAction] = useState('')
  const [typeOfRequest, setTypeOfRequest] = useState<ICardReqTypeDict | string>('')

  // State for advanced filtering
  const [activeFilters, setActiveFilters] = useState({
    date: false,
    branch: false,
    status: false,
    type: false,
  })

  // Filter values
  const [filterValues, setFilterValues] = useState({
    dateFrom: '',
    dateTo: '',
    branch: '',
    status: '',
    type: '',
  })

  const { control } = useForm()
  const { isAllAdmin, isAllOD, isAllOK, userLoaded, memberId } = useAuth()
  const { filters, page, perPage, setLimit, changePage, setFilters } = useSearch({})
  const { userToken } = useAppSelector((state) => state.authReducer)
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()
  const navigate = useNavigate()

  const isAdmin = isAllAdmin()
  const isZODRole = isAllOD()
  const isZOKRole = isAllOK()

  // Function to build filter string based on active filters
  const buildFilterString = () => {
    let filterString = ''

    // Date filter
    if (activeFilters.date) {
      if (filterValues.dateFrom) {
        filterString += `&filter[createdAt][gte]=${filterValues.dateFrom}`
      }
      if (filterValues.dateTo) {
        filterString += `&filter[createdAt][lte]=${filterValues.dateTo}`
      }
    }

    // Branch filter
    if (activeFilters.branch && filterValues.branch) {
      filterString += `&filter[branch][]=${filterValues.branch}`
    }

    // Status filter
    if (activeFilters.status && filterValues.status) {
      filterString += `&filter[status]=${filterValues.status}`
    }

    // Type filter
    if (activeFilters.type && filterValues.type) {
      filterString += `&filter[type]=${filterValues.type}`
    }

    return filterString
  }

  // Apply all active filters
  const applyFilters = () => {
    const filterString = buildFilterString()
    setFilters(filterString)
  }

  // Reset all filters
  const resetAllFilters = () => {
    setActiveFilters({
      date: false,
      branch: false,
      status: false,
      type: false,
    })

    setFilterValues({
      dateFrom: '',
      dateTo: '',
      branch: '',
      status: '',
      type: '',
    })

    setFilters('')
  }

  // Toggle filter visibility
  const toggleFilter = (filterType: string) => {
    setActiveFilters({
      ...activeFilters,
      [filterType as keyof typeof activeFilters]:
        !activeFilters[filterType as keyof typeof activeFilters],
    })
  }

  // Update filter values
  const updateFilterValue = (filterType: string, value: string) => {
    setFilterValues({
      ...filterValues,
      [filterType]: value,
    })
  }

  // GET LEGITIMATIONS LIST
  let setupFilters = showCompleteData ? filters + '&unredacted=true' : filters

  // Add archive filter if enabled
  if (showArchiveData) {
    setupFilters += '&filter[archived]=true'
  } else {
    setupFilters += '&filter[archived]=false'
  }

  // Add ready items filter if enabled
  if (showReadyItems) {
    setupFilters += '&filter[status]=printed,in_branch'
  }

  const {
    data: cards,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardsListRequest, page, perPage, memberId, setupFilters],
    queryFn: () => getCardsRequestList(page, perPage, setupFilters),
    retry: useRetryHandler({
      resourceName: 'CardListRequest cards',
      maxRetries: 1,
    }),
  })

  // REQUEST STATUS
  const { data: reqStatus, isLoading: statusLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqStatusDict],
    queryFn: () => getCardReqStatusDict(),
    retry: useRetryHandler({
      resourceName: 'CardListRequest reqStatus',
      maxRetries: 1,
    }),
  })

  const statusList = reqStatus?.items || []
  const cardsList = cards?.items
    ? cards.items.map((card: { status: any }) => ({
        ...card,
        status: statusList.find((status: { id: any }) => status.id === card.status)?.name,
      }))
    : []

  // REQUEST TYPE
  const { data: reqType, isLoading: typeLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqTypeDict],
    queryFn: () => getCardReqTypeDict(),
    retry: useRetryHandler({
      resourceName: 'CardListRequest reqType',
      maxRetries: 1,
    }),
  })
  const typeList = reqType?.items || []

  // HANDLE CHECKBOX CHANGE
  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    row: { row: { original: ICardReqSingle } }
  ) => {
    const isChecked = e.currentTarget.checked
    setSelectedIds((prevList) => {
      if (isChecked) {
        return [...prevList, row.row.original]
      } else {
        return prevList.filter((item) => item.id !== row.row.original.id)
      }
    })
  }
  const allSelected = selectedIds.length === cards?.items?.length
  const handleDeselectAll = () => setSelectedIds([])
  const handleToggleSelection = () => setSelectedIds(allSelected ? [] : cardsList)

  // PAGE HANDLERS
  const lastPage = useLastPage(cards?.pagination, isLoading)
  const onLimitHandler = (e: { value: string | number }) => setLimit(e.value)
  const handleChangePage = (page: string | number) => {
    handleDeselectAll()
    changePage(page)
  }

  // MODAL HANDLERS
  const openModal = () => setIsOpen(true)
  const closeModal = () => {
    setIsOpen(false)
    setTypeOfRequest('')
  }
  const openTypeModal = (selected: ICardReqSingle) => {
    setTypeModalIsOpen(true)
    setTypeModalId(selected)
  }
  const closeTypeModal = () => {
    setTypeModalIsOpen(false)
  }

  // GET MASS ACTION OPTIONS BASED ON USER ROLE
  const getMassActionOptions = () => {
    const baseOptions = []

    // Options available for all roles
    baseOptions.push({ label: 'Odrzuć', value: 'rejected' })

    // Only administrator and ZOK can approve requests
    if (isAdmin || isZOKRole) {
      baseOptions.push({ label: 'Zatwierdź wniosek', value: 'accepted' })
    }

    // Only administrator and ZOK can print
    if (isAdmin || isZOKRole) {
      baseOptions.push({ label: 'Drukuj', value: 'printed' })
    }

    // Only administrator and ZOK can transfer
    if (isAdmin || isZOKRole) {
      baseOptions.push({ label: 'Przekaż', value: 'in_branch' })
    }

    // ZOD can confirm issuance
    if (isZODRole || isAdmin || isZOKRole) {
      baseOptions.push({ label: 'Potwierdź wydanie', value: 'issued' })
    }

    return baseOptions
  }

  // Memoize branch options
  const branchOptions = useMemo(() => {
    if (!cards?.items) {
      return []
    }

    const uniqueBranches = [
      ...new Map(
        cards.items.map((item: { branch: IBranch }) => [item.branch.id, item.branch])
      ).values(),
    ]

    return uniqueBranches.map((branch) => {
      const b = branch as IBranch
      return {
        label: b.name,
        value: b.id,
      }
    })
  }, [cards?.items])

  // Status options
  const statusOptions = [
    { label: 'Nowy', value: 'new' },
    { label: 'Zaakceptowany', value: 'accepted' },
    { label: 'Odrzucony', value: 'rejected' },
    { label: 'Wydrukowany', value: 'printed' },
    { label: 'W oddziale', value: 'in_branch' },
    { label: 'Wydany', value: 'issued' },
    { label: 'Unieważniony', value: 'invalidated' },
  ]

  // Type options
  const typeOptions = [
    { label: 'Nowy', value: 'new' },
    { label: 'Duplikat', value: 'duplicate' },
    { label: 'Ponowne wydanie', value: 'reissue' },
    { label: 'Unieważnienie', value: 'invalidation' },
  ]

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        Header: (
          <Checkbox
            label=""
            id="selectAll"
            checked={selectedIds.length === cardsList?.length}
            onChange={handleToggleSelection}
          />
        ),
        accessor: 'id',
        Cell: (row: { row: { original: ICardReqSingle } }) => (
          <Controller
            name="cardId"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={selectedIds.some((item) => item.id === row.row.original.id)}
                onChange={(e) => {
                  onChange(e.target.checked)
                  handleCheckboxChange(e, row)
                }}
              />
            )}
          />
        ),
      },
      { Header: 'Imię', accessor: 'member.firstName' },
      { Header: 'Nazwisko', accessor: 'member.lastName' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Typ wniosku',
        accessor: 'type',
        Cell: ({ row }: { row: any }) => {
          return typeList.find((type: { id: any }) => type.id === row.original.type)?.name
        },
      },
      { Header: 'Oddział', accessor: 'branch.name' },
      { Header: 'Data wniosku', accessor: 'createdAt' },
      { Header: 'Data aktualizacji', accessor: 'updatedAt' },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: { row: { original: ICardReqSingle } }) => {
          const declarationId = row.row.original.id
          return (
            <div className="flex">
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName="EyeIcon"
                title="Zobacz"
                onClick={() => navigate(`/card-request/${declarationId}`)}
              />
              {row.row.original.status === 'Nowy' && (
                <HoverIcon
                  disabled={row.row.original?.archived}
                  iconName="PencilIcon"
                  title="Zmień typ"
                  onClick={() => openTypeModal(row.row.original)}
                />
              )}
            </div>
          )
        },
      },
    ],
    [selectedIds, cards?.items, showCompleteData, navigate, typeList]
  )

  const loadingHandler = getLoadingHandler(error, !userLoaded, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  if (isOpen && typeOfRequest) {
    return (
      <CardReqUpdate
        type={typeOfRequest as ICardReqTypeDict}
        selectedIds={selectedIds}
        closeModal={closeModal}
        refetch={refetch}
        deselectAll={handleDeselectAll}
      />
    )
  }
  if (typeModalIsOpen && typeModalId) {
    return (
      <CardReqUpdateType
        selectedId={typeModalId}
        closeModal={closeTypeModal}
        refetch={refetch}
        deselectAll={handleDeselectAll}
      />
    )
  }

  return (
    <div>
      <Typography size="xl" weight="medium">
        Lista wniosków o legitymację
      </Typography>

      <div className="my-5 flex">
        <Button label="Utwórz wniosek" onClick={() => navigate(path.card.request.create)} />
      </div>

      <div className="bg-white p-5">
        <div className="mb-4 flex items-center justify-between">
          <Controller
            name="completeData"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                label="Pokazuj kompletne dane"
                id="Pokazuj kompletne dane"
                checked={showCompleteData}
                onChange={(e) => {
                  onChange(e.target.checked)
                  setShowCompleteData((prev) => !prev)
                  refetch()
                }}
              />
            )}
          />

          {!isAdmin && (
            <div className="flex items-center gap-4">
              <Button
                label="Pokaż gotowe wydania"
                variant={showReadyItems ? 'primary' : 'secondary'}
                onClick={() => {
                  setShowReadyItems((prev) => !prev)
                  refetch()
                }}
              />
              <Controller
                name="ArchiveData"
                control={control}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    label="Pokaż archiwalne"
                    id="Pokaż archiwalne"
                    checked={showArchiveData}
                    onChange={(e) => {
                      onChange(e.target.checked)
                      setShowArchiveData((prev) => !prev)
                      refetch()
                    }}
                  />
                )}
              />
            </div>
          )}
        </div>

        {/* Mass actions */}
        <div className="mb-4">
          <div className="flex items-center gap-4">
            <Select
              width="215px"
              handleSelect={(val) => setTypeOfRequest(`${val.value}`)}
              withEmpty
              selectLabel="Działania masowe"
              options={getMassActionOptions()}
            />

            <Button
              label="Zastosuj"
              variant="secondary"
              onClick={openModal}
              disabled={!typeOfRequest}
            />
          </div>
        </div>

        {/* Advanced filtering section */}
        <div className="mb-6 rounded-lg border border-gray-200 p-4">
          <div className="mb-4">
            <Typography size="base" weight="medium">
              Filtry zaawansowane
            </Typography>
          </div>

          <div className="flex flex-wrap gap-4">
            {/* Date filter toggle */}
            <div>
              <Button
                label={`Data ${activeFilters.date ? '✓' : ''}`}
                variant={activeFilters.date ? 'primary' : 'secondary'}
                onClick={() => toggleFilter('date')}
              />
            </div>

            {/* Branch filter toggle */}
            <div>
              <Button
                label={`Oddział ${activeFilters.branch ? '✓' : ''}`}
                variant={activeFilters.branch ? 'primary' : 'secondary'}
                onClick={() => toggleFilter('branch')}
              />
            </div>

            {/* Status filter toggle */}
            <div>
              <Button
                label={`Status ${activeFilters.status ? '✓' : ''}`}
                variant={activeFilters.status ? 'primary' : 'secondary'}
                onClick={() => toggleFilter('status')}
              />
            </div>

            {/* Type filter toggle */}
            <div>
              <Button
                label={`Typ wniosku ${activeFilters.type ? '✓' : ''}`}
                variant={activeFilters.type ? 'primary' : 'secondary'}
                onClick={() => toggleFilter('type')}
              />
            </div>
          </div>

          {/* Active filter controls */}
          <div
            className={`${
              activeFilters.branch ||
              activeFilters.date ||
              activeFilters.status ||
              activeFilters.type
                ? 'mt-4'
                : ''
            } space-y-4 flex flex-wrap gap-x-4 gap-y-2 `}
          >
            {/* Date filter controls */}
            {activeFilters.date && (
              <div className="flex flex-wrap items-end gap-4">
                <div>
                  <label className="block text-sm font-medium">Od daty</label>
                  <input
                    type="date"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={filterValues.dateFrom}
                    onChange={(e) => updateFilterValue('dateFrom', e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium">Do daty</label>
                  <input
                    type="date"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={filterValues.dateTo}
                    onChange={(e) => updateFilterValue('dateTo', e.target.value)}
                  />
                </div>
              </div>
            )}

            {/* Branch filter controls */}
            {activeFilters.branch && (
              <div className="flex flex-wrap items-end gap-4">
                <div className="w-64">
                  <Select
                    selectLabel="Wybierz oddział"
                    handleSelect={(val) => updateFilterValue('branch', String(val.value))}
                    options={branchOptions}
                    withEmpty
                  />
                </div>
              </div>
            )}

            {/* Status filter controls */}
            {activeFilters.status && (
              <div className="flex flex-wrap items-end gap-4">
                <div className="w-64">
                  <Select
                    selectLabel="Wybierz status"
                    handleSelect={(val) => updateFilterValue('status', String(val.value))}
                    options={statusOptions}
                    withEmpty
                  />
                </div>
              </div>
            )}

            {/* Type filter controls */}
            {activeFilters.type && (
              <div className="flex flex-wrap items-end gap-4">
                <div className="w-64">
                  <Select
                    selectLabel="Wybierz typ wniosku"
                    handleSelect={(val) => updateFilterValue('type', String(val.value))}
                    options={typeOptions}
                    withEmpty
                  />
                </div>
              </div>
            )}

            {/* Filter action buttons - only show if at least one filter is active */}
            {(activeFilters.date ||
              activeFilters.branch ||
              activeFilters.status ||
              activeFilters.type) && (
              <div className="flex items-end gap-4">
                <Button label="Zastosuj filtry" variant="primary" onClick={applyFilters} />
                <Button
                  label="Wyczyść wszystkie filtry"
                  variant="secondary"
                  onClick={resetAllFilters}
                />
              </div>
            )}
          </div>
        </div>

        {/* Table with data */}
        <Table columns={columns} data={cardsList} />
        {isLoading && (
          <div className="flex justify-center p-4 align-middle">
            <Spinner />
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-between gap-4">
          <div className="ml-auto flex items-center gap-4">
            {lastPage >= 2 && (
              <>
                <div className="flex items-center gap-4">
                  <p className=" text-sm font-medium">Wierszy na stronę</p>
                  <Select
                    handleSelect={onLimitHandler}
                    options={[
                      { label: '10', value: 10 },
                      { label: '20', value: 20 },
                    ]}
                  />
                </div>
                <Pagination
                  lastPage={lastPage}
                  currentPage={page}
                  handlePageChange={handleChangePage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
