import React, { useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, SubmitHandler, useForm, useFormContext } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { TextareaField } from 'components/Atoms/TextareaField/TextareaField'
import FormField from 'components/FormField'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportOrdersErorrs } from 'error-data/orders'
import { getBranchesList } from 'fetchers/branchFetchers'
import { addOrderFile, createOrder } from 'fetchers/ordersFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { orderCreateSchema } from 'schemas/orderCreateSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption } from 'types/form'
// Define allowed MIME types
const ALLOWED_MIME_TYPES = [
  'application/pdf',
  'application/x-pdf',
  'image/x-png',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

// 5MB in bytes
const MAX_FILE_SIZE = 5 * 1024 * 1024

interface IFormInputs {
  name: string
  content: string
  units: string[]
}

const OrderCreate = () => {
  const [fileIsAdding, setFileIsAdding] = useState(false)
  const [file, setFile] = useState<File>()
  const [fileIds, setFileIds] = useState<string[]>([])
  const [finalFiles, setFinalFiles] = useState<IOption[]>([])
  const [fileNames, setFileNames] = useState<Record<string, string>>({}) // Store original file names
  // Removed fileError state as we'll use errorToast instead
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { unitContext, userLoaded, isPOG, isAllOD, isAllOK, isAllAdmin } = useAuth()

  const isRegion = isAllOK()
  const isBranch = isAllOD()
  const isSection = isPOG()
  const isAdmin = isAllAdmin()

  const { data: regionData, isLoading: branchesIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(0, 1000),
    enabled: isAdmin,
    retry: useRetryHandler({
      resourceName: 'OrderCreate regionData',
      maxRetries: 1,
    }),
  })

  const { data: branchesData, isLoading: branchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(0, 1000),
    enabled: isRegion,
    retry: useRetryHandler({
      resourceName: 'OrderCreate branchesData',
      maxRetries: 1,
    }),
  })

  const { data: sectionData, isLoading: sectionLoading } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList],
    queryFn: () => getSectionsList(0, 1000),
    enabled: isBranch,
    retry: useRetryHandler({
      resourceName: 'OrderCreate sectionData',
      maxRetries: 1,
    }),
  })

  const filteredItems = React.useMemo(() => {
    if (isAdmin && regionData) {
      return regionData.items.map((item: { id: string; name: string }) => ({
        value: item.id,
        label: item.name,
      }))
    } else if (isRegion && branchesData) {
      return branchesData.items.map((item: { id: string; name: string }) => ({
        value: item.id,
        label: item.name,
      }))
    } else if (isBranch && sectionData) {
      return sectionData.items.map((item: { id: string; name: string }) => ({
        value: item.id,
        label: item.name,
      }))
    } else {
      return []
    }
  }, [isAdmin, isRegion, isBranch, regionData, branchesData, sectionData])

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(orderCreateSchema),
    defaultValues: {
      name: '',
      content: '',
      units: [],
    },
  })

  const mutation = useMutation({
    mutationFn: (data: any) => createOrder(data),
    onSuccess: (data) => {
      successToast('Dodano żądanie utworzenia polecenia.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingCreate] })
      navigate(path.orders.issued)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      console.log({ error })
      if (error.response?.status === 403) {
        errorToast(
          'Brak dostępu: Proszę upewnić się, że masz odpowiednie uprawnienia do utworzenia szkolenia.'
        )
        return
      }
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, exportOrdersErorrs.create, 'Nie udało się utworzyć polecenia.')
    },
  })

  const fileMutation = useMutation({
    mutationFn: (data: File) => {
      return addOrderFile(data)
    },
    onSuccess: (data) => {
      const fileId = data.id
      const newFile = { value: fileId, label: `Plik ${fileIds.length + 1}` }
      setFileIds((prevFileIds) => [...prevFileIds, fileId])
      setFinalFiles((prevFinalFiles) => [...prevFinalFiles, newFile])
      setFileNames((prev) => ({ ...prev, [fileId]: file?.name || '' }))
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      successToast('Plik został dodany.')
      setFileIsAdding(false)
      setFile(undefined)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      mutationErrorHandler(error, exportOrdersErorrs.create, 'Nie udało się dodać pliku.')
      setFileIsAdding(false)
    },
  })

  const validateFile = (file: File | undefined): boolean => {
    if (!file) {
      errorToast('Plik jest wymagany.')
      return false
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      errorToast(
        `Rozmiar pliku nie może przekraczać 5MB. Obecny rozmiar: ${
          file.size / (1024 * 1024)
        }.toFixed(2)}MB`
      )
      return false
    }

    // Check file type
    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      errorToast('Nieprawidłowy typ pliku. Dozwolone typy to PDF, PNG, JPEG, DOC, DOCX.')
      return false
    }

    return true
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined
    setFile(selectedFile)
  }

  const handleAddFile = (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateFile(file)) {
      return
    }

    setFileIsAdding(true)
    if (file) {
      fileMutation.mutate(file)
    }
  }

  const submitHandler: SubmitHandler<IFormInputs> = (data) => {
    const exportData = {
      name: data.name,
      content: data.content,
      units: data.units.map((unit) => unit.toString()),
      files: fileIds,
    }
    mutation.mutate(exportData)
  }

  if (branchesLoading || sectionLoading || branchesIsLoading) {
    return (
      <Card label="Dodaj polecenie">
        <dl className="grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6">
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className="col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3">
                <div className="h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200"></div>
              </dt>
              <dd className="pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none">
                <div className="h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200"></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  const filteredFiles = fileIds.map((fileId) => ({
    value: fileId,
    label: fileNames[fileId] || fileId, // Use original filename if available
  }))

  return (
    <>
      <Card label="Dodaj polecenie">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="grid max-w-4xl grid-cols-1 gap-4 pb-4 sm:grid-cols-2">
            <div>
              <FormField
                label="Nazwa polecenia"
                placeholder="Wpisz nazwę polecenia"
                name="name"
                type="text"
                register={register}
                errors={errors}
              />
            </div>
            <div>
              <p className="mb-1 block text-sm font-medium text-gray-700">Sekcje</p>
              <Controller
                name="units"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    options={filteredItems}
                    value={filteredItems.filter((item: { value: string }) =>
                      value.includes(item.value)
                    )}
                    onChange={(selected: any[]) => {
                      onChange(selected.map((s: { value: any }) => s.value))
                    }}
                    hasSelectAll={false}
                    className="mb-1 text-sm"
                    overrideStrings={{
                      selectSomeItems: 'Zaznacz sekcje',
                      allItemsAreSelected: 'Wszystkie sekcje są zaznaczone',
                      selectAll: 'Zaznacz wszystkie',
                      search: 'Wyszukaj',
                    }}
                    labelledBy="Wyszukaj"
                  />
                )}
              />
              {errors.units && <p className="text-sm text-red-500">{errors.units.message}</p>}
            </div>
            <div className="md:col-span-2">
              {/* Replace TextareaField with simple textarea */}
              <div>
                <label htmlFor="content" className="mb-1 block text-sm font-medium text-gray-700">
                  Treść polecenia
                </label>
                <textarea
                  id="content"
                  {...register('content')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  rows={4}
                  placeholder="Wpisz treść polecenia"
                />
                {errors.content && (
                  <p className="mt-2 text-sm text-red-600">{errors.content.message}</p>
                )}
              </div>
            </div>
            <div className="md:col-span-2">
              <p className="mb-1 block text-sm font-medium text-gray-700">Pliki</p>
              <MultiSelect
                options={filteredFiles}
                value={finalFiles}
                onChange={setFinalFiles}
                hasSelectAll={false}
                className="text-sm"
                overrideStrings={{
                  selectSomeItems: 'Zaznacz pliki',
                  allItemsAreSelected: 'Wszystkie pliki są zaznaczone',
                  selectAll: 'Zaznacz wszystkie',
                  search: 'Wyszukaj',
                  noOptions: 'Brak plików',
                }}
                labelledBy="Wyszukaj"
              />
            </div>
            <div className="md:col-span-2">
              <label className="mb-1 block text-sm font-medium text-gray-700">Dodaj plik</label>
              <div className="flex max-w-fit flex-col">
                <div className="flex items-center gap-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    ref={fileInputRef}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={handleFileChange}
                  />
                  <Button
                    className="mt-1 flex min-w-[130px] justify-center text-center disabled:cursor-not-allowed"
                    disabled={!file || fileIsAdding}
                    label={
                      fileIsAdding ? (
                        <>
                          Dodaję plik
                          <span className="loader ml-1"></span>
                        </>
                      ) : (
                        'Dodaj plik'
                      )
                    }
                    onClick={handleAddFile}
                  />
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Dozwolone typy plików: PDF, PNG, JPEG, DOC, DOCX. Maksymalny rozmiar: 5MB
                </p>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="mt-4 flex justify-center">
            <Button
              label={mutation.isPending ? 'Tworzenie...' : 'Utwórz polecenie'}
              disabled={mutation.isPending}
              type="submit"
            />
          </div>
        </form>
      </Card>
      <div className="my-4 flex justify-center">
        <Button label="Powrót" variant="secondary" onClick={() => navigate(path.orders.issued)} />
      </div>
    </>
  )
}

export default OrderCreate
