import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'

export const cardForm = yup.object({
  memberId: yup.string().required(errorMessage.requiredField),
  type: yup.string().required(errorMessage.requiredField),
  comment: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars), // Ensure this is present
})

export const cardMassForm = yup.object({
  items: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(errorMessage.requiredField),
      })
    )
    .required(errorMessage.requiredField),
  type: yup.string().required(errorMessage.requiredField),
  comment: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
})

export const cardReqUpdate = yup.object({
  comment: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
})

export const cardTypeReqUpdate = yup.object({
  comment: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  type: yup.string().required(errorMessage.requiredField),
})
