import { useState } from 'react'

import { useParams } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Select } from 'components/Atoms/Select'
import { Typography } from 'components/Atoms/Typography'
import { HistoryList } from 'components/Members/HistoryList'

const data = [
  {
    id: 1,
    changing: 'Anna Joma',
    date: '01.01.2022 01.15',
    changedFiles: ['email', 'telefon'],
    beforeChange: 'przed@wp.pl',
    afterChange: 'po@wp.pl',
  },
  {
    id: 2,
    changing: 'Marek Baraszek',
    date: '15.03.2021 21.27',
    changedFiles: ['email', 'telefon'],
    beforeChange: '131 141 534',
    afterChange: '111 222 333',
  },
]

export const UserHistory = () => {
  const [isVisile, setIsVisible] = useState(false)
  const params = useParams()

  const handleVisible = () => {
    setIsVisible(true)
  }

  return (
    <div>
      <Typography size='xl' weight='medium'>
        Baza członków
      </Typography>
      <div className='mt-5'>
        <Card label={`Historia zmian - ${params.id}`}>
          <div className='flex justify-between'>
            <div className='flex items-center'>
              <Select
                options={[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                ]}
              />
              <div className='ml-8'>
                <Button label='Zaznacz wybrane' />
              </div>
              <div className='ml-4'>
                <Button label='Export danych z listy' />
              </div>
            </div>
            {/* <Pagination /> */}
          </div>
        </Card>
      </div>
      <div className='mt-5'>
        <Card>
          <Typography size='xl' className='mb-6'>
            Wprowadzający zmiany
          </Typography>

          <div className='grid grid-cols-7 gap-4'>
            <Typography size='sm'>Zmieniający</Typography>
            <Typography size='sm'>Data</Typography>
            <Typography size='sm'>Godzina</Typography>
            <Typography size='sm'>Zmienione pola</Typography>
            {isVisile && (
              <>
                <p>Treść przed zmianą</p>
                <p>Treść po zmianie</p>
              </>
            )}
          </div>
          {data.map((item) => (
            <div className='mb-2' key={item.id}>
              <HistoryList handleVisibleDetails={handleVisible} {...item} />
            </div>
          ))}
        </Card>
      </div>
    </div>
  )
}
