import { Controller, FieldErrors, UseFormRegister, UseFormSetError } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { ComboboxField } from 'components/ComboboxField/ComboboxField'
import { errorMessage } from 'constants/errorMessage'
import { IDeclaration } from 'types/declaration'
import { IOption } from 'types/form'

export const EmploymentSection = ({
  fields,
  control,
  institutionList,
  positionList,
  subjectList,
  setError,
  errors,
  register,
  initJobs,
  remove,
  addWork,
}: {
  fields: any[]
  control: any
  institutionList: IOption[]
  positionList: IOption[]
  subjectList: IOption[]
  setError: UseFormSetError<any>
  errors: FieldErrors<IDeclaration>
  register: UseFormRegister<any>
  initJobs: any[]
  remove: (index: number) => void
  addWork: () => void
}) => {
  return (
    <div>
      <ul className="mt-2">
        {fields.map((field: any, index: number) => (
          <li className={index > 0 ? 'mt-6' : ''} key={`job-${index}-${field.id}`}>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
              <div>
                <Controller
                  name={`jobs.${index}.institution.value`}
                  control={control}
                  defaultValue={initJobs[index]?.institution?.value || ''}
                  render={({ field: { onChange, value } }) => (
                    <ComboboxField
                      label={index === 0 ? 'Główne miejsce pracy' : 'Miejsce pracy'}
                      options={institutionList}
                      value={value}
                      error={
                        errors?.jobs?.[index]?.institution?.value?.message ||
                        errors?.jobs?.[index]?.institution?.message ||
                        (errors?.jobs?.[index]?.message && errorMessage.requiredField)
                      }
                      onChange={(newValue) => {
                        onChange(newValue)
                        const matchingInstitution = institutionList.find(
                          (i) => i.value === newValue
                        )
                        if (matchingInstitution) {
                          // Update form state for both value and label
                          if (control._formValues.jobs && control._formValues.jobs[index]) {
                            control._formValues.jobs[index].institution = {
                              value: newValue,
                              label: matchingInstitution.label,
                            }
                          }
                        }
                      }}
                      placeholder="Wybierz miejsce pracy..."
                      emptyMessage="Nie znaleziono miejsca pracy."
                      required={index === 0} // Only require main workplace
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name={`jobs.${index}.position.value`}
                  control={control}
                  defaultValue={initJobs[index]?.position?.value || ''}
                  render={({ field: { onChange, value } }) => (
                    <ComboboxField
                      label={index === 0 ? 'Stanowisko' : 'Stanowisko'}
                      options={positionList}
                      value={value}
                      error={
                        errors?.jobs?.[index]?.position?.value?.message ||
                        errors?.jobs?.[index]?.position?.message ||
                        (errors?.jobs?.[index]?.message && errorMessage.requiredField)
                      }
                      onChange={(newValue) => {
                        onChange(newValue)
                        const matchingPosition = positionList.find((p) => p.value === newValue)
                        if (matchingPosition) {
                          // Update form state for both value and label
                          if (control._formValues.jobs && control._formValues.jobs[index]) {
                            control._formValues.jobs[index].position = {
                              value: newValue,
                              label: matchingPosition.label,
                            }
                          }
                        }
                      }}
                      placeholder="Wybierz stanowisko..."
                      emptyMessage="Nie znaleziono stanowiska."
                      required={index === 0} // Only require position for main workplace
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name={`jobs.${index}.subject.value`}
                  control={control}
                  defaultValue={initJobs[index]?.subject?.value || ''}
                  render={({ field: { onChange, value } }) => (
                    <ComboboxField
                      label={index === 0 ? 'Przedmiot' : 'Przedmiot'}
                      options={subjectList}
                      value={value}
                      error={
                        errors?.jobs?.[index]?.subject?.value?.message ||
                        errors?.jobs?.[index]?.subject?.message ||
                        (errors?.jobs?.[index]?.message && errorMessage.requiredField)
                      }
                      onChange={(newValue) => {
                        onChange(newValue)
                        const matchingSubject = subjectList.find((s) => s.value === newValue)
                        if (matchingSubject) {
                          // Update form state for both value and label
                          if (control._formValues.jobs && control._formValues.jobs[index]) {
                            control._formValues.jobs[index].subject = {
                              value: newValue,
                              label: matchingSubject.label,
                            }
                          }
                        }
                      }}
                      placeholder="Wybierz przedmiot..."
                      emptyMessage="Nie znaleziono przedmiotu."
                      required={false} // Subject is not required
                    />
                  )}
                />
              </div>

              {index > 0 && (
                <div className="mt-2 flex justify-end md:col-span-3">
                  <Button
                    disabled={index === 0}
                    type="button"
                    label="usuń"
                    onClick={() => remove(index)}
                    variant="secondary"
                  />
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="mt-6 flex justify-center">
        <Button label="Dodaj kolejne" type="button" onClick={addWork} variant="secondary" />
      </div>
    </div>
  )
}
