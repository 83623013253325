import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { RadioGroup } from 'components/Atoms/RadioGroup'
import { Spinner } from 'components/Atoms/Spinner'
import { TextareaField } from 'components/Atoms/TextareaField/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { cardErrors } from 'error-data/card'
import { createCard, getCardReqTypeDict } from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { cardForm } from 'schemas/cardSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorRequires, successToast } from 'tools/ToastHelpers'
import { IMember } from 'types/member'

type Props = {
  member: IMember
  closeModal: () => void
}

export const CardMemberNew = ({ closeModal, member }: Props) => {
  const [isSending, setIsSending] = useState(false)
  const { userToken, id } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      memberId: id,
      type: 'new',
      comment: '',
    },
    resolver: yupResolver(cardForm),
  })
  const setRequestType = (e: string) => {
    setValue('type', e)
  }
  // REQUEST TYPE
  const {
    data: reqType,
    isLoading: reqTypeLoading,
    error: reqTypeError,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardReqTypeDict],
    queryFn: () => getCardReqTypeDict(),
  })
  const reqTypeList = reqType?.items
    ? reqType?.items?.map((item: any) => {
        return { title: item.name, id: item.id }
      })
    : []
  // END REQUEST TYPE
  const onValidationFailure = () => errorRequires(errors)

  // SUBMIT HANDLER
  const mutate = useMutation({
    mutationFn: (data: any) => createCard(data.memberId, data.type, data.comment),
    onSuccess: () => {
      successToast('Wniosek został wysłany')
      closeModal()
      setIsSending(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, cardErrors.create, cardErrors.create.message)
    },
  })
  const onSubmitHandler = async (data: any) => {
    try {
      setIsSending(true)
      mutate.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, cardErrors.create, cardErrors.create.message)
    }
  }
  // END SUBMIT HANDLER
  if (reqTypeLoading) {
    return (
      <section>
        <Typography size="xl" weight="medium">
          Wniosek o legitymację / {member?.firstName} {member?.lastName}
        </Typography>
        <Card label="Wczytywanie danych...">
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        </Card>
      </section>
    )
  }
  return (
    <section>
      <Typography size="xl" weight="medium">
        Wniosek o legitymację / {member?.firstName} {member?.lastName}
      </Typography>
      <Card label="Wypełnij wniosek">
        <form className="mx-auto flex max-w-3xl flex-col gap-4 py-6">
          <RadioGroup
            label={'Typ wniosku'}
            defaultChecked="new"
            onChange={(e) => setRequestType(e.target.value)}
            data={reqTypeList}
          />

          <div>
            <label
              htmlFor="cardRequestReason"
              className="mb-1 mt-3 block text-sm font-medium text-gray-700"
            >
              Opisz powód
            </label>
            <textarea
              id="comment"
              {...register('comment')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              rows={4}
            />
            {errors.comment?.message && (
              <p className="mt-2 text-sm text-red-600">{errors.comment.message}</p>
            )}
          </div>
          <div className="flex justify-center gap-4">
            <Button label="Anuluj" variant="secondary" onClick={closeModal} />
            <Button
              disabled={isSending}
              onClick={handleSubmit(onSubmitHandler, onValidationFailure)}
              label="Wyślij wniosek"
            />
          </div>
        </form>
      </Card>
    </section>
  )
}

export default CardMemberNew
