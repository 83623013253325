import { ReactNode, useEffect, useRef, useState } from 'react' 
import { useVirtualizer } from '@tanstack/react-virtual' 
import useSearchModule from 'hooks/useSearchModule' 
import { IOption } from 'types/form' 
import { Button } from '../Button' 
import { LabelName } from '../LabelName' 
import { Spinner } from '../Spinner' 
import { TextField } from '../TextField' 
import type { Control, UseFormRegister } from 'react-hook-form'

const useDebounce = <T,>(value: T, delay: number): T => { 
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => { 
    const timer = setTimeout(() => { 
      setDebouncedValue(value) 
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue 
}

type Props = { 
  shortenName?: boolean 
  name: string 
  label: string 
  options: IOption[] 
  defaultValue?: IOption 
  defaultOption?: IOption 
  onValueChange?: (value: IOption) => void 
  isLoading?: boolean 
  searchMore?: () => void 
  children?: ReactNode 
  searchName?: string 
  filterBox?: boolean 
  control?: Control<any> 
  register?: UseFormRegister<any> 
  externalChangeHandler?: (label: string) => void 
  externalChangeValue?: (value: string) => void 
  onReset?: () => void 
}

export const SearchAndFetchInput = ({ 
  shortenName, 
  name, 
  label, 
  options, 
  defaultValue, 
  defaultOption, 
  onValueChange, 
  isLoading, 
  searchMore, 
  children, 
  searchName, 
  filterBox, 
  control, 
  onReset, 
  register, 
  externalChangeHandler, 
  externalChangeValue, 
}: Props) => { 
  const { onRefreshHandler, setSearchString, setPerPage } = useSearchModule({ 
    archive: false, 
    searchName, 
  }) 
  const [searchInput, setSearchInput] = useState<string>('') 
  const [currentList, setCurrentList] = useState<IOption[]>([]) 
  const [listFilter, setListFilter] = useState<string>('') 
  const [isLoadingMore, setIsLoadingMore] = useState(false) 
  const [selectedValue, setSelectedValue] = useState<IOption | undefined>(undefined) 
  const lastScrollPositionRef = useRef(0)

  const debouncedSearchValue = useDebounce(searchInput, 500) 
  const debouncedFilterValue = useDebounce(listFilter, 500)

  // Filter the list based on search input 
  const filterListData = currentList.filter((e) => 
    e.label.toLowerCase().includes(listFilter.toLowerCase()) 
  )

  // Setup virtualizer 
  const listRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({ 
    count: filterListData.length + (isLoadingMore ? 1 : 0), 
    getScrollElement: () => listRef.current, 
    estimateSize: () => 40, 
    overscan: 5, 
  })

  // Handle scroll and loading more items 
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => { 
    const element = e.currentTarget 
    const { scrollHeight, scrollTop, clientHeight } = element 
    const isNearBottom = scrollHeight - scrollTop <= clientHeight * 1.5

    lastScrollPositionRef.current = scrollTop

    if (isNearBottom && !isLoadingMore && searchMore) {
      setIsLoadingMore(true)
      searchMore()
    }
  }

  // Properly handle search input changes
  useEffect(() => { 
    if (debouncedSearchValue !== '') { 
      if (!filterBox) { 
        setSearchString(debouncedSearchValue) 
      } 
    } 
  }, [debouncedSearchValue, filterBox, setSearchString])

  // Debounced effect for triggering search
  useEffect(() => { 
    if (!filterBox && debouncedSearchValue !== '') { 
      const timer = setTimeout(() => { 
        onRefreshHandler() 
      }, 100) 
      return () => clearTimeout(timer) 
    } 
  }, [debouncedSearchValue, filterBox, onRefreshHandler])

  // Apply local filtering
  useEffect(() => { 
    if (filterBox) { 
      setListFilter(debouncedFilterValue) 
    } 
  }, [debouncedFilterValue, filterBox])

  // Update current options list when options change
  useEffect(() => { 
    if (options.length) { 
      setCurrentList(options) 
    } 
  }, [options])

  // Initialize selected value from defaultOption
  useEffect(() => { 
    if (defaultOption && !selectedValue) {
      // Ensure defaultOption is properly formatted
      let formattedOption: IOption;
      
      // Handle case when defaultOption.value is an object
      if (
        defaultOption.value &&
        typeof defaultOption.value === 'object' &&
        defaultOption.value !== null
      ) {
        formattedOption = {
          label: typeof defaultOption.label === 'string' ? defaultOption.label : 'Unknown',
          value: 'id' in defaultOption.value ? (defaultOption.value as any).id : defaultOption.value,
        }
      } else {
        formattedOption = defaultOption
      }
      
      setSelectedValue(formattedOption);
    }
  }, [defaultOption, selectedValue])

  const resetInput = () => { 
    setSearchInput('') 
    setListFilter('') 
    setSelectedValue(undefined) 
    setCurrentList([]) 
    setPerPage(10)

    if (!filterBox) {
      setSearchString('') 
      setTimeout(() => {
        onRefreshHandler() 
      }, 100)
    }

    if (onReset) {
      onReset()
    }

    const searchParams = new URLSearchParams(window.location.search)
    if (searchName) searchParams.delete(searchName)
    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams}` : ''}`
    window.history.replaceState({}, '', newUrl)
  }

  // Update URL when user explicitly submits search
  const updateUrlParams = () => { 
    const searchParams = new URLSearchParams(window.location.search) 
    if (searchInput) { 
      searchParams.set(searchName || 'search', searchInput) 
    } else { 
      searchParams.delete(searchName || 'search') 
    } 
    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams}` : ''}` 
    window.history.replaceState({}, '', newUrl) 
  }

  // Reset when component name changes
  const nameRef = useRef(name) 
  useEffect(() => { 
    if (nameRef.current !== name) { 
      setSearchInput('') 
      setListFilter('') 
      setSelectedValue(undefined) 
      setCurrentList([]) 
      nameRef.current = name 
    } 
  }, [name])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => { 
    if (e.key === 'Enter') { 
      e.preventDefault() 
      if (filterBox) { 
        setListFilter(e.currentTarget.value) 
      } else { 
        setSearchString(e.currentTarget.value) 
        onRefreshHandler() 
        updateUrlParams() 
      } 
    } 
  }

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const value = e.target.value 
    setSearchInput(value)
  }

  const handleOptionSelect = (option: IOption) => { 
    setSelectedValue(option)

    if (register) {
      const registerProps = register(name)
      registerProps.onChange?.({
        target: { name, value: option.value },
      } as React.ChangeEvent<HTMLInputElement>)
    }

    if (onValueChange) {
      onValueChange(option)
    }
    if (externalChangeHandler) {
      externalChangeHandler(option.label)
    }
    if (externalChangeValue) {
      externalChangeValue(String(option.value))
    }
  }

  return ( 
    <div className="grid gap-2"> 
      <div className="flex justify-between gap-2"> 
        <div className="flex-1"> 
          <TextField 
            onKeyDown={handleKeyPress} 
            label={label} 
            type="text" 
            value={searchInput} 
            placeholder="Wyszukaj..." 
            onChange={searchHandler} 
          /> 
        </div> 
        <div className="flex gap-2"> 
          {searchInput && ( 
            <Button 
              className="mt-auto h-auto" 
              type="button" 
              onClick={resetInput} 
              label="Wyczyść" 
            /> 
          )} 
          {!filterBox && ( 
            <Button 
              className="mt-auto h-auto" 
              type="button" 
              onClick={() => { 
                onRefreshHandler() 
                updateUrlParams() 
              }} 
              label={isLoading ? <Spinner /> : 'Wyszukaj'} 
            /> 
          )} 
        </div> 
      </div> 
      <div className="flex justify-between">{children}</div> 
      <div 
        ref={listRef} 
        onScroll={handleScroll} 
        className="relative h-56 overflow-y-auto rounded-md border-2 border-solid border-gray-300" 
      > 
        <div 
          className="relative w-full" 
          style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
        > 
          {rowVirtualizer.getVirtualItems().map((virtualRow) => { 
            const item = filterListData[virtualRow.index]

            if (!item && isLoadingMore && virtualRow.index === filterListData.length) {
              if (filterListData.length < currentList.length) {
                return (
                  <div
                    key="loader"
                    className="absolute left-0 flex w-full items-center justify-center py-2"
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <Spinner />
                  </div>
                )
              } else {
                // Stop showing the spinner once no more items remain
                setIsLoadingMore(false)
                return null
              }
            }

            if (!item) return null

            const rKey = `r-${item.value}-${name}`
            const isSelected = selectedValue?.value === item.value

            return (
              <div
                key={rKey}
                className="absolute left-0 w-full border-b border-solid border-gray-300"
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <label
                  htmlFor={rKey}
                  className="relative flex h-full w-full cursor-pointer items-center px-3 py-2 text-sm"
                >
                  <input
                    type="radio"
                    id={rKey}
                    name={name}
                    checked={isSelected}
                    onChange={() => handleOptionSelect(item)}
                    value={item.value}
                    className="mr-2"
                  />
                  {shortenName ? (
                    <LabelName name={item.label} />
                  ) : (
                    <span className="truncate pl-2">{item.label}</span>
                  )}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  ) 
}

export default SearchAndFetchInput