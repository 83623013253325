import { useState } from 'react'

import { InfoCircledIcon } from '@radix-ui/react-icons'

import { Alert, AlertDescription } from 'components/shadcn/ui/alert'
import { Button as ShadcnButton } from 'components/shadcn/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { Input } from 'components/shadcn/ui/input'

const CardVerify = () => {
  const [cardNumber, setCardNumber] = useState('')
  interface VerificationResult {
    status: string
    msg: string
  }

  const [verificationResult, setVerificationResult] = useState<VerificationResult | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleVerify = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (!cardNumber.trim()) return

    setIsLoading(true)
    setError('')
    setVerificationResult(null)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}card/check?n=${encodeURIComponent(cardNumber)}`,
      )
      const data = await response.json()
      setVerificationResult(data)
    } catch (err) {
      setError('Wystąpił błąd podczas weryfikacji. Spróbuj ponownie później.')
      console.error('Verification error:', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card>
      <CardContent>
        <CardHeader className='flex flex-row items-center justify-between px-0'>
          <CardTitle className='text-2xl'>Sprawdź ważność legitymacji</CardTitle>
        </CardHeader>
        <form onSubmit={handleVerify} className='space-y-6'>
          <div className='flex max-w-md items-center gap-2'>
            <Input
              placeholder='Numer legitymacji'
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              className='flex-1'
              disabled={isLoading}
            />
            <ShadcnButton type='submit' disabled={isLoading || !cardNumber.trim()}>
              {isLoading ? 'Sprawdzam...' : 'Sprawdź'}
            </ShadcnButton>
          </div>

          <div className='min-h-[46px]'>
            {verificationResult && (
              <Alert
                className={`flex items-center ${
                  verificationResult.status === 'ok'
                    ? 'border-green-200 bg-green-50'
                    : 'border-red-200 bg-red-50'
                }`}
              >
                <AlertDescription
                  className={`${verificationResult.status === 'ok' ? 'text-green-800' : 'text-red-800'}`}
                >
                  {verificationResult.msg}
                </AlertDescription>
              </Alert>
            )}
          </div>

          {error && (
            <Alert variant='destructive'>
              <InfoCircledIcon className='h-5 w-5' />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </form>
      </CardContent>
    </Card>
  )
}

export default CardVerify
