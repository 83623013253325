import { useEffect, useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Pagination } from 'components/Atoms/Pagination'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { Typography } from 'components/Atoms/Typography'
import { InstitutionActionCell } from 'components/Institution/InstitutionActionCell'
import InstitutionSearch from 'components/Institution/InstitutionSearch'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { useArchiveInstitution } from 'fetchers/hooks/useArchiveInstitution'
import { getInstitutionsList, getInstitutionsListByMe } from 'fetchers/institutionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { getLastPage } from 'tools/queryHelpers'
import { IInstitutionListElement, IInstitutionSearchQuery } from 'types/institution'

export const InstitutionList = () => {
  const [query, setQuery] = useState<IInstitutionSearchQuery>({
    name: '',
    city: '',
    voivodeship: [], // Initialize voivodeship as an empty array
  })
  const navigate = useNavigate()
  const location = useLocation()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const page = Number(searchParams.get('page') || 1)
  const perPageFromUrl = parseInt(searchParams.get('perPage') || '10', 10)
  const [limit, setLimit] = useState(perPageFromUrl)

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      userToken,
      queryKeys.institutionsList,
      page,
      limit,
      query.city,
      query.name,
      // Include full voivodeship array in query key for proper cache invalidation
      ...(query.voivodeship || []),
    ],
    queryFn: () => getInstitutionsListByMe(page, limit, query),
    retry: useRetryHandler({
      resourceName: 'InstitutionList data',
      maxRetries: 1,
    }),
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  })

  // Calculate effective pagination based on filtered results
  const effectivePagination = useMemo(() => {
    // If there are no items, we should be on page 1 with lastPage also 1
    if (!data?.items || data.items.length === 0) {
      return { currentPage: 1, lastPage: 1, count: 0 }
    }
    // Otherwise use the pagination from the API response
    return data.pagination
  }, [data])

  // Use the effective pagination instead of raw data.pagination
  const { lastPage } = getLastPage(effectivePagination)

  // Check if we're on a page higher than lastPage after filtering
  useEffect(() => {
    if (page > lastPage && lastPage > 0) {
      // If current page is greater than last page, reset to page 1
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('page', '1')
      setSearchParams(newSearchParams)
    }
  }, [lastPage, page, searchParams, setSearchParams])

  const institutionData = (data?.items || []).map((item: any): IInstitutionListElement => {
    const firstName = item?.headmaster?.firstName || null
    const lastName = item?.headmaster?.lastName || null
    const name = [firstName, lastName].filter(Boolean).join(' ')
    return {
      id: item.id,
      name: item.name,
      headmaster: name,
      type: item?.type?.name || null,
      memberCount: item?.memberCount,
    }
  })

  const { showModalHandler, ArchiveModal } = useArchiveInstitution({
    refetch,
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Nazwa placówki',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Dyrektor placówki',
        accessor: 'headmaster',
      },
      {
        Header: 'Typ placówki',
        accessor: 'type',
      },
      {
        Header: 'Liczba członków',
        accessor: 'memberCount',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <InstitutionActionCell
              showModalHandler={showModalHandler}
              data={row.row.original}
              route={'institution'}
            />
          )
        },
      },
    ],
    [isAllow]
  )

  const onPageChange = (newPage: number) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', newPage.toString())
    setSearchParams(newSearchParams)
  }

  const onLimitHandler = (value: string) => {
    const newLimit = parseInt(value, 10)
    setLimit(newLimit)

    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('perPage', newLimit.toString())
    newSearchParams.set('page', '1') // Reset to page 1 when changing limit
    setSearchParams(newSearchParams)
  }

  useEffect(() => {
    refetch()
  }, [limit, query]) // Refetch when the query changes

  const queryHandler = (query: IInstitutionSearchQuery) => {
    setQuery(query)
    // Reset to page 1 when search query changes
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', '1')
    setSearchParams(newSearchParams)
  }

  // Show no data message if there are no items after filtering
  const showNoDataMessage = !isLoading && (!data?.items || data.items.length === 0)

  return (
    <div>
      <Typography size="xl" weight="medium">
        Lista placówek
      </Typography>
      <InstitutionSearch queryHandler={queryHandler} />

      <div className="mt-5 flex justify-between">
        <Button
          label="Utwórz placówkę"
          className="justify-center"
          onClick={() => navigate(path.institution.create)}
        />
        <Button disabled label="Zarchiwizowane" />
      </div>

      <div className="my-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Typography weight="medium" className="whitespace-nowrap text-sm">
            Liczba wierszy na stronę
          </Typography>
          <Select value={limit.toString()} onValueChange={(value) => onLimitHandler(value)}>
            <SelectTrigger className="w-full max-w-[80px] bg-white" id="institutionSelect">
              <SelectValue placeholder={limit.toString()} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="max-w-sm">
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="20">20</SelectItem>
                <SelectItem value="50">50</SelectItem>
                <SelectItem value="100">100</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {lastPage > 1 && (
          <Pagination lastPage={lastPage} currentPage={page} handlePageChange={onPageChange} />
        )}
      </div>

      {isLoading ? (
        <TableSkeleton />
      ) : showNoDataMessage ? (
        <div className="my-8 text-center">
          <Typography size="lg" className="text-gray-500">
            Brak wyników dla wybranych filtrów
          </Typography>
        </div>
      ) : (
        <Table columns={columns} data={institutionData} />
      )}

      <div className="mt-4 flex justify-end">
        {lastPage > 1 && (
          <Pagination lastPage={lastPage} currentPage={page} handlePageChange={onPageChange} />
        )}
      </div>

      <ArchiveModal />
    </div>
  )
}
