import { successToast } from './ToastHelpers'

export const exportJson = (data: any) => {
  const jsonData = JSON.stringify(data, null, 2)
  const blob = new Blob([jsonData], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'data.json')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  successToast('Dane zostały wyeksportowane')
}
