import React, { useState } from 'react'

import { FieldErrors, UseFormRegister } from 'react-hook-form'

import { cn } from 'components/lib/utils'
import { Input } from 'components/shadcn/ui/input'
import { Label } from 'components/shadcn/ui/label'

interface FormFieldProps {
  name: string
  label: string
  register: UseFormRegister<any>
  errors: FieldErrors<any>
  optional?: boolean
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  type?: string
  autocomplete?: string
}

const FormField = ({
  name,
  label,
  register,
  errors,
  optional = false,
  placeholder = '',
  onChange: externalOnChange,
  value,
  type = 'text',
  autocomplete,
}: FormFieldProps) => {
  const [isTouched, setIsTouched] = useState(false)
  const { onChange, onBlur, ref, ...registerProps } = register(name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Call the form's onChange
    onChange(e)

    // Call the external onChange if provided
    if (externalOnChange) {
      externalOnChange(e)
    }

    if (errors?.[name]) {
      setIsTouched(true)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e)
    setIsTouched(true)
  }

  // Show error if there's an error, regardless of touch state
  const showError = errors?.[name]?.message

  return (
    <div>
      <Label htmlFor={name} className="block mb-1 text-sm font-medium text-gray-700">
        {label}
        {!optional && <span className="ml-1 text-red-500">*</span>}
      </Label>
      <Input
        id={name}
        type={type}
        placeholder={placeholder}
        className={cn('w-full')}
        {...registerProps}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
        autoComplete={autocomplete}
      />
      {showError && errors[name]?.message && (
        <p className="mt-1 text-sm text-red-500">{String(errors[name].message)}</p>
      )}
    </div>
  )
}

export default FormField
