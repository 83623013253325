import DOMPurify from 'dompurify';
import { decode } from 'html-entities'

/**
 * Decodes HTML entities to their corresponding characters
 * @param {string} text - Text that may contain HTML entities
 * @returns {string} Text with HTML entities decoded
 */
export function decodeHtmlEntities(text: string): string {
  if (!text) return ''
  return decode(text)
}

/**
 * Recursively processes an object/array to decode HTML entities in all string values
 * @param data - The data to process (object, array, or primitive)
 * @returns The processed data with HTML entities decoded in string values
 */
export function processDataWithHtmlDecoding(data: any): any {
  if (!data) return data

  if (typeof data === 'string') {
    const decoded = decodeHtmlEntities(data);
    return DOMPurify.sanitize(decoded);
  }

  if (Array.isArray(data)) {
    return data.map((item) => processDataWithHtmlDecoding(item))
  }

  if (typeof data === 'object') {
    const result: Record<string, any> = {}
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        result[key] = processDataWithHtmlDecoding(data[key])
      }
    }
    return result
  }

  return data
}
