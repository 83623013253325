import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { ComboboxField } from 'components/ComboboxField/ComboboxField'
import { errorMessage } from 'constants/errorMessage'
import { queryKeys } from 'constants/queryKeys'
import { getInstitutionsList, getInstitutionsListByMe } from 'fetchers/institutionFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import useAuth from 'hooks/useAuth'
import { updateMemberEmploymentSchema } from 'schemas/memberSchema'
import { JobElement } from 'types/declaration'

type Props = {
  job?: JobElement
  isOpen: boolean
  isSending?: boolean
  closeModal: () => void
  submitHandler: (data: JobElement) => void
}

export const MemberEmploymentModal = ({
  job,
  isOpen,
  isSending,
  closeModal,
  submitHandler,
}: Props) => {
  const { userToken } = useAuth()

  // This block has been moved up above and is now deleted to avoid duplication

  // DATA FETCHING
  // INSTITUTIONS
  const { data: institution, isLoading: institutionLoading } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, 1, 1000],
    queryFn: () => getInstitutionsListByMe(1, 1000),
  })

  // POSITIONS
  const { data: position, isLoading: positionLoading } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, 1, 1000],
    queryFn: () => getPositionsList(1, 1000),
  })

  // SUBJECTS
  const { data: subject, isLoading: subjectLoading } = useQuery({
    queryKey: [userToken, queryKeys.subjectsList, 1, 1000],
    queryFn: () => getSubjectsList(1, 1000),
  })

  // Set initial values once data is loaded
  useEffect(() => {
    if (job && institution?.items && position?.items) {
      setValue('institution', job.institution?.value || '')
      setValue('position', job.position?.value || '')
      setValue('subject', job.subject?.value || '')
    }
  }, [institution?.items, position?.items, subject?.items, job])

  const institutionList = institution?.items
    ? institution?.items?.map((item: { name: any; id: any }) => ({
        label: item.name,
        value: item.id,
      }))
    : []

  const positionList = position?.items
    ? position?.items?.map((item: { name: any; id: any }) => ({
        label: item.name,
        value: item.id,
      }))
    : []

  const subjectList = subject?.items
    ? subject?.items?.map((item: { name: any; id: any }) => ({
        label: item.name,
        value: item.id,
      }))
    : []

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      id: job?.id || undefined,
      institution: job?.institution?.value || '',
      position: job?.position?.value || '',
      subject: job?.subject?.value || '',
      isMain: job?.isMain || false,
    },
    resolver: yupResolver(updateMemberEmploymentSchema),
    mode: 'onChange',
  })

  // Only show errors after submission attempt
  const shouldShowError = isSubmitted

  const onSubmit = (data: { position: any; institution: any; subject: any }) => {
    // Transform data format to match the expected API format
    const formattedData = {
      id: job?.id,
      position: data.position,
      institution: data.institution,
      subject: data.subject || '',
      isMain: job?.isMain || false,
    }

    submitHandler(formattedData)
  }

  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <Card label="Edytuj miejsce zatrudnienia">
        <form className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <div>
            <Controller
              name="institution"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ComboboxField
                  label="Miejsce pracy"
                  options={institutionList}
                  value={value}
                  error={
                    shouldShowError && errors?.institution?.message
                      ? errorMessage.requiredField
                      : undefined
                  }
                  onChange={(newValue) => {
                    onChange(newValue)
                    trigger('institution')
                  }}
                  placeholder="Wybierz miejsce pracy..."
                  emptyMessage="Nie znaleziono miejsca pracy."
                  required={true}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="position"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ComboboxField
                  label="Stanowisko"
                  options={positionList}
                  value={value}
                  error={
                    shouldShowError && errors?.position?.message
                      ? errorMessage.requiredField
                      : undefined
                  }
                  onChange={(newValue) => {
                    onChange(newValue)
                    trigger('position')
                  }}
                  placeholder="Wybierz stanowisko..."
                  emptyMessage="Nie znaleziono stanowiska."
                  required={true}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="subject"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ComboboxField
                  label="Przedmiot"
                  options={subjectList}
                  value={value}
                  error={
                    shouldShowError && errors?.subject?.message
                      ? errorMessage.requiredField
                      : undefined
                  }
                  onChange={(newValue) => {
                    onChange(newValue)
                  }}
                  placeholder="Wybierz przedmiot..."
                  emptyMessage="Nie znaleziono przedmiotu."
                  required={false}
                />
              )}
            />
          </div>

          <div className="col-span-1 mt-5 flex justify-center gap-5 md:col-span-3">
            <Button label="Anuluj" variant="secondary" onClick={closeModal} />
            <Button
              disabled={isSending}
              className="disabled:border disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none"
              label={isSending ? 'Zapisywanie...' : 'Zapisz'}
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </Card>
    </Modal>
  )
}

export default MemberEmploymentModal
