import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { errorRequires } from 'tools/ToastHelpers'
import { INotifyHandle } from 'types/notify'

import { TextareaField } from '../TextareaField/TextareaField'
import { Typography } from '../Typography'

interface IProps {
  isOpen: boolean
  isSending?: boolean
  handleClose: () => void
  handleResolve: (formData: INotifyHandle) => void
}

export const ResolveNotifyModal: React.FC<IProps> = ({
  handleResolve,
  isOpen,
  isSending,
  handleClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: '',
    },
  })
  const onValidationFailure = () => errorRequires(errors)

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} className="w-1/3">
      <div>
        <Card
          label="Obsłuż zgłoszenie"
          className="px-10 py-5"
          // actionsButton={[{ icon: <XCircleIcon className='w-5' />, handleClick: handleClose }]}
        >
          <form onSubmit={handleSubmit(handleResolve, onValidationFailure)}>
            <Typography>Potwierdź zmianę danych członka</Typography>
            <div className="mt-4">
              <div>
                <textarea
                  id="comment"
                  {...register('comment')}
                  placeholder="Wpisz komentarz"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  rows={4}
                />
                {errors.comment?.message && (
                  <p className="mt-2 text-sm text-red-600">{errors.comment.message}</p>
                )}
              </div>
            </div>
            <div className="mt-4 flex justify-center gap-5">
              <Button label="Anuluj" variant="secondary" onClick={handleClose} />
              <Button disabled={isSending} label="Zatwierdź" />
            </div>
          </form>
        </Card>
      </div>
    </Modal>
  )
}
