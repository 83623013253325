import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { publicBaseApiURL } from 'api'
import { RootState } from 'app/store'
import { endpoint } from 'constants/endpoints'
import { HttpStatus } from 'types/httpStatus.enum'

export interface Roles {
  systemwide: Branch[]
  region: Branch[]
  branch: Branch[]
  section: Branch[]
}

export interface Branch {
  role: string
  name: string
}

const initialState: {
  roles: Roles
  status: HttpStatus
} = {
  status: HttpStatus.IDLE,
  roles: { systemwide: [], region: [], branch: [], section: [] },
}

export const getRolesAsync = createAsyncThunk('role/list', async () => {
  try {
    const response = await publicBaseApiURL.get(endpoint.role.list, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (err) {
    return err
  }
})

export const rolesListSlice = createSlice({
  name: 'role/list/me',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRolesAsync.pending, (state) => {
        state.status = HttpStatus.PENDING
      })
      .addCase(getRolesAsync.fulfilled, (state, action) => {
        state.status = HttpStatus.FULFILLED
        state.roles = action.payload
      })
      .addCase(getRolesAsync.rejected, (state) => {
        state.status = HttpStatus.REJECTED
      })
  },
})

export const getRoles = (state: RootState) => state.rolesReducer.roles

export default rolesListSlice.reducer
