import { useQuery } from '@tanstack/react-query'

import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import RodoResourceForm from 'components/Rodo/RodoResourceForm'
import { queryKeys } from 'constants/queryKeys'
import { getResourceDeclarationCriteria } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import { getLoadingHandler } from 'tools/queryHelpers'
import { IDict } from 'types/dictionary'

export const ResourceDeclarationsList = () => {
  const { userToken, userLoaded, isAllAdmin, isAllOD } = useAuth()

  const { data, error, isPending } = useQuery({
    queryKey: [userToken, queryKeys.rodoResourceDeclarationsCriteria],
    queryFn: () => getResourceDeclarationCriteria(),
  })

  const criteriaList: IDict[] = data?.items || []

  const initValues = {}
  const activeCriteria = {}
  criteriaList.forEach((item) => {
    Object.assign(initValues, { [item.id]: '' })
    Object.assign(activeCriteria, { [item.id]: false })
  })
  const isLoading = isPending || !userLoaded
  const isValid = isAllAdmin() || isAllOD()
  const loadingHandler = getLoadingHandler(error, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='medium'>
        RODO / Nowe zapytanie o deklaracje członkowskie
      </Typography>
      <Card label='Formularz zapytania'>
        <RodoResourceForm
          type='declarations'
          criteriaList={criteriaList}
          initValues={initValues}
          initActive={activeCriteria}
        />
      </Card>
    </div>
  )
}

export default ResourceDeclarationsList
