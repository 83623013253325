export const errorMessage = {
  requiredField: 'Pole jest wymagane',
  selectBranch: 'Wybierz oddział',
  selectSectionType: 'Wybierz typ sekcji',
  invalidPhone: 'Numer telefonu jest niepoprawny',
  invalidPostalCode: 'Kod pocztowy jest niepoprawny',
  invalidEmail: 'Adres email jest niepoprawny',
  invalidPesel: 'PESEL jest niepoprawny',
  invalidHouseNr: 'Numer jest niepoprawny',
  min5Chars: 'Minimum 5 znaków',
  min3Chars: 'Minimum 3 znaki',
  min2Chars: 'Minimum 2 znaki',
  invalidFormat: 'Niepoprawny format',
  invalidCity: 'Niepoprawna nazwa miasta',
  group: 'Minimum 1 grupa',
  unitType: 'Wybierz typ jednostki',
  job: 'Minimum 1 stanowisko',
  jobBoth: 'Podaj stanowisko i miejsce pracy',
  requiredDocument: 'Wskazanie uchwały jest wymagane',
}
