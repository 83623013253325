import { useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ConfirmDeleteNotifyModal } from 'components/Atoms/ConfirmDeleteNotifyModal'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import LoadingModal from 'components/Atoms/LoadingModal'
import { ResolveNotifyModal } from 'components/Atoms/ResolveNotifyModal'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { notificationErrors } from 'error-data/notification'
import {
  getNotificationById,
  getReasonsList,
  handleNotification,
  removeNotification,
} from 'fetchers/notifyFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { INotifyHandle } from 'types/notify'

export const MemberNotificationsDetail = () => {
  const [isSending, setIsSending] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenResolveModal, setIsOpenResolveModal] = useState(false)
  const { userToken, id, isAllAdmin, isAllOD, isPOG } = useAuth()
  const isSection = isPOG()
  const isBranch = isAllOD()
  const isValid = isAllAdmin() || isBranch || isSection
  const navigate = useNavigate()
  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.notifications, id],
    queryFn: () => getNotificationById(id),
    retry: errorQuery,
  })
  const userName = `${data?.member?.firstName} ${data?.member?.lastName}`

  const {
    data: reasons,
    isLoading: reasonsLoading,
    error: reasonsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.reasonsList],
    queryFn: () => getReasonsList(),
    retry: useRetryHandler({
      resourceName: 'MemberNotificationsDetail reasons',
      maxRetries: 1,
    }),
  })
  const reasonsList = reasons?.items || []
  const showDeleteModal = () => setIsOpenDeleteModal(true)
  const closeDeleteModal = () => setIsOpenDeleteModal(false)

  const showResolveModal = () => setIsOpenResolveModal(true)
  const closeResolveModal = () => setIsOpenResolveModal(false)

  // DELETE HANDLER
  const mutate = useMutation({
    mutationFn: async () => removeNotification(id),
    onSuccess: () => {
      successToast('Usunięto zgłoszenie.')
      navigate(-1)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, notificationErrors.remove, 'Nie udało się usunąć zgłoszenia')
    },
  })
  const handleDelete = async () => {
    try {
      setIsSending(true)
      mutate.mutate()
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się usunąć zgłoszenia')
    }
  }

  // RESOLVE HANDLER
  const mutateResolve = useMutation({
    mutationFn: async (data: INotifyHandle) => handleNotification(id, data),
    onSuccess: () => {
      successToast('Obsłużono zgłoszenie.')
      navigate(-1)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, notificationErrors.handle, 'Nie udało się obsłużyć zgłoszenia')
    },
  })
  const handleResolve = async (data: INotifyHandle) => {
    try {
      setIsSending(true)
      mutateResolve.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się obsłużyć zgłoszenia')
    }
  }

  const actionButtons = isSection
    ? [
        {
          label: 'Usuń zgłoszenie',
          handleClick: showDeleteModal,
        },
      ]
    : isBranch
      ? [
          {
            label: 'Obsłuż zgłoszenie',
            handleClick: showResolveModal,
          },
        ]
      : []

  // LOADING HANDLER
  const loadingHandler = getLoadingHandler(error, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='medium'>
        {userName} / Zmiana danych
      </Typography>
      <Card label='Prośba o zmianę danych członka' actionsButton={actionButtons}>
        <div className='mb-5 grid grid-cols-5 gap-5'>
          <LabelTypography label='Imię i nazwisko' text={userName} />
          <LabelTypography label='Jednostka' text={data?.issuer?.name} />
          <div className='col-span-3' />
          <LabelTypography
            label='Rodzaj zgłoszenia'
            text={data?.type === 'change' ? 'Zmiana danych' : 'Usunięcie danych'}
          />
          <LabelTypography
            label='Powód zgłoszenia'
            text={reasonsList.find((e: any) => e.id === data.reason)?.name}
          />
          <LabelTypography label='Data zmiany danych' text={data?.changeDate} />
          <div className='col-span-3'>
            <LabelTypography label='Komentarz do zmiany' text={data?.description} />
          </div>
        </div>
        <div className='col-span-5 flex justify-center gap-5'>
          <Button label='Powrót' variant='secondary' onClick={() => navigate(-1)} />
        </div>
      </Card>
      <ConfirmDeleteNotifyModal
        isSending={isSending}
        isOpen={isOpenDeleteModal}
        handleDelete={handleDelete}
        handleClose={closeDeleteModal}
      />
      <ResolveNotifyModal
        isSending={isSending}
        isOpen={isOpenResolveModal}
        handleClose={closeResolveModal}
        handleResolve={handleResolve}
      />
    </div>
  )
}

export default MemberNotificationsDetail
