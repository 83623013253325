import React, { useEffect, useMemo, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Modal } from 'components/Atoms/Modal'
import { Pagination } from 'components/Atoms/Pagination'
import SearchMultiselect from 'components/Atoms/SearchMultiselect/SearchMultiselect'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import FormField from 'components/FormField'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportTrainingErrors } from 'error-data/training'
import { getMembersList } from 'fetchers/membersFetchers'
import {
  addMemberToTraining,
  addTrainigFile,
  getTrainingDetails,
  getTrainingMembersList,
  removeMemberFromTraining,
  updateTraining,
} from 'fetchers/trainingFetchers'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'
import { createTrainingSchema } from 'schemas/trainingCreateSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption, IBasicSettingForm } from 'types/form'

// Define allowed MIME types
const ALLOWED_MIME_TYPES = [
  'application/pdf',
  'application/x-pdf',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

// 5MB in bytes
const MAX_FILE_SIZE = 5 * 1024 * 1024

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const TrainingUpdate = () => {
  const [fileIsAdding, setFileIsAdding] = useState(false)
  const [isSendingMember, setIsSendingMember] = useState(false)
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const [memberSearch, setMemberSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState<File>()
  const [fileNames, setFileNames] = useState<Record<string, string>>({}) // Store original file names
  const [fileIds, setFileIds] = useState<string[]>([])
  const [finalFiles, setFinalFiles] = useState<IOption[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isSending, setIsSending] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()
  const cardId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)

  const {
    data: trainingDetails,
    isLoading: isTrainingDetailsLoading,
    error: trainingDetailsError,
    refetch: refetchTrainingDetails,
  } = useQuery({
    queryKey: [userToken, queryKeys.trainingDetails, cardId],
    queryFn: () => getTrainingDetails(cardId),
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createTrainingSchema),
    defaultValues: {
      name: '',
      dateStart: '',
      hourStart: '',
      trainerName: '',
      type: '',
      location: '',
      url: '',
      locationDetails: '',
      description: '',
      files: [],
    },
  })

  const trainingType = watch('type')

  useEffect(() => {
    if (trainingDetails) {
      const files = trainingDetails.files || []
      const fileIds = files.map((file: any) => file.id)

      reset({
        name: trainingDetails.name || '',
        dateStart: trainingDetails.dateStart || '',
        hourStart: trainingDetails.hourStart || '',
        trainerName: trainingDetails.trainerName || '',
        type: trainingDetails.type || '',
        location: trainingDetails.location || '',
        url: trainingDetails.url || '',
        locationDetails: trainingDetails.locationDetails || '',
        description: trainingDetails.description || '',
        files: fileIds,
      })

      // Set fileIds state
      setFileIds(fileIds)

      // Set fileNames state with filename
      const newFileNames: Record<string, string> = {}
      files.forEach((file: any) => {
        newFileNames[file.id] = file.filename
      })
      setFileNames(newFileNames)
    }
  }, [trainingDetails, reset])

  useEffect(() => {
    setFinalFiles(
      fileIds.map((fileId) => ({
        value: fileId,
        label: fileNames[fileId] || fileId, // Use filename if available, else use ID
      }))
    )
  }, [fileIds, fileNames])

  const {
    data: membersList,
    isLoading: isAllMembersListLoading,
    error: allMembersListError,
    refetch: refetchAllMembersList,
  } = useQuery({
    queryKey: [userToken, queryKeys.membersList],
    queryFn: () => getMembersList(1, 100, '&unredacted=true'),
  })

  const allMembersList = membersList?.items || []

  // Function to filter members based on search term
  const getFilteredMembers = () => {
    if (!memberSearch.trim()) {
      return allMembersList
    }

    const searchTerm = memberSearch.toLowerCase()
    return allMembersList.filter((member: any) =>
      `${member.firstName} ${member.lastName}`.toLowerCase().includes(searchTerm)
    )
  }

  const filteredMembers = getFilteredMembers()

  const {
    data: trainingMembersList,
    isLoading: isTrainingMembersListLoading,
    error: trainingMembersListError,
    refetch: refetchTrainingMembersList,
  } = useQuery({
    queryKey: [userToken, queryKeys.trainingMembersList, cardId],
    queryFn: () => getTrainingMembersList(cardId),
  })

  const lastPage = useLastPage(trainingMembersList?.pagination, isTrainingMembersListLoading)
  const generatedExportsList = trainingMembersList?.items || []
  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: any) => {
      const { redacted, status, ...formData } = data
      return updateTraining(cardId, formData)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingCreate] })
      navigate(path.training.list)
      setIsSending(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSending(false)

      if (error.response?.status === 403) {
        // Handle 403 errors silently
        return
      }

      const errorMessages = error.response?.data.errors

      mutationErrorHandler(
        error,
        exportTrainingErrors.create,
        errorMessages?.map((err) => `${err.property}: ${err.error}`).join('\n')
      )
    },
  })

  const onSubmit = (data: any) => {
    setIsSending(true)

    const exportData = {
      name: data.name,
      dateStart: data.dateStart,
      hourStart: data.hourStart,
      trainerName: data.trainerName,
      location: data.type === 'online' ? '' : data.location,
      locationDetails: data.locationDetails,
      type: data.type,
      url: data.type === 'on-site' ? '' : data.url,
      description: data.description,
      files: fileIds,
    }

    mutation.mutate(exportData)
  }

  // Handle member selection
  const handleMemberCheck = (member: IBasicSettingForm) => {
    setSelectedMembers((prev) => {
      if (!member.id) return prev // Exit early if member.id is undefined

      if (prev.includes(member.id)) {
        return prev.filter((id) => id !== member.id)
      } else {
        return [...prev, member.id]
      }
    })
  }

  // Function to handle "Select All" functionality
  const handleSelectAllMembers = () => {
    if (selectedMembers.length === filteredMembers.length) {
      // If all are selected, deselect all
      setSelectedMembers([])
    } else {
      // Select all
      setSelectedMembers(filteredMembers.map((member: any) => member.id))
    }
  }

  const memberMutation = useMutation({
    mutationFn: (memberIds: string[]) => {
      // Convert to an array of promises
      const promises = memberIds.map((memberId) => addMemberToTraining(cardId, memberId))
      return Promise.all(promises)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingAddMemberToList] })
      refetchTrainingMembersList()
      setIsSendingMember(false)
      setSelectedMembers([])
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSendingMember(false)
      const errorMessages = error.response?.data.errors

      mutationErrorHandler(
        error,
        exportTrainingErrors.create,
        errorMessages?.map((err) => `${err.property}: ${err.error}`).join('\n')
      )
    },
  })

  const memberSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSendingMember(true)
    memberMutation.mutate(selectedMembers)
    setIsOpen(false)
  }

  const removeMemberMutation = useMutation({
    mutationFn: (memberId: string) => removeMemberFromTraining(cardId, memberId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingRemoveMember] })
      refetchTrainingMembersList()
      setIsSendingMember(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSendingMember(false)
      const errorMessages = error.response?.data.errors

      mutationErrorHandler(
        error,
        exportTrainingErrors.create,
        errorMessages?.map((err) => `${err.property}: ${err.error}`).join('\n')
      )
    },
  })

  const removeMemberHandler = (memberId: string) => {
    setIsSendingMember(true)
    removeMemberMutation.mutate(memberId)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Imię i nazwisko',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.firstName + ' ' + row.original?.lastName
        },
      },
      {
        Header: 'Numer legitymacji',
        accessor: 'number',
        Cell: ({ row }: any) => {
          return row.original?.card
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className="flex">
              <HoverIcon
                disabled={row.original?.archived}
                iconName="EyeIcon"
                title="Zobacz dane uczestnika"
                onClick={() => {
                  navigate(`/member/${row.original.id}`)
                }}
              />
              <HoverIcon
                iconName="TrashIcon"
                title="Usuń uczestnika"
                onClick={() => {
                  confirm('Czy na pewno chcesz usunąć uczestnika z listy?') &&
                    removeMemberHandler(row.original.id)
                }}
              />
            </div>
          )
        },
      },
    ],
    []
  )

  const filteredFiles = fileIds.map((fileId) => ({
    value: fileId,
    label: fileNames[fileId] || fileId, // Use original filename if available
  }))

  // File validation function
  const validateFile = (file: File | undefined): boolean => {
    if (!file) {
      return false
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      return false
    }

    // Check file type
    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      return false
    }

    return true
  }

  const fileMutation = useMutation({
    mutationFn: (data: File) => {
      return addTrainigFile(data)
    },
    onSuccess: (data) => {
      const fileId = data.id
      const newFile = {
        value: fileId,
        label: file?.name || `Plik ${fileIds.length + 1}`,
      }
      setFileIds((prevFileIds) => [...prevFileIds, fileId])
      setFinalFiles((prevFinalFiles) => [...prevFinalFiles, newFile])
      setFileNames((prev) => ({ ...prev, [fileId]: file?.name || '' }))
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      setFileIsAdding(false)
      setFile(undefined)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      const errorMessages = error.response?.data.errors
        ?.map((err) => `${err.property}: ${err.error}`)
        .join('\n')

      mutationErrorHandler(error, exportTrainingErrors.create, errorMessages)
      setFileIsAdding(false)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined
    setFile(selectedFile)
  }

  const handleAddFile = (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateFile(file)) {
      return
    }
    setFileIsAdding(true)
    if (file) {
      fileMutation.mutate(file)
    }
  }

  if (isTrainingDetailsLoading || isTrainingMembersListLoading || isAllMembersListLoading) {
    return (
      <Card label="Szkolenie">
        <dl className="grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6">
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className="col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3">
                <div className="h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200"></div>
              </dt>
              <dd className="pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none">
                <div className="h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200"></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card label={`Edycja ${trainingDetails?.name}`}>
          <div className="grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4">
            <FormField
              name="name"
              label="Nazwa szkolenia"
              placeholder="Wpisz nazwę szkolenia"
              register={register}
              errors={errors}
              type="text"
            />

            <div className="flex gap-2">
              <div className="flex-1">
                <Controller
                  name="dateStart"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Calendar
                      date={value ? new Date(value) : undefined}
                      handleDate={(val) => {
                        onChange(new Date(val).toISOString().split('T')[0])
                      }}
                      label="Data rozpoczęcia"
                      required={true}
                    />
                  )}
                />
                {errors.dateStart && (
                  <p className="text-sm text-red-500">{errors.dateStart.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="hourStart">
                Godzina szkolenia
                <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                type="time"
                id="hourStart"
                {...register('hourStart')}
                className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              />
              {errors.hourStart && (
                <p className="text-sm text-red-500">{errors.hourStart.message}</p>
              )}
            </div>

            <div>
              <Controller
                name="type"
                control={control}
                defaultValue={trainingDetails?.type}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Tryb szkolenia
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <select
                      className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      <option value="">---</option>
                      <option value="on-site">Stacjonarne</option>
                      <option value="online">Online</option>
                    </select>
                    {errors.type && <p className="text-sm text-red-500">{errors.type.message}</p>}
                  </div>
                )}
              />
            </div>

            <FormField
              name="trainerName"
              label="Prowadzący"
              placeholder="Wpisz imię prowadzącego"
              register={register}
              errors={errors}
              type="text"
            />

            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="location">
                Miejsce szkolenia
                {trainingType === 'on-site' && <span className="ml-1 text-red-500">*</span>}
              </label>
              <input
                id="location"
                {...register('location')}
                className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                disabled={trainingType === 'online'}
              />
              {errors.location && <p className="text-sm text-red-500">{errors.location.message}</p>}
            </div>

            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="url">
                URL
                {trainingType === 'online' && <span className="ml-1 text-red-500">*</span>}
              </label>
              <input
                id="url"
                {...register('url')}
                className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                disabled={trainingType === 'on-site'}
              />
              {errors.url && <p className="text-sm text-red-500">{errors.url.message}</p>}
            </div>

            <div className="md:col-span-2">
              <label
                className="mb-1 block text-sm font-medium text-gray-700"
                htmlFor="locationDetails"
              >
                Szczegóły lokalizacji
                <span className="ml-1 text-red-500">*</span>
              </label>
              <textarea
                id="locationDetails"
                {...register('locationDetails')}
                aria-rowspan={4}
                className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                placeholder="Wpisz szczegóły dot. lokalizacji"
              ></textarea>
              {errors.locationDetails && (
                <p className="text-sm text-red-500">{errors.locationDetails.message}</p>
              )}
            </div>

            <div className="md:col-span-2">
              <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="description">
                Opis szkolenia
                <span className="ml-1 text-red-500">*</span>
              </label>
              <textarea
                id="description"
                {...register('description')}
                aria-rowspan={4}
                className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                placeholder="Opisz szkolenie"
              ></textarea>
              {errors.description && (
                <p className="text-sm text-red-500">{errors.description.message}</p>
              )}
            </div>

            <div className="md:col-span-2">
              <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor="docs">
                Dokumenty
              </label>
              <MultiSelect
                options={filteredFiles}
                value={finalFiles}
                onChange={setFinalFiles}
                hasSelectAll={false}
                className="text-sm"
                overrideStrings={{
                  selectSomeItems: 'Zaznacz dokumenty',
                  allItemsAreSelected: 'Wszystkie dokumenty są zaznaczone',
                  selectAll: 'Zaznacz wszystkie',
                  search: 'Wyszukaj',
                  noOptions: 'Brak dokumentów',
                }}
                labelledBy="Wyszukaj"
              />
            </div>

            <div className="md:col-span-3"></div>
            <div className="md:col-span-2">
              <label className="mb-1 block text-sm font-medium text-gray-700">Dodaj plik</label>
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    ref={fileInputRef}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={handleFileChange}
                  />
                  <Button
                    className="mt-1 flex min-w-[130px] justify-center text-center disabled:cursor-not-allowed"
                    disabled={!file || fileIsAdding}
                    label={
                      fileIsAdding ? (
                        <>
                          Dodaję plik
                          <span className="loader ml-1"></span>
                        </>
                      ) : (
                        'Dodaj plik'
                      )
                    }
                    onClick={handleAddFile}
                  />
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Dozwolone typy plików: PDF, PNG, JPEG, DOC, DOCX. Maksymalny rozmiar: 5MB
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card label="Lista uczestników">
          <div className="flex items-center justify-between gap-4">
            {lastPage && (
              <>
                <div className="flex flex-col justify-end gap-4 md:flex-row">
                  <div className="flex items-center gap-4">
                    <Typography weight="medium" className="text-sm">
                      Liczba wierszy na stronę
                    </Typography>
                    <select
                      className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                      onChange={(e) =>
                        onLimitHandler({ value: Number(e.target.value), label: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>

                <span className="flex flex-col items-center gap-2 md:flex-row md:gap-4">
                  <Button
                    label="Dodaj uczestnika"
                    onClick={(e: any) => {
                      e.preventDefault()
                      setIsOpen(true)
                    }}
                  />
                  {lastPage > 1 && (
                    <Pagination
                      lastPage={lastPage}
                      currentPage={page}
                      handlePageChange={changePage}
                    />
                  )}
                </span>
              </>
            )}
          </div>
          {!isTrainingMembersListLoading && (
            <>
              <Table maxColumnWidth="300px" columns={columns} data={generatedExportsList || []} />
            </>
          )}
          {isTrainingMembersListLoading && <TableSkeleton />}
        </Card>

        <hr className="my-4" />
        <div className="my-4 flex justify-center">
          <Button
            variant="secondary"
            label="Anuluj"
            className="mr-4"
            onClick={(e) => {
              e.preventDefault()
              navigate(path.training.list)
            }}
          />
          <Button
            type="submit"
            label={isSending ? 'Zapisuję zmiany...' : 'Zapisz zmiany'}
            disabled={isSending}
          />
        </div>
      </form>

      <Modal isOpen={isOpen}>
        <Card label="Dodaj uczestników">
          <form onSubmit={memberSubmitHandler} className="mt-2">
            <div className="mb-4">
              <SearchMultiselect
                type="members"
                label="Wyszukaj uczestników"
                isLoading={isAllMembersListLoading}
                options={filteredMembers.map((member: any) => ({
                  id: member.id,
                  name: `${member.firstName} ${member.lastName}`,
                }))}
                inputCheck={selectedMembers}
                handleInputCheck={handleMemberCheck}
                queryHandler={setMemberSearch}
                query={memberSearch}
                resultLabel="Brak pasujących uczestników"
              />
            </div>

            <div className="mb-4">
              <Button
                type="button"
                variant="secondary"
                label={
                  selectedMembers.length === filteredMembers.length
                    ? 'Odznacz wszystkich'
                    : 'Zaznacz wszystkich'
                }
                onClick={handleSelectAllMembers}
                className="w-full"
              />
            </div>

            <div className="mt-4 flex justify-center gap-4">
              <Button
                variant="secondary"
                label="Anuluj"
                onClick={(e) => {
                  e.preventDefault()
                  setIsOpen(false)
                  setSelectedMembers([])
                }}
              />
              <Button
                type="submit"
                label={`Dodaj uczestników (${selectedMembers.length})`}
                disabled={
                  selectedMembers.length === 0 ||
                  isAllMembersListLoading ||
                  isTrainingMembersListLoading ||
                  isSendingMember
                }
                icon={
                  (isAllMembersListLoading || isTrainingMembersListLoading || isSendingMember) && (
                    <span className="loader"></span>
                  )
                }
              />
            </div>
          </form>
        </Card>
      </Modal>
    </>
  )
}

export default TrainingUpdate
