import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import {
  confirmOrderReceived,
  getOrderFile,
  getOrdersReceivedDetails,
} from 'fetchers/ordersFetchers'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'

const OrderReceivedDetails = () => {
  const { id } = useParams()
  const cardId = id as string
  const navigate = useNavigate()
  const [pickedFileID, setPickedFileID] = useState<string>('')
  const [fileURL, setFileURL] = useState<string>('')
  const [downloadFilename, setDownloadFilename] = useState<string>('')

  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const {
    data: orderReceivedDetails,
    isLoading: ordersReceivedLoading,
    refetch: ordersReceivedRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.ordersReceiedDetails],
    queryFn: () => getOrdersReceivedDetails(cardId),
    retry: useRetryHandler({
      resourceName: 'OrderReceivedDetails orderReceivedDetails',
      maxRetries: 1,
    }),
  })

  const { data: receivedDetailsFile, isLoading: isreceivedDetailsFileLoading } = useQuery({
    queryKey: [userToken, queryKeys.orderFileByID, pickedFileID],
    queryFn: () => getOrderFile(pickedFileID),
    enabled: !!pickedFileID,
    retry: useRetryHandler({
      resourceName: 'OrderReceivedDetails receivedDetailsFile',
      maxRetries: 1,
    }),
  })

  useEffect(() => {
    if (receivedDetailsFile && pickedFileID) {
      const fileURL = URL.createObjectURL(
        new Blob([receivedDetailsFile.data], {
          type: receivedDetailsFile.type || 'application/octet-stream',
        })
      )
      setFileURL(fileURL)

      // Get filename from orderReceivedDetails.files
      if (orderReceivedDetails?.files) {
        // Find the file object that matches the selected ID
        const fileInfo = orderReceivedDetails.files.find((file: any) => file.id === pickedFileID)

        if (fileInfo) {
          // Set the downloadFilename to the filename from the file object
          setDownloadFilename(fileInfo.filename)
        } else {
          // If no match is found, use filename from API response or default
          setDownloadFilename(receivedDetailsFile.filename || 'downloaded_file')
        }
      } else {
        // If orderReceivedDetails.files is missing, use filename from API response
        setDownloadFilename(receivedDetailsFile.filename || 'downloaded_file')
      }
    }
  }, [receivedDetailsFile, pickedFileID, orderReceivedDetails])

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = fileURL
    link.download = downloadFilename // Use the extracted filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const mutation = useMutation({
    mutationFn: (id: string) => confirmOrderReceived(id),
    onSuccess: () => {
      // Handle success case
      successToast('Polecenie zostało potwierdzone.')
      ordersReceivedRefetch()
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      // Handle error case
      errorToast('Nie udało się potwierdzić polecenia.')
    },
  })

  const confirm = (id: string) => {
    mutation.mutate(id)
  }

  if (ordersReceivedLoading) {
    return (
      <Card label="Szczegóły otrzymanego polecenia">
        <dl className="grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6">
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className="col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3">
                <div className="h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200"></div>
              </dt>
              <dd className="pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none">
                <div className="h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200"></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  const translatedStatus = (status: boolean) => {
    return status ? 'Tak' : 'Nie'
  }

  return (
    <>
      <Card
        label="Szczegóły otrzymanego polecenia"
        actionsButton={[
          {
            disabled: orderReceivedDetails?.ack,
            label: orderReceivedDetails?.ack ? 'Polecenie potwierdzone' : 'Potwierdź polecenie',
            handleClick: () => confirm(cardId),
          },
        ]}
      >
        <div className="grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4">
          <LabelTypography label="Nazwa polecenia" text={orderReceivedDetails?.name || '-'} />
          <LabelTypography
            label="Data utworzenia polecenia"
            text={
              orderReceivedDetails?.createdAt
                ? orderReceivedDetails?.createdAt.replace('T', ' ').replace('+00:00', '')
                : '-'
            }
          />
          <div className="md:col-span-2">
            <LabelTypography
              label="Pliki"
              text={
                orderReceivedDetails?.files?.length > 0
                  ? orderReceivedDetails?.files.map((file: any) => file.filename).join(', ')
                  : 'Brak plików'
              }
            />{' '}
          </div>
          <div className="md:col-span-2">
            <LabelTypography label="Treść polecenia" text={orderReceivedDetails?.content || '-'} />
          </div>

          <LabelTypography
            label="Nazwa jednostki nadawczej"
            text={orderReceivedDetails?.unit?.name || '-'}
          />
          <LabelTypography
            label="Archiwalne"
            text={translatedStatus(orderReceivedDetails?.archived) || '-'}
          />
          {orderReceivedDetails?.acks?.map((ack: any, index: number) => (
            <React.Fragment key={index}>
              <LabelTypography
                label="Status odczytu"
                text={ack.readAt ? 'Przeczytane' : 'Nieprzeczytane'}
              />
              <LabelTypography
                label="Status potwierdzenia"
                text={ack.ackAt ? 'Potwierdzone' : 'Niepotwierdzone'}
              />
            </React.Fragment>
          ))}
        </div>
      </Card>
      {orderReceivedDetails?.files?.length > 0 && (
        <Card label="Pobierz plik">
          <p className="mb-1 block text-sm font-medium text-gray-700">
            Wybierz plik, który chcesz pobrać
          </p>
          <select
            className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            name=""
            id=""
            onChange={(e) => setPickedFileID(e.target.value)}
          >
            <option value="">Wybierz</option>
            {orderReceivedDetails?.files?.map((file: any) => (
              <option key={file.id} value={file.id}>
                {file.filename}
              </option>
            ))}
          </select>
          {isreceivedDetailsFileLoading && (
            <a className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white opacity-30 shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
              Wczytuję plik <span className="loader"></span>
            </a>
          )}
          {!isreceivedDetailsFileLoading && pickedFileID && fileURL && (
            <a
              href="#"
              onClick={handleDownload}
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Pobierz plik {downloadFilename}
            </a>
          )}
        </Card>
      )}
      <div className="my-4 flex justify-center gap-4">
        <Button label="Powrót" variant="secondary" onClick={() => navigate(path.orders.receive)} />
        <Button
          label={orderReceivedDetails?.ack ? 'Polecenie potwierdzone' : 'Potwierdź polecenie'}
          disabled={orderReceivedDetails?.ack}
          onClick={() => confirm(cardId)}
        />
      </div>
    </>
  )
}

export default OrderReceivedDetails
