import axios from 'axios'

import { processDataWithHtmlDecoding } from 'tools/decodeHtmlEntities'

export const publicBaseApiURL = axios.create({ baseURL: process.env.REACT_APP_PUBLIC_API_URL })
export const secureBaseApiURL = axios.create({
  baseURL: process.env.REACT_APP_SECURE_API_URL,
})

// Add response interceptor to automatically decode HTML entities
publicBaseApiURL.interceptors.response.use((response) => {
  if (response.data) {
    response.data = processDataWithHtmlDecoding(response.data)
  }
  return response
})

secureBaseApiURL.interceptors.response.use((response) => {
  if (response.data) {
    response.data = processDataWithHtmlDecoding(response.data)
  }
  return response
})
