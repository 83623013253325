import React, { useEffect, useMemo } from 'react'

import { jwtDecode } from 'jwt-decode'
import qs from 'qs'
import { useSWRConfig } from 'swr'

import { publicBaseApiURL, secureBaseApiURL } from 'api'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { endpoint } from 'constants/endpoints'
import { fetchUserContexts, fetchUserData } from 'features/auth/authActions'

type Props = {
  children: React.ReactNode
}

const AuthWrapper = ({ children }: Props): JSX.Element => {
  const auth = useAppSelector((state) => state.authReducer)
  const dispatch = useAppDispatch()
  const { mutate } = useSWRConfig()
  const querystring = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  let token = ''
  const user = localStorage.getItem('userInfo')
  const url = encodeURIComponent(process.env.REACT_APP_LOGIN_URL as string)

  if (auth.userToken) {
    token = auth.userToken
  } else {
    token = localStorage.getItem('token') || ''
  }

  const tokenChecker = (token: any) => {
    if (typeof token === 'string') {
      return token.slice(-5)
    }

    return token
  }

  // console.info('TokenOutside____', tokenChecker(token))

  useMemo(() => {
    publicBaseApiURL.interceptors.request.use((config) => {
      const t = localStorage.getItem('token')
      config.headers['Content-Type'] = 'application/json'
      config.headers['X-Auth-Token'] = `Bearer ${t}`
      config.headers['Accept'] = 'application/json'
      return config
    })

    publicBaseApiURL.interceptors.response.use((response) => {
      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')

        // if (window.location.href !== '/postlogin') {
        //   window.location.href = '/postlogin'
        // }
      }

      return response
    })

    secureBaseApiURL.interceptors.request.use((config) => {
      const t = localStorage.getItem('token')
      // config.headers['Content-Type'] = 'application/json'
      config.headers['X-Auth-Token'] = `Bearer ${t}`
      config.headers['Accept'] = 'application/json'
      return config
    })

    secureBaseApiURL.interceptors.response.use((response) => {
      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')

        // if (window.location.href !== '/postlogin') {
        //   window.location.href = '/postlogin'
        // }
      }

      return response
    })
  }, [token])

  const authUrl = process.env.REACT_APP_AUTH_URL || ''

  const handleLogin = () => {
    // Make sure we have a valid URL to redirect to
    if (authUrl) {
      window.location.href = authUrl
    } else {
      console.error(
        'Login URL is not configured properly. Please check REACT_APP_LOGIN_URL environment variable.'
      )
      // You may want to show an error message to the user here
    }

    if (querystring.token !== undefined) {
      localStorage.setItem('token', `${querystring.token}`)
      localStorage.setItem('userInfo', '{}')
    }
  }

  useEffect(() => {
    if (querystring.token !== undefined) {
      localStorage.setItem('token', `${querystring.token}`)
      localStorage.setItem('userInfo', '{}')
      const decoded = jwtDecode(querystring.token as string)
      if (decoded.exp && decoded.exp < Date.now() / 1000) {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
      } else {
        dispatch(fetchUserData(token))
        dispatch(fetchUserContexts(token))
      }
      window.location.href = '/'
    }

    if (token && user) {
      const decoded = jwtDecode(token)
      if (decoded.exp && decoded.exp < Date.now() / 1000) {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
      } else {
        dispatch(fetchUserData(token))
        dispatch(fetchUserContexts(token))
      }
    }
  }, [])

  //   Whenever token changes / eg. after changing context refetch user data
  useEffect(() => {
    if (auth.userToken) {
      console.info('Token changed', tokenChecker(auth.userToken))
    }

    if (auth.userToken) {
      dispatch(fetchUserData(auth.userToken))
      dispatch(fetchUserContexts(auth.userToken))
      localStorage.setItem('token', `${auth.userToken}`)
      mutate(endpoint.structure.regions(1, 100))
    }
  }, [auth.userToken])

  useEffect(() => {
    if (auth.userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(auth.userInfo))
    }
  }, [auth.userInfo])

  // useEffect(() => {
  //   console.info('Local', tokenChecker(localStorage.getItem('token')))
  // })

  if (!user && !token) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Button onClick={handleLogin} label="login" />
      </div>
    )
  }

  return <>{children}</>
}

export default AuthWrapper
